import React from 'react';
import { AlignCenterOutlined } from '@ant-design/icons';
import {Features} from 'components/Features';

const featuresSettings = {
    name: 'features',
    parent: 'Settings',
    pageTitle: 'Features items',
    globalSearch: false,
    menuIcon: AlignCenterOutlined,
    ListComponent: Features
};

export default featuresSettings;
