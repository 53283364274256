import _uniq from 'lodash/uniq';
import { Category } from 'graphql/types';
import { getIds } from 'utils/getData';
import { getDynamicModelsPayload } from '../DynamicFieldsComponent/helpers';

export const getUpdateCategoryPayload = (category: Category) => {
  const {
    title,
    shortDescription,
    longDescription,
    icon,
    image,
    skills,
    color,
    active,
    dynamic,
    seoCustom,
    slug,
    slugCustom,
    fontAwesomeIcon,
  } = category;

  return {
    title,
    shortDescription,
    longDescription,
    icon,
    image,
    color,
    active,
    seoCustom,
    skills: skills ? getIds(skills as any) : [],
    dynamic: dynamic ? getDynamicModelsPayload(dynamic as any) : [],
    slug: slugCustom ? slug : (undefined as any),
    slugCustom,
    fontAwesomeIcon,
  };
};

export const getUpdateSortScreenSizePayload = (
  sortCategories: Category[][]
) => {
  return sortCategories.map(categories => {
    return categories.map(category => {
      return {
        id: category.id,
        itemsCountTotal: category.sortItemsCountTotal,
        itemsCountVisible: category.sortItemsCountVisible,
        items: _uniq((category.items || []).map(({ id }) => id)),
      };
    });
  });
};
