export const collectTags = ({ filters, sort, query }) => {
  const chosenFilters: { name: string; value: string; parent?: string }[] = [];
  Object.entries(query).forEach(([queryKey, queryValue]) => {
    filters.find(filter => {
      if (filter.items) {
        const ff = filter.items.find(
          f => f.value === queryKey && f.status === queryValue
        );
        if (!ff) return false;
        chosenFilters.push({
          name: ff.name,
          value: ff.value,
        });
        return true;
      }
      if (filter.variants && queryKey === filter.value) {
        chosenFilters.push({
          name: filter.name,
          value: filter.value,
        });
        return true;
      }

      if (filter.value !== queryKey) return false;
      (queryValue as string).split(',').forEach(ffValue => {
        chosenFilters.push({
          name: ffValue.includes('~') ? ffValue.split('~')[0] : filter.name,
          value: ffValue,
          parent: queryKey,
        });
      });
      return true;
    });
  });

  const chosenSort = (sort || []).find(ss => ss.value === query.sort);
  const sortDirection =
    chosenSort &&
    chosenSort.variants.find(
      ({ direction }) => direction === query.sortDirection
    );

  return {
    filters: chosenFilters,
    sort: chosenSort ? `${chosenSort.name} : ${sortDirection.name}` : '',
  };
};
