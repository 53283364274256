import React from 'react';
import {FontAwesomeIcon as IconProps} from 'graphql/types';
import { findIconDefinition, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup } from '@fortawesome/pro-thin-svg-icons';

type Props = {
    icon: IconProps;
};

export const AwesomeIcon = ({icon}: Props) => {

    const coffeeIconDefinition: IconDefinition = findIconDefinition({
        prefix: icon?.prefix || 'fas',
        iconName: icon?.name || 'ban',
    } as IconLookup);

    return <FontAwesomeIcon icon={coffeeIconDefinition} color={icon.color as string || '#000'} style={{width: 30, height: 30}} />;
};
