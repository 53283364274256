import React, { useState } from 'react';
import { List, Input, Button, Modal, Select } from 'antd';
import {
    useGetAdsQuery,
    useCreateAdMutation,
    useUpdateAdMutation,
    useDeleteAdMutation
} from 'graphql/types';
import styles from './Ads.module.scss';

const { Option } = Select;

const Ads: React.FC = () => {
    const { data, loading, refetch } = useGetAdsQuery();
    const [createAd] = useCreateAdMutation();
    const [updateAd] = useUpdateAdMutation();
    const [deleteAd] = useDeleteAdMutation();

    const [newAdBackground, setNewAdBackground] = useState<string>('');
    const [newAdLink, setNewAdLink] = useState<string>('');
    const [newAdAlign, setNewAdAlign] = useState<string>('left');
    const [newAdActionColor, setNewAdActionColor] = useState<string>('red');

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentAdId, setCurrentAdId] = useState<string | null>(null);

    const handleCreateOrUpdateAd = async () => {
        const input = { background: newAdBackground, link: newAdLink, align: newAdAlign, actionColor: newAdActionColor };
        
        if (currentAdId) {
            await updateAd({ variables: { id: currentAdId, input } });
        } else {
            await createAd({ variables: { input } });
        }

        setIsModalVisible(false);
        setNewAdBackground('');
        setNewAdLink('');
        setNewAdAlign('left');
        setNewAdActionColor('red');
        setCurrentAdId(null);
        refetch();
    };

    const handleDeleteAd = async (id: string) => {
        await deleteAd({ variables: { id } });
        refetch();
    };

    const handleEditAd = (ad: any) => {
        setCurrentAdId(ad.id);
        setNewAdBackground(ad.background);
        setNewAdLink(ad.link);
        setNewAdAlign(ad.align);
        setNewAdActionColor(ad.actionColor);
        setIsModalVisible(true);
    };

    if (loading) return <p>Loading...</p>;

    const isGif = (url: string) => url.toLowerCase().endsWith('.gif');

    const handleImageUrlChange = (value: string) => {
        // Check if URL starts with "http://" or "https://", if not, append "https://"
        if (!value.startsWith('http://') && !value.startsWith('https://')) {
            value = `https://${value}`;
        }
        setNewAdBackground(value);
    };

    return (
        <div className={styles.adsContainer}>
            <Button type="primary" onClick={() => {
                setIsModalVisible(true);
                setCurrentAdId(null);
                setNewAdBackground('');
                setNewAdLink('');
                setNewAdAlign('left');
                setNewAdActionColor('red');
            }} className={styles.createButton}>
                Create Ad
            </Button>
            <List
                itemLayout="horizontal"
                dataSource={data?.ads}
                renderItem={(ad: any) => (
                    <List.Item
                        actions={[
                            <Button key="edit" onClick={() => handleEditAd(ad)}>Edit</Button>,
                            <Button key="delete" onClick={() => handleDeleteAd(ad.id)}>Delete</Button>
                        ]}
                    >
                        <div className={styles.adItem}>
                            <div className={styles.adPreview}>
                                <a href={ad.link} target="_blank" rel="noopener noreferrer" className={styles.linkDisabled}>
                                    <img
                                        src={ad.background}
                                        alt="ad background"
                                        className={`${styles.adBackground} ${isGif(ad.background) ? styles.gif : ''}`}
                                        style={{ width: '86px', height: '86px' }}
                                    />
                                </a>
                            </div>
                            <div className={styles.adInfo}>
                                <span className={styles.adLink}>{ad.link}</span>
                            </div>
                        </div>
                    </List.Item>
                )}
            />
            <Modal
                title={currentAdId ? 'Edit Ad' : 'Create Ad'}
                visible={isModalVisible}
                onOk={handleCreateOrUpdateAd}
                onCancel={() => {
                    setIsModalVisible(false);
                    setCurrentAdId(null);
                }}
                destroyOnClose
            >
                <Input value={newAdBackground} onChange={e => handleImageUrlChange(e.target.value)} placeholder="Ad background" />
                <Input value={newAdLink} onChange={e => setNewAdLink(e.target.value)} placeholder="Ad link" />
                <Select value={newAdAlign} onChange={value => setNewAdAlign(value)} placeholder="Select alignment">
                    <Option value="left">Left</Option>
                    <Option value="right">Right</Option>
                    <Option value="center">Center</Option>
                </Select>
                <Select value={newAdActionColor} onChange={value => setNewAdActionColor(value)} placeholder="Select action color">
                    <Option value="red">Red</Option>
                    <Option value="blue">Blue</Option>
                    <Option value="green">Green</Option>
                    <Option value="yellow">Yellow</Option>
                    <Option value="purple">Purple</Option>
                    <Option value="orange">Orange</Option>
                    <Option value="pink">Pink</Option>
                </Select>
            </Modal>
        </div>
    );
};

export default Ads;

