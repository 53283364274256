import { baseApi } from './baseApi';
import {
  SearchData,
  SearchArg,
  LoadAllIconsData,
  IconLight,
  GetIconByIdData,
  GetIconByIdArg,
} from '../types';

export const searchApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    search: builder.query<SearchData, SearchArg>({
      queryFn: async ({ page, limit, search }, { dispatch, getState }) => {
        try {
          const store = getState() as never;
          const cached = baseApi.endpoints.loadAllIcons.select()(store);
          let allIcons: LoadAllIconsData = cached.data || [];

          if (allIcons.length === 0) {
            const response = await dispatch(
              baseApi.endpoints.loadAllIcons.initiate()
            );
            allIcons = response.data || [];
          }

          const foundIcons = await searchIcons(allIcons, search);
          const {
            data,
            totalPagesCount,
            page: currentPage,
            totalRecordsCount,
          } = getPaginatedData({
            rawData: foundIcons,
            limit,
            page,
          });

          return {
            data: {
              data,
              totalPagesCount,
              currentPage,
              limit,
              totalRecordsCount,
            },
          };
        } catch (e) {
          const error = e as Error;
          return { error };
        }
      },
    }),
    getIconById: builder.query<GetIconByIdData, GetIconByIdArg>({
      queryFn: async ({ id }, { dispatch, getState }) => {
        try {
          const store = getState() as never;
          const cached = baseApi.endpoints.loadAllIcons.select()(store);
          let allIcons: LoadAllIconsData = cached.data || [];

          if (allIcons.length === 0) {
            const response = await dispatch(
              baseApi.endpoints.loadAllIcons.initiate()
            );
            allIcons = response.data || [];
          }

          const foundIcons = allIcons.find(el => el.id === id) || null;

          return {
            data: foundIcons,
          };
        } catch (e) {
          const error = e as Error;
          return { error };
        }
      },
    }),
  }),
});

const searchIcons = async <T extends IconLight>(
  icons: T[],
  query: string
): Promise<T[]> => {
  const { default: Fuse } = await import('fuse.js');
  const fuse = new Fuse(icons, { keys: ['label', 'id'] });

  if (query === '') {
    return icons;
  }
  return fuse.search(query).map(el => el.item);
};

export const getPaginatedData = <T>(arg: {
  rawData: T[];
  limit: number;
  page: number;
}) => {
  const { rawData, limit, page } = arg;
  const totalPagesCount = Math.ceil(rawData.length / limit);
  const data = rawData.slice((page - 1) * limit, page * limit);

  return {
    totalPagesCount,
    data,
    limit,
    page,
    totalRecordsCount: rawData.length,
  };
};

export const {
  useGetIconByIdQuery,
  useLazyGetIconByIdQuery,
  useLazySearchQuery,
  useSearchQuery,
  usePrefetch,
} = searchApi;
