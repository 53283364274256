/*eslint-disable*/
import { Card } from 'antd';
import React, { FC, useEffect, useRef } from 'react';
import { Pie } from '@antv/g2plot';
import '../analytics-style.css';

type Props = {
  title: string;
  data: Map<string, number>;
};

const getData = (
  data: Map<string, number>
): Array<{ type: string; value: number }> => {
  const result = [];
  for (const key in data) {
    // @ts-ignore
    result.push({ type: key, value: data[key] });
  }
  return result;
};

export const Doughnut: FC<Props> = ({ title, data }) => {
  const id = `pie-${title.toLowerCase().replaceAll(/\s/g, '')}`;
  const pie = useRef<Pie>(null);

  useEffect(() => {
    //@ts-ignore
    pie.current = new Pie(id, {
      appendPadding: 10,
      data: getData(data),
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: 'inner',
        offset: '-50%',
        content: '',
        style: {
          textAlign: 'center',
          fontSize: 14,
        },
      },
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          content: '',
        },
      },
    });
    pie.current.render();
  }, []);

  return (
    <div className={'cards card-doughnut'}>
      <Card
        style={{ width: '100%' }}
        title={title}
        bordered={false}
        className={'card-table'}
      >
        <div id={id} />
      </Card>
    </div>
  );
};

// https://reactchartjs.github.io/react-chartjs-2/#/doughnut
