import * as Types from 'graphql/types';

export const noopHook = () => [() => {}, {}];
export const modelFieldsName = (model: string) => `${model}Fields`;
export const mutationFieldsName = (model: string) =>
  `toggle${model.charAt(0).toUpperCase()}${model.slice(1)}Fields`;
export const fetchHookName = (model: string) =>
  `use${model.charAt(0).toUpperCase()}${model.slice(1)}FieldsLazyQuery`;
export const mutationHookName = (model: string) =>
  `useToggle${model.charAt(0).toUpperCase()}${model.slice(1)}FieldsMutation`;

export const fetchLazyHook = (model: string) => {
  return Types[fetchHookName(model)] || noopHook;
};
export const mutationHook = (model: string) =>
  Types[mutationHookName(model)] || noopHook;
