import {
  DynamicData,
  DynamicFieldModel,
  DynamicFieldType,
  DynamicModel,
  DynamicVariant,
} from 'graphql/types';

export class DynamicDataParser {
  private dynamicData: Array<DynamicData>;
  private models: Array<DynamicModel>;

  constructor(dynamicData: Array<DynamicData>, models: Array<DynamicModel>) {
    this.dynamicData = dynamicData;
    this.models = models;
  }

  fieldToInitialData = (field: DynamicFieldModel): any => {
    if (field.isArray) {
      return [this.getVariantInitialData(field.type)];
    }
    return [this.getVariantInitialData(field.type)];
  };

  getSelectedId = (model: DynamicModel): string => {
    return this.dynamicData.find(x => x.model.id === model.id)?.data || '';
  };

  getPrimaryField = (fields: DynamicFieldModel[]) => {
    return (
      fields.find(({ primaryField, isArray }) => primaryField && !isArray) ||
      ({} as DynamicFieldModel)
    );
  };

  getPrimaryValue = (
    model: DynamicModel,
    variant: DynamicVariant | DynamicVariant[]
  ): any => {
    const field = model.fields.find(x => x.primaryField && !x.isArray);
    if (!field) return (variant as DynamicVariant).id;

    if (variant instanceof Array) {
      return (variant as DynamicVariant[]).map(({ value }) => {
        const parsedValue = JSON.parse(value);
        return parsedValue[field.name] || parsedValue.id;
      });
    }
    const data = JSON.parse((variant as DynamicVariant).value);
    return data[field.name] || (variant as DynamicVariant).id;
  };

  changeData = (
    model: DynamicModel,
    field?: DynamicFieldModel,
    value?: any,
    index?: number
  ): Array<DynamicData> => {
    const data: Array<DynamicData> = JSON.parse(
      JSON.stringify(this.dynamicData)
    );
    if (data.findIndex(x => x.model.id === model.id) === -1) {
      data.push({ model: { ...model }, data: JSON.stringify({}) });
    }
    if (field && field.isArray && index !== undefined) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].model.id === model.id) {
          const fieldData = this.getFieldData(model, field!);
          fieldData[index!] =
            field!.type === DynamicFieldType.Number ? Number(value) : value;
          data[i].data = JSON.stringify({
            ...JSON.parse(data[i].data),
            [field!.name]: fieldData,
          });
        }
      }
    } else {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].model.id === model.id) {
          data[i].data = !field
            ? value
            : JSON.stringify({
                ...JSON.parse(data[i].data),
                [field.name]:
                  field.type === DynamicFieldType.Number
                    ? Number(value)
                    : value,
              });
        }
      }
    }

    return data;
  };

  removeFromArrayAndReturnDataList = (
    field: DynamicFieldModel,
    model: DynamicModel,
    index: number
  ): Array<DynamicData> => {
    const data: Array<DynamicData> = JSON.parse(
      JSON.stringify(this.dynamicData)
    );
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].model.id === model.id) {
        const fieldData = this.getFieldData(model, field);
        fieldData.splice(index, 1);
        data[i].data = JSON.stringify({
          ...JSON.parse(data[i].data),
          [field.name]: fieldData,
        });
      }
    }
    return data;
  };

  addItemToArrayAndReturnDataList = (
    field: DynamicFieldModel,
    model: DynamicModel
  ): Array<DynamicData> => {
    const data: Array<DynamicData> = JSON.parse(
      JSON.stringify(this.dynamicData)
    );
    if (data.findIndex(x => x.model.id === model.id) === -1) {
      data.push({
        model: { ...model },
        data: JSON.stringify({ [field.name]: this.fieldToInitialData(field) }),
      });
    } else {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].model.id === model.id) {
          let fieldData = this.getFieldData(model, field);
          if (!fieldData) {
            fieldData = [];
          }

          fieldData.push(this.getVariantInitialData(field.type));
          data[i].data = JSON.stringify({
            ...JSON.parse(data[i].data),
            [field.name]: fieldData,
          });
        }
      }
    }
    return data;
  };

  getFieldData = (
    model: DynamicModel,
    field?: DynamicFieldModel,
    index?: number
  ): any => {
    if (!field) {
      const { data } =
        this.dynamicData.find(x => x.model.id === model.id) || {};
      return data ? JSON.parse(data) : '';
    }
    const data = JSON.parse(
      this.dynamicData.find(x => x.model.id === model.id)?.data ||
        JSON.stringify({})
    )[field!.name];

    if (index || (index == 0 && data instanceof Array)) return data[index];
    return data;
  };

  private getVariantInitialData = (type: DynamicFieldType): any => {
    switch (type) {
      case DynamicFieldType.Boolean:
        return false;
      case DynamicFieldType.Number:
        return 0;
      case DynamicFieldType.String:
      case DynamicFieldType.Image:
        return '';
      default:
        return null;
    }
  };
}
