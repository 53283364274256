import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { initializeApollo } from 'utils/apollo';
import { UserProvider } from 'hooks/useUser';
import { Main } from 'nativeComponents/Main/Main';
import { StaffRecoveryPage } from 'components/StaffRecoveryPage/StaffRecoveryPage';
import { SecretStaff } from 'components/SecretStaff/SecretStaff';
import { WebWorkerProvider } from 'hooks/WebWorkerProvider/WebWorkerProvider';
import 'react-quill/dist/quill.snow.css';
import { ConfigProvider } from './hooks/ConfigProvider/useConfig';

function App() {
  const client = initializeApollo();
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <WebWorkerProvider>
          <UserProvider>
            <ConfigProvider>
              <Switch>
                <Route
                  path="/staff/recovery"
                  exact
                  component={StaffRecoveryPage}
                />
                <Route path="/secret-staff" exact component={SecretStaff} />
                <Route component={Main} />
              </Switch>
            </ConfigProvider>
          </UserProvider>
        </WebWorkerProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
