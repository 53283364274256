export enum CategoryFields {
  ShortDescription = 'shortDescription',
  LongDescription = 'longDescription',
  Icon = 'icon',
  Image = 'image',
  Skills = 'skills',
  Color = 'color',
  DynamicEntity = 'Category',
}

export enum CompanyFields {
  Icon = 'icon',
  Link = 'link',
  Social = 'social',
  Seo = 'seo',
}

export enum ItemFields {
  ShortDescription = 'shortDescription',
  LongDescription = 'longDescription',
  Free = 'free',
  Icon = 'icon',
  Images = 'images',
  Platform = 'platform',
  Company = 'company',
  Skills = 'skills',
  Categories = 'categories',
  Link = 'link',
  Price = 'price',
  Reviews = 'reviews',
  DynamicEntity = 'Item',
}

export enum SkillFields {
  Icon = 'icon',
}

export enum PageFields {
  DynamicEntity = 'Pages',
}

export enum BlogFields {
  DynamicEntity = 'Blog',
}

export enum AuthorFields {
  DynamicEntity = 'Author',
}
