import { ConfigStateType } from 'type';

export enum SITE_NAMES {
  Fetishsites = 'Fetishsites',
  Pornsites = 'Pornsites',
  Travelsites = 'Travelsites',
  Streamingsites = 'Streamingsites',
  Manytools = 'Manytools',
  ManytoolsAi = 'ManytoolsAi',
  Vpnsites = 'Vpnsites',
  Awesome = 'Awesome',
  Hentaisites = 'Hentaisites',
  Makemoneywithporn = 'Makemoneywithporn'
}

export const getSiteSettings = (): ConfigStateType['site'] => {
  const { href: pathname } = window.location;
  switch (true) {
    case pathname.includes('makemoneywithporn'):
      return {
        name: SITE_NAMES.Makemoneywithporn,
        icon: 'https://makemoneywithporn.com/images/breadcrumbs/homeIcon.png',
      };
    case pathname.includes('fetishpornsites'):
      return {
        name: SITE_NAMES.Fetishsites,
        icon: 'https://fetishpornsites.com/images/breadcrumbs/homeIcon.png',
      };
    case pathname.includes('pornsites'):
      return {
        name: SITE_NAMES.Pornsites,
        icon: 'https://pornsites.com/pornsites-logo.ico',
      };
    case pathname.includes('travelsites'):
      return {
        name: SITE_NAMES.Travelsites,
        icon: 'https://travelsites.com/wp-content/themes/travel/img/favicon/favicon-32x32.png',
      };
    case pathname.includes('streamingsites'):
      return {
        name: SITE_NAMES.Streamingsites,
        icon: 'https://streamingsites.com/wp-content/uploads/2020/04/cropped-StreamingSites-favicon-512-mod1-1-32x32.png',
      };
    case pathname.includes('manytools.ai'):
      return {
        name: SITE_NAMES.ManytoolsAi,
        icon: 'https://cdn-icons-png.flaticon.com/512/826/826118.png',
      };
    case pathname.includes('manytools'):
      return {
        name: SITE_NAMES.Manytools,
        icon: 'https://manytools.com/favicon.ico',
      };
    case pathname.includes('hentaisites'):
      return {
        name: SITE_NAMES.Hentaisites,
        icon: 'https://hentaisites.com/favicon.png'
      }
    case pathname.includes('vpnsites'):
      return {
        name: SITE_NAMES.Vpnsites,
        icon: 'https://vpnsites.com/favicon.ico',
      };

    default:
      return {
        name: SITE_NAMES.ManytoolsAi,
        icon: 'https://manytools.com/favicon.ico',
      };
  }
};
