import { useEffect, useState } from 'react';
import classnames from 'classnames';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { UIImagePlaceholder } from 'nativeComponents/UI/UIImagePlaceholder/UIImagePlaceholder';
import { getIconValue } from 'utils/getData';
import { Typography, message, Checkbox } from 'antd';
import {
  Item,
  ScreenSizeType,
  useGetSortCategoriesSizeLazyQuery,
} from 'graphql/types';
import { niceTitle } from 'utils/string';

export const ItemsList = ({ categoryId }) => {
  const [screenType, setScreenType] = useState<ScreenSizeType>(
    ScreenSizeType.Desktop
  );
  const [items, setItems] = useState<Item[]>([]);

  const [fetchItems, { loading }] = useGetSortCategoriesSizeLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ screenSizeV5 }) => {
      setItems(_get(screenSizeV5, '[0][0].items', []));
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const handleChangeType = (type: ScreenSizeType) => () => setScreenType(type);

  useEffect(() => {
    if (!categoryId) return;
    fetchItems({
      variables: {
        type: screenType,
        onlyIds: [categoryId],
      },
    });
  }, [screenType, categoryId]);

  if (!items.length) return null;
  return (
    <div className="editSideContent__item flex-column">
      <div>
        {Object.values(ScreenSizeType)
          .reverse()
          .map(type => (
            <Checkbox
              key={type}
              onChange={handleChangeType(type)}
              checked={screenType === type}
            >
              {niceTitle(type)}
            </Checkbox>
          ))}
      </div>
      <div
        className={classnames('pt-4', {
          pending: loading,
        })}
      >
        {items.map((item, i) => (
          <Link
            key={item.id}
            className={`flex align-center ${i !== 0 ? 'mt-2' : ''}`}
            to={`/item/edit?id=${item.id}`}
          >
            <UIImagePlaceholder
              src={getIconValue(item.icon)}
              round
              size={30}
              height={30}
              className="mr-2"
            />
            <Typography.Title level={5}>{item.title}</Typography.Title>
          </Link>
        ))}
      </div>
    </div>
  );
};
