import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import _noop from 'lodash/noop';
import { Select, message } from 'antd';
import { tagRender } from './TagRender';
import {
  getSearchHandler,
  getConstructedOptions,
  SEARCH_SELECT_TYPES,
} from './helpers';

export type OptionType = {
  title?: string;
  label?: string;
  id: string;
};

type Props = {
  mode?: 'multiple' | 'tags' | undefined;
  allowClear?: boolean;
  placeholder?: string;
  name: SEARCH_SELECT_TYPES;
  onChange?: (d: OptionType | OptionType[]) => void;
  onSearch?: (s: string) => void;
  onBlur?: () => void;
  fetchCallback?: (items: any) => void;
  values: { title: string; id: string }[];
  optionPickKey?: string;
};

export const SearchSelect: React.FC<Props> = ({
  mode,
  placeholder,
  onChange = _noop,
  fetchCallback = _noop,
  values = [],
  name,
  optionPickKey = 'title',
}) => {
  const [search, setSearch] = useState('');
  const [state, setState] = useState({
    loading: false,
    error: '',
  });
  const drag = useRef<number | null>(null);
  const dragNode = useRef<HTMLDivElement | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [collection, setCollection] = useState(values);

  const getCollectionList = async (search: string) => {
    const handler = getSearchHandler(name) as any;
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await handler({ page: 1, limit: 1000, search }, true);
      fetchCallback(data);
      setCollection(data);
    } catch (e) {
      console.log(e);
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSearchChange = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    getCollectionList('');
  }, []);

  const documentDragPreventDefault = (e: DragEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener('dragover', documentDragPreventDefault);
    return () => {
      document.removeEventListener('dragover', documentDragPreventDefault);
    };
  }, [values]);

  /* eslint-disable */
  const handleChange = (value: string[] | null, option: OptionType) => {
    const editedOption = { title: option.title || option.label, id: option.id };
    const valueExist = values.find(
      item => !!item && item.id === editedOption.id
    );
    const resultOptions =
      mode == 'multiple'
        ? [...values, editedOption]
        : valueExist
        ? []
        : [editedOption];
    onChange(resultOptions.filter(item => !!item && !!item.id));
  };
  const handleDeselect = (value: string, option: any) => {
    onChange(
      values.filter(item => item.id !== option.id).filter(({ id }) => !!id)
    );
  };

  const handleDragEnd = (e: any) => {
    dragNode.current?.removeEventListener('dragend', handleDragEnd);
    drag.current = null;
    dragNode.current = null;
    setDragging(false);
  };
  const handleDragStart = (e: ChangeEvent<HTMLDivElement>, index: number) => {
    drag.current = index;
    dragNode.current = e.target;
    dragNode.current.addEventListener('dragend', handleDragEnd);
    setTimeout(() => {
      setDragging(true);
    }, 0);
  };
  const handleDragEnter = (e: any, index: number) => {
    if (e.target === dragNode.current) return;
    const newList: Array<string> = JSON.parse(JSON.stringify(values));
    newList.splice(index, 0, newList.splice(drag.current!, 1)[0]);
    onChange(newList);
  };

  const onTagDelete = (tagId: string) => {
    const updatedValues = values.filter(({ id }) => id !== tagId);
    onChange(updatedValues);
  };

  const optionRender = params => {
    let index;
    let tagId;
    collection.find(({ title, id }, i) => {
      if (title === params.label) {
        index = i;
        tagId = id;
        return true;
      }
      return false;
    });
    const props = {
      index,
      drag,
      handleDragStart,
      handleDragEnter,
      onTagDelete,
      tagId,
      dragging,
      ...params,
    };
    return tagRender(props);
  };

  const filteredOptions = getConstructedOptions(
    collection,
    optionPickKey,
    search
  );

  const searchValue = values.filter(item => !!item).map(({ title }) => title);
  return (
    <Select
      loading={state.loading}
      disabled={state.loading}
      mode={mode}
      style={{ width: '100%' }}
      placeholder={placeholder}
      // @ts-ignore
      onSelect={handleChange}
      onDeselect={handleDeselect}
      onSearch={handleSearchChange}
      value={searchValue}
      onFocus={() => handleSearchChange('')}
      filterOption={false}
      tagRender={mode === 'multiple' ? optionRender : undefined}
      showSearch
      options={filteredOptions}
    />
  );
};
