import React from 'react';
import classnames from 'classnames';
import s from './styles.module.css';

export type ImagePlaceholderProps = {
  src: string;
  ratio?: number;
  alt?: string;
  className?: string;
  size?: number;
  height?: number;
  round?: boolean;
};

export const UIImagePlaceholder = ({
  src,
  alt,
  ratio = 0.55,
  className = '',
  size = 50,
  height,
  round,
}: ImagePlaceholderProps) => {
  const styles: any = {
    paddingTop: `${ratio * 100}%`,
    width: size,
    maxWidth: size,
    minWidth: size,
  };
  if (height) {
    styles.paddingTop = '';
    styles.height = height;
    styles.maxHeight = height;
  }
  return (
    <div
      className={classnames('UI__imagePlaceholder', className, {
        [s.placeholder]: true,
        [s.avatar]: round,
      })}
      style={styles}
    >
      <img src={src || '/images/imagePlaceholder.png'} alt={alt} />
    </div>
  );
};
