import React from 'react';
import { Drawer } from 'antd';
import classnames from 'classnames';
import { VariantFilter } from '../VariantFilter/VariantFilter';
import { SearchFilter } from '../SearchFilter/SearchFilter';

import s from './styles.module.css';

export const MoreFilters = ({ visible, onClose, extendFilters, apply }) => {
  const getFilter = item => {
    switch (true) {
      case !!item.variants:
        return <VariantFilter {...item} apply={apply} key={item.name} />;
      case item.type === 'search':
        return <SearchFilter {...item} apply={apply} key={item.name} />;

      default:
        return null;
    }
  };

  return (
    <Drawer
      visible={visible}
      mask={false}
      onClose={onClose}
      destroyOnClose
      title="More filters"
      className={classnames({
        [s.drawer]: visible,
      })}
      headerStyle={{
        borderBottom: 'none',
      }}
      bodyStyle={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 0,
      }}
    >
      {extendFilters.map(item => getFilter(item))}
    </Drawer>
  );
};
