import { Card } from 'antd';

export const EditCard = ({ children }) => (
  <Card
    bodyStyle={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0,
    }}
  >
    {children}
  </Card>
);
