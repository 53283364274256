import classNames from 'classnames';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import 'assets/bootstrap/_pagination.scss';

export interface CustomPaginationProps
  extends Partial<
    Omit<ReactPaginateProps, 'forcePage' | 'onPageChange' | 'pageCount'>
  > {
  page: ReactPaginateProps['forcePage'];
  onPageChange: ReactPaginateProps['onPageChange'];
  totalPagesCount: ReactPaginateProps['pageCount'];
}

function CustomPagination({
  page = 1,
  onPageChange,
  totalPagesCount,
  containerClassName,
}: CustomPaginationProps) {
  return (
    <ReactPaginate
      previousLabel={''}
      nextLabel={''}
      forcePage={page - 1}
      onPageChange={onPageChange}
      pageCount={totalPagesCount || 1}
      breakLabel={'...'}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      containerClassName={classNames(
        'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1',
        containerClassName
      )}
    />
  );
}

export default CustomPagination;
