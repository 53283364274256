import React, { useEffect, useState, useRef } from 'react';
import { Card, Tooltip } from 'antd';

import s from './style.module.css';

export const SliderSettingsItem = ({
  onMouseDown,
  onMouseUp,
  index,
  title,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState({
    index,
  });

  const handleChange = ({ target: { value } }) => {
    if (isNaN(value)) return;
    setState(prev => ({ ...prev, index: Number(value) }));
  };

  const onKeyPress = e => {
    if (e.key && e.key !== 'Enter') return;
    onChange(state.index - 1, index - 1);
    inputRef.current!.blur();
  };

  const handleSelection = e => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  useEffect(() => {
    setState(prev => ({ ...prev, index }));
  }, [index]);

  return (
    <Card
      className={s.sliderItem}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseDown}
    >
      <Tooltip placement="top" title={title}>
        <div className={s.sliderItemContent}>
          <input
            type="text"
            ref={inputRef}
            value={state.index}
            onChange={handleChange}
            className={s.changeInput}
            onKeyPress={onKeyPress}
            onBlur={() => {
              setState(prev => ({ ...prev, index }));
            }}
            onFocus={handleSelection}
          />
          <h3>{title}</h3>
        </div>
      </Tooltip>
    </Card>
  );
};
