import {LeftOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import React, {useMemo} from 'react';
import {useResource} from 'hooks/useResource';
import {useRouter} from 'hooks/useRouter';
import {SITE_NAMES} from 'hooks/ConfigProvider/helpers';
import {useConfig} from 'hooks/ConfigProvider/useConfig';

import s from './styles.module.css';

export enum TYPE_PAGES {
    item = 'item',
    category = 'category',
    posts = 'posts',
    skill = 'skill',
    authors = 'authors',
    staff = 'staff',
    page = 'page',
    company = 'company'
}

type Props = {
    withBack?: boolean;
    breadcrumbs?: string[];
    slug?: string;
};

export const Breadcrumbs = ({withBack, breadcrumbs = [], slug}: Props) => {
    const res = useResource();
    const router = useRouter();
    const {site} = useConfig();

    const breadcrumbItems = useMemo(() => {
        return [res.resources.pageTitle, ...breadcrumbs];
    }, [breadcrumbs]);

    const handleBack = () => {
        if (window.history.length < 2) {
            return router.push(`/${res.resource}`);
        }
        return router.goBack();
    };

    const handleToSite = () => {
        switch (res.resource) {
            case TYPE_PAGES.item:
                if (site.name === SITE_NAMES.Travelsites || site.name === SITE_NAMES.Streamingsites) {
                    window.open(`${process.env.REACT_APP_CLIENT_URL}/${slug}`);
                } else {
                    window.open(`${process.env.REACT_APP_CLIENT_URL}/review/${slug}`);
                }
                break;
            case TYPE_PAGES.category:
                window.open(`${process.env.REACT_APP_CLIENT_URL}/${slug}`);
                break;
          default: return null
        }
    };

    return (
        <div className={s.breadcrumbs}>
            {withBack && <LeftOutlined/>}
            <div className={s.breadcrumbsContent}>
                {breadcrumbItems.map((item, i, arr) => (
                    <span
                        key={item}
                        role="presentation"
                        className={classnames({
                            [s.active]: arr.length > 1 && i === arr.length - 1,
                        })}
                        onClick={i === 0 && withBack ? handleBack : handleToSite}
                    >{`${item} ${i !== arr.length - 1 ? '/' : ''}`}</span>
                ))}
            </div>
        </div>
    );
};
