import React from 'react';
import {Avatar, List, Space, Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {getIconUrl} from 'utils/getData';
import {Feature} from 'graphql/types';
import {useConfig} from 'hooks/ConfigProvider/useConfig';
import {SITE_NAMES} from 'hooks/ConfigProvider/helpers';
import {AwesomeIcon} from 'components/AwesomeIcon/AwesomeIcon';

import s from './style.module.scss';

export type Props = {
    feature: Partial<Feature>
    changeFeature: (feature: Feature) => void;
};

export const FeatureItem = React.memo(({
                                           feature,
                                           changeFeature
                                       }: Props) => {
    const {site} = useConfig()

    return <List.Item className={s.item} extra={<Space className={s.buttons}>
        <EditOutlined onClick={() => changeFeature(feature as Feature)}/>
    </Space>}>
        <List.Item.Meta
            avatar={(site.name === SITE_NAMES.ManytoolsAi || site.name === SITE_NAMES.Streamingsites ) && !!feature.fontAwesomeIcon
                ? <AwesomeIcon icon={feature.fontAwesomeIcon} />
                : <Avatar src={getIconUrl(feature.icon)} size={30} shape={'square'} />}
            title={<Typography.Title ellipsis className={s.title} level={5}>{feature.title}</Typography.Title>}
            description={<Typography.Text ellipsis className={s.description}>{feature.description}</Typography.Text>}

        />
    </List.Item>;
});
