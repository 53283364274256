import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import {
  DefaultSizesSettings,
  SaveSettingsDocument,
  SaveSettingsMutation,
  SaveSettingsMutationVariables,
  SettingsDocument,
  SettingsQuery,
  SettingsQueryVariables,
} from 'graphql/types';
import { ConfigStateType } from 'type';
import { initializeApollo } from 'utils/apollo';
import { ConfigContext, defaultState } from './defaultValues';
import { useUser } from '../useUser';

export const ConfigProvider: React.FC = ({ children }) => {
  const { user } = useUser();
  const [state, setState] = useState(defaultState);

  async function updateSettings(settings: DefaultSizesSettings) {
    const client = initializeApollo();
    try {
      await client.mutate<SaveSettingsMutation, SaveSettingsMutationVariables>({
        mutation: SaveSettingsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          input: settings,
        },
      });
      setState(prev => ({ ...prev, screenshot: settings }));
      message.info('Setting saved');
    } catch (e) {
      message.error(e.message);
    }
  }

  async function getSettings() {
    const client = initializeApollo();
    try {
      const {
        data: { defaultSizeSettings },
      } = await client.query<SettingsQuery, SettingsQueryVariables>({
        query: SettingsDocument,
        fetchPolicy: 'no-cache',
      });
      const updateObj: ConfigStateType = {
        ...defaultState,
        screenshot: {
          ...defaultState.screenshot,
          oneByOneCrop: defaultSizeSettings.oneByOneCrop,
        },
      };
      if (defaultSizeSettings.sizes.length > 0) {
        updateObj.screenshot.sizes = defaultSizeSettings.sizes;
      }
      setState(updateObj);
    } catch (e) {
      message.error(e.message);
    }
  }

  useEffect(() => {
    if (user) {
      getSettings();
    } else {
      setState(prev => ({ ...prev, screenshot: defaultState.screenshot }));
    }
  }, [user]);

  return (
    <ConfigContext.Provider
      value={{
        ...state,
        updateSettings,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => React.useContext(ConfigContext);
