import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { Card, Button, message } from 'antd';
import { UIUpload } from 'nativeComponents/UI/UIUpload/UIUpload';
import { SeoBox } from 'components/Common/Seo/Seo';
import {
  useSeoLazyQuery,
  useSeoUpdateMutation,
  Seo as SeoType,
} from 'graphql/types';
import classnames from 'classnames';
import { getImageValue } from 'utils/getData';
import { SEO_TEMPLATES } from './helpers';

export const Seo = () => {
  const [state, setState] = useState({
    title: '',
    description: '',
    image: '',
    templates: {
      item: {} as SeoType,
      category: {} as SeoType,
    },
  });

  const [fetchSeo, { loading: fetchLoading }] = useSeoLazyQuery({
    onCompleted: ({ seo: response }) => {
      const { seo, image, templates } = (response || {}) as any;
      setState({ ...seo, image, templates } as any);
    },
    onError: e => {
      message.error(e);
    },
  });
  const [updateSeo, { loading: updateLoading }] = useSeoUpdateMutation({
    onError: e => {
      message.error(e.message);
    },
  });

  const handleImageChange = (name: string, image: any) => {
    setState(prev => ({ ...prev, image: getImageValue(image) }));
  };

  const handleImageDelete = () => {
    setState(prev => ({ ...prev, image: '' }));
  };

  const handleInputChange = ({ target: { name, value } }: any) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeTemplate =
    (name: string) =>
    ({ target: { name: inputName, value } }: any) => {
      setState(prev => {
        const prevTemplate = _get(prev, `templates.${name}`, {});
        return {
          ...prev,
          templates: {
            ...(prev.templates || {}),
            [name]: {
              ...prevTemplate,
              [inputName]: value,
            },
          },
        };
      });
    };

  const onSubmit = async () => {
    const { title, description, image, templates } = state;
    await updateSeo({
      variables: {
        seo: {
          title,
          description,
        },
        image,
        templates,
      } as any,
    });
  };

  useEffect(() => {
    fetchSeo();
  }, []);

  const loading = fetchLoading || updateLoading;
  return (
    <div className="fluid pa-8">
      <h1 className="mb-8">Seo settings</h1>
      <Card
        className={classnames({
          pending: loading,
        })}
      >
        <div className="mb-4">
          <SeoBox
            seo={{
              title: state.title,
              description: state.description,
            }}
            onChange={handleInputChange}
          />
        </div>
        {SEO_TEMPLATES.map(({ name, title, variables }) => (
          <div className="mb-4" key={name}>
            <SeoBox
              title={title}
              seo={{
                title: _get(state, `templates[${name}].title`),
                description: _get(state, `templates[${name}].description`),
              }}
              onChange={handleChangeTemplate(name)}
              variables={variables}
            />
          </div>
        ))}
        <div className="mb-8 flex-column">
          <span className="mb-2">OGG Image</span>
          <UIUpload
            name="image"
            type="image"
            previews={state.image}
            onChange={handleImageChange}
            onDelete={handleImageDelete}
          />
        </div>

        <Button type="primary" onClick={onSubmit}>
          Save
        </Button>
      </Card>
    </div>
  );
};
