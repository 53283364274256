import React from 'react';
import { Form, Input, Button, Col, Typography } from 'antd';
import { UserInput } from 'graphql/types';

type Props = {
  onSubmit: (d: UserInput) => void;
};

export const AuthForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        zIndex: 10,
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '7rem',
      }}
    >
      <Col span={8}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Title level={2}>Admin panel</Typography.Title>
        </div>
        <Form
          onFinish={onSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Enter value' }]}
            labelCol={{ span: 6 }}
            labelAlign="left"
          >
            <Input placeholder="Email..." />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Enter value' }]}
            labelCol={{ span: 6 }}
            labelAlign="left"
          >
            <Input placeholder="Password..." type="password" />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Col>
    </div>
  );
};
