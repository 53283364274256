import React from 'react';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import classnames from 'classnames';
import { Maybe, Positions } from 'graphql/types';

export type PictureOriginalImageProps = {
  src: string;
  preview?: string;
  aspectRatio: number;
  size: number;
  showCropRatio?: number;
  disabled?: boolean;
  onCrop?: (data: {
    size: number;
    aspectRatio: number;
    showCropRatio?: number;
  }) => void;
  positions?: Maybe<Positions>;
};

export const PictureOriginalImage = React.memo(
  function PictureOriginalImage({
    src,
    onCrop = () => {},
    showCropRatio,
    aspectRatio,
    size,
    preview,
    disabled,
  }: PictureOriginalImageProps) {
    const config = useConfig();
    const handleImageClick = () => {
      if (disabled) return;
      onCrop({
        showCropRatio,
        aspectRatio,
        size,
      });
    };

    return (
      <div
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        className={classnames({
          ['big-image']: true,
          ['big-image__shrink']: config.screenshot.oneByOneCrop,
        })}
        role="presentation"
      >
        <div className="big-image__original">
          <img src={src} />
        </div>
        <div className="thumbnail-edit">
          <div className="thumbnail-edit__cropped" onClick={handleImageClick}>
            {!!preview && <img src={preview} />}
          </div>
          {!!preview && (
            <img
              src={preview}
              className="hover-picture"
            />
          )}
        </div>
      </div>
    );
  }
);
