import { Typography } from 'antd';
import { UIUpload } from 'nativeComponents/UI';
import React from 'react';
import { PictureDictionary } from 'graphql/types';
import { getImageValue } from 'utils/getData';

export const SeoImage = ({ image, onDelete, onChange }) => {
  const handleChange = (name: string, image: PictureDictionary[]) => {
    onChange(name, getImageValue(image, 1200));
  };

  return (
    <div className="editTitle mt-2" style={{ border: 'none', paddingBottom: 0 }}>
      <Typography.Title level={5}>Seo ogg image</Typography.Title>
      <UIUpload
        previews={image}
        name="seoImage"
        type="image"
        onDelete={onDelete}
        onChange={handleChange}
        saveOptions={{
          sizes: [
            {
              width: 1200,
              aspectRatio: 1,
              watermark: false,
            },
          ],
        }}
      />
    </div>
  );
};
