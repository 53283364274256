import { UsergroupAddOutlined } from '@ant-design/icons';
import { StaffsEdit } from 'components/Staffs/StaffsEdit';

const staffResource = {
  name: 'staff',
  menuIcon: UsergroupAddOutlined,
  pageTitle: 'Moderators',
  editComponent: StaffsEdit,
  editBreadcrumbs: record => record.email,
  list: {
    actions: ['delete'],
    columns: [
      {
        title: 'Email',
        key: 'email',
      },
    ],
  },
};

export default staffResource;
