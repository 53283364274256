import React, { useEffect, useState, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Input, Checkbox } from 'antd';
import ImageUploader from 'quill-image-uploader';
import { addBindings, formats, modules } from './helpers';

import s from './styles.module.css';

Quill.register('modules/imageUploader', ImageUploader);

export const HTMLEditor = ({
  value = '',
  onChange,
  currentLanguage,
  name,
}: {
  value: string;
  onChange: (e: any) => void;
  currentLanguage: string;
  name?: string;
}) => {
  if (!window) return <></>;
  const quillRef = useRef<any>(null);
  const [data, setData] = useState(value);
  const [showOriginal, setShowOriginal] = useState(false);

  useEffect(() => {
    if (!quillRef.current) return;
    const quillEditor = quillRef.current.getEditor();
    addBindings(quillEditor);
    setData(value);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (data !== value) {
        onChange({ target: { value: data, name } });
      }
    }, 0);
  }, [data]);

  const getCharsCount = (): number => {
    if (!data) return 0;
    return data.replaceAll(/<[^>]*>?/gm, '').length;
  };

  const getWordsCount = (): number => {
    if (!data) return 0;
    return data.replaceAll(/<[^>]*>?/gm, '').split(' ').length;
  };

  const handleChangeOriginal = e => {
    setShowOriginal(e.target.checked);
  };

  return (
    <div className={s.editor}>
      {showOriginal ? (
        <Input.TextArea
          style={{
            height: 'auto',
            resize: 'none',
            fontSize: '14px',
            fontFamily: 'monospace',
          }}
          value={data
            .replaceAll('</p>', '</p>\n')
            .replaceAll('<h2>', '\n<h2>')
            .replaceAll('</h2>', '</h2>\n')}
          onChange={e => {
            setData(e.target.value.replaceAll('\n', ''));
          }}
          autoSize
        />
      ) : (
        <ReactQuill
          ref={quillRef}
          value={data}
          onChange={setData}
          modules={modules}
          formats={formats}
        />
      )}
      <div className={s.footer}>
        <Checkbox onChange={handleChangeOriginal} checked={showOriginal}>
          Show HTML
        </Checkbox>
        <span style={{ margin: '0 16px' }}>
          Characters count: {getCharsCount()}
        </span>
        <span>Words count: {getWordsCount()}</span>
      </div>
    </div>
  );
};
