import {imageHandler} from './imageHandler';
import Quill from 'quill';

let Block = Quill.import('blots/block');

export const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'align',
    'list',
    'bullet',
    'link',
    'image',
    'imageBlot',
    'btn-link',
];

export const modules = {
    toolbar: [
        [{header: [2, 3, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{list: 'ordered'}, {list: 'bullet'}],
        ['link'],
        ['image'],
        ['clean'],
        ['btn-link'],
    ],
    imageUploader: {
        upload: file => {
            return imageHandler(file);
        },
    },
};

export class BtnLink extends Block {
    static create() {
        let node = super.create();
        node.setAttribute('class', 'btn-link');
        return node;
    }

    static formats(domNode) {
        return true;
    }
}

BtnLink.blotName = 'btn-link';
BtnLink.tagName = 'div';
Quill.register(BtnLink);

export const keyBindings = [
    {
        key: '2',
        altKey: true,
        shiftKey: true,
        handler(range) {
            (this as any).quill.formatLine(range.index, 1, 'header', '2');
        },
    },
    {
        key: '3',
        altKey: true,
        shiftKey: true,
        handler(range) {
            (this as any).quill.formatLine(range.index, 1, 'header', '3');
        },
    },
];

export const addBindings = (editor: Quill) => {
    keyBindings.forEach(item => {
        const {handler, ...rest} = item;
        editor.keyboard.addBinding(rest, handler);
    });
};
