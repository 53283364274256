import { Tag, Typography } from 'antd';
import React from 'react';
import { BlockOutlined } from '@ant-design/icons';
import { AuthorEdit } from 'components/Author/AuthorEdit';

const authorsResource = {
  name: 'authors',
  menuIcon: BlockOutlined,
  pageTitle: 'Author',
  parent: 'Blog',
  editComponent: AuthorEdit,
  editBreadcrumbs: record => record.user.email,
  list: {
    actions: ['delete'],
    columns: [
      {
        title: 'User',
        key: 'user',
        render: record => {
          return <h4 style={{ marginBottom: 0 }}>{record.email}</h4>;
        },
      },
      {
        title: 'Posts',
        key: 'post',
        render: (record = [], data) => {
          const posts = data.posts || [];
          return (
            <div>
              {posts.slice(0, 3).map(({ title }) => (
                <Tag key={title} className="mr-1">
                  {title}
                </Tag>
              ))}
              {posts.length > 3 ? '...' : ''}
            </div>
          );
        },
      },
    ],
  },
};

export default authorsResource;
