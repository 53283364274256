import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import { Watermark } from 'components/Settings/Watermark/Watermark';

const watermark = {
  name: 'watermark',
  parent: 'Settings',
  pageTitle: 'Watermark settings',
  globalSearch: false,
  menuIcon: FileImageOutlined,
  ListComponent: Watermark,
};

export default watermark;
