import React from 'react';
import { Checkbox, Col, Form, Row, Tag, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SortDirection } from 'graphql/types';
import { HeaderTypes, SortHeader } from './CSVSortProvider';

type Props = {
  headers: string[];
  altHeader: SortHeader;
  primaryHeader: SortHeader;
  onChange: (type: HeaderTypes) => (data: CheckboxChangeEvent) => void;
  onChangeSortDirection: (
    key: HeaderTypes,
    sortDirection: SortDirection
  ) => () => void;
};

const { Text } = Typography;

export const CSVSortInputForm = ({
  headers,
  altHeader,
  onChange,
  onChangeSortDirection,
  primaryHeader,
}: Props) => {
  return (
    <div>
      {headers.map(title => {
        const isPrimaryChosen = primaryHeader.key === title;
        const isAltChosen = altHeader.key === title;
        const sortPrimaryDirectionDesc =
          isPrimaryChosen && primaryHeader.sortDirection === SortDirection.Desc;
        const sortAltDirectionDesc =
          isAltChosen && altHeader.sortDirection === SortDirection.Desc;
        const descSortDirection =
          sortPrimaryDirectionDesc || sortAltDirectionDesc;
        return (
          <Row>
            <Col span={12}>
              <Form.Item
                key={title}
                labelCol={{ span: 15 }}
                label={title}
                labelAlign="left"
              >
                <Checkbox
                  name={title}
                  checked={isPrimaryChosen}
                  onChange={onChange('primaryHeader')}
                >
                  <Text type="secondary">Primary key</Text>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item key={title} labelAlign="left">
                <Checkbox
                  name={title}
                  checked={isAltChosen}
                  onChange={onChange('altHeader')}
                >
                  <Text type="secondary">Alt key</Text>
                </Checkbox>
              </Form.Item>
            </Col>
            {(isPrimaryChosen || isAltChosen) && (
              <Col>
                <Form.Item key="sortDirection" labelAlign="right">
                  <Tag
                    onClick={onChangeSortDirection(
                      isPrimaryChosen ? 'primaryHeader' : 'altHeader',
                      SortDirection.Asc
                    )}
                    color={!descSortDirection ? 'blue' : ''}
                    className="pointer"
                  >
                    1 - 9
                  </Tag>
                  <Tag
                    onClick={onChangeSortDirection(
                      isPrimaryChosen ? 'primaryHeader' : 'altHeader',
                      SortDirection.Desc
                    )}
                    color={descSortDirection ? 'blue' : ''}
                    className="pointer"
                  >
                    9 - 1
                  </Tag>
                </Form.Item>
              </Col>
            )}
          </Row>
        );
      })}
    </div>
  );
};
