const AngleDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path d="M24 24V0H0V24H24Z" fill="white" />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M3.52973 8.51496L12.015 17.0002L20.5003 8.51496"
        stroke="#1A1B46"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default AngleDown;
