import React, { useState } from 'react';
import classnames from 'classnames';
import { Card, Input, Typography, Col, Row, Radio } from 'antd';
import { niceTitle } from 'utils/string';
import { ActionName, ModelName } from '../../graphql/types';
import { SubmitGroup } from '../Common/SubmitGroup/SubmitGroup';
import s from './staffs_style.module.css';

export const StaffsEdit = ({ email, permissions = [], onSubmit }) => {
  const [state, setState] = useState({
    email,
    ...permissions.reduce(
      (acc, { model, actions }) => ({
        ...acc,
        [model]: actions,
      }),
      {}
    ),
  });

  const onChangeInput = ({ target: { value, name } }) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const onChange =
    (modelValue: ModelName, actionValue: ActionName) => (checked: any) => {
      setState(prev => ({ ...prev, [modelValue]: checked ? actionValue : '' }));
    };

  const handleSubmit = (redirect?: boolean) => {
    const { email, ...statePermissions } = state;
    const arrayedPermissions = Object.entries(statePermissions).map(
      ([key, value]) => ({
        model: key,
        actions: value,
      })
    );
    onSubmit({ email: state.email, permissions: arrayedPermissions, redirect });
  };

  return (
    <Card
      className={s.staffEdit}
      bodyStyle={{
        paddingBottom: 0,
      }}
    >
      <div className="flex-column mb-2">
        <Typography.Title level={5}>Moderator’s email</Typography.Title>
        <Input
          value={state.email}
          onChange={onChangeInput}
          name="email"
          disabled
        />
      </div>

      {Object.entries(ModelName).map(([key, value]) => (
        <div className={s.row} key={key}>
          <div className="flex align-center">
            <Col span={8} className={s.title}>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                {niceTitle(key)}
              </Typography.Title>
            </Col>
            <Col span={18} className="flex justify-between">
              {Object.entries(ActionName).map(([actionKey, actionValue]) => {
                return (
                  <Col
                    key={actionKey}
                    className={classnames({
                      [s.background]: state[key] === actionValue,
                      [s.border]: state[key] != actionValue,
                    })}
                    span={8}
                    style={{ marginRight: 40 }}
                  >
                    <Radio
                      checked={state[key] === actionValue}
                      onChange={onChange(value, actionValue)}
                    >
                      {niceTitle(actionKey)}
                    </Radio>
                  </Col>
                );
              })}
            </Col>
          </div>
        </div>
      ))}

      <div className="mt-2">
        <SubmitGroup onSubmit={handleSubmit} withDelete withCancel={false} />
      </div>
    </Card>
  );
};
