import React from 'react';
import { FileJpgOutlined } from '@ant-design/icons';
import { CSVSettings } from 'components/Settings/CSV/CSVSettings';

const cropSettings = {
  name: 'csv',
  parent: 'Settings',
  pageTitle: 'CSV parser',
  globalSearch: false,
  menuIcon: FileJpgOutlined,
  ListComponent: CSVSettings,
};

export default cropSettings;
