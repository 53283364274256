import React from 'react';
import { Checkbox } from 'antd';

type Props = {
  checked?: boolean;
  title: string;
  id: string;
  onChange: (id: string) => void;
  disabled?: boolean;
};

export const IconGenerateItem = ({
  checked,
  title,
  id,
  onChange,
  disabled,
}: Props) => {
  const handleChange = () => onChange(id);

  return (
    <div
      className="flex align-center px-4 py-2 mb-4 mr-4"
      role="presentation"
      onClick={handleChange}
      style={{
        backgroundColor: '#ffffff',
        borderRadius: 5,
        cursor: 'pointer',
        width: '20%',
      }}
    >
      <Checkbox checked={checked} disabled={disabled} />
      <span
        className="ml-2"
        style={{
          fontWeight: 500,
        }}
      >
        {title}
      </span>
    </div>
  );
};
