import { Divider } from 'antd';
import { useState } from 'react';
import {
  CompareCategory,
  FieldError,
} from 'components/Settings/CSV/CSVProvider/types';
import { CVSCompareContentItem } from './CVSCompareContentItem/CVSCompareContentItem';

import s from './styles.module.scss';

type Props = {
  // compareFields: any;
  compareFields: CompareCategory['differentFields'];
  errors?: Record<string, FieldError>;
};

export const CVSCompareContent = ({ compareFields, errors = {} }: Props) => {
  const [state, setState] = useState({
    opened: {} as Record<string, string[]>,
  });

  const handleShowData =
    ([type, title]) =>
    () => {
      const isExist = state.opened[title] && state.opened[title].includes(type);
      setState(prev => ({
        ...prev,
        opened: {
          ...prev.opened,
          [title]: isExist
            ? prev.opened[title].filter(tt => tt !== type)
            : [...(prev.opened[title] || []), type],
        },
      }));
    };

  return (
    <div>
      <div className={s.compareHeader}>
        <span>Exist value</span>
        <span>Uploaded value</span>
      </div>
      <Divider />
      <div className={s.compareContent}>
        {Object.entries(compareFields).map(([name, values], i) => {
          return (
            <div key={i} className={s.contentItem}>
              {values.map((value, index, arr) => {
                const error = errors[name] && errors[name].error;
                const requireError =
                  errors[name] && errors[name].requireError && 'Require value';
                return (
                  <div className={s.subItem} key={value}>
                    <CVSCompareContentItem
                      item={{ title: name, value }}
                      opened={state.opened}
                      onShow={handleShowData}
                      error={
                        (index === arr.length - 1 &&
                          (error || requireError)) as string
                      }
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
