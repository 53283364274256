import React, { useEffect, useState } from 'react';
import { Card, Input, Switch, Typography } from 'antd';
import { HTMLEditor } from 'nativeComponents/Common/HTMLEditor/HTMLEditor';
import { UIUpload } from 'nativeComponents/UI';
import { getIconValue, getImageValue } from 'utils/getData';
import { ColorPicker } from 'nativeComponents/Common/ColorPicker/ColorPicker';
import { SEARCH_SELECT_TYPES } from 'components/Common/SearchSelect/helpers';
import { SearchSelect } from 'components/Common/SearchSelect/SearchSelect';
import { SubmitGroup } from 'components/Common/SubmitGroup/SubmitGroup';
import { DynamicModel, Entity } from 'graphql/types';
import { DynamicFieldsComponent } from '../../DynamicFieldsComponent';
import { ItemsList } from './ItemsList';
import { getInitialState, getUpdatedDynamicFilters } from './helpers';
import { CategoryItemsDynamic } from '../CategoryItemsDynamic/CategoryItemsDynamic';
import { useForm } from 'antd/es/form/Form';
import { fetchDynamicModels } from 'api/dynamic';
import { SeoBox } from 'components/Common/Seo/Seo';
import { ItemEditCustomSlug } from '../../Item/ItemEditCustomSlug';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { SITE_NAMES } from 'hooks/ConfigProvider/helpers';
import { AskQuestions } from 'components/AskQuestions/AskQuestions';
import { BindedIcon, FaIconPicker } from 'entities/font-awesome-icon';

import s from '../../Item/styles.module.scss';

export const CategoryEdit = ({ onSubmit, modelFields, id, ...props }) => {
  const [dynamicFiltersForm] = useForm();
  const [state, setState] = useState(getInitialState(props as any));
  const { site } = useConfig();

  const onChangeIcon = (name: string, changedImage: any) => {
    setState(prev => ({
      ...prev,
      [name]: changedImage,
    }));
  };

  const onDelete = (name: string) => {
    setState(prev => ({
      ...prev,
      [name]: null,
    }));
  };

  const onInputChange =
    (type: string) =>
    ({ target: { name, value, checked } }: any) => {
      setState(prev => {
        const isObject = prev[type] !== null && typeof prev[type] === 'object';
        return {
          ...prev,
          [type]: isObject
            ? {
                ...prev[type],
                [name]: typeof value === 'string' ? value : checked,
              }
            : value || checked,
        };
      });
    };

  const handleChange = (name: string) => (data: any) => {
    setState(prev => ({ ...prev, [name]: data }));
  };

  const handleSubmit = async (redirect?: boolean) => {
    const models = await fetchDynamicModels({ entity: Entity.Category });
    const payload = {
      ...state,
      dynamic: getUpdatedDynamicFilters(
        state.dynamic,
        models as DynamicModel[],
        dynamicFiltersForm.getFieldsValue()
      ),
      redirect,
    };
    onSubmit(payload, updatedData =>
      setState(prev => ({ ...prev, ...updatedData }))
    );
  };

  const setColor = (color: string) => {
    setState(prev => ({ ...prev, color }));
  };

  const handleFontAwesomeIcon = (icon: BindedIcon) => {
    setState(prev => ({
      ...prev,
      fontAwesomeIcon: {
        ...prev.fontAwesomeIcon,
        prefix: icon.prefix,
        name: icon.name,
      },
    }));
  };

  const setColorFontAwesomeIcon = (color: string) => {
    setState(prev => ({
      ...prev,
      fontAwesomeIcon: { ...prev.fontAwesomeIcon, color },
    }));
  };

  useEffect(() => {
    setState(getInitialState(props as any));
  }, [id]);

  return (
    <Card
      bodyStyle={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
    >
      <div className="fluid flex">
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Title</Typography.Title>
            <Input
              value={state.title}
              onChange={onInputChange('title')}
              name="title"
            />
          </div>
          {modelFields.longDescription && (
            <div className="editTitle">
              <Typography.Title level={5}>Long description</Typography.Title>
              <HTMLEditor
                value={state.longDescription}
                currentLanguage=""
                onChange={onInputChange('longDescription')}
              />
            </div>
          )}
          {modelFields.shortDescription && (
            <div className="editTitle">
              <Typography.Title level={5}>Short description</Typography.Title>
              <Input
                value={state.shortDescription}
                onChange={onInputChange('shortDescription')}
              />
            </div>
          )}
          {site.name === SITE_NAMES.ManytoolsAi ? (
            <div className={'editTitle'} style={{ maxWidth: '300px' }}>
              <Typography.Title level={5}>Icon</Typography.Title>
              <FaIconPicker
                value={state.fontAwesomeIcon as BindedIcon}
                onChange={e => handleFontAwesomeIcon(e)}
                setColor={setColorFontAwesomeIcon}
              />
            </div>
          ) : (
            modelFields.icon && (
              <div className="editTitle">
                <Typography.Title level={5}>Icon</Typography.Title>
                <UIUpload
                  previews={getIconValue(state.icon)}
                  onChange={onChangeIcon}
                  name="icon"
                  type="icon"
                  onDelete={onDelete}
                />
              </div>
            )
          )}
          {modelFields.image && (
            <div
              className="editTitle"
              style={{ border: 'none', paddingBottom: 0 }}
            >
              <Typography.Title level={5}>Image</Typography.Title>
              <UIUpload
                previews={getImageValue(state.image)}
                name="image"
                type="image"
                onDelete={onDelete}
                onChange={onChangeIcon}
              />
            </div>
          )}
          <div className={`editTitle ${s.seoBlock}`}>
            <SeoBox
              seo={state.seoCustom}
              onChange={onInputChange('seoCustom')}
              withImage
            />
          </div>
          {(site.name === SITE_NAMES.Manytools ||
            site.name === SITE_NAMES.ManytoolsAi) && (
            <AskQuestions faq={state.faq} />
          )}
          <DynamicFieldsComponent
            update={d => {
              setState(prev => ({ ...prev, dynamic: d }));
            }}
            dynamicData={state.dynamic}
            entity={Entity.Category}
          />
        </div>
        <div className="editSideContent" style={{ paddingTop: 32 }}>
          <div className="editSideContent__item">
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              Active state
            </Typography.Title>
            <Switch
              checked={!!state.active}
              onChange={handleChange('active')}
            />
          </div>
          {modelFields.color && (
            <div className="editSideContent__item">
              <ColorPicker value={state.color} setValue={setColor} />
            </div>
          )}
          {modelFields.skills && (
            <div className="editSideContent__item flex-column align-start">
              <Typography.Title level={4} className="mb-3">
                Topics
              </Typography.Title>
              <SearchSelect
                mode="multiple"
                allowClear
                placeholder="Select topic"
                values={state.skills}
                onChange={handleChange('skills')}
                name={SEARCH_SELECT_TYPES.SKILL}
              />
            </div>
          )}
          <ItemEditCustomSlug
            slug={state.slug}
            slugCustom={state.slugCustom}
            onChange={onInputChange}
          />
          <ItemsList categoryId={id} />
          <CategoryItemsDynamic
            items={props.items}
            categoryDynamic={state.dynamic}
            form={dynamicFiltersForm}
          />
        </div>
      </div>

      <SubmitGroup onSubmit={handleSubmit} />
    </Card>
  );
};
