import { GetLabelsDocument } from 'graphql/types';
import { initializeApollo } from 'utils/apollo';

const apolloClient = initializeApollo();

export const getList = async () => {
  const {
    data: { labels },
  } = await apolloClient.query({
    query: GetLabelsDocument,
    fetchPolicy: 'no-cache',
  });

  const transformedLabels = labels.map(label => ({
    id: label.id,
    title: label.name, 
  }));

  return { data: transformedLabels };
};
