import { DynamicFieldType, DynamicModelType } from 'graphql/types';

export const MODEL_TYPES = [
  {
    model: {
      type: DynamicModelType.Inline,
    },
    field: {
      type: DynamicFieldType.String,
    },
    title: 'Single line text',
  },
  {
    model: {
      type: DynamicModelType.Inline,
    },
    field: {
      type: DynamicFieldType.Number,
    },
    title: 'Number',
  },
  {
    model: {
      type: DynamicModelType.Inline,
    },
    field: {
      type: DynamicFieldType.Boolean,
    },
    title: 'Checkbox',
  },
  {
    model: {
      type: DynamicModelType.Inline,
    },
    field: {
      type: DynamicFieldType.Html,
    },
    title: 'HTML text',
  },
  {
    model: {
      type: DynamicModelType.Inline,
    },
    field: {
      type: DynamicFieldType.Image,
    },
    title: 'Image',
  },
  {
    model: {
      type: DynamicModelType.OneToMany,
    },
    field: {
      type: DynamicFieldType.String,
    },
    title: 'Single select',
  },
  {
    model: {
      type: DynamicModelType.ManyToMany,
    },
    field: {
      type: DynamicFieldType.String,
    },
    title: 'Multiple select',
  },
];
