const typesPage = {
    Item: 'item',
    Blog: 'posts',
    Category: 'category'
}

export const linkGeneration = (record: any) => {
    if( Number(record.pageId) === 0) return  <span>{record.pageName}</span>
    return <a
        href={`/${typesPage[record.pageType]}/edit?id=${record.pageId}`}
        target={'_blank'}
    >
        {record.pageName}
    </a>
}
