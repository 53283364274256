import React from 'react';
import { Button } from 'antd';
import { SubmitGroup } from 'components/Common/SubmitGroup/SubmitGroup';

/**
 * Temporary preview url generator util
 * @param slug slug in url
 * @returns review page link
 * NOTE: It is one time fix for generating url depending on slug. 
 * It needs to rewrite
 */
const getReference = (slug: string) => {
  const url = new URL(process.env.REACT_APP_CLIENT_URL || '');

  switch (true) {
    case url.host.includes('manytools'): {
      return[
        url.protocol.concat('//'), 
        'preview.'.concat(url.host), 
        'review',
        slug
      ].join('/');
    }
    case url.host.includes('streamingsites'): {
      return[
        url.protocol.concat('//'), 
        'preview.'.concat(url.host), 
        slug
      ].join('/');
    }
    case url.host.includes('travelsites'): {
      return[
        url.protocol.concat('//'), 
        'preview.'.concat(url.host), 
        slug
      ].join('/');
    }
    default:
      return `${process.env.REACT_APP_CLIENT_URL}/review/${slug}`
  }
};

export const ItemEditSubmit = ({ onSubmit, slug }) => {
  return (
    <SubmitGroup
      onSubmit={onSubmit}
      leftPostfixSlot={
        <Button
          type="primary"
          ghost
          href={getReference(slug)}
          target="_blank"
        >
          Preview
        </Button>
      }
    />
  );
};
