import {
  CompaniesResponse,
  Company,
  CreateCompanyDocument,
  DeleteCompanyDocument,
  GetCompaniesListDocument,
  GetCompanyDocument,
  UpdateCompanyDocument,
  SortBy,
  SortDirection,
} from '../../graphql/types';
import { initializeApollo } from '../../utils/apollo';
const apolloClient = initializeApollo();

export const getList = async (
  {
    page = 1,
    limit = 10,
    sort = SortBy.CreationDate,
    sortDirection = SortDirection.Desc,
    search,
    filters = {},
  } = {} as any
) => {
  const {
    data: { companies: { data, count } = {} as CompaniesResponse },
  } = await apolloClient.query({
    query: GetCompaniesListDocument,
    fetchPolicy: 'no-cache',
    variables: {
      page: Number(page),
      limit: Number(limit),
      search,
      sortBy: sort,
      sortByDirection: sortDirection,
      ...filters,
    },
  });
  return { data, total: count };
};

export const getOne = async ({ id }) => {
  if (!id) {
    return { data: {} };
  }
  const {
    data: { company },
  } = await apolloClient.query({
    query: GetCompanyDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });
  return { data: company };
};

export const createOne = async (data: Company) => {
  const { title, icon, link, social, seo } = data;
  return apolloClient.query({
    query: CreateCompanyDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        title,
        icon,
        link,
        social,
        seo,
      },
    },
  });
};
export const updateOne = async (data: Company) => {
  const { title, icon, link, social, seo, slug } = data;
  return apolloClient.query({
    query: UpdateCompanyDocument,
    fetchPolicy: 'no-cache',
    variables: {
      slug,
      input: {
        title,
        icon,
        link,
        social,
        seo,
      },
    },
  });
};

export const deleteOne = async ({ id }: { id: string | number }) => {
  await apolloClient.mutate({
    mutation: DeleteCompanyDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });
  return id;
};
