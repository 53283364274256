import { Button } from 'antd';
import _noop from 'lodash/noop';
import { getIconValue, getImageValue } from 'utils/getData';
import { GlobalSearchItem } from './GlobalSearchItem';
import { ArrayItem } from './ItemsList';

import s from './styles.module.css';

type Props = {
  title: string;
  resource?: string;
  items: ArrayItem;
  onLoadMore?: () => void;
  loading?: boolean;
  onClick?: () => void;
};

export const GlobalSearchColumn = ({
  title,
  items,
  onLoadMore = () => {},
  loading,
  resource,
  onClick = _noop,
}: Props) => {
  if (!items.items.length) return null;
  return (
    <div className={s.column}>
      <p className={s.columnTitle}>{title}</p>
      <div>
        {items.items.map(item => (
          <GlobalSearchItem
            resource={resource || item.resource}
            key={item.id || item.slug}
            onClick={onClick}
            icon={
              /* eslint-disable */
              item.icon
                ? getIconValue(item.icon)
                : item.thumb
                ? getImageValue(item.thumb)
                : null
            }
            title={item.title}
            link={item.link && (item.link.url || item.link.alternativeUrl)}
            id={item.id}
          />
        ))}
      </div>
      {items.count > items.items.length && (
        <Button
          type="link"
          onClick={onLoadMore}
          className={s.viewAllBtn}
          loading={loading}
        >
          View all items
        </Button>
      )}
    </div>
  );
};
