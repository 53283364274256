import _uniqBy from 'lodash/uniqBy';
import {
  GetCategoriesListDocument,
  GetCategoriesSuperLightListDocument,
  GetCompaniesListDocument,
  GetItemsDocument,
  GetItemsSuperLightDocument,
  GetSkillListDocument,
} from 'graphql/types';

export const getFilterFetcher = (key: string) => {
  switch (key) {
    case 'categories':
      return GetCategoriesListDocument;
    case 'items':
      return GetItemsDocument;
    case 'companies':
      return GetCompaniesListDocument;
    case 'skills':
      return GetSkillListDocument;

    default:
      return null;
  }
};

export const getKekItem = (
  item: { id: string; [key: string]: string },
  sign: string
) => {
  if (sign === 'id') return item.id;
  return `${item[sign]}~${item.id}`;
};
const parseKek = (kek: string) => kek.split('~')[0];

export const sortByActive = (
  items: any[],
  activeItems: string[],
  sign: string
) => {
  const actives: any[] = [];
  const inActive = items.filter(item => {
    if (activeItems.includes(getKekItem(item, sign))) {
      actives.push(item);
      return false;
    }
    return true;
  });

  return [...actives, ...inActive];
};

export const getItemsFromData = (
  data: Record<string, any>,
  chosenItems: string[],
  prevItems: any[],
  sign: string
) => {
  let mainKey;
  Object.keys(data).find((key, i) => {
    if (i === 0) {
      mainKey = key;
      return true;
    }
    return false;
  });

  return sortByActive(
    mainKey
      ? _uniqBy(
          [
            ...data[mainKey].data,
            ...prevItems.filter(item =>
              chosenItems.includes(getKekItem(item, sign))
            ),
          ],
          sign
        )
      : [],
    chosenItems,
    sign
  );
};
