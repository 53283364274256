import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { handleInputChange } from 'utils/handlers';

type Props = {
  typeText: string;
  onConfirm: () => void;
};

export const HardConfirmAction: React.FC<Props> = ({
  children,
  onConfirm,
  typeText,
}) => {
  const [state, setState] = useState({
    modalOpened: false,
    text: '',
  });

  const toggleModal = () => {
    setState(prev => ({
      ...prev,
      modalOpened: !prev.modalOpened,
      text: prev.modalOpened ? '' : prev.text,
    }));
  };

  const handleConfirm = () => {
    toggleModal();
    onConfirm();
  };

  return (
    <div>
      <div role="presentation" onClick={toggleModal}>
        {children}
      </div>

      <Modal
        title="Confirm action"
        visible={state.modalOpened}
        onOk={handleConfirm}
        onCancel={toggleModal}
        okButtonProps={{
          disabled: typeText !== state.text,
        }}
      >
        <p>
          Type <strong>{typeText}</strong>
        </p>
        <Input
          value={state.text}
          onChange={handleInputChange(setState)}
          name="text"
        />
      </Modal>
    </div>
  );
};
