import React from 'react';
import { FileJpgOutlined } from '@ant-design/icons';
import { ProjectSettings } from 'components/Settings/ProjectSettings/ProjectSettings';

const projectSettings = {
  name: 'project',
  parent: 'Settings',
  pageTitle: 'Project settings',
  globalSearch: false,
  menuIcon: FileJpgOutlined,
  ListComponent: ProjectSettings,
};

export default projectSettings;
