import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as resources from 'config/resourses';
import { List } from 'nativeComponents/List/List';
import { Edit } from 'nativeComponents/Edit/Edit';

import s from './styles.module.css';

const routes = Object.values(resources).map(({ name, ListComponent }: any) => {
  return (
    <Switch key={name}>
      <Route
        path={`/${name}`}
        exact
        render={props => {
          if (ListComponent) {
            return <ListComponent {...props} resource={name} />;
          }
          return <List {...props} resource={name} />;
        }}
      />
      <Route path={`/${name}/show/:id`} exact key={`${name}-show`} />
      <Route
        path={`/${name}/edit`}
        key={`${name}-edit`}
        exact
        component={Edit}
      />
      <Route path="/tech" exact />
    </Switch>
  );
});

export const Routes = () => <div className={s.page}>{routes}</div>;
