import React, { useState, useEffect } from 'react';
import { Checkbox, Input, message, Modal } from 'antd';
import { useScreenshotCreateMutation } from 'graphql/types';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { constructUploadImages } from './helpers';

export const Screenshot = ({ visible, onClose, siteUrl, onFinish }) => {
  const { screenshot } = useConfig();
  const [isFullPage, setFullPage] = useState(false);
  const [loadScreenshot, setLoadScreenshot] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(siteUrl);

  const [screenShotHandle] = useScreenshotCreateMutation({
    onError: e => {
      message.error(e.message);
    },
  });

  const toggleFullPage = ({ target: { checked } }: CheckboxChangeEvent) => {
    setFullPage(checked);
  };

  const getScreenshot = async () => {
    try {
      setLoadScreenshot(true);
      const { data: response } = await screenShotHandle({
        variables: {
          url: screenshotUrl,
          sizes: constructUploadImages(screenshot.sizes),
          fullPage: isFullPage,
        },
      });
      if (!response) {
        throw new Error('There is no screenshot, may be url is broken');
      }
      onFinish(response?.screenshotCreate);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoadScreenshot(false);
      onClose();
    }
  };

  useEffect(() => {
    setScreenshotUrl(siteUrl);
  }, [siteUrl]);

  return (
    <Modal
      className="screenshot-modal"
      title="Screenshot"
      visible={visible}
      onOk={getScreenshot}
      confirmLoading={loadScreenshot}
      onCancel={onClose}
    >
      <Input
        placeholder="Set url"
        onChange={e => setScreenshotUrl(e.target.value)}
        value={screenshotUrl}
      />
      <div className="flex mt-2">
        <Checkbox checked={isFullPage} onChange={toggleFullPage} />
        <span className="ml-4">Full page</span>
      </div>
    </Modal>
  );
};
