import { LayoutOutlined } from '@ant-design/icons';
import { PageEdit } from 'components/Page/PageEdit';

const pageResource = {
  name: 'page',
  menuIcon: LayoutOutlined,
  pageTitle: 'Pages',
  filters: [],
  globalSearch: false,
  editComponent: PageEdit,
  list: {
    actions: ['delete'],
    columns: [
      {
        title: 'Title',
        key: 'title',
      },
      {
        title: 'Slug',
        key: 'slug',
      },
    ],
  },
};

export default pageResource;
