import {
  Category,
  DynamicData,
  DynamicFieldModel,
  DynamicFieldType,
  DynamicModel,
  DynamicModelType,
  Entity,
  Item,
  ItemFree,
} from 'graphql/types';
import {
  createDynamicField,
  createDynamicModel,
  createDynamicVariant,
} from 'api/dynamic';
import { getPlainString, toSnakeCase } from 'utils/string';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';
import { CompareCategory } from './types';

const convertDynamicValueToString = (value: any, modelName: string) => {
  if (!value) return '';
  const arrayedValue = Array.isArray(value) ? value : [value];
  return arrayedValue
    .map(vv => vv[modelName])
    .sort((a, b) => (a > b ? -1 : 1))
    .join(' | ');
};
const collectDynamicDiff = (oldItem: Item, newItem: Item) => {
  const dynamicDiff = {};
  const dynamics = _uniqBy(
    [...(newItem.dynamic || []), ...(oldItem.dynamic || [])],
    'model.name'
  );

  dynamics.forEach((nd: DynamicData) => {
    const oldModel =
      oldItem.dynamic!.find(({ model }) => model.name === nd.model.name) ||
      ({} as any);
    const newModel =
      newItem.dynamic!.find(({ model }) => model.name === nd.model.name) ||
      ({} as any);
    const oldValue = convertDynamicValueToString(
      oldModel?.filledData,
      nd.model.name
    );
    const newValue = convertDynamicValueToString(
      newModel?.filledData,
      nd.model.name
    );

    if (oldValue !== newValue) {
      dynamicDiff[nd.model.name] = [oldValue, newValue];
    }
  });

  return dynamicDiff;
};
const collectDifferentFields = (oldItem: Item, newItem: Item) => {
  const dynamicDiff = newItem.dynamic
    ? collectDynamicDiff(oldItem, newItem)
    : {};

  return Object.entries(newItem).reduce((acc, [key, value]) => {
    if (key === 'dynamic') return acc;

    const keyHandler = ITEM_KEYS[getPlainString(key)];
    let oldValue = oldItem[key].toString();
    let newValue = newItem[key].toString();
    if (keyHandler) {
      oldValue = keyHandler.get(oldItem);
      newValue = keyHandler.get(newItem);
    }

    if (Array.isArray(oldValue)) {
      oldValue = oldValue.sort((a, b) => (a > b ? -1 : 1)).join(', ');
      newValue = newValue.sort((a, b) => (a > b ? -1 : 1)).join(', ');
    }
    if (oldValue == newValue) return acc;

    return {
      ...acc,
      [key]: [oldValue, newValue],
    };
  }, dynamicDiff);
};

export const getCompareItemAndFields = (
  allItemsMap: Record<string, Item>,
  newItem: Item
) => {
  const existItem: Item = allItemsMap[getPlainString(newItem.title)];
  if (!existItem) return { newItem, differentFields: {}, sameItem: existItem };

  const sameItem = {
    ...existItem,
    dynamic: (existItem.dynamic || []).map(dd => ({
      ...dd,
      filledData: JSON.parse(dd.filledData),
    })),
  };

  const differentFields = collectDifferentFields(sameItem, newItem);
  return {
    sameItem,
    differentFields,
  };
};

const getDynamicValue = (dynamicModel: DynamicModel, value: string) => {
  let data = JSON.stringify({ [dynamicModel.name]: value });
  let filledData: any = { [dynamicModel.name]: value };
  if (dynamicModel.type === DynamicModelType.ManyToMany) {
    const ids = value
      .split(',')
      .map(vv => {
        const variant = dynamicModel.variants?.find(
          variant => variant.value[dynamicModel.name] === vv
        );
        return Number(variant?.id);
      })
      .filter(v => !!v);

    data = JSON.stringify({ ids });
    filledData = value.split(',').map(v => ({ [dynamicModel.name]: v }));
  }
  return {
    data,
    filledData,
  };
};

const checkDynamicModel = async (
  key: string,
  value: string,
  allDynamicModels: DynamicModel[]
) => {
  const name = toSnakeCase(key.replace('~', ''));
  const arrValue = value.split(',').filter(v => !!v);
  let dynamicModel: DynamicModel = allDynamicModels.find(
    model => model.name === name
  ) as DynamicModel;
  const isModelExist = !!dynamicModel;

  if (!dynamicModel) {
    const type =
      arrValue.length > 1
        ? DynamicModelType.ManyToMany
        : DynamicModelType.Inline;
    dynamicModel = await createDynamicModel({
      entity: Entity.Item,
      name,
      type,
    });
  }

  let dynamicField: DynamicFieldModel = dynamicModel.fields?.find(
    field => field.name === name
  ) as DynamicFieldModel;
  if (!dynamicField) {
    dynamicField = await createDynamicField({
      name,
      type: DynamicFieldType.String,
      modelID: dynamicModel.id,
      isArray: false,
      primaryField: true,
    });
    dynamicModel.fields.push(dynamicField);
  }

  if (dynamicModel.type === DynamicModelType.ManyToMany) {
    const dynamicVariants =
      dynamicModel.variants?.map(variant => variant.value[dynamicField.name]) ||
      [];
    const newVariants = arrValue.filter(vv => !dynamicVariants.includes(vv));
    if (newVariants.length) {
      const variants = await Promise.all(
        newVariants.map(variantValue =>
          createDynamicVariant(dynamicModel.id, dynamicField.name, variantValue)
        )
      );
      dynamicModel.variants = [
        ...(dynamicModel.variants || []),
        ...variants.map(v => ({ ...v, value: JSON.parse(v.value) })),
      ];
    }
  }

  allDynamicModels.forEach((model, i) => {
    if (model.id === dynamicModel.id) {
      allDynamicModels[i] = dynamicModel;
    }
  });
  if (!isModelExist) allDynamicModels.push(dynamicModel);

  const { data, filledData } = getDynamicValue(dynamicModel, value);

  return {
    model: dynamicModel,
    filledData,
    data,
  };
};

const constructNewItemV2 = async (
  item: Item & { category_id: string },
  allDynamicModels: DynamicModel[]
) => {
  const newItem = { dynamic: [], active: true } as any;
  const entries = Object.entries(item);

  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];
    const isDynamic = /~/.test(key);
    if (!isDynamic) {
      newItem[key] = value;
    } else {
      const dynamicData = await checkDynamicModel(key, value, allDynamicModels);
      newItem.dynamic = [...(newItem.dynamic || []), dynamicData];
    }
  }

  return newItem;
};

export const getParsedItemsV2 = async (
  response: Record<string, any>[],
  allCategories: Category[],
  allItemsMap: Record<string, Item>,
  allDynamicModels: DynamicModel[]
): Promise<any> => {
  const newCategories: Record<string, Partial<Category>> = {};
  const allParsedItems = {} as Record<string, CompareCategory>;
  const convertedDynamicModels = allDynamicModels.map(model => {
    if (model.variants) {
      return {
        ...model,
        variants: model.variants.map(variant => ({
          ...variant,
          value: JSON.parse(variant.value),
        })),
      };
    }
    return model;
  });

  for (let i = 0; i < response.length; i++) {
    const item = response[i];
    const { category_id, ...fields } = item;
    const [category] = fields.categories;
    const existCategory = allCategories.find(({ id, title }) => {
      if (!category) return false;
      return (
        id == category_id ||
        getPlainString(title) === getPlainString(category.title)
      );
    });

    let newItemCategory: any = existCategory;
    if (!existCategory) {
      newCategories[getPlainString(category.title)] = category;
      newItemCategory = category;
    } else if (existCategory && existCategory.title !== category.title) {
      newCategories[getPlainString(category.title)] = {
        ...existCategory,
        ...category,
        id: category_id,
      };
      newItemCategory = newCategories[getPlainString(category.title)];
    }

    const newItem = await constructNewItemV2(
      fields as any,
      convertedDynamicModels
    );

    if (allParsedItems[getPlainString(newItem.title)]) {
      const prevItem = allParsedItems[getPlainString(newItem.title)];
      const differentCategories = newItem.categories.filter(({ title }) =>
        prevItem.categories?.find(category => category.title !== title)
      );
      allParsedItems[getPlainString(newItem.title)].categories = [
        ...(prevItem.categories || []),
        ...differentCategories,
      ];
    } else {
      allParsedItems[getPlainString(newItem.title)] = newItem;
    }

    const { sameItem, differentFields } = getCompareItemAndFields(
      allItemsMap,
      newItem
    );
    newItem.sameItem = sameItem;
    newItem.differentFields = differentFields;
  }

  const categories = Object.values(allParsedItems).reduce((acc, item) => {
    const categoryTitle = item.categories![0].title;
    if (!categoryTitle) return acc;

    if (!_isEmpty(item.differentFields) || !item.sameItem) {
      return {
        ...acc,
        [categoryTitle]: [...(acc[categoryTitle] || []), item],
      };
    } else {
      return acc;
    }
  }, {});

  return {
    categories,
    allParsedItems,
    newCategories: Object.values(newCategories),
  };
};

export const getItemKeys = (categories: Category[]) => {
  if (!categories.length) return [];
  return Object.keys(
    (categories.find(({ items }) => !!items && items[0])?.items as Item[])[0]
  );
};

export const ITEM_KEYS = {
  free: {
    convertTo: 'free',
    getPath: 'free[0]',
    set: (value: string) => value.split(','),
    get: (item: Item) => item.free || [],
    check: (values: ItemFree[]) => {
      const isWrongValue = values.find(v => !ItemFree[v]);
      return isWrongValue
        ? `Available values are ${Object.values(ItemFree).join(', ')}`
        : '';
    },
  },
  link: {
    convertTo: 'link',
    getPath: 'link.url',
    set: (value: string) => ({
      url: value || '',
    }),
    get: (item: Item) => item.link?.url || '',
    check: () => {},
  },
  categories: {
    set: (value: string) => [{ title: value || '' }],
    get: (item: Item) => {
      return (item.categories || []).map(({ title }) => title);
    },
    check: () => {},
  },
};

export const transformCsvHeader = (
  csvHead: string,
  itemKeys: Record<string, string>,
  addingHeadersForParse: string[] = []
) => {
  const plainHead = getPlainString(csvHead);
  if (plainHead === 'category') return 'categories';
  if (plainHead === 'categoryid') {
    return toSnakeCase(csvHead);
  }
  if (addingHeadersForParse.includes(plainHead)) return csvHead;
  if (itemKeys[plainHead] || /~/.test(csvHead)) {
    return itemKeys[plainHead] || toSnakeCase(csvHead);
  }
};

export const transformCsvValue = (value: string, column: string = '') => {
  const handler = ITEM_KEYS[getPlainString(column)];
  if (!handler) return value;

  return handler.set(value);
};
