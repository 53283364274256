import React, { useRef, useState, DragEvent as DE, useEffect } from 'react';
import { Input, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import s from './styles.module.css';

interface Props {
  values: Array<string>;
  name?: string;
  onChange: (val: Array<string>, name: string) => void;
}

export const MultiString = ({ values, onChange, name = '' }: Props) => {
  const [withTagInput, toggleTagInput] = useState(false);
  const tagInputRef = useRef<any>(null);
  const addTag = () => {
    const value = tagInputRef.current?.input?.value || '';
    if (!value) {
      toggleTagInput(false);
      return;
    }

    values.push(value);
    onChange(values, name);
    tagInputRef.current?.setValue('');
    setTimeout(() => {
      tagInputRef.current?.focus();
    }, 0);
  };

  const drag = useRef<any>(null);
  const dragNode = useRef<any>(null);
  const [dragging, setDragging] = useState<boolean>(false);

  const documentDragPreventDefault = (e: DragEvent) => {
    e.preventDefault();
  };

  const handleDragEnd = (e: DE<HTMLDivElement>) => {
    dragNode.current?.removeEventListener('dragend', handleDragEnd);
    drag.current = null;
    dragNode.current = null;
    setDragging(false);
  };

  useEffect(() => {
    document.addEventListener('dragover', documentDragPreventDefault);
    return () => {
      document.removeEventListener('dragover', documentDragPreventDefault);
    };
  }, []);

  const handleDragStart = (e: DE<HTMLDivElement>, index: number) => {
    drag.current = index;
    dragNode.current = e.target;
    dragNode.current.addEventListener('dragend', handleDragEnd);
    setTimeout(() => {
      setDragging(true);
    }, 0);
  };

  const handleDragEnter = (e: DE<HTMLDivElement>, index: number) => {
    if (e.target === dragNode.current) return;
    const newList: Array<string> = JSON.parse(JSON.stringify(values));
    newList.splice(index, 0, newList.splice(drag.current!, 1)[0]);
    drag.current = index;
    onChange(newList, name);
  };

  const deleteTag = (idx: number) => {
    values.splice(idx, 1);
    onChange(values, name);
  };

  return (
    <div className={s.multiString}>
      {values.map((item, index) => (
        <div
          style={{ visibility: drag.current === index ? 'hidden' : 'visible' }}
          key={index}
          onDragStart={e => handleDragStart(e, index)}
          // @ts-ignore
          onDragEnter={dragging ? e => handleDragEnter(e, index) : null}
          draggable="true"
        >
          <Tag
            className={s.tag}
            closable
            onClose={e => {
              e.preventDefault();
              deleteTag(index);
            }}
          >
            {item}
          </Tag>
        </div>
      ))}

      {!withTagInput ? (
        <Tag
          className={s.inputTag}
          onClick={() => {
            toggleTagInput(true);
            setTimeout(() => {
              tagInputRef.current?.focus();
            }, 0);
          }}
        >
          <PlusOutlined /> New tag
        </Tag>
      ) : (
        <Input
          ref={tagInputRef}
          type="text"
          size="small"
          style={{ width: 78, height: '20px', fontSize: '14px', margin: '6px' }}
          onBlur={addTag}
          onPressEnter={addTag}
        />
      )}
    </div>
  );
};
