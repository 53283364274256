/** Application reducers */
import { faApiBaseApi } from 'entities/font-awesome-icon';

const applicationReducer = {
  [faApiBaseApi.reducerPath]: faApiBaseApi.reducer,
} as const;

const getRootReducer = () => {
  return Object.assign({}, applicationReducer);
};

const rootReducer = getRootReducer();

export default rootReducer;
