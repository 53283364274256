import { Checkbox } from 'antd';
import { DynamicModel } from 'graphql/types';
import { DynamicDataParser } from 'utils/dynamicDataParser';

type Props = {
  model: DynamicModel;
  dynamicDataParser: DynamicDataParser;
  onChange: (id: string | string[]) => void;
  chosenId?: string | string[];
};

export const DynamicInputImage = ({
  model,
  dynamicDataParser,
  onChange,
  chosenId,
}: Props) => {
  const isChosen = (id: string | number) => {
    if (chosenId instanceof Array) {
      return !!chosenId.find(item => id == item);
    }
    return chosenId == id;
  };

  const primaryKey = dynamicDataParser.getPrimaryField(model.fields).name;
  return (
    <div className="flex flex-wrap">
      {(model.variants || []).map(({ value, id }) => {
        return (
          <div
            key={id}
            role="presentation"
            onClick={() => onChange(id)}
            style={{
              position: 'relative',
              padding: 10,
              width: 150,
              height: 150,
              cursor: 'pointer',
            }}
          >
            <Checkbox
              checked={isChosen(id)}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
            <img
              src={JSON.parse(value)[primaryKey]}
              alt={id}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
