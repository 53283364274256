import React, { CSSProperties, useState } from 'react';
import { message, Modal, Popconfirm } from 'antd';
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import {
  File,
  ParentType,
  PictureDictionary,
  UploadImageMutation,
  UploadInput,
  useUploadImageMutation,
} from 'graphql/types';
import classnames from 'classnames';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { constructUploadImagesWithRetina } from 'nativeComponents/Common/Pictures/helpers';
import { getImageDimensions } from './helpers';

import s from './styles.module.css';

type Props = {
  onChange?: (name: string, image: PictureDictionary[]) => void;
  length?: number;
  saveOptions?: Record<string, any>;
  previews?: string | string[];
  name: string;
  circle?: boolean;
  withZeroCrop?: boolean;
  disabled?: boolean;
  size?: number | string;
  onDelete: (name: string, i: number) => void;
  style?: CSSProperties;
  uploadHandler?: (file: File) => void;
  restrictSize?: number;
  type: 'image' | 'icon';
  parent?: ParentType;
};

export const UIUpload: React.FC<Props> = ({
  previews = [],
  onChange = () => {},
  length = 1,
  saveOptions = {},
  size = 104,
  type,
  onDelete,
  name,
  circle,
  style = {},
  uploadHandler,
  disabled,
  restrictSize,
  parent,
  withZeroCrop,
}) => {
  const { screenshot } = useConfig();
  const [modalState, setModalState] = useState({
    isOpen: false,
    image: '',
  });

  const filteredPreviews = (
    previews instanceof Array ? previews : [previews]
  ).filter(item => !!item);

  const [saveImage, { loading: imageLoading }] = useUploadImageMutation({
    onError: e => {
      message.error(e.message);
    },
  });

  const handleSaveImage = async (input: UploadInput) => {
    try {
      const { data: { uploadImage } = {} as UploadImageMutation } =
        (await saveImage({ variables: { input } })) as any;

      let outputImage;
      switch (type) {
        case 'image': {
          outputImage = restrictSize
            ? {
                ...uploadImage,
                Sizes: uploadImage.Sizes.filter(
                  ({ size }) => size === restrictSize
                ),
              }
            : uploadImage;
          break;
        }
        case 'icon': {
          outputImage = uploadImage.Sizes;
          break;
        }
        default:
          break;
      }

      onChange(name, outputImage as any);
    } catch (e) {
      message.error(e.message);
    }
  };

  const handleChange = async ({ target: { files } }) => {
    if (imageLoading) return;
    if (uploadHandler) {
      await uploadHandler(files[0]);
      return;
    }

    let positions = null;
    if (withZeroCrop) {
      const { width, height } = await getImageDimensions(files[0]);
      positions = { width, height, x: 0, y: 0 } as any;
    }

    const input = {
      file: files[0],
      sizes: constructUploadImagesWithRetina(
        screenshot.sizes,
        parent,
        // HARDCODE
        parent === ParentType.BlogPost
      ),
      positions,
    };

    if (restrictSize) {
      const fr = new FileReader();
      fr.onload = async function () {
        const img = new Image();
        img.onload = async function () {
          input.sizes = [
            {
              width: Math.min(img.width, restrictSize),
              aspectRatio: 1,
              watermark: false,
            } as any,
          ];
          handleSaveImage(input);
        };
        img.src = fr.result as string;
      };
      fr.readAsDataURL(files[0]);
    } else {
      handleSaveImage(input);
    }
  };

  const handleModalState = (image: string) => () => {
    setModalState({ isOpen: true, image });
  };

  /* eslint-disable */
  return (
    <div className="flex" style={style}>
      {filteredPreviews.map((src, i) => (
        <div
          key={src}
          className={s.previewContainer}
          style={{
            position: 'relative',
            width: size,
            height: size,
          }}
        >
          <div className={s.preview}>
            <img
              className={s.image}
              src={src}
              alt={src}
              style={{
                borderRadius: circle ? '50%' : 0,
              }}
            />
            <div className={s.actions}>
              <EyeOutlined onClick={handleModalState(src)} />
              <Popconfirm
                onConfirm={() => onDelete(name, i)}
                title="Delete image?"
              >
                <DeleteOutlined />
              </Popconfirm>
            </div>
          </div>
        </div>
      ))}
      {length > filteredPreviews.length && (
        <label
          className={classnames({
            [s.label]: true,
            pending: imageLoading || disabled,
          })}
          style={{
            width: size,
            height: size,
          }}
        >
          <input
            type="file"
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          <PlusOutlined />
          <span className={s.inputText}>Upload</span>
        </label>
      )}
      <Modal
        visible={modalState.isOpen}
        title={false}
        footer={null}
        onCancel={() => setModalState({ isOpen: false, image: '' })}
      >
        <img alt="example" style={{ width: '100%' }} src={modalState.image} />
      </Modal>
    </div>
  );
};
