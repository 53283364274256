import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { CmsModels, useActiveModelsLazyQuery } from 'graphql/types';

type ActiveModelContextType = {
  activeModels: Record<CmsModels, boolean> & { __typename?: string };
  changeState: (v: Record<CmsModels, boolean>) => void;
};

const ActiveModelContext = React.createContext<ActiveModelContextType>({
  activeModels: {} as ActiveModelContextType['activeModels'],
  changeState: () => {},
});

export const useActiveModel = () => React.useContext(ActiveModelContext);

export const ActiveModelProvider = ({ children }) => {
  const [state, setState] = useState({
    activeModels: {} as ActiveModelContextType['activeModels'],
  });

  const [fetchModels] = useActiveModelsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ activeModels }) => {
      setState(prev => ({ ...prev, activeModels: activeModels || {} } as any));
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const changeState = (activeModels: Record<CmsModels, boolean>) => {
    setState(prev => ({ ...prev, activeModels }));
  };

  useEffect(() => {
    fetchModels();
  }, []);

  return (
    <ActiveModelContext.Provider
      value={{
        activeModels: state.activeModels,
        changeState,
      }}
    >
      {children}
    </ActiveModelContext.Provider>
  );
};
