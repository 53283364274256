import React from 'react';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import s from './sort.module.css';

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
  onChange: (data: string | number, increment?: boolean) => number | void;
  onEnter?: () => void;
  value: string | number;
};

export const VariantInput = ({ onChange, value, ...inputProps }: Props) => {
  const handleIncrement = () => {
    onChange(parseInt(value as string) + 1, true);
  };
  const handleDecrement = () => {
    onChange(Math.max(parseInt(value as string) - 1, 0), true);
  };

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  const handleKeyDown = e => {
    const enter = e.charCode === 13;
    if (enter && inputProps.onBlur) {
      inputProps.onBlur(e as any);
    }
  };

  return (
    <div className={s.variantInput}>
      <input
        onChange={handleChange}
        placeholder="Variants"
        value={value}
        onKeyPress={handleKeyDown}
        {...inputProps}
      />
      <div className={s.counter}>
        <UpOutlined onClick={handleIncrement} />
        <DownOutlined onClick={handleDecrement} />
      </div>
    </div>
  );
};
