import React, { FC, useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Slider } from 'antd';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

export type ImageSizes = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Props = {
  src: string;
  aspectRatio: number;
  showCropRatio?: number;
  lockSize?: number;
  close: () => void;
  description: string;
  uploadHandler: (imageData: ImageSizes, onComplete?: () => void) => void;
};

export const ReactCropTool: FC<Props> = ({
  src,
  description,
  aspectRatio,
  lockSize,
  showCropRatio,
  close,
  uploadHandler,
}) => {
  const dataUrlRef =
    useRef<
      | (ImageSizes & {
          data: any;
        })
      | null
    >(null);
  const [fullscreen, setFullscreen] = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [zoomSpeed, setZoomSpeed] = useState(0.2);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [canvasLoading, setCanvasLoading] = useState(true);

  const clearCrop = () => {
    // @ts-ignore
    const cropper: any = cropperRef?.current?.cropper;
    if (!cropper) return;
    cropper.clear();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    const { code } = e;
    if (
      code === 'ArrowRight' ||
      code === 'ArrowUp' ||
      code === 'ArrowLeft' ||
      code === 'ArrowDown'
    ) {
      // @ts-ignore
      const cropper: any = cropperRef?.current?.cropper;
      if (!cropper) return;

      e.preventDefault();
      e.stopPropagation();
      switch (code) {
        case 'ArrowDown':
          cropper.move(0, -1);
          break;
        case 'ArrowLeft':
          cropper.move(-1, 0);
          break;
        case 'ArrowRight':
          cropper.move(1, 0);
          break;
        case 'ArrowUp':
          cropper.move(0, 1);
          break;
        default:
          break;
      }
      return;
    }
    if (code === 'ShiftLeft') {
      clearCrop();
    }
  };

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const cropData = cropper.getData();
    dataUrlRef.current = {
      data: cropper.getCroppedCanvas().toDataURL(),
      ...cropData,
    };
    setCurrentWidth(cropper.getCroppedCanvas().width);
  };

  const save = async () => {
    if (!dataUrlRef.current?.data) return;
    setLoading(true);
    await uploadHandler(dataUrlRef.current);
    setLoading(false);
  };

  const requestFullScreen = () => {
    if (!container.current) return;
    setFullscreen(true);
    if (container.current.requestFullscreen) {
      container.current.requestFullscreen();
      // @ts-ignore
    } else if (container.current.webkitRequestFullscreen) {
      // @ts-ignore
      container.current.webkitRequestFullscreen();
      // @ts-ignore
    } else if (container.current.msRequestFullscreen) {
      // @ts-ignore
      container.current.msRequestFullscreen();
    } else {
      setFullscreen(false);
    }
  };

  const exitFullscreen = () => {
    document.exitFullscreen();
    setFullscreen(false);
  };

  const init = () => {
    window.onkeydown = onKeyDown;
  };

  const onCanvasReady = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const containerData = cropper.getContainerData();
    cropper.setCropBoxData({
      width: cropper.imageData.width,
      height: containerData.height,
      top: 10,
      left: containerData.width / 2 - cropper.imageData.width / 2,
    });
    cropper.setCanvasData({ top: 10 });
    setCanvasLoading(false);
  };

  const deinit = () => {
    window.onkeydown = null;
  };

  useEffect(() => {
    init();
    return deinit;
  }, []);

  return (
    <div
      ref={container}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        paddingTop: 20,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        zIndex: 1000,
        flexDirection: 'column',
      }}
    >
      {canvasLoading && (
        <div
          style={{
            zIndex: 10000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <LoadingOutlined spin style={{ fontSize: 24 }} />
        </div>
      )}

      <Cropper
        src={src}
        style={{ height: '80%', width: '80%' }}
        crop={onCrop}
        ref={cropperRef}
        movable
        wheelZoomRatio={zoomSpeed}
        dragMode="move"
        aspectRatio={showCropRatio || aspectRatio}
        ready={onCanvasReady}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '20%',
        }}
      >
        <Button
          onClick={fullscreen ? exitFullscreen : requestFullScreen}
          style={{ marginRight: '24px' }}
          icon={
            !fullscreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />
          }
        />
        <span>Zoom speed: </span>
        <Slider
          style={{ width: 150, margin: '0 12px' }}
          min={1}
          max={10}
          onChange={val => setZoomSpeed(val / 20)}
          value={zoomSpeed * 20}
        />
        <span style={{ marginRight: '24px' }}>
          Crop width: <span>{currentWidth}</span> / {description}
        </span>

        <Button
          loading={loading}
          disabled={loading || (!!lockSize && currentWidth < lockSize)}
          onClick={save}
          type="primary"
          style={{ marginRight: '12px' }}
        >
          Save
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          onClick={close}
          type="dashed"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
