import { EnumChooser } from '../Common/EnumChooser/EnumChooser';
import { ItemFree, Platform } from '../../graphql/types';
import React from 'react';
import { Input, Typography } from 'antd';

export const ItemEditFreePlatform = ({
  modelFields = {} as any,
  platform,
  onChangeEnums = (() => () => {}) as any,
  onInputChange = (() => () => {}) as any,
  free,
  price,
}) => {
  return (
    <div>
      {modelFields.price && (
        <div className="sideContentItem mb-2">
          <Typography.Title level={5}>Price</Typography.Title>
          <Input
            type="number"
            value={price}
            onChange={onInputChange('price')}
            name="price"
          />
        </div>
      )}
      {modelFields.platform && (
        <EnumChooser
          checkedList={platform}
          enumList={Platform}
          title="Platforms"
          onChange={(checked, value) =>
            onChangeEnums('platform', value, true)()
          }
        />
      )}
      {modelFields.free && (
        <EnumChooser
          checkedList={free}
          enumList={ItemFree}
          title="Free"
          onChange={(checked, value) => onChangeEnums('free', value)()}
        />
      )}
    </div>
  );
};
