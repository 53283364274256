import React, { useState } from 'react';
import { Input, Typography } from 'antd';
import { EditCard } from 'components/Common/EditCard/EditCard';
import { HTMLEditor } from '../../nativeComponents/Common/HTMLEditor/HTMLEditor';
import { SubmitGroup } from '../Common/SubmitGroup/SubmitGroup';
import { DynamicFieldsComponent } from '../DynamicFieldsComponent';
import { Entity } from '../../graphql/types';
import { getDynamicModelsPayload } from '../DynamicFieldsComponent/helpers';

export const PageEdit = props => {
  const { title, slug, content, onSubmit, dynamic = [] } = props;

  const [state, setState] = useState<any>({
    title,
    slug,
    content,
    dynamic,
  });

  const onChange = ({ target: { name, value } }) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (redirect?: boolean) => {
    onSubmit({
      ...state,
      dynamic: getDynamicModelsPayload(state.dynamic),
      redirect,
    });
  };

  return (
    <EditCard>
      <div className="fluid flex">
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Title</Typography.Title>
            <Input value={state.title} onChange={onChange} name="title" />
          </div>
          <div className="editTitle">
            <Typography.Title level={5}>Slug</Typography.Title>
            <Input value={state.slug} onChange={onChange} name="slug" />
          </div>
          <div className="editTitle" style={{ border: 'none' }}>
            <Typography.Title level={5}>Content</Typography.Title>
            <HTMLEditor
              value={state.content}
              currentLanguage=""
              onChange={onChange}
              name="content"
            />
          </div>
          <DynamicFieldsComponent
            update={d => {
              setState(prev => ({ ...prev, dynamic: d }));
            }}
            dynamicData={state.dynamic}
            entity={Entity.Pages}
            noBuffer
          />
        </div>
      </div>

      <SubmitGroup onSubmit={handleSubmit} />
    </EditCard>
  );
};
