import React from 'react';
import { Switch, Typography } from 'antd';
import { capitalFirst } from 'utils/string';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import { DynamicFieldModel, DynamicModel } from '../../graphql/types';
import { getData } from './helpers';

import s from './styles.module.css';

type Props = {
  onUpdate: (a: any) => void;
  dynamicDataParser: DynamicDataParser | null;
  model: DynamicModel;
  field: DynamicFieldModel;
  index?: number;
};

export const BooleanDynamicComponent = ({
  dynamicDataParser,
  model,
  field,
  onUpdate,
  index,
}: Props) => {
  return (
    <div className={s.boolComponent}>
      <span>{capitalFirst(field.name)}</span>
      <Switch
        checked={getData(dynamicDataParser?.getFieldData(model, field, index))}
        onChange={checked => {
          onUpdate(dynamicDataParser!.changeData(model, field, checked, index));
        }}
      />
    </div>
  );
};
