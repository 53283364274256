import { WithWithout } from 'graphql/types';

export const filters = {
  filters: [
    {
      name: 'By status',
      items: [
        {
          name: 'Favicon',
          value: 'iconFilter',
          status: WithWithout.With,
        },
        {
          name: 'Active',
          value: 'active',
          status: 'true',
        },
        {
          name: 'Disabled',
          value: 'active',
          status: 'false',
        },
      ],
    },
  ],
  booleanFilters: [
    {
      label: 'Show deleted',
      filter: 'deleted',
    },
    // Adding new filter for items uploaded by Admin
    {
      label: 'User Uploaded',
      filter: 'uploadedByUser',
    },
  ],
  extendFilters: [
    {
      name: 'Status',
      value: 'active',
      variants: [
        {
          name: 'Available',
          value: 'true',
        },
        {
          name: 'Not available',
          value: 'false',
        },
      ],
    },
    {
      name: 'Category',
      value: 'categories',
      type: 'search',
      sign: 'slug',
    },
    {
      name: 'Company',
      value: 'companies',
      type: 'search',
      sign: 'slug',
    },
    {
      name: 'Topics',
      value: 'skills',
      type: 'search',
      sign: 'title',
    },
    {
      name: 'Screenshot',
      value: 'thumbFilter',
      variants: [
        {
          name: 'Available',
          value: WithWithout.With,
        },
        {
          name: 'Not available',
          value: WithWithout.Without,
        },
      ],
    },
    {
      name: 'Description',
      value: 'descriptionFilter',
      variants: [
        {
          name: 'Available',
          value: WithWithout.With,
        },
        {
          name: 'Not available',
          value: WithWithout.Without,
        },
      ],
    },
    {
      name: 'Favicon',
      value: 'iconFilter',
      variants: [
        {
          name: 'Available',
          value: WithWithout.With,
        },
        {
          name: 'Not available',
          value: WithWithout.Without,
        },
      ],
    },
  ],
};
