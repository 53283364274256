import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { AuthForm } from 'nativeComponents/Auth/AuthForm';
import {
  getCurrentUser,
  authUser as loginUser,
  createUser,
} from 'nativeConfig/user';
import { message } from 'antd';
import { User, UserInput } from 'graphql/types';

type UserContextType = {
  user?: User;
  auth: (d: UserInput) => void;
  logout: () => void;
};
type StateType = {
  user?: UserContextType['user'];
  loading: boolean;
  isHaveToken: boolean;
};

const UserContext = React.createContext<UserContextType>({
  user: undefined,
  auth: () => false,
  logout: () => false,
});

export const useUser = () => React.useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<StateType>({
    user: undefined,
    loading: false,
    isHaveToken: !!localStorage.getItem('adminToken'),
  });

  const handleLogin = async ({ email, password }: UserInput) => {
    try {
      setState(prev => ({ ...prev, pending: true }));
      const user = await loginUser({ email, password });
      // const user = await createUser({ email, password });
      localStorage.setItem('adminToken', user.token);
      setState(prev => ({
        ...prev,
        pending: false,
        user,
        isHaveToken: true,
      }));
    } catch (e) {
      setState(prev => ({ ...prev, pending: false, isHaveToken: false }));
      localStorage.removeItem('adminToken');
      message.error(e.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setState(prev => ({ ...prev, isHaveToken: false }));
  };

  const authUser = async () => {
    try {
      setState(prev => ({ ...prev, pending: true }));
      const user = await getCurrentUser();

      setState(prev => ({ ...prev, pending: false, user, isHaveToken: true }));
    } catch (e) {
      setState(prev => ({ ...prev, pending: false }));
      localStorage.removeItem('adminToken');
      message.error(e.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) return;
    authUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        auth: handleLogin,
        logout: handleLogout,
      }}
    >
      <div className={classnames({ pending: state.loading })}>
        {!state.isHaveToken && <AuthForm onSubmit={handleLogin} />}
        {state.isHaveToken && children}
      </div>
    </UserContext.Provider>
  );
};
