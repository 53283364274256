import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import s from './styles.module.css';
import { useGetPipelineDataQuery } from 'graphql/types';

const BuildInfo: React.FC = () => {
  const { loading, error, data, refetch } = useGetPipelineDataQuery();
  const [sinceTime, setSinceTime] = useState<string>('');

  const formatDateTime = (date: string): string => {
    const formattedDate = new Date(date);
    const formattedDateString = `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()} ${formattedDate.getHours()}:${formattedDate.getMinutes() < 10 ? '0' : ''}${formattedDate.getSeconds() < 10 ? '0' : ''}${formattedDate.getSeconds()}`;
    return formattedDateString;
  };

  const calculateElapsedTime = (createdAt: string): string => {
    const startDate = new Date(createdAt);
    const currentDate = new Date();
    const elapsedTime = currentDate.getTime() - startDate.getTime();

    const years = Math.floor(elapsedTime / (1000 * 60 * 60 * 24 * 365));
    const months = Math.floor((elapsedTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor((elapsedTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
    const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

    return `${years} y, ${months} m, ${days} d, ${hours} h, ${minutes} m, ${seconds} s`;
  };

  useEffect(() => {
    if (data && data.fetchPipelineData) {
      const since = calculateElapsedTime(data.fetchPipelineData.created_at);
      setSinceTime(since);
    }
  }, [data]);

  const handleUpdateInfo = async () => {
    try {
      const { data: refetchedData } = await refetch();
      if (refetchedData && refetchedData.fetchPipelineData) {
        const since = calculateElapsedTime(refetchedData.fetchPipelineData.created_at);
        setSinceTime(since);
      }
    } catch (error) {
      console.error('Error refetching data:', error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const pipelineData = data ? data.fetchPipelineData : null;

  if (!pipelineData) return <p>No data available</p>;

  const builtDateTime = formatDateTime(pipelineData.created_at);
  const lastBuildDateTime = formatDateTime(pipelineData.updated_at);
  const currentBuildStatus = pipelineData.status;

  return (
    <div className={s.BuildInfoContainer}>
      <button className={s.button} onClick={handleUpdateInfo} style={{ marginBottom: '20px' }}>
        Update Info
      </button>
      <p>Built: {builtDateTime}</p>
      <p>Since: {sinceTime}</p>
      <p>Last Build Status: {currentBuildStatus}</p>
    </div>
  );
};

export default BuildInfo;
