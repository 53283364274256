import {
  GetSkillListDocument,
  GetSkillDocument,
  CreateSkillDocument,
  UpdateSkillDocument,
  Item,
  DeleteSkillDocument,
  SortBy,
  SortDirection,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';
const apolloClient = initializeApollo();

export const getList = async (
  {
    page = 1,
    limit = 10,
    sort = SortBy.CreationDate,
    sortDirection = SortDirection.Desc,
    search,
    filters = {},
  } = {} as any
) => {
  const {
    data: {
      skills: { data, count },
    },
  } = await apolloClient.query({
    query: GetSkillListDocument,
    fetchPolicy: 'no-cache',
    variables: {
      page: Number(page),
      limit: Number(limit),
      search,
      sortBy: sort,
      sortByDirection: sortDirection,
      ...filters,
    },
  });

  return { data, total: count };
};

export const getOne = async ({ id }) => {
  if (!id) return { data: { skill: {} } };

  const {
    data: { skill },
  } = await apolloClient.query({
    query: GetSkillDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { data: skill };
};

export const createOne = async (data: any) => {
  return apolloClient.query({
    query: CreateSkillDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        title: data.title,
        icon: data.icon,
      },
    },
  });
};
export const updateOne = async (data: any) => {
  return apolloClient.query({
    query: UpdateSkillDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
      input: {
        title: data.title,
        icon: data.icon,
        fontAwesomeIcon: data.fontAwesomeIcon,
      },
    },
  });
};

export const deleteOne = async (data: Item) => {
  await apolloClient.query({
    query: DeleteSkillDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
