import React from 'react';
import { Checkbox } from 'antd';

export const ScreenshotGenerateItem = ({ checked, title, id, onChange }) => {
  const handleChange = () => onChange(id);

  return (
    <div
      className="flex align-center px-4 py-2 mb-4 mr-4"
      role="presentation"
      onClick={handleChange}
      style={{
        backgroundColor: '#ffffff',
        borderRadius: 5,
        cursor: 'pointer',
        width: '20%',
      }}
    >
      <Checkbox checked={checked} />
      <span
        className="ml-2"
        style={{
          fontWeight: 500,
        }}
      >
        {title}
      </span>
    </div>
  );
};
