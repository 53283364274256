import React from 'react';
import { Switch } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import { CategoryEdit } from 'components/Categories/CategoryEdit/CategoryEdit';
import { TitleWithImage } from 'nativeComponents/List/ListRowTitle/TitleWithImage';
import { filters } from './filters';
import { sort } from './sort';

const categoryResource = {
  name: 'category',
  menuIcon: FolderOpenOutlined,
  pageTitle: 'Categories',
  editComponent: CategoryEdit,
  ...filters,
  sort,
  list: {
    actions: ['active', 'delete'],
    columns: [
      {
        title: 'Title',
        key: 'title',
        sort: true,
        filter: true,
        asLink: true,
        render: (record, data) => {
          return <TitleWithImage data={data} record={record} />;
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: (record, data, { onChangeActive }) => {
          return (
            <Switch
              checked={data.active}
              onChange={checked => {
                onChangeActive(data.id, checked);
              }}
            />
          );
        },
      },
    ],
  },
};

export default categoryResource;
