import {
  findIconDefinition,
  IconLookup,
  IconName
} from '@fortawesome/fontawesome-svg-core';
import { IconLight } from '../types';
import { isFabIcon } from './is-fab-icon';

export const getIcon = (
  iconLike: IconLight
): Undefinable<IconLookup | IconName> => {
  if (iconLike?.shim && iconLike.shim.prefix) {
    return findIconDefinition({
      iconName: iconLike.id,
      prefix: iconLike.shim.prefix
    });
  } else if (isFabIcon(iconLike)) {
    return findIconDefinition({ iconName: iconLike.id, prefix: 'fab' });
  }
  return iconLike.id;
};
