import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Radio, message, Button, Typography } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useActiveModel } from 'nativeComponents/ActiveModel/ActiveModel';
import { Breadcrumbs } from 'nativeComponents/Breadcrumbs/Breadcrumbs';
import { EditCard } from 'components/Common/EditCard/EditCard';
import {
  CmsModels,
  InputActiveModels,
  useToggleActiveModelsMutation,
} from 'graphql/types';
import { SettingsSubItem } from '../SettingsSubItem/SettingsSubItem';
import * as Types from './helpers';

import s from './styles.module.css';

export const SettingsList = () => {
  const { activeModels, changeState } = useActiveModel();
  const [state, setState] = useState({ models: {}, opened: '' });

  const [saveActiveModels] = useToggleActiveModelsMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ toggleActiveModels }) => {
      const { __typename, ...models } = toggleActiveModels || {};
      changeState(models as any);
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const handleToggleModel = (model: CmsModels) => e => {
    e.stopPropagation();
    setState(prev => ({
      ...prev,
      models: {
        ...prev.models,
        [model]: !prev.models[model],
      },
    }));
  };

  const handleOpen = (model: CmsModels) => () => {
    setState(prev => ({ ...prev, opened: prev.opened === model ? '' : model }));
  };

  const submit = () => {
    saveActiveModels({
      variables: {
        input: state.models as InputActiveModels,
      },
    });
  };

  useEffect(() => {
    const { __typename, ...models } = activeModels;
    setState(prev => ({ ...prev, models }));
  }, [activeModels]);
  /* eslint-disable */
  return (
    <div className="container-shrink">
      <Breadcrumbs breadcrumbs={[]} withBack />
      <EditCard>
        <div className="flex-column">
          <div className="flex flex-wrap">
            {Object.values(CmsModels).map(model => {
              const isOpen = state.opened === model;
              const options =
                Types[
                  `${model.charAt(0).toUpperCase()}${model.slice(1)}Fields`
                ] || {};
              const isHaveOptions = !_isEmpty(options);
              return (
                <div key={model} className={s.itemWrapper}>
                  <div
                    className={s.itemContent}
                    role="presentation"
                    onClick={handleOpen(model)}
                  >
                    <Typography.Title level={5} className="flex align-center">
                      <span
                        role="presentation"
                        onClick={handleToggleModel(model)}
                      >
                        <Radio checked={state.models[model]} />
                        {model}
                      </span>
                    </Typography.Title>
                    {isHaveOptions && (
                      <CaretDownOutlined
                        className="ml-4 mt-1"
                        style={{
                          transform: `rotate(${isOpen ? 180 : 0}deg)`,
                        }}
                      />
                    )}
                  </div>
                  <SettingsSubItem
                    fields={options}
                    model={model}
                    visible={isOpen && isHaveOptions}
                    onSubmit={submit}
                    dynamicEntity={options.DynamicEntity}
                  />
                </div>
              );
            })}
          </div>

          <div className="py-4">
            <Button
              color="primary"
              type="primary"
              style={{
                position: 'sticky',
                top: 80,
              }}
              onClick={submit}
            >
              Save
            </Button>
          </div>
        </div>
      </EditCard>
    </div>
  );
};
