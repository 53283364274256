import {
  DefaultSizesSettings,
  ParentType,
  Picture,
  SizesSettings,
} from 'graphql/types';
import _flatten from 'lodash/flatten';
import { ProjectSettings } from 'type/settings';
import { PictureMeta } from 'type';

// export const aspectRatio = (
//   width: number
// ): { text: string; value: number; forSave?: number } => {
//   switch (width) {
//     case 210:
//       return { text: '1/1', value: 1 };
//     case 350:
//       return { text: '4/3', value: 4 / 3 };
//     case 700:
//       return { text: '4/3', value: 4 / 3 };
//     case 1200:
//       return { text: '4/3', value: 4 / 3, forSave: 1118 / 460 };
//
//     default:
//       return { text: '1/1', value: 1 };
//   }
// };
// const DEFAULT_SIZES = [
//   { width: 1200, aspectRatio: 1118 / 460 },
//   { width: 700, aspectRatio: 4 / 3 },
//   { width: 350, aspectRatio: 4 / 3 },
// ];
export const constructUploadImages = (
  sizes: ProjectSettings['sizes'],
  type: ParentType = ParentType.Item
) => {
  const entitySizes = sizes.filter(({ parentType }) => parentType === type);
  const theBiggestSize = entitySizes.reduce((acc, size) => {
    if (size.width > acc) {
      return size.width;
    }
    return acc;
  }, 0);

  return entitySizes.map(({ width, aspectRatio }) => {
    return {
      width,
      aspectRatio,
      watermark: width === theBiggestSize,
    };
  });
};

export const constructUploadImagesWithRetina = (
  sizes: SizesSettings[],
  type: ParentType = ParentType.Item,
  noWatermark?: boolean
) => {
  const uploadSizes = constructUploadImages(sizes, type);
  return _flatten(
    uploadSizes.map(size => {
      const configSize = sizes.find(
        cs => cs.width === size.width && cs.parentType === type
      );
      if (configSize?.retina) {
        return [size, { ...size, width: size.width * 2 }];
      }

      return size;
    })
  ).map(size => {
    if (noWatermark) {
      return {
        ...size,
        watermark: false,
      };
    }
    return size;
  });
};

export const getNewCroppingImagesList = (
  prevList: Picture[],
  croppedList: Picture,
  { index: imageIndex, size: croppedSize = 0 }: { index: number; size: number },
  positions: Picture['positions'],
  config: DefaultSizesSettings
): Picture[] => {
  const currentOriginImage: Picture['Sizes'][number] = prevList[
    imageIndex
  ].Sizes.find(({ size }) => size === 0) as Picture['Sizes'][number];
  if (!config.oneByOneCrop) {
    return [
      ...prevList.slice(0, imageIndex),
      {
        ...prevList[imageIndex],
        id: prevList[imageIndex].id,
        Sizes: [currentOriginImage, ...(croppedList.Sizes || [])],
        positions,
      },
      ...prevList.slice(imageIndex + 1),
    ];
  }

  const needCroppedImage = croppedList.Sizes.find(
    ({ size }) => size === croppedSize
  ) as Picture['Sizes'][number];
  return [
    ...prevList.slice(0, imageIndex),
    {
      id: prevList[imageIndex].id,
      Sizes: [
        currentOriginImage,
        ...prevList[imageIndex].Sizes.filter(
          ({ size }) => size !== croppedSize
        ),
        needCroppedImage,
      ].filter(t => !!t),
      positions,
    },
    ...prevList.slice(imageIndex + 1),
  ];
};

export const constructImagesFroCropShow = (
  settings: ProjectSettings,
  itemPicture: Picture
): {
  screenshots: PictureMeta[];
  meta: PictureMeta;
} => {
  const defaultSettings = !settings.oneByOneCrop || !settings;
  const screenshots = constructUploadImages(settings.sizes, ParentType.Item)
    .filter(r => !!r)
    .sort((a, b) => (a.width > b.width ? -1 : 1))
    .map((size, i) => ({
      ...size,
      showCropRatio:
        size.width === 1200 && !settings.oneByOneCrop ? 1.3 : undefined,
      disabledToEdit: defaultSettings,
    }));

  if (!settings.oneByOneCrop) {
    const mainScreenshotWithWatermark = itemPicture.Sizes.find(
      ({ hasWatermark }) => hasWatermark
    );

    return {
      screenshots: [],
      meta: {
        ...screenshots[0],
        src:
          mainScreenshotWithWatermark?.value ||
          (
            itemPicture.Sizes.find(
              ({ size }) => size === screenshots[0].width
            ) || {}
          ).value,
      },
    };
  }
  return { screenshots, meta: screenshots[0] };
};

export const handleSaveImage = async (
  saveHandler: (d: any) => any,
  file: File | Blob | null,
  sizes: DefaultSizesSettings['sizes'],
  type: ParentType = ParentType.Item
) => {
  if (!file) return [];
  const input = {
    file,
    sizes: constructUploadImagesWithRetina(sizes, type),
  };
  const response = await saveHandler({ variables: { input } });
  if (!response.data) throw new Error('Upload error');

  const { uploadImage } = response.data;
  return uploadImage || [];
};

export const getImageBlob = (src: string) => {
  const restUrl = (process.env.REACT_APP_API_URI || '').replace('/query', '');
  return fetch(`${restUrl}/image?url=${src}`, {
    method: 'GET',
  });
};
