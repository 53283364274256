import {
  Item,
  GetStaffsDocument,
  UpdateStaffDocument,
  StaffInput,
  CreateStaffDocument,
  GetStaffDocument,
  DeleteStaffDocument,
} from 'graphql/types';
import { initializeApollo } from '../../utils/apollo';
const apolloClient = initializeApollo();

export const getList = async ({ search }) => {
  const {
    data: { staffs },
  } = await apolloClient.query({
    query: GetStaffsDocument,
    fetchPolicy: 'no-cache',
    variables: {
      search,
    },
  });

  return { data: staffs };
};

export const getOne = async ({ id }) => {
  if (!id) return { data: { staff: {} } };
  const {
    data: { staff },
  } = await apolloClient.query({
    query: GetStaffDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });
  return { data: staff };
};

export const createOne = async (data: StaffInput) => {
  return apolloClient.mutate({
    mutation: CreateStaffDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        email: data.email,
        permissions: data.permissions,
      },
    },
  });
};
export const updateOne = async (data: StaffInput & { id: string }) => {
  return apolloClient.mutate({
    mutation: UpdateStaffDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
      input: {
        email: data.email,
        permissions: data.permissions,
      },
    },
  });
};

export const deleteOne = async (data: Item) => {
  await apolloClient.query({
    query: DeleteStaffDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
