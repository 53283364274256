import React, { useState, useEffect, useCallback } from 'react';
import { Select } from 'antd';
import { DynamicFieldType, DynamicModel, DynamicVariant } from 'graphql/types';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import { DynamicInputImage } from '../DynamicInputImage/DynamicInputImage';

type Props = {
  model: DynamicModel;
  dynamicDataParser: DynamicDataParser;
  onUpdate: any;
};

export const OneToMany: React.FC<Props> = ({
  model,
  dynamicDataParser,
  onUpdate,
}) => {
  const handleChange = (value: any) => {
    const updatedValue = dynamicDataParser!.changeData(
      model,
      undefined,
      JSON.stringify({ id: Number(value) })
    );
    onUpdate(updatedValue);
  };

  const getChosenValue = () => {
    const variant = dynamicDataParser && dynamicDataParser.getFieldData(model);
    if (!variant) return '';
    return String(variant.id || variant.ids[0]);
  };

  const isImage =
    dynamicDataParser &&
    dynamicDataParser.getPrimaryField(model.fields).type ===
      DynamicFieldType.Image;
  const chosenId = getChosenValue();
  return (
    <div className="flex">
      {isImage && (
        <DynamicInputImage
          model={model}
          dynamicDataParser={dynamicDataParser}
          onChange={handleChange}
          chosenId={chosenId}
        />
      )}
      {!isImage && (
        <Select
          value={chosenId}
          style={{ width: '100%' }}
          onChange={handleChange}
        >
          {model.variants?.map(variant => {
            const variantValue = JSON.parse(variant.value);
            const { name = '' } =
              (model.fields || []).find(({ primaryField }) => primaryField) ||
              {};
            return (
              <Select.Option value={variant.id} key={variant.id}>
                {variantValue[name] || variant.id}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </div>
  );
};
