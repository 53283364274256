import { ParentType } from 'graphql/types';
import React from 'react';
import { ConfigContextType, ConfigStateType } from 'type';
import { getSiteSettings } from './helpers';

const DEFAULT_SCREENSHOT_STATE = {
  oneByOneCrop: false,
  sizes: [
    {
      width: 1200,
      aspectRatio: 1118 / 460,
      parentType: ParentType.Item,
    },
    {
      width: 700,
      aspectRatio: 4 / 3,
      parentType: ParentType.Item,
    },
    { width: 350, aspectRatio: 4 / 3, parentType: ParentType.Item },
  ],
};

export const ConfigContext = React.createContext<ConfigContextType>({
  screenshot: DEFAULT_SCREENSHOT_STATE,
  site: {
    name: '',
    icon: '',
  },
  updateSettings: () => {},
});

export const defaultState: ConfigStateType = {
  screenshot: DEFAULT_SCREENSHOT_STATE,
  site: getSiteSettings(),
};
