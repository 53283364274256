import React from 'react';
import { Typography } from 'antd';
import { UIUpload } from 'nativeComponents/UI';
import { getIconValue } from 'utils/getData';

type Props = {
  images: any;
  handleThumbnail: any;
};

export const ThumbnailBox = ({ images, handleThumbnail }: Props) => {
  const onUpload = (name: string, image: any) => {
    const images = image.map(img => ({
      size: img.size,
      value: img.value,
      id: img.id,
    }));
    handleThumbnail(prev => ({ ...prev, [name]: images }));
  };

  const onDelete = () => {
    handleThumbnail(prev => ({ ...prev, thumbnail: [] }));
  };

  return (
    <div className={'mb-3'}>
      <Typography.Title level={5}>Big Icon</Typography.Title>
      <UIUpload
        previews={getIconValue(images)}
        onChange={onUpload}
        name="thumbnail"
        type="icon"
        onDelete={onDelete}
        restrictSize={120}
      />
    </div>
  );
};
