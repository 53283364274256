import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import { Button, Checkbox, Divider, message, Popconfirm } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useApolloClient } from '@apollo/client';
import { fetchLazyHook, modelFieldsName, mutationHook } from './helpers';

import s from './styles.module.css';
import {
  CmsModels,
  DeleteDynamicModelDocument,
  DeleteDynamicModelMutation,
  Entity,
} from '../../../graphql/types';
import { DynamicModelView } from '../../DynamicModel/DynamicModelView';

type Props = {
  fields: Record<string, string>;
  model: string;
  onSubmit?: () => void;
  visible?: boolean;
  dynamicEntity?: Entity;
};

export const SettingsSubItem: React.FC<Props> = ({
  dynamicEntity,
  model,
  visible,
}) => {
  const client = useApolloClient();
  const [state, setState] = useState({});
  const [showDynamicModel, setShowDynamicModel] = useState<string>('');
  const [openedDynamicModelId, setOpenedDynamicModelId] =
    useState<string | null>(null);

  useEffect(() => {
    if (!showDynamicModel) setOpenedDynamicModelId(null);
  }, [showDynamicModel]);

  const [fetchFields, { loading }] = fetchLazyHook(model)({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setState({
        ...(data[modelFieldsName(model)] || {}),
        dynamicModels: data.dynamicModels,
      });
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const [handleSubmit, { loading: mutationLoading }] = mutationHook(model)({
    onError: e => {
      message.error(e.message);
    },
  });

  const handleChange = (model: string) => (e: CheckboxChangeEvent) => {
    setState(prev => ({ ...prev, [model]: e.target.checked }));
  };

  const deleteDynamicModel = async (id: string) => {
    setState({
      ...state,
      dynamicModels: (state as { [key: string]: any }).dynamicModels.filter(
        x => x.id !== id
      ),
    });
    try {
      const { errors } = await client.mutate<DeleteDynamicModelMutation>({
        mutation: DeleteDynamicModelDocument,
        variables: { id },
      });
      if (errors?.length) {
        message.error(
          errors.reduce(
            (acc, item, idx) => `${acc}${idx ? ', ' : ''}${item.message}`,
            ''
          )
        );
        return;
      }
    } catch (e) {
      message.error(e.toString());
    }
  };

  const submit = () => {
    const input = { ...state };
    // @ts-ignore
    delete input.dynamicModels;
    handleSubmit({
      variables: {
        input,
      },
    });
  };

  useEffect(() => {
    if (!visible) return;
    fetchFields();
  }, [visible]);

  return (
    <div
      className={classnames('flex-column flex-wrap', {
        pending: loading || mutationLoading,
      })}
      style={{
        overflow: 'hidden',
        height: visible ? 'initial' : 0,
      }}
    >
      <div className="mt-4">
        {Object.keys(state)
          .filter(x => x !== 'dynamicModels')
          .map(key => (
            <div key={key}>
              <div className={s.subItem}>
                <Checkbox checked={state[key]} onChange={handleChange(key)}>
                  {key}
                </Checkbox>
              </div>
            </div>
          ))}
        {!!dynamicEntity && (
          <div>
            {(state as { [key: string]: any })?.dynamicModels?.map(m => (
              <div key={m.id}>
                <div className="flex align-center justify-between py-3 px-4">
                  <span>{m.title || m.name}</span>
                  <div className="flex align-center">
                    <Button
                      onClick={() => {
                        setOpenedDynamicModelId(m.id);
                        setShowDynamicModel(model);
                      }}
                      type="dashed"
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      placement="left"
                      title="Are you sure?"
                      onConfirm={() => {
                        deleteDynamicModel(m.id);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        danger
                        type="dashed"
                        style={{ marginLeft: '4px' }}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ))}
            <div className={s.subItem}>
              <Button
                onClick={() => setShowDynamicModel(model)}
                color="primary"
                type="dashed"
                htmlType="button"
              >
                Add field
              </Button>
            </div>
          </div>
        )}
        <div className="flex fluid mt-4 justify-end">
          <Button color="primary" type="primary" onClick={submit}>
            Save
          </Button>
        </div>
      </div>
      {!!showDynamicModel && (
        <DynamicModelView
          id={openedDynamicModelId}
          entity={dynamicEntity as Entity}
          close={() => {
            fetchFields();
            setShowDynamicModel('');
          }}
        />
      )}
    </div>
  );
};
