import React from 'react';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import { DynamicFieldModel, DynamicModel } from 'graphql/types';
import { HTMLEditor } from 'nativeComponents/Common/HTMLEditor/HTMLEditor';
import { getData } from './helpers';

type Props = {
  onUpdate: (a: any) => void;
  dynamicDataParser: DynamicDataParser | null;
  model: DynamicModel;
  field: DynamicFieldModel;
  index?: number;
};

export const HTMLDynamicComponent = ({
  dynamicDataParser,
  model,
  field,
  onUpdate,
  index,
}: Props) => {
  const htmlValue = getData(
    dynamicDataParser?.getFieldData(model, field, index)
  );

  const onChange = ({ target: { value } }) => {
    onUpdate(dynamicDataParser!.changeData(model, field, value, index));
  };

  return (
    <div className="fluid">
      <HTMLEditor value={htmlValue} onChange={onChange} currentLanguage="" />
    </div>
  );
};
