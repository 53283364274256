import _noop from 'lodash/noop';
import { useRouter } from 'hooks/useRouter';
import { Link } from 'react-router-dom';
import { UIIcon } from 'nativeComponents/UI/UIIcons/UIIcon';
import { UIImagePlaceholder } from 'nativeComponents/UI/UIImagePlaceholder/UIImagePlaceholder';

import s from './styles.module.css';

export const GlobalSearchItem = ({
  icon,
  title,
  link,
  id,
  resource,
  onClick = _noop,
}) => {
  const { replace } = useRouter();

  const handleRoute = () => {
    replace(`/${resource}`);
    onClick();
  };

  return (
    <div className="flex align-center mb-2">
      <Link
        className="flex align-center"
        to={`/${resource}/edit?id=${id}`}
        onClick={handleRoute}
      >
        <UIImagePlaceholder src={icon} size={28} height={28} />
        <p className={s.itemTitle}>{title}</p>
      </Link>
      {!!link && (
        <a href={link} target="_blank" rel="noreferrer" className="ml-1">
          <UIIcon icon="link" />
        </a>
      )}
    </div>
  );
};
