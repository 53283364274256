import React from 'react';
import { getIconValue, getImageValue } from 'utils/getData';
import { UIImagePlaceholder } from 'nativeComponents/UI/UIImagePlaceholder/UIImagePlaceholder';
import {TYPE_PAGES} from '../../Breadcrumbs/Breadcrumbs';
import {SITE_NAMES} from 'hooks/ConfigProvider/helpers';
import {useResource} from 'hooks/useResource';
import {AwesomeIcon} from 'components/AwesomeIcon/AwesomeIcon';
import {useConfig} from 'hooks/ConfigProvider/useConfig';

type Props = {
  data: Record<string, any>;
  record: any;
  imageKey?: string;
  titlePrefix?: any;
};

export const TitleWithImage: React.FC<Props> = ({
  data,
  record,
  imageKey = 'icon',
  titlePrefix,
}) => {
    const {resource} = useResource()
    const {site} = useConfig();
    const isAwesomeIcon = (resource === TYPE_PAGES.category || resource === TYPE_PAGES.skill) && site.name === SITE_NAMES.ManytoolsAi && data.fontAwesomeIcon

    return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
        {isAwesomeIcon
            ? <AwesomeIcon icon={data.fontAwesomeIcon} />
            : <UIImagePlaceholder
            src={
                imageKey === 'icon'
                    ? getIconValue(data[imageKey])
                    : getImageValue(data[imageKey])
            }
            size={42}
            height={42}
        />}
      <h3
        style={{
          marginLeft: 15,
          marginBottom: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!!titlePrefix && <span>{titlePrefix}</span>}
        <span>{record}</span>
      </h3>
    </div>
  );
};
