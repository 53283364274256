import { initializeApollo } from '../utils/apollo';
import {
  CreateDynamicFieldDocument,
  CreateDynamicFieldMutation,
  CreateDynamicFieldMutationVariables,
  CreateDynamicModelDocument,
  CreateDynamicModelMutation,
  CreateDynamicModelMutationVariables,
  CreateDynamicVariantDocument,
  CreateDynamicVariantMutation,
  CreateDynamicVariantMutationVariables,
  DynamicModelsQuery,
  DynamicModelsDocument,
  DynamicModelsQueryVariables,
} from 'graphql/types';

export const fetchDynamicModels = async (
  variables: DynamicModelsQueryVariables
) => {
  const apolloFetch = initializeApollo();
  try {
    const {
      data: { dynamicModels },
    } = await apolloFetch.query<
      DynamicModelsQuery,
      DynamicModelsQueryVariables
    >({
      query: DynamicModelsDocument,
      variables,
    });

    return dynamicModels;
  } catch (e) {
    console.log(e);
  }
};

export const createDynamicModel = async (
  variables: CreateDynamicModelMutationVariables
) => {
  const apolloFetch = initializeApollo();
  try {
    const {
      // @ts-ignore
      data: { createDynamicModel },
    } = await apolloFetch.mutate<
      CreateDynamicModelMutation,
      CreateDynamicModelMutationVariables
    >({
      mutation: CreateDynamicModelDocument,
      variables,
    });

    return createDynamicModel;
  } catch (e) {
    console.log(e);
  }
};

export const createDynamicField = async (
  variables: CreateDynamicFieldMutationVariables
) => {
  const apolloFetch = initializeApollo();
  try {
    const {
      // @ts-ignore
      data: { createDynamicField },
    } = await apolloFetch.mutate<
      CreateDynamicFieldMutation,
      CreateDynamicFieldMutationVariables
    >({
      mutation: CreateDynamicFieldDocument,
      variables,
    });

    return createDynamicField;
  } catch (e) {
    console.log(e);
  }
};

export const createDynamicVariant = async (
  modelID: string,
  fieldName: string,
  value: any
) => {
  const apolloFetch = initializeApollo();
  try {
    const {
      // @ts-ignore
      data: { createDynamicVariant },
    } = await apolloFetch.mutate<
      CreateDynamicVariantMutation,
      CreateDynamicVariantMutationVariables
    >({
      mutation: CreateDynamicVariantDocument,
      variables: {
        modelID,
        value: JSON.stringify({ [fieldName]: value }),
      },
    });

    return createDynamicVariant;
  } catch (e) {
    console.log(e);
  }
};
