import React from 'react';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { EditCompany } from 'components/Company/EditCompany/EditCompany';
import { SortBy, SortDirection } from 'graphql/types';
import { filters } from './filters';
import { sort } from './sort';
import { TitleWithImage } from '../../../nativeComponents/List/ListRowTitle/TitleWithImage';

const companyResource = {
  name: 'company',
  menuIcon: DeploymentUnitOutlined,
  pageTitle: 'Companies',
  ...filters,
  sort,
  editComponent: EditCompany,
  list: {
    actions: ['delete'],
    columns: [
      {
        title: 'Title',
        key: 'title',
        sort: true,
        filter: true,
        width: 400,
        render: (record, data) => {
          return <TitleWithImage data={data} record={record} />;
        },
      },
      {
        title: 'Link',
        key: 'link',
        dataIndex: 'link',
        source: 'url',
        align: 'center',
        render: ({ url }) => {
          return <a href={url}>{url}</a>;
        },
      },
    ],
    pagination: {},
  },
};
export default companyResource;
