import React from 'react';
import {Feature} from 'graphql/types';
import {getIconUrl} from 'utils/getData';
import {UIUpload} from 'nativeComponents/UI';
import {Input, Space, Typography} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {SITE_NAMES} from 'hooks/ConfigProvider/helpers';
import {BindedIcon, FaIconPicker} from 'entities/font-awesome-icon';
import {useConfig} from 'hooks/ConfigProvider/useConfig';

type Props = {
    feature: Feature;
    handleFeature: (feature: {}) => void;
    handleFontAwesomeIcon: (icon: BindedIcon) => void;
    handleColorFontAwesomeIcon: (color: string) => void;
};

export const ModalFeature = ({feature, handleFeature, handleColorFontAwesomeIcon, handleFontAwesomeIcon}: Props) => {
    const {site} = useConfig();

    const handleInputs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        handleFeature(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const onImageUpload = (name: string, image: any) => {
        const images = image.map(item => ({size: item.size, url: item.value}));
        handleFeature(prev => ({...prev, [name]: images}));
    };

    const deleteIcon = () => {
        handleFeature(prev => ({...prev, icon: []}));
    };

    return (
        <Space direction={'vertical'} style={{width: '100%'}}>
            {(site.name === SITE_NAMES.ManytoolsAi || site.name === SITE_NAMES.Streamingsites) ? (
                <div className={'editTitle'} style={{maxWidth: '300px'}}>
                    <Typography.Title level={5}>Icon</Typography.Title>
                    {feature.fontAwesomeIcon && <FaIconPicker
                        value={feature.fontAwesomeIcon as BindedIcon}
                        onChange={e => handleFontAwesomeIcon(e)}
                        setColor={handleColorFontAwesomeIcon}
                    />}
                </div>
            ) : (
                <UIUpload
                    previews={getIconUrl(feature.icon)}
                    name="icon"
                    type="icon"
                    onDelete={deleteIcon}
                    onChange={onImageUpload}
                    size={68}
                />
            )}

            <Input
                value={feature.title}
                onChange={handleInputs}
                name={'title'}
                placeholder={'Title'}
            />
            <TextArea
                rows={4}
                value={feature.description}
                onChange={handleInputs}
                name={'description'}
                placeholder={'Description'}
            />
        </Space>
    );
};
