import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { envConfig } from 'configs/envConfig';
import axios, { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { swapDuotoneFamilyToStyle } from '../lib/swap-duotone-family-to-style';
import {
  TokenInput,
  TokenResponse,
  LoadAllIconsData,
  LoadAllIconsArg,
  IconLight,
} from '../types';

const loadAllIconsDocument = gql`
  query loadIncons($version: String!) {
    release(version: $version) {
      iconCount {
        free
        pro
      }
      icons {
        label
        id
        shim {
          id
          name
          prefix
        }
        familyStylesByLicense {
          pro {
            family
            style
          }
        }
      }
    }
  }
`;

export const baseApi = createApi({
  reducerPath: 'fontAwesomeApi',
  baseQuery: graphqlRequestBaseQuery({
    url: envConfig.fontAwesomeApiBaseUrl,
    prepareHeaders: async headers => {
      return headers;
    },
  }),
  endpoints: builder => ({
    token: builder.query<TokenResponse, TokenInput>({
      queryFn: async () => {
        try {
          const { data } = await axios.get<TokenResponse>(
            `${envConfig.fontAwesomeApiBaseUrl}/token`,
            {
              headers: {
                Authorization: `Bearer ${envConfig.fontAwesomeApiToken}`,
              },
            }
          );
          return { data };
        } catch (error) {
          const axiosError = error as AxiosError;
          return {
            error: {
              data: axiosError,
              message: axiosError.message,
            },
          };
        }
      },
      keepUnusedDataFor: 60 * 60, // seconds
    }),
    loadAllIcons: builder.query<LoadAllIconsData, LoadAllIconsArg>({
      query: () => {
        return {
          document: loadAllIconsDocument,
          variables: {
            query: '',
            first: 30_000,
            version: envConfig.fontAwesomeVersion,
          },
        };
      },
      transformResponse: (response: {
        release: {
          iconCount: { free: number; pro: number };
          icons: IconLight[];
        };
      }) => {
        return swapDuotoneFamilyToStyle(response.release.icons);
      },
      keepUnusedDataFor: 60 * 60,
    }),
  }),
});
