import React, { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';

export const LogList = ({ logs }: { logs: string[] }) => {
  const [isOpen, setOpen] = useState(!!logs.length);

  useEffect(() => {
    setOpen(!!logs.length);
  }, [logs.length]);

  if (!isOpen) return null;

  return (
    <Card className="mb-4">
      {logs.map((message, i) => (
        <div key={i} className="flex-column mb-2">
          {i !== 0 && <Divider className="my-2" />}
          <span>{message}</span>
        </div>
      ))}
    </Card>
  );
};
