import { CalculateType } from './type';

export const getMouseXY = e => {
  return e.targetTouches ? e.targetTouches[0] : e;
};

export const calculateMatching = (
  source: Record<string, CalculateType['dimensions']>,
  data: { x: number; y: number }
) => {
  let matchedId;
  Object.entries(source).find(([id, dimensions]) => {
    const isMatchX = data.x > dimensions.left && data.x < dimensions.right;
    const isMatchY = data.y > dimensions.top && data.y < dimensions.bottom;
    if (isMatchX && isMatchY) {
      matchedId = id;
      return true;
    }
    return false;
  });

  return matchedId;
};

export const findSliceIndexes = ({ target, dropTarget }, list: any[]) => {
  let targetIndex;
  let dropIndex;
  list.find((item, i) => {
    if (targetIndex !== undefined && dropIndex !== undefined) return true;
    if (item.slug === target) dropIndex = i;
    if (item.slug === dropTarget) targetIndex = i;

    return false;
  });

  return { targetIndex, dropIndex };
};

export const getReconstructList = (
  dragIndex: number,
  dropIndex: number,
  list: any[]
) => {
  if (dragIndex < dropIndex) {
    return [
      ...list.slice(0, dragIndex),
      ...list.slice(dragIndex + 1, dropIndex),
      list[dragIndex],
      ...list.slice(dropIndex),
    ];
  }
  if (dragIndex > dropIndex) {
    return [
      ...list.slice(0, dropIndex),
      list[dragIndex],
      ...list.slice(dropIndex, dragIndex),
      ...list.slice(dragIndex + 1),
    ];
  }
  return list;
};
