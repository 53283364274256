import React, { useState, useEffect } from 'react';
import { UIUpload } from 'nativeComponents/UI/UIUpload/UIUpload';
import { message } from 'antd';
import {
  useUploadWatermarkMutation,
  useWatermarkLazyQuery,
} from 'graphql/types';

import s from './styles.module.css';

export const Watermark = () => {
  const [watermark, setWatermark] = useState('');
  const [fetchWatermark, { loading: fetchLoading }] = useWatermarkLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ watermark: response }) => {
      setWatermark(response || '');
    },
    onError: e => {
      message.error(e.message);
    },
  });
  const [uploadWatermark, { loading: uploadLoading }] =
    useUploadWatermarkMutation({
      onCompleted: ({ uploadWatermark: response }) => {
        setWatermark(response);
      },
      onError: e => {
        message.error(e.message);
      },
    });

  const handleDelete = () => {
    setWatermark('');
  };

  const onUpload = (file: any) => {
    return uploadWatermark({
      variables: {
        input: file,
      },
    });
  };

  useEffect(() => {
    fetchWatermark();
  }, []);

  return (
    <div className="pa-8">
      <h1>Watermark settings</h1>
      <div className={s.wrapper}>
        <UIUpload
          previews={watermark}
          name="watermark"
          type="icon"
          onDelete={handleDelete}
          uploadHandler={onUpload}
          disabled={fetchLoading || uploadLoading}
          size="10rem"
        />
        {!!watermark && (
          <div className={s.bigImage}>
            <img
              src={watermark}
              alt=""
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
