import { useMemo } from 'react';
import { Button, Typography } from 'antd';
import _flatten from 'lodash/flatten';
import { CompareCategory } from 'components/Settings/CSV/CSVProvider/types';
import { useCSV } from 'components/Settings/CSV/CSVProvider/CSVProvider';
import { getPlainString } from 'utils/string';
import { constructNewItem } from '../CSVItem/helpers';
import _isEmpty from 'lodash/isEmpty';

const { Text } = Typography;

type Props = {
  title: string;
  items: CompareCategory[];
};

export const CVSUploaderCategoryTitle = ({ title, items }: Props) => {
  const { chosen, onChooseItem } = useCSV();

  const chosenItems = useMemo(() => {
    return items.filter(item => {
      return chosen[getPlainString(item.title)];
    });
  }, [chosen]);

  const handleChooseAll = () => {
    const itemsForChoose = chosenItems.length
      ? []
      : items.filter(item => {
          return _isEmpty(item.errors);
        });

    onChooseItem(itemsForChoose);
  };

  const { haveErrors, canSelectAll } = useMemo(() => {
    const itemsWithErrors = items.filter(item => !_isEmpty(item.errors));

    return {
      haveErrors: !!itemsWithErrors.length,
      canSelectAll: itemsWithErrors.length < items.length,
    };
  }, [items]);

  return (
    <div className="flex">
      <p className="mr-4">{title}</p>
      <Button
        size="small"
        className="mr-2"
        onClick={handleChooseAll}
        disabled={!canSelectAll}
      >
        {!!chosenItems.length ? 'Deselect all' : 'Select all'}
      </Button>
      {haveErrors && <Text type="danger">Error</Text>}
      {!!chosenItems.length && (
        <Text
          type="success"
          className="ml-2"
        >{`${chosenItems.length} selected`}</Text>
      )}
    </div>
  );
};
