import s from 'components/CSVUploader/styles.module.scss';

type Props = {
  total: number;
  successUploaded: number;
  failUploaded: { entity: any; msg: string }[];
  label?: string;
};

export const CSVProgressUpload = ({
  total,
  failUploaded,
  successUploaded,
  label = 'Uploaded',
}: Props) => {
  return (
    <div className={s.progress}>
      <span>{`${label}: ${total} / ${
        successUploaded + failUploaded.length
      }`}</span>
      {!!failUploaded.length && (
        <span className="error">{`Errors: ${failUploaded.length}`}</span>
      )}
    </div>
  );
};
