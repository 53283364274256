import { Card, Typography } from 'antd';
import { Category } from 'graphql/types';

import s from './styles.module.scss';

type Props = {
  sortCategories: Category[][];
};

const { Text } = Typography;

export const CSVSortCategoriesTree = ({ sortCategories }: Props) => {
  return (
    <Card
      bodyStyle={{ display: 'flex', maxHeight: 700, overflowY: 'auto' }}
      bordered={false}
    >
      {sortCategories.map((categories, i) => (
        <div key={i} className="flex-column">
          {categories.map(category => (
            <div key={category.id} className="flex-column mb-3">
              <Text className={s.treeTitle}>{category.title}</Text>
              {(category?.items || []).map((item, i) => (
                <Text
                  key={`${category.id}-${item.id}`}
                  type="secondary"
                  className={s.treeItem}
                >
                  <span>{`${i + 1}.`}</span>
                  <span>{`${item.title}`}</span>
                </Text>
              ))}
            </div>
          ))}
        </div>
      ))}
    </Card>
  );
};
