import { useLocation } from 'react-router-dom';
import * as providers from '../config/providers';
import * as resourcesList from '../config/resourses';

export const useResource = () => {
  const { pathname, search } = useLocation();
  const resource = pathname
    .split('?')[0]
    .split('/')
    .filter(item => !!item)[0];

  const getId = () => {
    const idSearch = search
      .replace('?', '')
      .split('&')
      .find(item => /id=\w/.test(item));
    if (!idSearch) return undefined;
    return idSearch.split('=')[1];
  };

  return {
    resource,
    resources: resourcesList[resource] || {},
    providers: providers[resource] || {},
    id: getId(),
  };
};
