import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  Category,
  ScreenSizeType,
  useGetSortCategoriesSizeLazyQuery,
} from 'graphql/types';
import { SortV4 } from '../../../../SortV4/SortV4';
import { SortV4Provider } from '../../../../SortV4/SortV4Provider';

import s from './styles.module.css';

type Props = {
  type: ScreenSizeType;
  isOpen?: boolean;
  handleOpen: () => void;
  onFillUp: (type: ScreenSizeType, callback: () => void) => void;
  onSave: (
    data: { categories: Category[][]; screenSize: ScreenSizeType },
    callback: () => void
  ) => void;
};

export const SortScreenSizeItem: React.FC<Props> = ({
  onFillUp,
  onSave,
  type,
  isOpen,
  handleOpen,
}) => {
  const [state, setState] = useState({
    categories: [[]] as Category[][],
  });

  const [getCurrentSize, { loading: fetchLoading }] =
    useGetSortCategoriesSizeLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ screenSizeV5 }) => {
        setState(prev => ({ ...prev, categories: screenSizeV5 as any }));
      },
      onError: e => {
        message.error(e.message);
      },
    });

  useEffect(() => {
    if (isOpen) {
      getCurrentSize({
        variables: {
          type,
        },
      });
    }
  }, [isOpen]);

  return (
    <div
      className={classnames({
        pending: fetchLoading,
        [s.sortScreenItem]: true,
      })}
    >
      <div>
        <div
          role="presentation"
          onClick={handleOpen}
          className={classnames({
            [s.sortScreenItemHeader]: true,
            [s.sortScreenItemOpened]: isOpen && !fetchLoading,
          })}
          style={{
            backgroundColor: '#fff',
            zIndex: 100000,
          }}
        >
          <span>{type}</span>
          <DownOutlined
            style={{
              transition: 'transform .3s',
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            }}
          />
        </div>
        {isOpen && !fetchLoading && (
          <SortV4Provider
            screenSize={type}
            saveData={onSave}
            fillUp={callback => onFillUp(type, callback)}
            sortList={state.categories}
          >
            <SortV4 />
          </SortV4Provider>
        )}
      </div>
    </div>
  );
};
