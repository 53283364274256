import { useUser } from 'hooks/useUser';
import { Link } from 'react-router-dom';
import { Avatar, Dropdown, Menu } from 'antd';
import { ExportOutlined, UserOutlined } from '@ant-design/icons';

import s from './styles.module.css';

export const LoggedUser = () => {
  const { user, logout } = useUser();

  const menu = (
    <Menu className={s.menu}>
      <Menu.Item>
        <Link to="/profile">
          <UserOutlined />
          <span className={s.menuText}>Manage account</span>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <ExportOutlined />
        <span role="presentation" className={s.menuText} onClick={logout}>
          Log out
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <div className={s.logout}>
        <Avatar
          size={32}
          src={user?.avatar?.find(i => i.size === 128)?.value || null}
          icon={<UserOutlined />}
        />
        <span style={{ lineHeight: '22px', fontWeight: 500 }} className="ml-2">
          {user && user.email}
        </span>
      </div>
    </Dropdown>
  );
};
