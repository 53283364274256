import s from './styles.module.scss';

type Props = { children: React.ReactElement[] };
export const IconGrid: React.FC<Props> = function FaIconGrid({ children }) {
  return (
    <div className={s.grid}>
      {children.map(child => (
        <div key={child.key} className={s.cell}>
          {child}
        </div>
      ))}
    </div>
  );
};
