import React from 'react';
import { Typography } from 'antd';
import { UIUpload } from 'nativeComponents/UI';
import { GetIconButton } from './GetIconButton/GetIconButton';

export const IconBox = ({ onUpload, icon, onDelete, url, alternativeUrl }) => {
  return (
    <div>
      <Typography.Title level={5}>Icon</Typography.Title>
      <div className="mb-2">
        <GetIconButton
          url={url}
          name="icon"
          alternativeUrl={alternativeUrl}
          onGet={onUpload}
        />
      </div>
      <UIUpload
        previews={icon}
        onChange={onUpload}
        name="icon"
        type="icon"
        onDelete={onDelete}
        restrictSize={120}
      />
    </div>
  );
};
