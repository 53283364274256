import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { message } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { useRouter } from 'hooks/useRouter';
import { useResource } from 'hooks/useResource';
import * as resources from 'config/resourses';
import * as providers from 'config/providers';
import {
  fetchLazyHook,
  modelFieldsName,
} from 'components/Settings/SettingsSubItem/helpers';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { getBreadcrumbs, setLastViewed, updateLastViewed } from './helpers';

type StateType = {
  data: Record<string, any>;
  loading: boolean;
};

export const Edit = () => {
  const { replace, location, query, goBack, push } = useRouter();
  const { resource } = useResource();
  const [state, setState] = useState<StateType>({ data: {}, loading: false });
  const [modelFields, setModelFields] = useState({});
  const resourceProvider = providers[resource];
  const source = resources[resource] || ({} as any);

  const [fetchModelFields] = fetchLazyHook(resource)({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setModelFields(data[modelFieldsName(resource)] || {});
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const getEditData = async (id: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await resourceProvider.getOne({
        id,
        approved: query?.approved,
      });

      setState(prev => ({ ...prev, data, loading: false }));
      setLastViewed(data, resource);
    } catch (e) {
      setState(prev => ({ ...prev, loading: false }));
      message.error(e.message);
    }
  };

  useEffect(() => {
    if (!resourceProvider) return;
    getEditData(query.id);
    fetchModelFields();
  }, [location.search]);

  const onSubmit = async (
    fullData: any,
    callback: (updatedData: any) => void = () => {}
  ) => {
    try {
      const { redirect, ...data } = fullData;
      if (typeof data.title !== 'undefined' && !data.title) {
        message.error('Title should be set');
        return;
      }
      setState(prev => ({ ...prev, loading: true }));
      const {
        data: { id },
      } = state;

      if (!resourceProvider.updateOne || !resourceProvider.createOne) {
        console.error('No updateOne or createOne handler provided');
        return;
      }

      let actualId = id;
      if (!id) {
        const { id: ID, ...payload } = data;
        const { data: response } = await resourceProvider.createOne(payload);

        const newData: any = Object.entries(response)[0][1];
        actualId = newData.id;
        updateLastViewed(newData, resource);
        setState(prev => ({ ...prev, data: newData }));
        callback(newData);
      } else {
        const { data: response } = await resourceProvider.updateOne({
          id,
          ...data,
        });
        const newData: any = Object.entries(response)[0][1];
        setState(prev => ({ ...prev, data: newData }));
        updateLastViewed({ id, ...data }, resource);
        callback(newData);
      }

      setState(prev => ({ ...prev, loading: false }));
      if (!redirect) {
        replace(`/${resource}/edit?id=${actualId}`);
        return;
      }
      if (window.history.length < 2) {
        push(`/${resource}`);
      } else {
        // goBack();
      }
    } catch (e) {
      setState(prev => ({ ...prev, loading: false }));
      message.error(e.message);
    }
  };
  const Component = source.editComponent;

  return (
    <div
      className={classnames('pb-8 container-shrink', {
        pending: state.loading,
      })}
      style={{
        marginLeft: 0,
      }}
    >
      <Breadcrumbs
        withBack
        breadcrumbs={[getBreadcrumbs(source, state.data, query, resource)]}
        slug={state.data.slug}
      />
      {_isEmpty(state.data) && (
        <Component
          resource={resource}
          onSubmit={onSubmit}
          modelFields={modelFields}
        />
      )}
      {!_isEmpty(state.data) && (
        <Component
          {...state.data}
          resource={resource}
          onSubmit={onSubmit}
          modelFields={modelFields}
        />
      )}
    </div>
  );
};
