import { UIUpload } from 'nativeComponents/UI/UIUpload/UIUpload';
import { Input, Button } from 'antd';
import { PictureDictionary } from 'graphql/types';
import { ChangeEvent } from 'react';

import s from './styles.module.css';

type Props = {
  authorName?: string;
  authorAvatar?: string;
  authorCompany?: string;
  authorPosition?: string;
  companyLink?: string;
  onImageChange: (name: string, image: PictureDictionary[]) => void;
  onImageDelete: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const ItemReviewFooter = ({
  authorName,
  authorAvatar,
  authorCompany,
  authorPosition,
  companyLink,
  onImageChange,
  onImageDelete,
  onChange,
}: Props) => {
  return (
    <div className={`flex-column mt-4 ${s.itemReviewFooter}`}>
      <div className="flex mb-4">
        <UIUpload
          previews={authorAvatar}
          onChange={onImageChange}
          name="authorAvatar"
          type="icon"
          onDelete={onImageDelete}
          restrictSize={120}
        />
        <div className="flex-column ml-3 justify-between">
          <Input
            value={authorName}
            placeholder="Author name"
            name="authorName"
            onChange={onChange}
            bordered={true}
          />
          <div className="flex">
            <Input
              value={authorPosition}
              placeholder="Author position"
              name="authorPosition"
              onChange={onChange}
              bordered={true}
              className={`mr-1`}
            />
            <Input
              value={authorCompany}
              name="authorCompany"
              placeholder="Author company"
              onChange={onChange}
              bordered={true}
              className={`ml-2`}
            />
          </div>
          <Input
            value={companyLink}
            name="companyLink"
            placeholder="Company link"
            onChange={onChange}
            bordered={true}
            style={{
              color: 'var(--color-main)',
            }}
          />
        </div>
      </div>

      {/*<div className="flex justify-end">*/}
      {/*  <Button*/}
      {/*    className={s.saveBtn}*/}
      {/*    type="primary"*/}
      {/*    ghost={true}*/}
      {/*    style={{ width: 123 }}*/}
      {/*    onClick={onEditToggle}*/}
      {/*  >*/}
      {/*    {isEditing ? 'Save' : 'Edit'}*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
};
