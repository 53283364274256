import React from 'react';
import {CategoryFaq} from 'graphql/types';
import {List} from 'antd';

import s from './style.module.scss';

type Props = {
    faqItem: CategoryFaq;
    index: number;
    handleFaqItem: (item: CategoryFaq) => void;
};

export const AnswerItem = ({faqItem, index, handleFaqItem}: Props) => {
    return <List.Item onClick={() => handleFaqItem(faqItem)}>
        <List.Item.Meta
            className={s.item}
            avatar={`${index}.`}
            title={faqItem.question}
            description={faqItem.answer}
        />
    </List.Item>;
};
