import React, { useState, useRef } from 'react';
import { Avatar, Button, List, Modal } from 'antd';
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { ItemReview } from 'graphql/types';
import { ItemReviewForm } from './ItemReviewForm/ItemReviewForm';

import s from './styles.module.css';

type Props = {
  reviews: ItemReview[] & { id: number };
  onChange: (r: ItemReview[]) => void;
  onSave: () => void;
};

export const ItemReviewList: React.FC<Props> = ({
  reviews,
  onChange,
  onSave,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [activeReview, setActiveReview] =
    useState<(ItemReview & { id?: number }) | null>(null);

  const handleChange =
    () =>
    ({ target }) => {
      setActiveReview(
        prev =>
          ({ ...prev, [target.name]: target.value } as ItemReview & {
            id?: number;
          })
      );
    };

  const handleSave = () => {
    if (activeReview?.id) {
      onChange(
        (reviews as any).map(item => {
          if (item.id === activeReview?.id) {
            return { ...activeReview };
          }
          return item;
        })
      );
    } else {
      onChange([
        ...reviews,
        { ...activeReview, id: Math.random() } as ItemReview & { id?: number },
      ]);
    }
    setActiveReview(null);
  };

  const handleDelete = () => {
    // @ts-ignore
    onChange(reviews.filter(item => item.id !== activeReview?.id));
    setActiveReview(null);
  };

  const onAddNewItem = () => {
    setActiveReview({
      title: '',
      description: '',
      authorName: '',
      authorAvatar: '',
      authorCompany: '',
      authorPosition: '',
      date: '',
      companyLink: '',
    });
  };

  return (
    <div ref={wrapperRef} className={s.reviewsList}>
      <div className="flex align-center">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewItem}
          className={s.addBtn}
        >
          Add review
        </Button>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={reviews}
        renderItem={item => (
          <List.Item onClick={() => setActiveReview(item)}>
            <List.Item.Meta
              className={s.listItem}
              avatar={<Avatar src={item.authorAvatar} />}
              title={item.authorCompany}
              description={item.title}
            />
          </List.Item>
        )}
      />
      <Modal
          width={572}
          open={!!activeReview}
          footer={[
          <Button type="primary" onClick={handleSave}>
            Save
            <SaveOutlined />
          </Button>,
          <Button type="primary" onClick={handleDelete}>
            Delete
            <DeleteOutlined />
          </Button>,
        ]}
        onCancel={() => setActiveReview(null)}
      >
        <ItemReviewForm onChange={handleChange()} {...activeReview} />
      </Modal>
    </div>
  );
};
