import React, { FC, useEffect } from 'react';
import { Button, Card, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDashboardReportsLazyQuery } from 'graphql/types';
import { getDottedDate } from 'utils/date';
import { TableAnalytics } from './TableAnalytics';
import { Doughnut } from './Doughnut';

export const Reports: FC = () => {
  const [fetchData, { data, loading }] = useDashboardReportsLazyQuery({
    onError: e => {
      message.error(e.message);
    },
  });

  useEffect(() => {
    fetchData({
      variables: {
        dateStart: getDottedDate(new Date().setDate(new Date().getDate() - 1)),
        dateEnd: getDottedDate(new Date().setDate(new Date().getDate() + 30)),
      },
    });
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      ) : (
        <>
          <div className="cards">
            <Card title="Keywords" bordered={false} className="card-table">
              <TableAnalytics data={data?.keywords || []} />
              <Button type="primary" style={{ marginTop: '16px' }}>
                Open full report
              </Button>
            </Card>
            <Card title="Content" bordered={false} className="card-table">
              <TableAnalytics data={data?.content || []} />
              <Button type="primary" style={{ marginTop: '16px' }}>
                Open full report
              </Button>
            </Card>
            <Card title="Top pages" bordered={false} className="card-table">
              <TableAnalytics data={data?.topPages || []} />
              <Button type="primary" style={{ marginTop: '16px' }}>
                Open full report
              </Button>
            </Card>
            <Card title="Countries" bordered={false} className="card-table">
              <TableAnalytics data={data?.countries || []} />
              <Button type="primary" style={{ marginTop: '16px' }}>
                Open full report
              </Button>
            </Card>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {!!data?.deviceBreakdown && (
              <Doughnut
                title="Device breakdown"
                data={data!.deviceBreakdown as unknown as Map<string, number>}
              />
            )}
            {!!data?.visitors && (
              <Doughnut
                title="New vs Returning visitors"
                data={data!.visitors as unknown as Map<string, number>}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
