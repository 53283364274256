import React from 'react';
import { Card, Typography } from 'antd';
import { SearchSelect } from 'components/Common/SearchSelect/SearchSelect';
import { LinksBlock } from '../../Common/LinksBlock/LinksBlock';
import { SEARCH_SELECT_TYPES } from '../../Common/SearchSelect/helpers';

export const ItemSelects = ({
  currentMainCategory,
  currentSkills,
  currentCompany,
  currentCategories,
  onSelectChange,
  onInputChange,
  link,
  models,
}) => {
 
  const { company, categories, mainCategory, skills, link: modelLink } = models;
  
  if (!company && !categories && !skills && !modelLink) return null;

  return (
    <>
      {modelLink && (
        <div className="sideContentItem">
          <LinksBlock name="link" link={link} onChange={onInputChange} />
        </div>
      )}

      {company && (
        <Card className="mb-2">
          <Typography.Title level={5}>Company</Typography.Title>
          <SearchSelect
            placeholder="Select company"
            values={currentCompany ? [currentCompany] : []}
            onChange={onSelectChange('company')}
            name={SEARCH_SELECT_TYPES.COMPANY}
          />
        </Card>
      )}

      {skills && (
        <Card className="mb-2">
          <Typography.Title level={5}>Skills</Typography.Title>
          <SearchSelect
            mode="multiple"
            allowClear
            placeholder="Select skills"
            values={currentSkills ? currentSkills : []}
            onChange={onSelectChange('skills')}
            name={SEARCH_SELECT_TYPES.SKILL}
          />
        </Card>
      )}

      {categories && (
        <Card className="mb-2">
          <Typography.Title level={5}>Categories</Typography.Title>
          <SearchSelect
            mode="multiple"
            allowClear
            placeholder="Select categories"
            values={currentCategories ? currentCategories : []}
            onChange={onSelectChange('categories')}
            name={SEARCH_SELECT_TYPES.CATEGORY}
          />
        </Card>
      )}

      {(
        <Card className="mb-2">
          <Typography.Title level={5}>Main Category</Typography.Title>
          <SearchSelect
            placeholder="Select main category"
            values={currentMainCategory ? [currentMainCategory] : []}
            onChange={onSelectChange('mainCategory')}
            name={SEARCH_SELECT_TYPES.CATEGORY}
          />
        </Card>
      )}
    </>
  );
};

export default ItemSelects;
