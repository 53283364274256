import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Tag } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { UIImagePlaceholder } from 'nativeComponents/UI/UIImagePlaceholder/UIImagePlaceholder';
import { UIIcon } from 'nativeComponents/UI/UIIcons/UIIcon';
import { Category } from 'graphql/types';
import classnames from 'classnames';
import { getIconValue } from 'utils/getData';
import { useWorkerSubscribe } from 'hooks/useWorker';
import { SITE_NAMES } from 'hooks/ConfigProvider/helpers';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { useSortV4 } from './SortV4Provider';
import { SortV4Item } from './SortV4Item';
import { VariantInput } from './VariantInput/VariantInput';
import { DRAG_ENTER_EVENT } from './SortV4Provider';

import s from './sort.module.css';

type Props = {
  category: Category;
  index: number;
  column: number;
};

export const SortV4Category: FC<Props> = ({ category, index, column }) => {
  const {
    handleDragStart,
    drag,
    save,
    dragging,
    handleDragEnter,
    globalExpand,
    updateTotalItems,
    updateVisibleItems,
  } = useSortV4();
  const dragOn = useRef(false);
  const dragState = useRef({
    index,
    column,
  });

  const [state, setState] = useState({
    opened: false,
    dragOn: false,
  });
  const isHaveItems = !!category.items && !!category.items.length;
  const { site } = useConfig();

  const handleUpdateCount = (value: string | number, increment?: boolean) => {
    updateTotalItems(column, index, value as string, increment);
  };

  const handleUpdateVisibleItems = (value: string | number) => {
     updateVisibleItems(column, index, value as string)
  };

  useWorkerSubscribe(DRAG_ENTER_EVENT, ({ drag }) => {
    if (drag.type === 'end_drag') {
      if (dragOn.current) {
        dragOn.current = false;
        setState(prev => ({ ...prev, dragOn: false }));
      }
      return;
    }

    if (
      drag?.type !== 'category' ||
      drag?.index !== dragState.current.index ||
      drag?.column !== dragState.current.column
    ) {
      if (dragOn.current) {
        dragOn.current = false;
        setState(prev => ({ ...prev, dragOn: false }));
      }
    } else if (!dragOn.current) {
      dragOn.current = true;
      setState(prev => ({ ...prev, dragOn: true }));
    }
  });

  useEffect(() => {
    setState(prev => ({ ...prev, opened: isHaveItems && globalExpand }));
  }, [globalExpand]);

  useEffect(() => {
    dragState.current = { column, index };
  }, [column, index]);

  return (
    <div
      draggable
      onDragExit={() => setState(prev => ({ ...prev, dragOn: false }))}
      onDragStart={e => {
        handleDragStart(e, index, column, 'category');
        setState(prev => ({ ...prev, opened: false }));
      }}
      onDragEnter={
        dragging && drag?.type === 'category'
          ? e => handleDragEnter(e, index, column, 'category')
          : undefined
      }
      className={classnames({
        [s.category]: true,
        [s.showedItems]: state.opened,
        [s.disabled]: !category.active,
        [s.dragOn]: state.dragOn,
      })}
    >
      <Tooltip title={category.title} placement="top">
        <div className={s.categoryHead}>
          <div className={s.title}>
            <Tag>{index + 1}</Tag>
            {isHaveItems && (
              <CaretRightOutlined
                className={s.showItemsButton}
                onClick={() => {
                  setState(prev => ({ ...prev, opened: !prev.opened }));
                }}
              />
            )}
            <UIImagePlaceholder
              src={getIconValue(category.icon)}
              size={28}
              height={28}
              className="ml-1 mr-2"
              round
            />
            <span className={s.titleTitle}>{category.title}</span>
            <Link to={`/category/edit?id=${category.id}`}>
              <UIIcon icon="link" />
            </Link>
          </div>

          <div className={s.categoryHeadMenu}>
            <div className={s.categoryCountContainer}>
              {(site.name === SITE_NAMES.Fetishsites || site.name === SITE_NAMES.Hentaisites) && (
                <VariantInput
                  onChange={handleUpdateVisibleItems}
                  onFocus={e =>
                    e.target.setSelectionRange(0, e.target.value.length)
                  }
                  onBlur={save}
                  value={category.sortItemsCountVisible || 0}
                />
              )}
              <VariantInput
                onChange={handleUpdateCount}
                onFocus={e => {
                  e.target.setSelectionRange(0, e.target.value.length);
                }}
                onBlur={save}
                value={category.sortItemsCountTotal || 0}
              />
            </div>
          </div>
        </div>
        <div
          onDragEnter={
            (category.items?.length || 0) === 0 &&
            dragging &&
            drag?.type === 'item'
              ? e => handleDragEnter(e, 0, column, 'item', index)
              : undefined
          }
          className={s.itemsContainer}
        >
          {category.items?.map((item, idx) => (
            <SortV4Item
              key={item.id}
              item={item}
              index={idx}
              column={column}
              categoryIndex={index}
            />
          ))}
        </div>
      </Tooltip>
    </div>
  );
};
