/*eslint-disable*/
import React, { FC } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import './glance-style.css';

type Props = {
  yesterday: number;
  past: number;
  title: string;
};

const difference = (yesterday: number, past: number): number => {
  const diff = yesterday - past;
    if(diff === 0) return 0

    return (diff * 100) / yesterday;
};

export const ItemGlance: FC<Props> = ({ yesterday, past, title }) => {
  const diff = difference(yesterday, past);

  return (
    <div className={'item-glance'}>
      <p>{title}</p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className={'number'}>{yesterday.toFixed(2)}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: diff < 0 ? '#FD5454' : '#219653',
            }}
          >
            {diff < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
            {Math.abs(diff).toFixed(2)}%
          </span>
          <span style={{ fontSize: '12px', color: '#7D7D7D' }}>
            vs. previous 30 days
          </span>
        </div>
      </div>
    </div>
  );
};
