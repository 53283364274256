import React from 'react';
import { Promo } from '../../graphql/types';
import { Typography, Input, DatePicker } from 'antd';
import moment from 'moment/moment';

type Props = {
  promo: Promo;
  onChange: (e: any, date?: any) => void;
};

export const ItemEditPromo = React.memo(({ promo, onChange }: Props) => {
  return (
    <div className={'mb-4'}>
      <Typography.Title level={5}>Promo code</Typography.Title>
      <div style={{ display: 'flex', columnGap: 20 }}>
        <Input
          placeholder="Offer"
          value={promo?.offer as string}
          onChange={onChange}
          name="offer"
          className="mb-2"
          style={{ width: '300px' }}
        />
        <Input
          placeholder="Promo Code"
          value={promo?.code as string}
          onChange={onChange}
          name="code"
          className="mb-2"
          style={{ width: '300px' }}
        />
        <DatePicker
          style={{ height: '32px' }}
          id={'date-picker-antd'}
          size="small"
          onChange={onChange}
          disabled
          placeholder="Expiration"
          value={moment(promo?.expire || new Date())}
        />
      </div>
    </div>
  );
});

ItemEditPromo.displayName = 'ItemEditPromo';
