import React, { useEffect, useState } from 'react';
import {
  useGetCategoriesListLazyQuery,
  useUpscaleRelatedItems350CropTo700Mutation,
} from 'graphql/types';
import { UILoader } from 'nativeComponents/UI/UILoader/UILoader';
import { Button, message } from 'antd';
import { IconGenerateItem } from '../IconsGenerate/IconGenerateItem';

export const CropSettings = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [checked, setChecked] = useState<string>('');
  const [result, setResult] = useState<any>('');

  const [updateCrop] = useUpscaleRelatedItems350CropTo700Mutation();
  const [fetchCategories, { loading: fetchLoading }] =
    useGetCategoriesListLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ categories }) => {
        const { data } = categories;
        setList((data || []) as any);
      },
      onError: e => {
        message.error(e.message);
      },
    });

  const handleCheck = (id: string) => {
    const isExistInList = checked.includes(id);
    setChecked(isExistInList ? '' : id);
  };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const { data } = await updateCrop({
        variables: {
          categoryID: checked,
        },
      });
      setResult(data?.upscaleRelatedItems350CropTo700);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error(e.message);
    }
  };

  useEffect(() => {
    fetchCategories({
      variables: {
        limit: 1000,
      },
    });
  }, []);

  const currentLoading = loading || fetchLoading;
  return (
    <div className="pa-8 fluid">
      {currentLoading && <UILoader />}
      <h1>Upscale item&apos;s images from 350 to 700</h1>
      <div className="flex flex-wrap">
        {list.map(({ id, title }) => (
          <IconGenerateItem
            key={id}
            checked={checked === id}
            title={title}
            id={id}
            onChange={handleCheck}
          />
        ))}
      </div>

      <Button onClick={handleGenerate} type="primary" className="mt-8">
        Generate
      </Button>
    </div>
  );
};
