import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Radio, Dropdown, Menu } from 'antd';
import { constructUrl } from 'w-querify';
import { SortAscendingOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { getFilter } from 'utils/route';
import { SortVariant } from 'type';
import { SortBy, SortDirection } from 'graphql/types';

import s from '../Filter/styles.module.css';

type Props = {
  variants: SortVariant[];
  disabled?: boolean;
};

export const Sorting = ({ variants, disabled }: Props) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const parsedQuery = getFilter(search);
  const [state, setState] = useState({
    sortValue: parsedQuery.sort,
    isOpen: false,
  });

  const handleSort = (direction: SortDirection) => () => {
    const filters: any = parsedQuery;
    const url = constructUrl(pathname, {
      ...filters,
      sort: state.sortValue,
      sortDirection: direction,
    });
    setState(prev => ({ ...prev, isOpen: false }));
    history.replace(url);
  };

  const toggleOpen = () => {
    setState(prev => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleChangeSort = (value: SortBy) => e => {
    if (e.domEvent) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
    setState(prev => ({
      ...prev,
      sortValue: prev.sortValue === value ? '' : value,
    }));
  };

  useEffect(() => {
    if (!state.sortValue) {
      const url = constructUrl(pathname, {
        ...parsedQuery,
        sort: '',
        sortDirection: '',
      });
      history.replace(url);
    }
  }, [state.sortValue]);

  const chosenFilter = variants.find(
    ({ value }) => value === state.sortValue
  ) || {
    variants: [] as any[],
    value: undefined,
    name: '',
  };

  return (
    <Dropdown
      trigger={['click']}
      visible={state.isOpen}
      disabled={disabled}
      overlay={
        <Menu className={s.dropDownMenuFilter}>
          {variants.map(({ name, value }) => (
            <Menu.Item
              key={name}
              className={s.item}
              onClick={handleChangeSort(value as SortBy)}
            >
              <Radio
                checked={state.sortValue === value}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{
                  width: '100%',
                }}
                disabled={disabled}
              >
                {name}
              </Radio>
            </Menu.Item>
          ))}
          {!!state.sortValue && (
            <>
              {chosenFilter.variants.map((sort, i) => (
                <Menu.Item
                  key={sort.name}
                  onClick={handleSort(sort.direction)}
                  className={classnames({
                    [s.direction]: true,
                    [s.firstSortDirection]: i === 0,
                    [s.item]: true,
                    [s.activeDirection]:
                      parsedQuery.sortDirection === sort.direction &&
                      state.sortValue === chosenFilter.value,
                  })}
                >
                  {!!sort.icon && <sort.icon />}
                  <span>{sort.name}</span>
                </Menu.Item>
              ))}
            </>
          )}
        </Menu>
      }
    >
      <div className={s.filter} role="presentation" onClick={toggleOpen}>
        <span>{chosenFilter.name || 'Sort'}</span>
        <SortAscendingOutlined />
      </div>
    </Dropdown>
  );
};
