import CustomPagination from 'components/CustomPagination';
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  Row,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { IsLoading } from 'components/RequestStatus/IsLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  findIconDefinition,
  IconFamily,
  IconStyle,
} from '@fortawesome/fontawesome-svg-core';
import { MapPin, Slash, X, XSquare } from 'react-feather';
import { useToggle } from 'ahooks';
import classNames from 'classnames';
import { BindedIcon, CheckedLightIcon } from '../types';

import { IconGrid } from './icon-grid';
import { IconLightButton } from './icon-light-button';
import {
  useGetIconByIdQuery,
  usePrefetch,
  useSearchQuery,
} from '../model/searchApi';
import { getIcon } from '../lib/get-icon';
import { transform } from '../lib/transform';
import { getIconStyle } from '../lib/get-icon-style';
import { getIconFamily } from '../lib/get-icon-family';
import { getIconPrefix } from '../lib/get-icon-prefix';
import { isNonEmptyBindedIcon } from '../lib/is-non-empty-binded-icon';
import { getDefaultIconAttributes } from '../lib/get-default-icon-attributes';
import { ColorPicker } from 'nativeComponents/Common/ColorPicker/ColorPicker';

type Props = {
  value: BindedIcon;
  onChange: (newIcon: BindedIcon) => void;
  setColor: (color: string) => void;
};

export const IconPicker: React.FC<Props> = function FaIconPicker({
  value,
  onChange,
  setColor,
}) {
  const [toggleState, toggleActions] = useToggle(false);
  const checkedLightIcon = React.useMemo<Nullable<CheckedLightIcon>>(() => {
    if (isNonEmptyBindedIcon(value)) {
      return transform.fromNamePrefix(value);
    }
    return null;
  }, [value]);

  const [params, setParams] = React.useState({
    limit: 60,
    page: 1,
    search: '',
  });

  const [target, setTarget] = React.useState<Nullable<HTMLButtonElement>>(null);

  const { data: allIconsData, isSuccess, isLoading } = useSearchQuery(params);
  const { data: choosenIconData } = useGetIconByIdQuery({
    id: checkedLightIcon ? checkedLightIcon.id : '',
  }, {skip: !checkedLightIcon});

  const handleIconMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTarget(e.currentTarget);
  };

  const handleIconMouseLeave = () => {
    setTarget(null);
  };

  const handleNewItemSelection = (e: React.MouseEvent) => {
    const selected = allIconsData?.data.find(
      el => el.id === e.currentTarget.id
    );
    if (selected) {
      onChange(
        transform.toNamePrefix({
          id: selected.id,
          meta: getDefaultIconAttributes(selected),
        })
      );
    }
  };

  const handleClearSearch = () => {
    setParams(prev => ({ ...prev, search: '', page: 1 }));
  };

  const handleSelectIconStyle = (e: React.MouseEvent<HTMLElement>) => {
    const style = e.currentTarget.dataset.iconStyle as Undefinable<IconStyle>;
    if (style && checkedLightIcon) {
      const { meta } = checkedLightIcon;
      onChange(
        transform.toNamePrefix({
          ...checkedLightIcon,
          meta: { ...meta, style },
        })
      );
    }
  };

  const handleSelectIconFamily = (e: React.MouseEvent<HTMLElement>) => {
    const family = e.currentTarget.dataset
      .iconFamily as Undefinable<IconFamily>;
    if (family && checkedLightIcon) {
      const { meta } = checkedLightIcon;
      onChange(
        transform.toNamePrefix({
          ...checkedLightIcon,
          meta: { ...meta, family },
        })
      );
    }
  };

  const handleClearHighlighted = () => {
    onChange({ name: '', prefix: '' });
  };

  const icon =
    choosenIconData && checkedLightIcon
      ? getIconPrefix({
          name: checkedLightIcon.id,
          family: checkedLightIcon.meta.family,
          style: checkedLightIcon.meta.style,
        })
        ? findIconDefinition({
            iconName: checkedLightIcon.id,
            prefix:
              getIconPrefix({
                name: checkedLightIcon.id,
                family: checkedLightIcon.meta.family,
                style: checkedLightIcon.meta.style,
              }) || 'far',
          })
        : getIcon(choosenIconData)
      : undefined;

  usePrefetch('loadAllIcons');

  return (
    <>
      <Card className="form-control">
        <CardBody>
          <Row>
            <Col sm="12" className="mb-2">
              <span className="form-control text-center px-50">
                {choosenIconData ? (
                  icon && (
                    <FontAwesomeIcon
                      icon={icon}
                      size="5x"
                      color={value?.color}
                    />
                  )
                ) : (
                  <Slash size={70} />
                )}
              </span>
            </Col>

            <Col sm="12" className="d-flex justify-content-between">
              <Button
                type="button"
                color="primary"
                onClick={toggleActions.setRight}
                size={'sm'}
              >
                <MapPin size={14} />
                <span className="ml-1 align-middle">
                  {checkedLightIcon ? 'Change' : 'Pick'}
                </span>
              </Button>

              <Button
                type="button"
                color="danger"
                onClick={handleClearHighlighted}
                className={classNames({
                  'd-none': checkedLightIcon === null,
                })}
                size={'sm'}
              >
                <XSquare size={14} />
                <span className="ml-1 align-middle">Drop</span>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={toggleState} size="lg" onClosed={toggleActions.setLeft}>
        <ModalBody>
          <Row className="mt-1">
            <Col lg="12">
              <InputGroup className="mb-1">
                <InputGroupText
                  style={{ width: '70px' }}
                  className="justify-content-center"
                >
                  {isSuccess ? (
                    <span>
                      <Badge pill color="light-secondary">
                        {allIconsData?.totalRecordsCount}
                      </Badge>
                    </span>
                  ) : (
                    <Spinner size="sm" />
                  )}
                </InputGroupText>

                <Input
                  value={params.search}
                  onChange={e => {
                    setParams(prev => ({
                      ...prev,
                      search: e.target.value,
                      page: 1,
                    }));
                  }}
                />

                <Button
                  color="primary"
                  outline
                  onClick={handleClearSearch}
                  disabled={params.search === ''}
                >
                  Clear
                </Button>
              </InputGroup>
            </Col>
          </Row>

          <Row className="mb-50 position-relative">
            <Col lg="3" className="my-50">
              <div className="d-flex align-items-center">
                <span
                  className="form-control text-center px-50"
                  style={{ width: 60, height: 50 }}
                >
                  {choosenIconData ? (
                    icon && (
                      <FontAwesomeIcon
                        icon={icon}
                        size="2x"
                        color={value.color}
                      />
                    )
                  ) : (
                    <Slash size={28} />
                  )}
                </span>

                {choosenIconData && (
                  <span className="ms-1 text-primary font-small-5">
                    {choosenIconData.label}
                  </span>
                )}
              </div>
            </Col>

            <Col className="my-50" lg="3" sm="4">
              {choosenIconData && (
                <div>
                  <p className="text-muted form-label mb-0">Family</p>
                  {getIconFamily(choosenIconData).map(el => (
                    <Badge
                      color={
                        checkedLightIcon && checkedLightIcon.meta.family === el
                          ? 'primary'
                          : 'secondary'
                      }
                      key={el}
                      className="mr-1 pointer"
                      data-icon-family={el}
                      onClick={handleSelectIconFamily}
                    >
                      {el}
                    </Badge>
                  ))}
                </div>
              )}
            </Col>

            <Col className="my-50" lg="6" sm="8">
              {choosenIconData && (
                <div>
                  <p className="text-muted form-label mb-0">Style</p>
                  {getIconStyle(choosenIconData).map(el => (
                    <Badge
                      color={
                        checkedLightIcon && checkedLightIcon.meta.style === el
                          ? 'info'
                          : 'secondary'
                      }
                      key={el}
                      className="mr-1 pointer"
                      onClick={handleSelectIconStyle}
                      data-icon-style={el}
                    >
                      {el}
                    </Badge>
                  ))}
                </div>
              )}
            </Col>

            <Button
              className="btn-icon position-absolute"
              style={{ top: '0.5rem', right: '1rem', width: 35 }}
              onClick={handleClearHighlighted}
              color="danger"
              disable={checkedLightIcon === null}
            >
              <X size={10} />
            </Button>
          </Row>

          <Row className="mb-1">
            <Col lg="12">
              {isSuccess && (
                <IconGrid>
                  {
                    allIconsData?.data.map(el => (
                      <IconLightButton
                        key={el.id}
                        iconData={el}
                        isHighlighted={
                          checkedLightIcon !== null &&
                          checkedLightIcon.id === el.id
                        }
                        id={el.id}
                        onMouseEnter={handleIconMouseEnter}
                        onMouseLeave={handleIconMouseLeave}
                        onClick={handleNewItemSelection}
                      />
                    )) as React.ReactElement[]
                  }
                </IconGrid>
              )}

              {isLoading && (
                <IsLoading active className="py-5">
                  <Spinner color="primary" />
                </IsLoading>
              )}
            </Col>

            <CustomPagination
              totalPagesCount={
                isSuccess ? (allIconsData?.totalPagesCount as number) : 1
              }
              page={params.page}
              onPageChange={({ selected }) => {
                setParams(prev => ({ ...prev, page: selected + 1 }));
              }}
              containerClassName="pagination-md"
            />
          </Row>

          <Row className="mb-1">
            <Col>
              <ColorPicker value={value?.color as string} setValue={setColor} />
            </Col>
            <Col className="d-flex justify-content-end">
              <Button outline onClick={toggleActions.setLeft}>
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>

        {target && (
          <UncontrolledTooltip target={target}>
            {target.name}
          </UncontrolledTooltip>
        )}
      </Modal>
    </>
  );
};
