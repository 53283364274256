import React, { useState } from 'react';
import { useResource } from 'hooks/useResource';
import { useRouter } from 'hooks/useRouter';
import { Row, Button, Checkbox, Space } from 'antd';
import { SortVariant } from 'type';
import { SearchField } from '../Search/SearchField';
import { Filter } from './Filter/Filter';
import { Sorting } from './Sorting/Sorting';
import { MoreFilters } from './MoreFilters/MoreFilters';
import { FiltersTagsList } from './FiltersTagsList/FiltersTagsList';

import s from './styles.module.css';
import { BooleanFilter } from './BooleanFilter/BooleanFilter';

type Props = {
  filters?: {
    name: string;
    items: {
      value: string;
      name: string;
      status: any;
    }[];
  }[];
  disabled?: boolean;
  sort?: SortVariant[];
  extendFilters?: any[];
  booleanFilters?: { label: string; filter: string }[];
};

export const FilterSet: React.FC<Props> = ({
  filters = [],
  sort,
  extendFilters = [],
  booleanFilters = [],
  disabled,
}) => {
  const { push, constructUrl, pathname, query } = useRouter();
  const { resources } = useResource();

  const [state, setState] = useState({
    isMoreFiltersOpened: false,
  });

  const handleCreateNew = () => {
    push(`/${resources?.name}/edit`);
  };

  const toggleMoreFiltersOpen = () =>
    setState(prev => ({
      ...prev,
      isMoreFiltersOpened: !prev.isMoreFiltersOpened,
    }));

  const onChangeListFilter = (queries: Record<string, any>) => {
    push(constructUrl(pathname, { ...query, ...queries, page: 1 }));
  };

  return (
    <>
      <Row className={`${s.filterSet} pt-4 pr-4 pb-3 pl-4`}>
        <div className="flex justify-between fluid">
          <div className={s.content}>
            <div className="flex-column">
              {resources.search !== false && (
                <SearchField apply={onChangeListFilter} disabled={disabled} />
              )}
            </div>
            <Space className="flex-column align-start">
              <Space>
                {filters.map(({ name, items }) => (
                  <Filter
                    name={name}
                    key={name}
                    variants={items}
                    apply={onChangeListFilter}
                    disabled={disabled}
                  />
                ))}
                {!!sort && <Sorting variants={sort} disabled={disabled} />}

                {!!extendFilters.length && (
                  <Button
                    onClick={toggleMoreFiltersOpen}
                    className={s.btn}
                    disabled={disabled}
                  >
                    More filters
                  </Button>
                )}
              </Space>
              {!!booleanFilters.length && (
                <Space className="pt-2">
                  {booleanFilters.map(({ label, filter }) => (
                    <BooleanFilter label={label} filter={filter} key={filter} />
                  ))}
                </Space>
              )}
            </Space>
          </div>

          <Button
            type="primary"
            onClick={handleCreateNew}
            className={s.btn}
            disabled={disabled}
          >
            {`Add ${resources.name}`}
          </Button>
        </div>
        <FiltersTagsList filters={[...filters, ...extendFilters]} sort={sort} />
      </Row>

      {!!extendFilters && (
        <MoreFilters
          visible={state.isMoreFiltersOpened}
          onClose={toggleMoreFiltersOpen}
          apply={onChangeListFilter}
          extendFilters={extendFilters}
        />
      )}
    </>
  );
};
