export const capitalFirst = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const niceTitle = (title: string) => {
  return capitalFirst(
    title
      .split(/(?=[A-Z])/)
      .join(' ')
      .toLowerCase()
  );
};

export const getPlainString = (str: string) =>
  str.toLocaleLowerCase().replace(/\s/g, '');

export const toSnakeCase = (str: string) => {
  return str.toLowerCase().replace(/\s/g, '_');
};

export function string_to_slug(str, separator = '-') {
  str = str.trim();
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  return str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end of text
    .replace(/-/g, separator);
}
