const LoadImage = () => (
  <svg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.25609 9.26627C7.73781 9.26627 8.9573 8.03566 8.9573 6.54051C8.9573 5.04536 7.73777 3.81474 6.25609 3.81474C4.77441 3.81474 3.55488 5.04536 3.55488 6.54051C3.58842 8.0357 4.77437 9.26627 6.25609 9.26627ZM6.25609 5.04532C7.07926 5.04532 7.73781 5.70986 7.73781 6.54051C7.73781 7.37115 7.07926 8.03569 6.25609 8.03569C5.43293 8.03569 4.77437 7.37115 4.77437 6.54051C4.80795 5.70986 5.46646 5.04532 6.25609 5.04532ZM22.3903 0.461365H1.57926C0.722563 0.461365 0 1.15971 0 2.05498V20.1632C0 21.0277 0.692088 21.7568 1.57926 21.7568H22.4207C23.2774 21.7568 24 21.0585 24 20.1632V2.05498C23.9695 1.1905 23.2774 0.461365 22.3903 0.461365ZM22.7531 17.6713H4.74084L8.49391 12.6197L10.8659 14.8471C10.997 14.9794 11.1616 15.0132 11.3598 15.0132C11.5244 14.9794 11.689 14.9148 11.7866 14.7486L17.7805 6.14367L22.7531 11.7583V17.6713ZM1.57926 1.68889H22.4207C22.6189 1.68889 22.7835 1.85504 22.7835 2.05498V9.86313L18.2073 4.71307C18.0762 4.58076 17.9116 4.51308 17.7134 4.51308C17.5153 4.51308 17.3506 4.61155 17.2531 4.77766L11.1951 13.4503L8.82316 11.2599C8.69205 11.1275 8.52744 11.0937 8.36279 11.0937C8.19814 11.0937 8.03353 11.1921 7.93595 11.3275L3.22563 17.6374H1.21647V2.02114C1.21647 1.855 1.38112 1.68889 1.57926 1.68889ZM22.3903 20.5294H1.57926C1.38108 20.5294 1.21647 20.3632 1.21647 20.1633V18.9019H22.75V20.1633C22.7531 20.3632 22.5884 20.5294 22.3903 20.5294Z'
      fill='#D0D3D9' />
  </svg>

);

export default LoadImage;
