import { Button, Input } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { StringDynamicComponent } from './StringDynamicComponent';
import { IMageDynamicComponent } from './IMageDynamicComponent';
import { BooleanDynamicComponent } from './BooleanDynamicComponent';
import { DynamicFieldType } from '../../graphql/types';

export const ArrayedDynamicContainer = ({
  dynamicData,
  dynamicDataParser,
  model,
  field,
  onUpdate,
}) => {
  if (!dynamicData.find(x => x.model.id === model.id)?.data) return null;

  const data = dynamicDataParser?.getFieldData(model, field) || [];
  const arrayedData = data instanceof Array ? data : [data];

  return (
    <div>
      {arrayedData.map((item, idx) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '4px',
          }}
          key={idx}
        >
          {(field.type === DynamicFieldType.String ||
            field.type === DynamicFieldType.Number) && (
            <StringDynamicComponent
              dynamicDataParser={dynamicDataParser}
              model={model}
              field={field}
              onUpdate={onUpdate}
              index={idx}
            />
          )}
          {field.type === DynamicFieldType.Image && (
            <IMageDynamicComponent
              dynamicDataParser={dynamicDataParser}
              model={model}
              field={field}
              onUpdate={onUpdate}
              index={idx}
            />
          )}
          {field.type === DynamicFieldType.Boolean && (
            <BooleanDynamicComponent
              dynamicDataParser={dynamicDataParser}
              model={model}
              field={field}
              onUpdate={onUpdate}
              index={idx}
            />
          )}
          <Button
            type="text"
            icon={<MinusCircleOutlined />}
            onClick={() => {
              onUpdate(
                dynamicDataParser?.removeFromArrayAndReturnDataList(
                  field,
                  model,
                  idx
                )
              );
            }}
          />
        </div>
      ))}
      <div>
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          onClick={() =>
            onUpdate(
              dynamicDataParser!.addItemToArrayAndReturnDataList(field, model)
            )
          }
        />
      </div>
    </div>
  );
};
