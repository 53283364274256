import React, { useEffect } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { DefaultSizesSettings, ParentType } from 'graphql/types';
import { preventClick } from 'utils/handlers';

export const ScreenshotSettings = () => {
  const [form] = Form.useForm();
  const { updateSettings, screenshot } = useConfig();

  const onSubmit = async ({ sizes, oneByOneCrop }) => {
    const payload: DefaultSizesSettings['sizes'] = sizes.map(
      ({ width, aspectRatio, ...rest }) => ({
        width: Number(width),
        aspectRatio: Number(aspectRatio),
        ...rest,
      })
    );
    await updateSettings({ oneByOneCrop, sizes: payload });
  };

  useEffect(() => {
    form.setFieldsValue({
      sizes: screenshot.sizes.map(size => ({
        ...size,
        parentType: size.parentType || ParentType.Item,
      })),
      oneByOneCrop: screenshot.oneByOneCrop,
    });
  }, [screenshot]);

  return (
    <Card>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        labelAlign="left"
        layout="vertical"
        wrapperCol={{ span: 24 }}
        onFinish={onSubmit}
      >
        <Form.Item name="oneByOneCrop" valuePropName="checked">
          <Checkbox>Crop every image separately</Checkbox>
        </Form.Item>
        <Form.List name="sizes">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Row gutter={20} key={name}>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'width']}
                        label="Width"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'aspectRatio']}
                        label="Aspect ratio"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'parentType']}
                        label="Type"
                      >
                        <Select>
                          {Object.entries(ParentType).map(([key, type]) => {
                            return (
                              <Select.Option value={type} key={key}>
                                {type}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'retina']}
                        label="Double size"
                        valuePropName="checked"
                      >
                        <Checkbox>Retina</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, 'lock']}
                        label="Lock crop"
                        valuePropName="checked"
                      >
                        <Checkbox>Lock</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        danger
                        style={{ marginTop: 30 }}
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(name)}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Button
                type="dashed"
                onClick={e => {
                  preventClick(e);
                  add();
                }}
                icon={<PlusOutlined />}
              >
                Add size
              </Button>
            </div>
          )}
        </Form.List>
        <Button htmlType="submit" type="primary" className="mt-5">
          Submit
        </Button>
      </Form>
    </Card>
  );
};
