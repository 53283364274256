import React, { useMemo } from 'react';
import { DynamicFieldType, DynamicModel } from 'graphql/types';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import { Select } from 'antd';
import { DynamicInputImage } from '../DynamicInputImage/DynamicInputImage';

type Props = {
  model: DynamicModel;
  dynamicDataParser: DynamicDataParser;
  onUpdate: any;
};

export const ManyToMany: React.FC<Props> = ({
  model,
  dynamicDataParser,
  onUpdate,
}) => {
  const getChosenValue = () => {
    const variantIDs =
      dynamicDataParser && dynamicDataParser.getFieldData(model);
    if (!variantIDs) return [];
    const ids = variantIDs.ids || variantIDs.id;
    const arrayedIds = ids instanceof Array ? ids : [ids];
    return arrayedIds.map(id => id);
  };

  const handleChange = (_, option) => {
    const valueId = option?.option?.id;
    const currentValue = getChosenValue();
    const isInList = currentValue.find(id => id == valueId);
    const resultValue = isInList
      ? currentValue.filter(id => id != valueId)
      : [...currentValue, valueId];
    onUpdate(
      dynamicDataParser!.changeData(
        model,
        undefined,
        JSON.stringify({
          ids: resultValue.map(id => Number(id)).filter(v => !!v),
        })
      )
    );
  };

  const options = useMemo(() => {
    return (
      model.variants?.map(variant => {
        const label = JSON.parse(variant.value)[model.name];
        return {
          label,
          value: label,
          option: { id: variant.id },
        };
      }) || []
    );
  }, [model?.variants]);

  const isImage =
    dynamicDataParser &&
    dynamicDataParser.getPrimaryField(model.fields).type ===
      DynamicFieldType.Image;

  const chosenIds = getChosenValue();

  const valueSelect = useMemo(() => {
    return chosenIds.map(
      id => options.find(({ option }) => option?.id == id)?.value
    );
  }, [options, chosenIds]);

  return (
    <div
      className="flex"
      style={{
        maxWidth: 500,
      }}
    >
      {isImage && (
        <DynamicInputImage
          model={model}
          dynamicDataParser={dynamicDataParser}
          onChange={handleChange as any}
          chosenId={chosenIds}
        />
      )}
      {!isImage && (
        <Select
          value={valueSelect}
          style={{ width: '100%' }}
          onSelect={handleChange}
          onDeselect={handleChange}
          className="mr-2"
          mode="tags"
          options={options}
        />
      )}
    </div>
  );
};
