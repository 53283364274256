import { Category } from '../../graphql/types';

export const constructSortCategories = (list, dragOn, drag) => {
  const newList = list.concat([]);
  const dragCategory = list[drag.column][drag.index];
  newList[drag.column].splice(drag.index, 1);
  newList[dragOn.column].splice(dragOn.index, 0, dragCategory);
  return newList;
};

export const constructDragItems = (list, dragOn, drag) => {
  const newList = list.concat([]);
  const dragItem = list[drag.column][drag.categoryIndex].items[drag.index];
  newList[drag.column][drag.categoryIndex!].items!.splice(drag.index, 1);
  newList[dragOn.column][dragOn.categoryIndex!].items!.splice(
    dragOn.index,
    0,
    dragItem
  );

  return newList;
};

export const getSortActiveSortCategories = (sortCategories: Category[][]) => {
  const result: Category[][] = [];
  sortCategories.forEach(categories => {
    const active: Category[] = [];
    const disabled: Category[] = [];
    categories.forEach(category => {
      category.active ? active.push(category) : disabled.push(category);
    });
    result.push([...active, ...disabled]);
  });

  return result;
};
