import { UploadImageDocument } from 'graphql/types';
import { initializeApollo } from 'utils/apollo';

const handleSaveImage = async (file: File) => {
  if (!file) return false;

  const fetch = initializeApollo();
  const {
    data: {
      uploadImage: {
        Sizes: [savedFile],
      },
    },
  } = await fetch.mutate({
    mutation: UploadImageDocument,
    variables: {
      input: { file },
    },
  });
  return savedFile;
};

export const imageHandler = async (image: File) => {
  const savedFile = await handleSaveImage(image);
  return savedFile.value;
};
