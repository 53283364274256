import React from 'react';
import classnames from 'classnames';
import { Checkbox, Typography } from 'antd';
import { useCSV } from 'components/Settings/CSV/CSVProvider/CSVProvider';
import { getPlainString } from 'utils/string';

import s from './styles.module.scss';

const { Text } = Typography;

export const CSVPlainItem = ({ item, disabled }) => {
  const { chosen, onChooseItem } = useCSV();

  const onChoose = () => {
    onChooseItem(item);
  };

  const isChosen = !!chosen[getPlainString(item.title)];
  return (
    <Checkbox checked={isChosen} onChange={onChoose} disabled={disabled}>
      <div
        className={classnames({
          [s.compareItem]: true,
        })}
      >
        <span className={s.itemTitle}>{item.title}</span>
        <Text type="success" className="ml-2">
          New
        </Text>
      </div>
    </Checkbox>
  );
};
