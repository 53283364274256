const AngleUp = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path d="M0 0L0 24H24V0L0 0Z" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M20.4703 15.485L11.985 6.99976L3.4997 15.485"
        stroke="#1A1B46"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default AngleUp;
