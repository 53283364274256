import { IconLight } from '../types';

export const isFabIcon = (icon: IconLight): boolean => {
  const fabByPrefix = icon.shim && icon.shim.prefix === 'fab';
  const fabByFamily =
    icon.familyStylesByLicense &&
    icon.familyStylesByLicense.pro.findIndex(el => el.style === 'brands') !==
      -1;
  return fabByPrefix || fabByFamily;
};
