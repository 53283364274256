import React from 'react';
import {Col, Input, Row, Switch, Typography} from 'antd';
import {niceTitle} from 'utils/string';
import {Link} from 'graphql/types';
import {booleanSort} from './helpers';

type Props = {
    link: Link;
    name: string;
    onChange: (n: string) => (d: any) => void;
};

export const LinksBlock = ({link, onChange, name}: Props) => {
    const onSwitchLink = (switchName: string) => (checked: boolean) => {
        onChange(name)({target: {checked, name: switchName}});
    };

    const handleInputChange = (e: any) => {
        onChange(name)(e);
    };
    const linkState = booleanSort(link as any);

    return (
        <>
            <Typography.Title level={5}>Links</Typography.Title>
            <div className="flex-column">
                {Object.entries(linkState.string).map(([key]) => (
                    <Input
                        name={key}
                        value={link[key]}
                        onChange={handleInputChange}
                        placeholder={niceTitle(key)}
                        style={{marginBottom: 'var(--space-2)'}}
                    />
                ))}
            </div>
            <div className="flex-column mt-1">
                <Typography.Title level={5} style={{marginBottom: 'var(--space-3)'}}>
                    Link options
                </Typography.Title>
                {Object.entries(linkState.boolean).map(([key], i, arr) => (
                    <div
                        key={key}
                        className={`flex justify-between ${
                            i !== arr.length - 1 ? 'mb-3' : ''
                        }`}
                    >
                        <span>{niceTitle(key)}</span>
                        <Switch checked={link[key]} onChange={onSwitchLink(key)}/>
                    </div>
                ))}
            </div>
        </>
    );
};
