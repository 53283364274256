import React, { useEffect, useState } from 'react';
import { Input, Typography } from 'antd';
import { UIUpload } from 'nativeComponents/UI';
import { EditCard } from 'components/Common/EditCard/EditCard';
import { HTMLEditor } from 'nativeComponents/Common/HTMLEditor/HTMLEditor';
import { Author, BlogPost, Entity, ParentType } from 'graphql/types';
import { handleInputChange } from 'utils/handlers';
import { getIds, getImageValue } from 'utils/getData';
import { SearchSelect } from '../Common/SearchSelect/SearchSelect';
import { SEARCH_SELECT_TYPES } from '../Common/SearchSelect/helpers';
import { DynamicFieldsComponent } from '../DynamicFieldsComponent';
import { SubmitGroup } from '../Common/SubmitGroup/SubmitGroup';
import { getDynamicModelsPayload } from '../DynamicFieldsComponent/helpers';
import { BlogPostDate } from './BlogPostDate';
import { constructState } from './helpers';
import { GetLabelsDocument } from 'graphql/types';
import { initializeApollo } from 'utils/apollo';

export const BlogPostEdit = ({ onSubmit, ...props }) => {
  const { author = {} as Author, date, slug, active, id } = props;
  const [state, setState] = useState(constructState(props as BlogPost));

  const onImageUpload = (name: string, image: any) => {
    setState(prev => ({ ...prev, [name]: image }));
  };

  const onDelete = (name: string, index: number) => {
    setState(prev => ({
      ...prev,
      [name]: null,
    }));
  };

  const handleChange = (name: string) => (data: any) => {
    setState(prev => ({ ...prev, [name]: data }));
  };

  const handleSubmit = (redirect?: boolean) => {

  onSubmit({
    ...state,
    active,
    dynamic: getDynamicModelsPayload(state.dynamic),
    categories: getIds(state.categories),
    labels: getIds(state.labels),
    redirect,
  });
	};

  useEffect(() => {
    setState(constructState(props as BlogPost));
  }, [id]);
  return (
    <EditCard>
      <div className="fluid flex">
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Title</Typography.Title>
            <Input
              placeholder="Select title"
              value={state.title}
              onChange={handleInputChange(setState)}
              name="title"
            />
          </div>
          <div className="editTitle">
            <Typography.Title level={5}>Thumb</Typography.Title>
            <UIUpload
              previews={getImageValue(state.thumb)}
              onChange={onImageUpload}
              name="thumb"
              type="image"
              onDelete={onDelete}
              withZeroCrop
              parent={ParentType.BlogPost}
            />
          </div>
          <div className="editTitle">
            <Typography.Title level={5}>Short description</Typography.Title>
            <Input
              value={state.shortDescription}
              onChange={handleInputChange(setState)}
              name="shortDescription"
            />
          </div>
          <div className="editTitle last">
            <Typography.Title level={5}>Content</Typography.Title>
            <HTMLEditor
              value={state.content}
              currentLanguage=""
              onChange={handleInputChange(setState)}
              name="content"
            />
          </div>
          <DynamicFieldsComponent
            update={(d) => setState(prev => ({ ...prev, dynamic: d }))}
            dynamicData={state.dynamic}
            entity={Entity.Blog}
            noBuffer
          />
        </div>
        <div className="sideContent">
          <div className="sideContentItem">
            <Typography.Title level={5}>Categories</Typography.Title>
            <SearchSelect
              mode="multiple"
              placeholder="Select categories"
              values={state.categories}
              onChange={handleChange('categories')}
              name={SEARCH_SELECT_TYPES.CATEGORY}
            />
          </div>
          <div className="sideContentItem">
            <Typography.Title level={5}>Labels</Typography.Title>
            <SearchSelect
              mode="multiple"
              placeholder="Select labels"
              values={state.labels}
              onChange={handleChange('labels')}
              name={SEARCH_SELECT_TYPES.LABEL}
            />
          </div>
          {!!author.name && (
            <div className="sideContentItem flex-column">
              <Typography.Title level={5}>Author</Typography.Title>
              <Typography.Text>{author.name}</Typography.Text>
            </div>
          )}
          {!!slug && (
            <div className="sideContentItem flex-column">
              <Typography.Title level={5}>Slug</Typography.Title>
              <Input
                value={state.slug}
                onChange={handleInputChange(setState)}
                name="slug"
              />
            </div>
          )}
          <BlogPostDate date={state.date} onChange={handleChange('date')} />
        </div>
      </div>
      <SubmitGroup onSubmit={handleSubmit} />
    </EditCard>
  );
};
