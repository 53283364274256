/* eslint-disable */
import React, { useRef, useState, useMemo } from 'react';
import _noop from 'lodash/noop';
import classnames from 'classnames';
import { useWorker, useWorkerSubscribe } from 'hooks/useWorker';
import { getMouseXY } from './helpers';
import { DRAG_EVENT, MATCH_DRAG, DROP_DRAG, START_DRAG } from './DragProvider';

import s from './style.module.css';
import { useDevice } from '../useDevice';

type ItemProps = {
  orientation?: 'vertical' | 'horizontal';
  dataId: string;
  delayTime?: number;
  className?: string;
  customTrigger?: boolean;
  children: ({ onMouseDown, onMouseUp }) => React.ReactElement;
  disabledPrevent?: boolean;
};

export const DragItem: React.FC<ItemProps> = ({
  children,
  dataId,
  delayTime = 300,
  orientation = 'horizontal',
  className,
  customTrigger,
  disabledPrevent,
}) => {
  const timer = useRef<any>(null);
  const startCoords = useRef({ clientX: 0, clientY: 0 });
  const elemRef = useRef<HTMLDivElement>(null);
  const matchDraggingRef = useRef(false);
  const [state, setState] = useState({ active: false, matchedDrag: false });
  const { postMessage } = useWorker();
  const { isMobile } = useDevice();

  useWorkerSubscribe(DRAG_EVENT, data => {
    switch (data.event) {
      case MATCH_DRAG: {
        if (
          data.dragging === dataId ||
          (data.dragId !== dataId && !matchDraggingRef.current)
        )
          return;
        if (data.dragId !== dataId && matchDraggingRef.current) {
          matchDraggingRef.current = false;
          setState(prev => ({ ...prev, matchedDrag: false }));
          return;
        }
        if (data.dragId === dataId && !matchDraggingRef.current) {
          matchDraggingRef.current = true;
          setState(prev => ({ ...prev, matchedDrag: true }));
          return;
        }
        return;
      }

      case DROP_DRAG: {
        dropDrag();
        return;
      }

      default:
        return;
    }
  });

  const dropDrag = () => {
    clearTimer();
    setState(prev => ({ ...prev, active: false, matchedDrag: false }));
    startCoords.current = { clientX: 0, clientY: 0 };
    window.removeEventListener('mousemove', handleDragInitialMove);
  };

  const clearTimer = () => {
    clearTimeout(timer.current);
    timer.current = null;
  };

  const handleDragInitialMove = e => {
    const { clientX, clientY } = getMouseXY(e);
    const isEnoughToDrag =
      Math.abs(clientX - startCoords.current.clientX) > 20 ||
      Math.abs(clientY - startCoords.current.clientY) > 20;
    if (!isEnoughToDrag) return;

    window.removeEventListener('mousemove', handleDragInitialMove);
    setState(prev => ({ ...prev, active: true }));
    postMessage({
      type: DRAG_EVENT,
      event: START_DRAG,
      dataId,
      clientX: startCoords.current.clientX,
      clientY: startCoords.current.clientY,
    });
  };

  const onMouseDown = e => {
    if (!e.targetTouches && !disabledPrevent) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!isMobile) {
      const { clientX, clientY } = getMouseXY(e);
      startCoords.current = { clientX, clientY };
      window.addEventListener('mousemove', handleDragInitialMove);
    } else {
      clearTimer();
      timer.current = setTimeout(() => {
        const { clientX, clientY } = getMouseXY(e);
        setState(prev => ({ ...prev, active: true }));
        postMessage({
          type: DRAG_EVENT,
          event: START_DRAG,
          dataId,
          clientX,
          clientY,
        });
      }, delayTime);
    }
  };

  const onMouseUp = () => {
    dropDrag();
  };

  const triggers = useMemo(() => {
    if (customTrigger) {
      return {
        onMouseDown: _noop,
        onTouchStart: _noop,
        onTouchEnd: _noop,
        onMouseUp: _noop,
      };
    }
    return {
      onMouseDown,
      onTouchStart: onMouseDown,
      onTouchEnd: onMouseUp,
      onMouseUp,
    };
  }, [customTrigger]);

  return (
    <div
      data-drag={dataId}
      {...triggers}
      onContextMenu={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      ref={elemRef}
      draggable="false"
      className={classnames(className, {
        [s.dragItem]: true,
        [s.active]: state.active,
        [s.matched]: state.matchedDrag,
        [s.vertical]: orientation === 'vertical',
        [s.horizontal]: orientation === 'horizontal',
      })}
    >
      {children({ onMouseDown, onMouseUp })}
    </div>
  );
};
