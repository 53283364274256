import React from 'react';
import { Button, Popconfirm } from 'antd';
import { SortV4CategoriesColumn } from './SortV4CategoriesColumn';
import { useSortV4 } from './SortV4Provider';

import s from './sort.module.css';

export const SortV4 = () => {
  const {
    data,
    loading,
    updateColumnsCount,
    handleFillUp,
    globalExpand,
    setGlobalExpand,
  } = useSortV4();

  const handleExpand = () => {
    setGlobalExpand(!globalExpand);
  };

  const expandText = globalExpand ? 'Collapse all' : 'Expand all';
  return (
    <div>
      <div className={s.metaContainer}>
        <Popconfirm onConfirm={() => handleFillUp(false)} title="Sync content?">
          <Button type="primary" loading={loading} ghost>
            Sync sorting
          </Button>
        </Popconfirm>
        <Button
          type="primary"
          loading={loading}
          ghost
          onClick={handleExpand}
          className="ml-2"
        >
          {expandText}
        </Button>
      </div>
      <div className={s.container}>
        {data.map((col, idx) => (
          <SortV4CategoriesColumn columnIndex={idx} column={col} key={idx} />
        ))}
      </div>
    </div>
  );
};
