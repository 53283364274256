import React from 'react';
import { BulbOutlined } from '@ant-design/icons';
import { SkillsEdit } from 'components/Skills/SkillsEdit';
import { TitleWithImage } from 'nativeComponents/List/ListRowTitle/TitleWithImage';
import { filters } from './filters';
import { sort } from './sort';

const skillResource = {
  name: 'skill',
  menuIcon: BulbOutlined,
  pageTitle: 'Topics',
  editComponent: SkillsEdit,
  ...filters,
  sort,
  list: {
    actions: ['delete'],
    columns: [
      {
        title: 'Title',
        key: 'title',
        render: (record, data) => {
          return <TitleWithImage data={data} record={record} />;
        },
      },
    ],
  },
};

export default skillResource;
