import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Input, Typography } from 'antd';
import { Seo } from 'graphql/types';
import { SeoVariables } from './SeoVariables';

import s from './styles.module.css';
import { SeoImage } from '../../Categories/CategoryEdit/SeoImage';

const LENGTH_LIMIT_TITLE = 600;
const LENGTH_LIMIT_DESCRIPTION = 0;

export type SeoBoxProps = {
  seo: Seo;
  onChange: (e: ChangeEvent) => void;
  title?: string;
  withImage?: boolean;
  variables?: { title: string; value: string }[];
  onChangeImage?: (data: any) => void;
  onDeleteImage?: (data: any) => void;
};

export const SeoBox: React.FC<SeoBoxProps> = ({
  seo,
  onChange,
  title = 'SEO',
  variables,
  withImage,
  // onDeleteImage,
  // onChangeImage,
}) => {
  const inputTitleRef = useRef<any>(null);
  const titleRef = useRef<HTMLSpanElement>(null);
  const descRef = useRef<HTMLSpanElement>(null);
  const [seoEditable, toggleSeoEditable] = useState(false);
  const [titleWidth, setTitleWidth] = useState(0);
  const [descWidth, setDescWidth] = useState(0);

  const onGetVariable = (variable: string) => {
    if (!seoEditable) return;
    const cursorPosition = inputTitleRef.current?.input.selectionStart;
    const seoString = `${seo.title?.slice(
      0,
      cursorPosition
    )}${variable}${seo.title?.slice(cursorPosition)}`;
    onChange({
      target: { name: 'title', value: seoString },
    } as unknown as ChangeEvent);
  };

  const onChangeImage = (_, image) => {
    onChange({
      target: { name: 'image', value: image },
    } as unknown as ChangeEvent);
  };
  const onDeleteImage = () => {
    onChange({
      target: { name: 'image', value: '' },
    } as unknown as ChangeEvent);
  };

  useEffect(() => {
    if (!titleRef.current || !descRef.current) return;
    setTitleWidth(Math.ceil(titleRef.current.getBoundingClientRect().width));
    setDescWidth(Math.ceil(descRef.current.getBoundingClientRect().width));
  }, [seo]);

  return (
    <div className="fluid">
      <div className={s.seoHead}>
        <Typography.Title level={5}>{title}</Typography.Title>
        {!!variables && (
          <SeoVariables variables={variables} onClick={onGetVariable} />
        )}
        <Button
          onClick={() => toggleSeoEditable(!seoEditable)}
          type="text"
          style={{ color: '#5656B8', padding: '0' }}
        >
          {seoEditable ? 'Lock website SEO' : 'Edit website SEO'}
        </Button>
        <div style={{ float: 'right' }}>
          {seo?.title?.length || 0} chars (
          <span
            style={{
              color: titleWidth > LENGTH_LIMIT_TITLE ? 'red' : 'inherit',
            }}
          >
            {titleWidth}
          </span>
          / 600px)
        </div>
      </div>
      <div style={{ width: '100%', height: 0, overflow: 'hidden' }}>
        <span
          ref={titleRef}
          style={{
            fontSize: '20px',
            whiteSpace: 'nowrap',
            lineHeight: '1.3',
            fontWeight: 'normal',
            fontFamily: 'arial, sans-serif',
          }}
        >
          {seo?.title || ''}
        </span>
        <span
          ref={descRef}
          style={{
            fontFamily: 'arial, sans-serif',
            whiteSpace: 'nowrap',
            fontWeight: 'normal',
            fontSize: '14px',
            color: '#4d5156',
            lineHeight: '1.58',
          }}
        >
          {seo?.description || ''}
        </span>
      </div>

      <Input
        onChange={onChange}
        value={seo?.title || ''}
        disabled={!seoEditable}
        placeholder="Enter title"
        name="title"
        // @ts-ignore
        ref={inputTitleRef}
      />
      <div>
        <Button
          className={s.capitalizeBtn}
          onClick={() => {
            const editedTitle = seo.title
              ?.split(' ')
              .reduce(
                (acc, item, idx) =>
                  `${acc}${idx > 0 ? ' ' : ''}${
                    item && item.charAt(0).toUpperCase() + item.slice(1)
                  }`,
                ''
              );
            onChange({
              target: { value: editedTitle, name: 'title' },
            } as unknown as ChangeEvent);
          }}
          disabled={!seoEditable}
        >
          Capitalize title
        </Button>
      </div>
      {LENGTH_LIMIT_DESCRIPTION !== 0 && (
        <div style={{ float: 'right' }}>
          {seo?.description?.length || 0} chars (
          <span
            style={{
              color: descWidth > LENGTH_LIMIT_DESCRIPTION ? 'red' : 'inherit',
            }}
          >
            {descWidth}
          </span>{' '}
          / 960px)
        </div>
      )}
      <Input.TextArea
        style={{
          height: 140,
          resize: 'none',
        }}
        name="description"
        onChange={onChange}
        value={seo?.description || ''}
        disabled={!seoEditable}
        placeholder="Description"
      />
      {withImage && (
        <SeoImage
          image={seo.image}
          onChange={onChangeImage}
          onDelete={onDeleteImage}
        />
      )}
    </div>
  );
};
