import React from 'react';
import { Spin } from 'antd';

export const UILoader = () => {
  return (
    <div
      className="pending justify-center align-center"
      style={{
        display: 'flex',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
    >
      <Spin />
    </div>
  );
};
