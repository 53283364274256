import React, { FC, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Item } from 'graphql/types';
import { UIIcon } from 'nativeComponents/UI/UIIcons/UIIcon';
import { useWorkerSubscribe } from 'hooks/useWorker';
import { DRAG_ENTER_EVENT, useSortV4 } from './SortV4Provider';

import s from './sort.module.css';

type Props = {
  item: Item;
  index: number;
  column: number;
  categoryIndex: number;
};

export const SortV4Item: FC<Props> = ({
  item,
  index,
  column,
  categoryIndex,
}) => {
  const dragState = useRef({
    index,
    column,
    categoryIndex,
    dragOn: false,
  });
  const { drag, dragging, handleDragEnter, handleDragStart, updateItemsSort } =
    useSortV4();
  const [itemIndex, setItemIndex] = useState(index + 1);
  const [state, setState] = useState({
    dragOn: false,
  });

  const changeDragOn = (value: boolean) => {
    dragState.current = { ...dragState.current, dragOn: value };
    setState(prev => ({ ...prev, dragOn: value }));
  };

  useWorkerSubscribe(DRAG_ENTER_EVENT, ({ drag }) => {
    if (drag.type === 'end_drag') {
      if (dragState.current.dragOn) {
        changeDragOn(false);
      }
      return;
    }

    if (
      drag?.type !== 'item' ||
      drag?.index !== dragState.current.index ||
      drag?.column !== dragState.current.column
    ) {
      if (dragState.current.dragOn) {
        changeDragOn(false);
      }
    } else if (!dragState.current.dragOn) {
      changeDragOn(true);
    }
  });

  const dropState = () => {
    setItemIndex(index + 1);
  };

  const handleConfirmChangeItemIndex = () => {
    if (!itemIndex) {
      dropState();
      return;
    }
    updateItemsSort(column, categoryIndex, index, itemIndex.toString());
  };

  const handleKeyPressIndex = e => {
    if (e.keyCode === 27) {
      dropState();
      return;
    }
    if (e.charCode === 13) {
      handleConfirmChangeItemIndex();
      e.target.blur();
    }
  };

  const handleChangeIndex = ({ target: { value } }) => {
    if (isNaN(value)) return;
    setItemIndex(Number(value));
  };

  useEffect(() => {
    dragState.current = { ...dragState.current, index, column };
  }, [index, column]);

  useEffect(() => {
    setItemIndex(index + 1);
  }, [index]);

  return (
    <div
      draggable
      onDragStart={e =>
        handleDragStart(e, index, column, 'item', categoryIndex)
      }
      onDragEnter={
        dragging && drag?.type === 'item'
          ? e => handleDragEnter(e, index, column, 'item', categoryIndex)
          : undefined
      }
      className={classnames({
        [s.item]: true,
        [s.dragOn]: state.dragOn,
        [s.disabled]: !item.active
      })}
    >
      <input
        onFocus={e => {
          e.target.setSelectionRange(0, e.target.value.length);
        }}
        onKeyPress={handleKeyPressIndex}
        onChange={handleChangeIndex}
        onBlur={handleConfirmChangeItemIndex}
        value={itemIndex}
      />
      <span className={s.itemTitle}>{item.title}</span>

      <Link to={`/item/edit?id=${item.id}`} className="ml-1">
        <UIIcon icon="link" />
      </Link>
    </div>
  );
};
