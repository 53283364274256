/*eslint-disable*/
import React, { useEffect } from 'react';
import { getDottedDate } from 'utils/date';
import { LoadingOutlined } from '@ant-design/icons';
import { useDashboardGlanceLazyQuery } from 'graphql/types';
import { ItemGlance } from './ItemGlance';
import { fields, fieldTitle } from '../GPlot';

import './glance-style.css';

export const Glance = () => {
  const [fetchYesterday, { data: yesterdayData, loading: yesterdayLoading }] =
    useDashboardGlanceLazyQuery({});
  const [fetchPast, { data: pastData, loading: pastLoading }] =
    useDashboardGlanceLazyQuery({});

  useEffect(() => {
    fetchYesterday({
      variables: {
        date: getDottedDate(new Date().setDate(new Date().getDate() - 1)),
      },
    });
    fetchPast({
      variables: {
        date: getDottedDate(new Date().setDate(new Date().getDate() - 30)),
      },
    });
  }, []);

  return (
    <div className={'glace'}>
      {pastLoading || yesterdayLoading || !yesterdayData || !pastData ? (
        <div
          style={{
            width: '100%',
            height: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      ) : (
        <>
          {fields.map(f => (
            <ItemGlance
              key={f}
              yesterday={yesterdayData.glance[f] || 0}
              title={fieldTitle(f)}
              past={pastData.glance[f] || 0}
            />
          ))}
        </>
      )}
    </div>
  );
};
