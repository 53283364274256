import React from 'react';
import { Typography, Input } from 'antd';

type Props = {
  twitter?: string;
  facebook?: string;
  youtube?: string;
  onChange: (e: any) => void;
};

export const ItemSocial = ({ twitter, facebook, youtube, onChange }: Props) => {
  return (
    <div className="mb-4">
      <Typography.Title level={5}>Social</Typography.Title>
      <Input
        placeholder="Twitter"
        value={twitter}
        onChange={onChange}
        name="twitter"
        className="mb-2"
      />
      <Input
        placeholder="Facebook"
        value={facebook}
        onChange={onChange}
        name="facebook"
        className="mb-2"
      />
      <Input
        placeholder="Youtube"
        value={youtube}
        onChange={onChange}
        name="youtube"
      />
    </div>
  );
};
