import React, { FC } from 'react';
import { Category } from 'graphql/types';
import { SortV4Category } from './SortV4Category';
import { useSortV4 } from './SortV4Provider';

import s from './sort.module.css';

type Props = {
  column: Array<Category>;
  columnIndex: number;
};

export const SortV4CategoriesColumn: FC<Props> = ({ column, columnIndex }) => {
  const { data, handleDragEnter, drag, dragging } = useSortV4();

  const width = (): string => {
    if (data.length === 0) return '100%';
    return `calc(${100 / data.length}%)`;
  };

  const onDragEnter = e => {
    if (column.length === 0 && dragging && drag?.type === 'category') {
      return handleDragEnter(e, 0, columnIndex, 'category');
    }
  };

  return (
    <div
      onDragEnter={onDragEnter}
      style={{ width: width() }}
      className={s.column}
    >
      {column.map((category, idx) => (
        <SortV4Category
          column={columnIndex}
          index={idx}
          key={category.id}
          category={category}
        />
      ))}
    </div>
  );
};
