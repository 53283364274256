import React, { useState } from 'react';
import { SubmitGroup } from 'components/Common/SubmitGroup/SubmitGroup';
import { filterTypeName } from 'utils/apollo';
import { Card, Input, Typography } from 'antd';

import './styles.css';
import { UIUpload } from '../../../nativeComponents/UI';
import { getIconValue } from '../../../utils/getData';
import s from '../../Item/styles.module.scss';
import { SeoBox } from '../../Common/Seo/Seo';
import { LinksBlock } from '../../Common/LinksBlock/LinksBlock';
import { SOCIAL } from '../../../type/edit';
import { niceTitle } from '../../../utils/string';

export const EditCompany = ({ onSubmit, modelFields, ...props }) => {
  const getState = () => {
    const {
      icon,
      link = {
        url: '',
        alternativeUrl: '',
        forceNoFollow: true,
        forceNoIndex: false,
        forceNoOpenner: true,
        targetBlank: true,
      },
      seo = {},
      social = {},
      slug = '',
      title = '',
      items = [],
    } = props;
    return {
      title: title || '',
      link: filterTypeName(link),
      seo: filterTypeName(seo),
      social: filterTypeName(social),
      slug: slug || '',
      items: items || [],
      icon: icon || [],
    };
  };
  const [state, setState] = useState(() => getState());

  const onChangeFile = (name: string, image: any) => {
    setState(prev => ({ ...prev, [name]: image }));
  };

  const onDelete = (name: string, index: number) => {
    setState(prev => ({
      ...prev,
      [name]: [],
    }));
  };

  const onInputChange =
    (type: string) =>
    ({ target: { name, value, checked } }: any) => {
      setState(prev => ({
        ...prev,
        [type]:
          typeof prev[type] !== 'string'
            ? {
                ...prev[type],
                [name]: typeof value === 'string' ? value : checked,
              }
            : value,
      }));
    };

  const onChangeSocial = ({ target: { value, name } }) => {
    setState(prev => ({
      ...prev,
      social: {
        ...prev.social,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (redirect?: boolean) => {
    onSubmit({
      ...state,
      redirect,
    });
  };

  return (
    <Card
      bodyStyle={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
    >
      <div className="fluid flex" style={{ paddingBottom: 150 }}>
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Title</Typography.Title>
            <Input
              value={state.title}
              onChange={onInputChange('title')}
              name="title"
            />
          </div>
          {modelFields.icon && (
            <div className="editTitle">
              <Typography.Title level={5}>Icon</Typography.Title>
              <UIUpload
                previews={getIconValue(state.icon)}
                onChange={onChangeFile}
                onDelete={onDelete}
                name="icon"
                type="icon"
              />
            </div>
          )}
          {modelFields.seo && (
            <div className={`editTitle ${s.seoBlock}`}>
              <SeoBox seo={state.seo} onChange={onInputChange('seo')} />
            </div>
          )}
        </div>
        <div className="editSideContent" style={{ paddingTop: 32 }}>
          {modelFields.link && (
            <div className="sideContentItem fluid mb-3">
              <LinksBlock
                name="link"
                link={state.link as any}
                onChange={onInputChange}
              />
            </div>
          )}
          {modelFields.social && (
            <div className="sideContentItem fluid">
              <Typography.Title level={5}>Social links</Typography.Title>
              {Object.values(SOCIAL).map((key, i, arr) => (
                <Input
                  placeholder={niceTitle(key)}
                  value={state.social[key]}
                  onChange={onChangeSocial}
                  name={key}
                  key={key}
                  className={i !== arr.length - 1 ? 'mb-2' : ''}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <SubmitGroup onSubmit={handleSubmit} />
    </Card>
  );
};
