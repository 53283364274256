import React, {useMemo, useState} from 'react';
import {Space, Typography, Checkbox, Button} from 'antd';
import {Category, useCategoryFeaturesQuery, useRegenerateLikesMutation} from 'graphql/types';
import CategoryFeature from './CategoryFeature/CategoryFeature';

export const Features = () => {
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);

    const {data} = useCategoryFeaturesQuery({
        fetchPolicy: 'no-cache',
        onCompleted: () => {
        },
        onError: e => {
            console.log(e);
        },
    });
    const [generateLikes, {loading}] = useRegenerateLikesMutation();


    const submitGenerateLikes = async () => {
        try {
            const {data: statusGenerate} = await generateLikes({
                variables: {
                    ids: checkedCategories
                }
            })
            if(statusGenerate) {
                setCheckedCategories([]);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const features = useMemo(() => {
        if (!data) return null;
        const array = data?.categories?.data;
        const size = Math.ceil(array.length / 4);
        let subarray = [] as any;
        for (let i = 0; i < Math.ceil(array.length / size); i++) {
            subarray[i] = array.slice((i * size), (i * size) + size);
        }
        return subarray;
    }, [data?.categories?.data]);

    const handlerCheckedCategories = (id: string) => {
        if(checkedCategories.includes(id)) {
            return setCheckedCategories(prev => prev.filter(i => i !== id))
        }
        setCheckedCategories(prev => ([...prev, id]))
    }

    return <Space direction={'vertical'} style={{width: "100%"}}>
        <Typography.Title level={2}>Features</Typography.Title>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {features?.map((item, index) =>
            <Space key={index} direction={'vertical'}>
                {item?.map(category => <Space key={category.id}>
                    <Checkbox onChange={() => handlerCheckedCategories(category.id)} checked={checkedCategories.includes(category.id)} disabled={loading} />
                    <CategoryFeature category={category as Category} />
                </Space>)}
            </Space>)}
        </div>
        <Button
            style={{marginTop: '40px'}}
            type={'primary'}
            disabled={!checkedCategories.length || loading}
            onClick={submitGenerateLikes}
        >Regenerate likes</Button>
    </Space>;
};
