import { useRef, useEffect, useCallback } from 'react';

const useDidReceiveProps = (
  callback: () => void,
  dependency: (string | number)[] | string | number | any
) => {
  const arrDependency = Array.isArray(dependency) ? dependency : [dependency];
  const dependencyRef = useRef(arrDependency);

  const memoizedCallback = useCallback(() => callback(), arrDependency);

  useEffect(() => {
    const isChanged = arrDependency.find(
      dep => !dependencyRef.current.includes(dep)
    );
    if (isChanged === undefined) return;

    dependencyRef.current = arrDependency;
    memoizedCallback();
  }, arrDependency);
};

export default useDidReceiveProps;
