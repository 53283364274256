import React, { useEffect, useRef, useState } from 'react';
import _flatten from 'lodash/flatten';
import _uniqBy from 'lodash/uniqBy';
import { Button, message } from 'antd';
import {
  Category,
  Item,
  useGetCategoriesListLazyQuery,
  useUpdateFaviconsMutation,
} from 'graphql/types';
import { UILoader } from 'nativeComponents/UI/UILoader/UILoader';
import { HardConfirmAction } from 'components/Common/HardConfirmAction/HardConfirmAction';
import { IconGenerateItem } from './IconGenerateItem';
import { LogItem, LogList } from './LogList';

export const IconsGenerate = () => {
  const logRef = useRef<LogItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [logs, updateLog] = useState<LogItem[]>([]);
  const [list, setList] = useState<Category[]>([]);
  const [checked, setChecked] = useState<string[]>([]);

  const isCheckAll = checked.length === list.length;

  const [updateFavicons] = useUpdateFaviconsMutation();
  const [fetchCategories, { loading: fetchLoading }] =
    useGetCategoriesListLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ categories }) => {
        const { data } = categories;
        setList((data || []) as any);
      },
      onError: e => {
        message.error(e.message);
      },
    });

  const handleCheck = (id: string) => {
    const isExistInList = checked.includes(id);
    setChecked(
      isExistInList ? checked.filter(item => item !== id) : [...checked, id]
    );
  };

  const toggleCheckAll = () => {
    if (isCheckAll) {
      setChecked([]);
    } else {
      setChecked(list.map(({ id }) => id));
    }
  };

  const handleGenerateError = (e, items) => {
    if (!e || !e.length) return;
    const errors = e
      .map(ms => {
        const match = ms.match(/item ID=(\d+)/);
        if (!match || !match[1]) return null;
        return {
          title: (items.find(({ id }) => id == match[1]) || {}).title,
          message: ms,
        };
      })
      .filter(item => !!item);
    updateLog(errors);
  };

  const handleGenerate = async () => {
    const items = _flatten(
      list
        .filter(({ id, items }) => checked.includes(id) && items)
        .map(({ items }) => items)
    ).filter(({ link }: any) => link && (link.url || link.alternativeUrl));
    updateLog([]);
    logRef.current = [];

    const uniqItems = _uniqBy(items, 'id');
    try {
      setLoading(true);
      const {
        data: { updateFavicons: updateErrors },
      } = (await updateFavicons({
        variables: {
          ids: uniqItems.map(({ id }) => id),
        },
      })) as any;
      handleGenerateError(updateErrors, uniqItems);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories({
      variables: {
        limit: 1000,
      },
    });
  }, []);

  const currentLoading = loading || fetchLoading;
  return (
    <div className="pa-8 fluid">
      {currentLoading && <UILoader />}
      <h1>Icons generate</h1>
      <LogList logs={logs} />
      <Button onClick={toggleCheckAll} type="primary" className="mb-2">{`${
        isCheckAll ? 'Uncheck' : 'Check all'
      }`}</Button>
      <div className="flex flex-wrap">
        {list.map(({ id, title, items }) => (
          <IconGenerateItem
            key={id}
            checked={checked.includes(id)}
            title={title}
            id={id}
            disabled={!items?.length}
            onChange={handleCheck}
          />
        ))}
      </div>

      <HardConfirmAction
        typeText="Generate favicons"
        onConfirm={handleGenerate}
      >
        <Button type="primary" className="mt-8">
          Generate favicons
        </Button>
      </HardConfirmAction>
    </div>
  );
};
