import * as resources from '../config/resourses';

export const menu = Object.values(resources)
  .filter(item => !(item as any).menuHidden)
  .map(({ name, menuIcon, ...rest }: any) => ({
    name,
    href: `/${name}`,
    MenuIcon: menuIcon,
    ...rest,
  }));
