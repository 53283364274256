import { Checkbox } from 'antd';
import { toggleValue } from 'utils/handlers';
import { useCSV } from '../../Settings/CSV/CSVProvider/CSVProvider';

type Props = {
  disabled?: boolean;
  className?: string;
};

export const CSVMakeActive = ({ disabled, className }: Props) => {
  const { makeActive, updateState } = useCSV();

  return (
    <Checkbox
      checked={makeActive}
      onClick={toggleValue(updateState, 'makeActive')}
      disabled={disabled}
      className={className}
    >
      Make active
    </Checkbox>
  );
};
