export const getGroupItems = (menu: { parent?: string; [p: string]: any }) => {
  const usedParents: string[] = [];
  const groupItems = menu.filter(({ parent }) => !!parent);

  return menu.reduce((acc, item) => {
    if (!item.parent) {
      return [...acc, item];
    }
    if (usedParents.includes(item.parent)) return acc;

    const subItems = groupItems.filter(
      gi =>
        gi.parent === item.parent &&
        !usedParents.includes(item.parent as string)
    );
    usedParents.push(item.parent);
    return [
      ...acc,
      {
        ...item,
        subItems,
      },
    ];
  }, []);
};

export const IGNORE_REDIRECT_PATHNAMES = [
  '/staff/recovery',
  '/oauth',
  '/oauth-callback',
  '/profile',
];
