import { WithWithout } from '../../../graphql/types';

export const filters = {
  filters: [
    {
      name: 'By status',
      items: [
        {
          name: 'Favicon',
          value: 'iconFilter',
          status: WithWithout.With,
        },
      ],
    },
  ],
  extendFilters: [
    {
      name: 'Favicon',
      value: 'iconFilter',
      variants: [
        {
          name: 'Available',
          value: WithWithout.Without,
        },
        {
          name: 'Not available',
          value: WithWithout.With,
        },
      ],
    },
  ],
};
