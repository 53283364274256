import React, { useState, useRef } from 'react';
import { List, Input, Button, Modal } from 'antd';
import {
    useGetLabelsQuery,
    useCreateLabelMutation,
    useUpdateLabelMutation,
    useDeleteLabelMutation
} from '../../graphql/types';
import styles from './LabelInfo.module.scss';

export const LabelInfo: React.FC = () => {
    const { data, loading, refetch } = useGetLabelsQuery();
    const [createLabel] = useCreateLabelMutation();
    const [updateLabel] = useUpdateLabelMutation();
    const [deleteLabel] = useDeleteLabelMutation();

    const [newLabelName, setNewLabelName] = useState<string>('');
    const [newLabelIcon, setNewLabelIcon] = useState<File | null>(null);
    const [selectedLabelIcon, setSelectedLabelIcon] = useState<string>('');

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentLabelId, setCurrentLabelId] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const overlayIcon = (iconImage: string) => {
        return iconImage.startsWith('data:image') ? iconImage : `data:image/*;base64,${iconImage}`;
    };

    const convertToBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const handleCreateOrUpdateLabel = async () => {
        let iconBase64 = selectedLabelIcon;
        if (newLabelIcon) {
            iconBase64 = await convertToBase64(newLabelIcon);
        }

        const input = { name: newLabelName, icon: iconBase64 || null };
        
        if (currentLabelId) {
            await updateLabel({ variables: { id: currentLabelId, input } });
        } else {
            await createLabel({ variables: { input } });
        }

        setIsModalVisible(false);
        setNewLabelIcon(null);
        setNewLabelName('');
        setSelectedLabelIcon('');
        setCurrentLabelId(null);
        refetch();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            const file = event.target.files[0];
            setNewLabelIcon(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result as string;
                setSelectedLabelIcon(base64);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteLabel = async (id: string) => {
        await deleteLabel({ variables: { id } });
        refetch();
    };

    const handleEditLabel = (id: string, name: string, icon?: string | null) => {
        setCurrentLabelId(id);
        setNewLabelName(name);
        setSelectedLabelIcon(icon ? overlayIcon(icon) : ''); // Set the existing icon or reset if null
        setIsModalVisible(true);
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className={styles.labelInfoContainer}>
            <Button type="primary" onClick={() => {
                setIsModalVisible(true);
                setCurrentLabelId(null);
                setNewLabelName('');
                setNewLabelIcon(null);
                setSelectedLabelIcon('');
            }} className={styles.createButton}>
                Create Label
            </Button>
            <List
                itemLayout="horizontal"
                dataSource={data?.labels}
                renderItem={(label) => (
                    <List.Item
                        actions={[
                            <Button key="edit" onClick={() => handleEditLabel(label.id, label.name, label.icon || undefined)}>Edit</Button>,
                            <Button key="delete" onClick={() => handleDeleteLabel(label.id)}>Delete</Button>
                        ]}
                    >
                        <div className={styles.labelNameIconContainer}>
                            {label.icon && (
                                <img
                                    src={overlayIcon(label.icon)}
                                    alt="label icon"
                                    className={styles.labelIcon}
                                />
                            )}
                            <div>{label.name}</div>
                        </div>
                    </List.Item>
                )}
            />
            <Modal
                title={currentLabelId ? 'Edit Label' : 'Create Label'}
                visible={isModalVisible}
                onOk={handleCreateOrUpdateLabel}
                onCancel={() => {
                    setIsModalVisible(false);
                    setCurrentLabelId(null);
                }}
                destroyOnClose
            >
                <Input value={newLabelName} onChange={e => setNewLabelName(e.target.value)} placeholder="Label name" />
                <input type="file" ref={fileInputRef} accept="image/*" className={styles.fileInput} onChange={handleFileChange} />
                <Button onClick={() => fileInputRef.current?.click()} className={styles.uploadButton}>Upload Icon</Button>
                {selectedLabelIcon && (
                    <img src={selectedLabelIcon} alt="icon preview" className={styles.iconPreview} />
                )}
            </Modal>
        </div>
    );
};

export default LabelInfo;