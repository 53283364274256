const AngleLeft = () => (
  <svg
    style={{
      width: '1.25em',
      height: '3.25em',
    }}
    viewBox="0 0 20 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2L2 26L18 50"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AngleLeft;
