import { Button, Modal } from 'antd';
import { useCSV } from '../Settings/CSV/CSVProvider/CSVProvider';

import s from './styles.module.scss';
import { CSVMakeActive } from './CSVMakeActive/CSVMakeActive';

type Props = {
  onClose: () => void;
  onSave: (withDelete?: boolean) => void;
  withArchiveItem?: boolean;
  withSavingItem?: boolean;
  itemsCount: number;
};

export const CSVUploaderArchiveModal = ({
  onClose,
  onSave,
  withArchiveItem,
  withSavingItem,
  itemsCount,
}: Props) => {
  const { loading } = useCSV();

  const handleSave = (withDelete?: boolean) => () => {
    onSave(withDelete);
  };

  return (
    <>
      <Modal title="Renew item" visible footer={null} closable={false}>
        <div className="flex justify-between">
          <Button className={s.takeNewButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={s.takeNewButton}
            type="primary"
            onClick={handleSave(true)}
            loading={loading}
            disabled={!withArchiveItem}
          >
            {`Archive rest items - ${itemsCount}`}
          </Button>
          <Button
            className={s.takeNewButton}
            type="primary"
            onClick={handleSave()}
            loading={loading}
            disabled={!withSavingItem}
          >
            Save items
          </Button>
        </div>
        <CSVMakeActive className="mt-2" />
      </Modal>
    </>
  );
};
