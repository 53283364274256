import { Input, Typography } from 'antd';
import React from 'react';

export const ItemEditShortDescription = ({ text, onChange }) => {
  return (
    <>
      <Typography.Title level={5}>Short description</Typography.Title>
      <Input
        placeholder="Type something..."
        value={text}
        onChange={onChange('shortDescription')}
        className="mb-2"
      />
    </>
  );
};
