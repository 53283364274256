import { string_to_slug } from 'utils/string';
import {
  CreateDynamicFieldMutation,
  CreateDynamicModelDocument,
  DynamicModel,
  CreateDynamicModelMutation,
  CreateDynamicVariantMutation,
  UpdateDynamicModelMutation,
  UpdateDynamicModelMutationVariables,
  UpdateDynamicModelDocument,
  DynamicFieldModel,
  UpdateDynamicFieldMutation,
  UpdateDynamicFieldMutationVariables,
  UpdateDynamicFieldDocument,
  DynamicFieldType,
  CreateDynamicFieldMutationVariables,
  CreateDynamicFieldDocument,
  DynamicVariant,
  UpdateDynamicVariantDocument,
  CreateDynamicVariantDocument,
  CreateDynamicVariantMutationVariables,
  DynamicModelType,
  Entity,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';

const apolloClient = initializeApollo();

export const getDynamicModelFromForm = (data, entity) => {
  const { title, types } = data;
  const [modelType, fieldType] = types.split('__');
  const modelSlug = string_to_slug(title);

  return {
    model: {
      title,
      name: modelSlug,
      type: modelType,
      entity,
    },
    fieldType,
  };
};

export const getDataForFormFromDynamicModel = (model: DynamicModel) => {
  return {
    ...model,
    variants:
      model?.variants?.map(({ id, value }) => ({
        id,
        value: JSON.parse(value)[model.name],
      })) || [],
    types: `${model.type}__${model.fields[0].type}`,
  };
};

export const saveDynamicModel = async (data: Partial<DynamicModel>) => {
  const { title, id, name, entity, type } = data;
  if (id) {
    const { data: { updateDynamicModel } = {} as any } =
      await apolloClient.mutate<
        UpdateDynamicModelMutation,
        UpdateDynamicModelMutationVariables
      >({
        mutation: UpdateDynamicModelDocument,
        variables: {
          id,
          type: type as DynamicModelType,
          entity: entity as Entity,
          title,
          name: name as string,
        },
      });
    return updateDynamicModel;
  }
  const { data: { createDynamicModel } = {} as any } =
    await apolloClient.mutate<CreateDynamicModelMutation>({
      mutation: CreateDynamicModelDocument,
      variables: {
        title,
        name,
        entity,
        type,
      },
    });

  return createDynamicModel as DynamicModel;
};

export const saveDynamicField = async (
  data: Partial<DynamicFieldModel> & { modelID?: string }
) => {
  const { id, name, type, modelID } = data;
  if (id) {
    const { data: { updateDynamicField } = {} as any } =
      await apolloClient.mutate<
        UpdateDynamicFieldMutation,
        UpdateDynamicFieldMutationVariables
      >({
        mutation: UpdateDynamicFieldDocument,
        variables: {
          id,
          name: name as string,
          type: type as DynamicFieldType,
          isArray: false,
          primaryField: true,
        },
      });
    return updateDynamicField;
  }
  const { data: { createDynamicField } = {} as any } =
    await apolloClient.mutate<
      CreateDynamicFieldMutation,
      CreateDynamicFieldMutationVariables
    >({
      mutation: CreateDynamicFieldDocument,
      variables: {
        modelID: modelID as string,
        name: name as string,
        type: type as DynamicFieldType,
        isArray: false,
        primaryField: true,
      },
    });

  return createDynamicField as DynamicFieldModel;
};

export const saveDynamicVariants = async (
  variants: (Partial<DynamicVariant> & { modelID?: string })[]
) => {
  const savedVariants = await Promise.all(
    variants.map(variant => {
      const { id, value, modelID } = variant;
      if (id) {
        return apolloClient.mutate({
          mutation: UpdateDynamicVariantDocument,
          variables: {
            id,
            value,
          },
        });
      }

      return apolloClient.mutate<
        CreateDynamicVariantMutation,
        CreateDynamicVariantMutationVariables
      >({
        mutation: CreateDynamicVariantDocument,
        variables: {
          modelID: modelID as string,
          value,
        },
      });
    })
  );

  return savedVariants.map(
    ({ data: { createDynamicVariant, updateDynamicVariant } }) =>
      updateDynamicVariant || createDynamicVariant
  );
};
