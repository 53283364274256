import { Checkbox } from 'antd';
import React from 'react';
import { useRouter } from '../../../../hooks/useRouter';

type Props = {
  label: string;
  filter: string;
};

export const BooleanFilter = ({ label, filter }: Props) => {
  const { query, push, constructUrl, pathname } = useRouter();

  const handleChange = ({ target: { checked } }) => {
    const url = constructUrl(pathname, {
      ...query,
      [filter]: checked,
      page: 1,
    });
    push(url);
  };

  return (
    <Checkbox onChange={handleChange} checked={!!query[filter]}>
      {label}
    </Checkbox>
  );
};
