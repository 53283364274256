import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Modal } from 'antd';
import { preventClick } from 'utils/handlers';
import { CompareCategory } from 'components/Settings/CSV/CSVProvider/types';
import { CSVConflictItem } from './CSVConflictItem';
import { CSVPlainItem } from './CSVPlainItem';
import { CVSCompareContent } from '../CVSCompareContent/CVSCompareContent';

import s from './styles.module.scss';

type Props = {
  item: CompareCategory;
};

export const CSVItem = ({ item }: Props) => {
  const [state, setState] = useState({
    compareOpened: false,
  });

  const invokeCompareModal = () => {
    setState(prev => ({ ...prev, compareOpened: true }));
  };

  const onClose = e => {
    preventClick(e);
    setState(prev => ({ ...prev, compareOpened: false }));
  };

  const isConflict = !_isEmpty(item.differentFields);
  const showModal = isConflict || !_isEmpty(item.errors);
  return (
    <div className={s.csvItem}>
      {!!item.sameItem && (
        <CSVConflictItem item={item} disabled={!_isEmpty(item.errors)} />
      )}
      {!item.sameItem && (
        <CSVPlainItem item={item} disabled={!_isEmpty(item.errors)} />
      )}

      {showModal && (
        <span
          className={`${s.notice} ${s.red}`}
          role="presentation"
          onClick={invokeCompareModal}
        >
          {!_isEmpty(item.errors) ? 'Error' : 'Conflict'}
        </span>
      )}
      <Modal
        wrapClassName={s.modal}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
        width="90%"
        visible={state.compareOpened}
        title={item.title}
        footer={null}
        onCancel={onClose}
      >
        <CVSCompareContent
          compareFields={{ ...item.differentFields }}
          errors={item.errors}
        />
      </Modal>
    </div>
  );
};
