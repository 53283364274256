import React, {useMemo, useState} from 'react';
import {Button, Divider, Input, List, Modal, Space, Typography} from 'antd';
import {
    ItemFaq,
    useAddItemFaqMutation,
    useDeleteItemFaqMutation,
    useUpdateItemFaqMutation,
    CategoryFaq,
    useAddCategoryFaqMutation,
    useDeleteCategoryFaqMutation,
    useUpdateCategoryFaqMutation,
} from 'graphql/types';
import {AnswerItem} from './AnswerItem';
import {DeleteOutlined, QuestionCircleOutlined, SaveOutlined} from '@ant-design/icons';
import {useRouter} from 'hooks/useRouter';
import TextArea from 'antd/es/input/TextArea';

type Props = {
    faq: CategoryFaq[] | ItemFaq[];
}

export const AskQuestions = ({faq}: Props) => {
    const [questions, setQuestions] = useState<any>(faq);
    const [activeFaq, setActiveFaq] = useState<any>(null);
    const {query, location} = useRouter();
    const [saveFaqCategory, {loading: saveLoading}] = useAddCategoryFaqMutation();
    const [deleteFaqCategory, {loading: deleteLoading}] = useDeleteCategoryFaqMutation();
    const [updateFaqCategory, {loading: updateLoading}] = useUpdateCategoryFaqMutation();
    const [saveFaqItem, {loading: saveItemLoading}] = useAddItemFaqMutation();
    const [deleteFaqItem, {loading: deleteItemLoading}] = useDeleteItemFaqMutation();
    const [updateFaqItem, {loading: updateItemLoading}] = useUpdateItemFaqMutation();

    const isItemPage = useMemo(() => {
        return location.pathname.includes('item')
    }, [location.pathname])

    const addFaq = async () => {
        try {
            const {data} = await (isItemPage ? saveFaqItem : saveFaqCategory)({
                variables: {
                    input: {
                        ...activeFaq
                    }
                }
            });
            // @ts-ignore
            if (data?.addItemFAQ  || data?.addCategoryFAQ) {
                // @ts-ignore
                setQuestions(prev => ([...prev, isItemPage ? data.addItemFAQ : data.addCategoryFAQ]));
                setActiveFaq(null);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const removeFaq = async () => {
        try {
            const {data} = await (isItemPage ? deleteFaqItem : deleteFaqCategory)({
                variables: {
                    id: activeFaq.id
                }
            });

            // @ts-ignore
            if (data?.deleteItemFAQ || data?.deleteCategoryFAQ) {
                // @ts-ignore
                setQuestions(prev => prev.filter(({id}) => isItemPage ? id !== data.deleteItemFAQ : id !== data.deleteCategoryFAQ));
                setActiveFaq(null);
            }

        } catch (e) {
            console.log(e);
        }
    };

    const createFaq = () => {
        setActiveFaq({
            question: '',
            answer: '',
            [isItemPage ? 'ItemID' : 'categoryID']: query?.id
        });
    };

    const changeItemFaq = async () => {
        try {
            const {data} = await (isItemPage ? updateFaqItem : updateFaqCategory)({
                variables: {
                    id: activeFaq.id,
                    input: {
                        question: activeFaq.question,
                        answer: activeFaq.answer,
                        [isItemPage ? 'ItemID' : 'categoryID']: query?.id
                    } as any
                }
            });
            // @ts-ignore
            if(data?.updateItemFAQ || data?.updateCategoryFAQ) {
                setQuestions(prev => prev.map(item => {
                    // @ts-ignore
                    if(item.id === data.updateItemFAQ?.id || item.id === data.updateCategoryFAQ?.id) {
                        // @ts-ignore
                        return isItemPage ? { ...data.updateItemFAQ} : {...data.updateCategoryFAQ}
                    }
                    return item
                }))
                setActiveFaq(null);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleInputs = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setActiveFaq(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    return <div>
        <Divider/>
        <Space style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={5}>FAQ</Typography.Title>
            <Button type={'default'} onClick={createFaq}>
                Add question
                <QuestionCircleOutlined/>
            </Button>
        </Space>
        <List itemLayout={'horizontal'} dataSource={questions} renderItem={(item, index) => (
            <AnswerItem faqItem={item as CategoryFaq} handleFaqItem={setActiveFaq} index={index + 1} key={index} />
        )}/>
        <Modal
            open={!!activeFaq}
            footer={[
                <Button
                    type="primary"
                    loading={saveLoading || updateLoading || saveItemLoading || updateItemLoading}
                    onClick={activeFaq?.id ? changeItemFaq : addFaq}
                >
                    Save
                    <SaveOutlined/>
                </Button>,
                <Button
                    type="primary"
                    loading={deleteLoading || deleteItemLoading}
                    onClick={removeFaq}
                >
                    Delete
                    <DeleteOutlined/>
                </Button>,
            ]}
            onCancel={() => setActiveFaq(null)}
        >
            <Space direction={'vertical'} style={{width: '100%'}}>
                <Input value={activeFaq?.question} onChange={handleInputs} name={'question'}
                       placeholder={'Question'} className={'mt-4'}/>
                <TextArea rows={4} value={activeFaq?.answer} onChange={handleInputs} name={'answer'}
                          placeholder={'Answer'}/>
            </Space>
        </Modal>
    </div>;
};
