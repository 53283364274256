import { CompareCategory } from 'components/Settings/CSV/CSVProvider/types';

export const constructNewItem = (item: CompareCategory) => {
  const dynamic = {};

  (item.dynamic || []).forEach((md: any) => {
    dynamic[md.model.name] = md;
  });

  return {
    ...item.sameItem,
    ...item,
    dynamic: Object.values(dynamic),
  };
};
