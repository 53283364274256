import React, { useState } from 'react';
import {
  Item,
  ItemInput,
  useGetItemsLazyQuery,
  useGetItemsSuperLightLazyQuery,
  useIconCreateMutation,
  useUpdateItemMutation,
} from 'graphql/types';
import { message, Spin, Button } from 'antd';
import { getUpdateItemPayload } from '../Item/helpers';

const ICON_SIZES = [42];

export const SecretStaff = () => {
  const [loading, setLoading] = useState(false);
  const [fetchIcon] = useIconCreateMutation();
  const [updateItem] = useUpdateItemMutation();

  const [getItems, { loading: itemsLoading }] = useGetItemsSuperLightLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ items: { data } }) => {},
    onError: e => {
      console.log(e);
    },
  });

  const handleItems = () => {
    getItems({
      variables: {
        limit: 1000,
      },
    });
  };

  const getIcon = async (url: string) => {
    const {
      // @ts-ignore
      data: { iconCreate },
    } = await fetchIcon({
      variables: {
        url,
        sizes: ICON_SIZES,
      },
    });
    return iconCreate;
  };

  const handleGettingIcon = async (item: Item) => {
    const { link: { url, alternativeUrl } = {} as any } = item || {};
    const { id, ...updatePayload } = getUpdateItemPayload(item);
    const isHaveAnyUrl = !!url || !!alternativeUrl;
    if (!isHaveAnyUrl || !id) {
      message.error('There is no such URL have been set');
      return;
    }
    try {
      const icon = await getIcon(url || alternativeUrl);
      await updateItem({
        variables: {
          id,
          input: {
            ...(updatePayload as ItemInput),
            icon,
          },
        },
      });
    } catch (e) {
      if (alternativeUrl) {
        const icon = await getIcon(alternativeUrl);
        await updateItem({
          variables: {
            id,
            input: {
              ...(updatePayload as ItemInput),
              icon,
            },
          },
        });
      }
    }
  };

  const [fetchItems] = useGetItemsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      page: 1,
      limit: 1000,
    },
    onCompleted: async ({ items }) => {
      setLoading(true);
      if (!items || !items.data) {
        message.error('Some error happen');
        return;
      }
      await Promise.all(
        items.data
          .filter(
            // @ts-ignore
            ({ link: { url, alternativeUrl } = {} }) => url || alternativeUrl
          )
          .map(item => {
            return handleGettingIcon(item as Item);
          })
      );

      setLoading(false);
    },
    onError: e => {
      console.log(e);
    },
  });

  const handleGrab = async () => {
    setLoading(true);
    await fetchItems();

    setLoading(false);
  };
  /*eslint-disable*/
  return (
    <div>
      {loading && (
        <div
          className="pending"
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
          }}
        >
          <Spin />
        </div>
      )}
      <div className="flex fluid pa-4">
        <Button onClick={handleItems} disabled={itemsLoading} type="primary">
          GET ITEMS
        </Button>
      </div>
      <div className="flex fluid pa-4">
        <Button disabled={itemsLoading} type="primary">
          GET HTMLS
        </Button>
      </div>
      {/*<div className="flex fluid pa-4">*/}
      {/*  <Button onClick={handleGrab} disabled={loading} type="primary">*/}
      {/*    GRAB ICONS*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
};
