import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, TableColumnType, Popconfirm } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useResource } from 'hooks/useResource';

import s from './styles.module.css';

export const ActionColumn = ({ record, onDelete, onChangeActive }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    resource,
    resources: {
      list: { actions = [] },
    },
  } = useResource();

  const handleDelete = () => {
    onDelete(record.id);
  };

  const handleActive = (isActive: boolean) => async () => {
    await onChangeActive(record.id, isActive);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={`/${resource}/edit?id=${record.id}`}>
          <span>Edit</span>
        </Link>
      </Menu.Item>
      {actions.includes('active') && (
        <Menu.Item onClick={handleActive(!record.active)}>
          {record.active ? 'Set inactive' : 'Set active'}
        </Menu.Item>
      )}
      {actions.includes('delete') && (
        <Menu.Item>
          <Popconfirm onConfirm={handleDelete} title="Delete?">
            Delete
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <div className={s.actionColumn} role="presentation" ref={dropdownRef}>
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );
};

const ActionsForDeletedItem = ({ record, onRestore }) => {
  const handleRestore = () => {
    onRestore(record);
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <Popconfirm onConfirm={handleRestore} title="Restore?">
              Restore
            </Popconfirm>
          </Menu.Item>
        </Menu>
      }
      placement="bottomCenter"
    >
      <div className={s.actionColumn} role="presentation">
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );
};

export const getActionColumn = ({
  onChangeActive,
  onDelete,
  onRestore,
  isDeleted = '',
}): TableColumnType<string> => ({
  title: 'Action',
  dataIndex: 'action',
  align: 'left',
  width: 150,
  render: (_, record) => {
    if (isDeleted) {
      return <ActionsForDeletedItem record={record} onRestore={onRestore} />;
    }
    return (
      <ActionColumn
        record={record}
        onChangeActive={onChangeActive}
        onDelete={onDelete}
      />
    );
  },
});
