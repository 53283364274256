import React, { DragEvent as DE, useEffect, useRef, useState } from 'react';
import { ProsConsList } from './ProsConsList';

import s from './styles.module.css';

interface Props {
  content?: { pros?: string[]; cons?: string[] };
  changeContent: (p: { pros?: string[]; cons?: string[] }) => void;
}

export const ProsAndConsContainer = ({ content, changeContent }: Props) => {
  const documentDragPreventDefault = (e: DragEvent) => {
    e.preventDefault();
  };

  const drag = useRef<{ colIdx: number; itemIdx: number } | null>(null);
  const dragNode = useRef<any>(null);
  const [dragging, setDragging] = useState<boolean>(false);

  const handleDragEnd = (e: DE<HTMLDivElement>) => {
    dragNode.current?.removeEventListener('dragend', handleDragEnd);
    drag.current = null;
    dragNode.current = null;
    setDragging(false);
  };

  const handleDragStart = (
    e: DE<HTMLDivElement>,
    params: { colIdx; itemIdx }
  ) => {
    drag.current = params;
    dragNode.current = e.target;
    dragNode.current.addEventListener('dragend', handleDragEnd);
    setTimeout(() => {
      setDragging(true);
    }, 0);
  };

  const handleDragEnter = (
    e: DE<HTMLDivElement>,
    params: { colIdx; itemIdx }
  ) => {
    if (e.target === dragNode.current) return;
    const newList: Array<Array<string>> = JSON.parse(
      JSON.stringify([content?.pros, content?.cons])
    );
    newList[params.colIdx].splice(
      params.itemIdx,
      0,
      newList[drag.current!.colIdx].splice(drag.current!.itemIdx, 1)[0]
    );
    drag.current = params;
    changeContent({ pros: newList[0], cons: newList[1] });
  };

  useEffect(() => {
    document.addEventListener('dragover', documentDragPreventDefault);
    return () => {
      document.removeEventListener('dragover', documentDragPreventDefault);
    };
  }, [content]);

  return (
    <div className={s.prosCons}>
      <div>
        <ProsConsList
          drag={drag}
          dragging={dragging}
          colIdx={0}
          handleDragStart={handleDragStart}
          handleDragEnter={handleDragEnter}
          title="Pros"
          list={content?.pros || []}
          // list={data[0]}
          changeList={pros => {
            changeContent({ ...content, pros });
          }}
        />
      </div>
      <div>
        <ProsConsList
          drag={drag}
          dragging={dragging}
          colIdx={1}
          handleDragStart={handleDragStart}
          handleDragEnter={handleDragEnter}
          title="Cons"
          list={content?.cons || []}
          changeList={cons => {
            changeContent({ ...content, cons });
          }}
        />
      </div>
    </div>
  );
};
