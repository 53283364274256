import React from 'react';
import { Link } from 'react-router-dom';
import { Switch, Tag } from 'antd';
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { ItemEdit } from 'components/Item/ItemEdit';
import { useRouter } from 'hooks/useRouter';
import { TitleWithImage } from 'nativeComponents/List/ListRowTitle/TitleWithImage';
import { Item, Status, SubmittedTool } from 'graphql/types';
import { filters } from './filters';
import { sort } from './sort';

const itemResource = {
  name: 'item',
  menuIcon: UnorderedListOutlined,
  pageTitle: 'Items',
  editComponent: ItemEdit,
  ...filters,
  sort,
  list: {
    actions: ['active', 'delete'],
    columns: [
      {
        title: 'Title',
        key: 'title',
        sort: true,
        filter: true,
        asLink: true,
        render: (record, data) => {
          return <TitleWithImage data={data} record={record} />;
        },
      },
      {
        title: 'Main Category',
        key: 'mainCategory',
        render: (record, data) => {
          if (!data.mainCategory || !data.mainCategory.title)
            return <div></div>;
          return <div>{data.mainCategory.title}</div>;
        },
      },
      {
        title: 'Categories',
        key: 'categories',
        align: 'center',
        render: record => {
          const { push } = useRouter();
          if (!record || record.length === 0) return <div></div>;
          return (
            <div>
              {record.slice(0, 3).map(({ title, id }) => (
                <Link
                  key={title}
                  className="mr-1"
                  to={`/category/edit?id=${id}`}
                  onClick={() => push(`/category/edit?id=${id}`)}
                >
                  <Tag>{title}</Tag>
                </Link>
              ))}
              <span>{record.length > 3 ? '...' : ''}</span>
            </div>
          );
        },
      },
      {
        title: 'Approve Status',
        key: 'approved',
        align: 'center',
        render: (record, data: Item & SubmittedTool) => {
          return (
            <div>
              {data.status === Status.Approved && (
                <CheckCircleOutlined
                  style={{ fontSize: '24px', color: 'green' }}
                />
              )}
              {data.status === Status.Pending && (
                <ClockCircleOutlined
                  style={{ fontSize: '24px', color: 'orange' }}
                />
              )}
              {data.status === Status.Rejected && (
                <CloseCircleOutlined
                  style={{ fontSize: '24px', color: 'red' }}
                />
              )}
            </div>
          );
        },
      },
      {
        title: 'Company',
        key: 'company',
        align: 'center',
        render: record => {
          return (record || {}).title;
        },
      },
      {
        title: 'Platform',
        key: 'platform',
        align: 'center',
        render: record => {
          return (
            <div>
              {(record || []).map(item => (
                <Tag key={item} className="mr-1">
                  {item}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: (record, data, { onChangeActive, query }) => {
          if (query.deleted) return null;
          return (
            <Switch
              checked={data.active}
              onChange={checked => {
                onChangeActive(data.id, checked);
              }}
            />
          );
        },
      },
      {
        title: 'Email',
        key: 'email',
        render: (record, data) => {
          if (!data.username) return null;
          return (
            <div style={{ fontSize: 14 }}>
              <span>{data.username}</span>
            </div>
          );
        },
      },
      {
        title: 'Created',
        key: 'createdAt',
        render: (record, data) => {
          if (!data.createdAt) return null;
          return (
            <div style={{ fontSize: 14 }}>
              <span>{new Date(data.createdAt).toLocaleDateString()}</span>
              <br />
              <span>{new Date(data.createdAt).toLocaleTimeString()}</span>
            </div>
          );
        },
      },
    ],
    pagination: {},
  },
};

export default itemResource;
