import { Button, Card, Modal } from 'antd';
import { partialFetchWorker } from 'utils/handlers';
import { CSVProgressUpload } from './CSVProgressUpload';

import s from 'components/CSVUploader/styles.module.scss';

type Props = {
  onClose: () => void;
  successUploaded: number;
  failUploaded: { entity: any; msg: string }[];
  loading?: boolean;
  total: number;
  label?: string;
};

export const CSVSaveModal = ({
  onClose,
  successUploaded,
  failUploaded,
  total,
  loading,
  label,
}: Props) => {
  const handleClose = () => {
    if (loading) {
      partialFetchWorker.postMessage({ type: 'abort_upload' });
    } else {
      onClose();
    }
  };

  return (
    <>
      {!!failUploaded.length && (
        <Card title="Update errors log" className={s.errorsLog}>
          {failUploaded.map(({ entity, msg }) => {
            return (
              <div key={entity.title} className={s.errorsLogItem}>
                <span>{entity.title}</span>
                <span>{msg}</span>
              </div>
            );
          })}
        </Card>
      )}
      <Modal title={label} visible footer={null} closable={false}>
        <CSVProgressUpload
          total={total}
          failUploaded={failUploaded}
          successUploaded={successUploaded}
          label={label}
        />
        <div className="flex justify-between">
          <Button className={s.takeNewButton} onClick={handleClose}>
            {loading ? 'Cancel uploading' : 'Close'}
          </Button>
        </div>
      </Modal>
    </>
  );
};
