const Download = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.90625 14.5C1.1466 14.5 1.37711 14.5955 1.54707 14.7654C1.71702 14.9354 1.8125 15.1659 1.8125 15.4062V21.75C1.8125 22.2307 2.00346 22.6917 2.34337 23.0316C2.68328 23.3715 3.14429 23.5625 3.625 23.5625H25.375C25.8557 23.5625 26.3167 23.3715 26.6566 23.0316C26.9965 22.6917 27.1875 22.2307 27.1875 21.75V15.4062C27.1875 15.1659 27.283 14.9354 27.4529 14.7654C27.6229 14.5955 27.8534 14.5 28.0938 14.5C28.3341 14.5 28.5646 14.5955 28.7346 14.7654C28.9045 14.9354 29 15.1659 29 15.4062V21.75C29 22.7114 28.6181 23.6334 27.9383 24.3133C27.2584 24.9931 26.3364 25.375 25.375 25.375H3.625C2.66359 25.375 1.74156 24.9931 1.06174 24.3133C0.381919 23.6334 0 22.7114 0 21.75V15.4062C0 15.1659 0.0954797 14.9354 0.265435 14.7654C0.435389 14.5955 0.665898 14.5 0.90625 14.5Z"
      fill="#D0D3D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06258 13.5937C9.23253 13.4238 9.46299 13.3284 9.7033 13.3284C9.9436 13.3284 10.1741 13.4238 10.344 13.5937L14.5001 17.7498L18.6561 13.5937C18.7397 13.5072 18.8397 13.4381 18.9503 13.3907C19.0609 13.3432 19.1798 13.3182 19.3001 13.3171C19.4205 13.3161 19.5398 13.339 19.6512 13.3846C19.7625 13.4301 19.8637 13.4974 19.9488 13.5825C20.0339 13.6676 20.1012 13.7688 20.1468 13.8802C20.1923 13.9915 20.2153 14.1109 20.2142 14.2312C20.2132 14.3515 20.1882 14.4705 20.1407 14.581C20.0932 14.6916 20.0241 14.7916 19.9376 14.8752L15.1417 19.6729C15.0575 19.7573 14.9575 19.8242 14.8474 19.8699C14.7373 19.9156 14.6193 19.9391 14.5001 19.9391C14.3809 19.9391 14.2628 19.9156 14.1527 19.8699C14.0426 19.8242 13.9426 19.7573 13.8585 19.6729L9.06258 14.8752C8.89268 14.7052 8.79724 14.4748 8.79724 14.2345C8.79724 13.9942 8.89268 13.7637 9.06258 13.5937Z"
      fill="#D0D3D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 1.8125C14.7404 1.8125 14.9709 1.90798 15.1408 2.07793C15.3108 2.24789 15.4062 2.4784 15.4062 2.71875V17.2188C15.4062 17.4591 15.3108 17.6896 15.1408 17.8596C14.9709 18.0295 14.7404 18.125 14.5 18.125C14.2596 18.125 14.0291 18.0295 13.8592 17.8596C13.6892 17.6896 13.5938 17.4591 13.5938 17.2188V2.71875C13.5938 2.4784 13.6892 2.24789 13.8592 2.07793C14.0291 1.90798 14.2596 1.8125 14.5 1.8125Z"
      fill="#D0D3D9"
    />
  </svg>
);

export default Download;
