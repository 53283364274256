import React, {useState} from 'react';
import {Button, List, Modal} from 'antd';
import {
    Category,
    Feature,
    useAddFeatureMutation,
    useDeleteFeatureMutation,
    useUpdateFeatureMutation
} from 'graphql/types';
import {FeatureItem} from '../FeatureItem/FeatureItem';
import {ModalFeature} from '../ModalFeature/ModalFeature';
import {DeleteOutlined, PlusSquareOutlined, SaveOutlined} from '@ant-design/icons';
import s from './style.module.scss';
import {BindedIcon} from 'entities/font-awesome-icon';

type Props = {
    category: Category;
};

const CategoryFeature = ({category}: Props) => {
    const [openCategory, setOpenCategory] = useState<boolean>(false);
    const [features, setFeatures] = useState<any>(category?.features || []);
    const [activeFeature, setActiveFeature] = useState<any>(null);
    const [saveFeature, {loading: saveLoading}] = useAddFeatureMutation();
    const [deleteFeature, {loading: deleteLoading}] = useDeleteFeatureMutation();
    const [updateFeature, {loading: updateLoading}] = useUpdateFeatureMutation();

    const createFeature = () => {
        setActiveFeature({
            icon: [],
            fontAwesomeIcon: {
                name: '',
                prefix: '',
                color: '',
            },
            title: '',
            description: ''
        });
    };

    const addFeature = async () => {
        try {
            const {data} = await saveFeature({
                variables: {
                    input: {
                        categoryID: category.id,
                        title: activeFeature.title,
                        description: activeFeature.description,
                        icon: activeFeature.icon || [],
                        fontAwesomeIcon: activeFeature.fontAwesomeIcon,
                    }
                }
            });
            if (data?.addFeature) {
                setFeatures(prev => ([...prev, data.addFeature]));
                setActiveFeature(null);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleFontAwesomeIcon = (icon: BindedIcon) => {
        setActiveFeature(prev => ({
            ...prev,
            fontAwesomeIcon: {
                ...prev.fontAwesomeIcon,
                prefix: icon.prefix,
                name: icon.name,
            }
        }));
    };

    const handleColorFontAwesomeIcon = (color: string) => {
        setActiveFeature(prev => ({
            ...prev,
            fontAwesomeIcon: {...prev.fontAwesomeIcon, color},
        }));
    };

    const changeFeature = async () => {
        try {
            const {data} = await updateFeature({
                variables: {
                    id: activeFeature.id,
                    input: {
                        title: activeFeature.title,
                        description: activeFeature.description,
                        icon: activeFeature.icon || [],
                        fontAwesomeIcon: activeFeature.fontAwesomeIcon
                    }
                }
            });
            if (data?.updateFeature) {
                setFeatures(prev => prev.map(item => {
                    if (item.id === data.updateFeature.id) {
                        return {...data.updateFeature};
                    }
                    return item;
                }));
                setActiveFeature(null);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const removeFeature = async () => {
        try {
            const {data} = await deleteFeature({
                variables: {
                    id: activeFeature.id
                }
            });

            if (data?.deleteFeature) {
                setFeatures(prev => prev.filter(({id}) => id !== data.deleteFeature));
                setActiveFeature(null);
            }

        } catch (e) {
            console.log(e);
        }
    };

    return <div className={s.category}>
        <Button
            type={'ghost'}
            key={category.id}
            onClick={() => setOpenCategory(prev => !prev)}
        >{category.title}
        </Button>
        {openCategory && <PlusSquareOutlined className={s.icon} onClick={createFeature}/>}
        {openCategory && <>
            <List
                itemLayout="horizontal"
                dataSource={features}
                renderItem={(item) => (
                    <FeatureItem feature={item as Feature} changeFeature={setActiveFeature}/>
                )}
            />
        </>}
        <Modal
            open={!!activeFeature}
            footer={[
                <Button
                    type="primary"
                    loading={saveLoading || updateLoading}
                    onClick={activeFeature?.id ? changeFeature : addFeature}
                >
                    Save
                    <SaveOutlined/>
                </Button>,
                <Button
                    type="primary"
                    loading={deleteLoading}
                    onClick={removeFeature}
                >
                    Delete
                    <DeleteOutlined/>
                </Button>,
            ]}
            onCancel={() => setActiveFeature(null)}
        >
            <ModalFeature
                feature={activeFeature as Feature}
                handleFeature={setActiveFeature}
                handleFontAwesomeIcon={handleFontAwesomeIcon}
                handleColorFontAwesomeIcon={handleColorFontAwesomeIcon}
            />
        </Modal>
    </div>;
};

export default CategoryFeature;
