import React from 'react';
import { Switch, Typography } from 'antd';
import { Platform } from 'graphql/types';
import { niceTitle } from 'utils/string';

import s from './styles.module.css';

export const EnumChooser = ({ checkedList, onChange, enumList, title }) => {
  return (
    <div className={`sideContentItem ${s.enumChooser} mb-2`}>
      <Typography.Title level={5}>{title}</Typography.Title>
      {Object.entries(enumList).map(([key, value]) => (
        <div key={key} className={s.enumChooserItem}>
          <span>{niceTitle(key)}</span>
          <Switch
            checked={checkedList.includes(value as Platform)}
            onChange={checked => onChange(checked, value)}
          />
        </div>
      ))}
    </div>
  );
};
