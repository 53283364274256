import React from 'react';
import { Picture } from 'graphql/types';
import { PictureOriginalImageProps } from './PictureOriginalImage';

export type PicturePreviewProps = {
  size: number;
  index: number;
  aspectRatio: number;
  showCropRatio?: number;
  disabled?: boolean;
  sizes: Picture['Sizes'];
  onCrop: PictureOriginalImageProps['onCrop'];
};

export const PicturePreview = React.memo(
  ({
    size,
    sizes,
    onCrop = () => {},
    aspectRatio,
    showCropRatio,
    disabled,
  }: PicturePreviewProps) => {
    const showImage = (sizes.find(image => image.size === size) || {}).value;

    const handleCrop = () => {
      if (disabled) return;
      onCrop({
        size,
        aspectRatio,
        showCropRatio,
      });
    };
    return (
      <div
        className="thumbnail px-2 pt-2 flex justify-center align-center"
        style={{ width: '20%', cursor: disabled ? 'not-allowed' : 'pointer' }}
        role="presentation"
        onClick={handleCrop}
      >
        <div className="flex-column fluid">
          <img
            src={showImage}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
          <span
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >{`${size} / ${aspectRatio}`}</span>
        </div>
        {!!showImage && (
          <div className="thumbnail-hover">
            <img src={showImage} />
          </div>
        )}
      </div>
    );
  }
);
