import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getFilter } from 'utils/route';
import { preventClick } from 'utils/events';
import { debounce } from 'utils/handlers';
import useDidReceiveProps from 'hooks/useDidReceiveProps';

import s from '../FilterSet/styles.module.css';

export const SearchField: React.FC<any> = ({
  name = 'search',
  apply,
  disabled = false,
}) => {
  const { search } = useLocation();
  const filters: any = getFilter(search);
  const [value, setValue] = useState(window.decodeURI(filters[name] || ''));

  const handleSearch = () => {
    const filters: any = getFilter(value);
    if (value) {
      filters[name] = value;
    } else {
      delete filters[name];
    }

    apply({ search: value });
  };

  const debounceSearch = debounce(handleSearch, 400);

  const handleChange = ({ target: { value: searchValue } }) => {
    setValue(searchValue);
  };

  useDidReceiveProps(() => {
    debounceSearch();
  }, [value]);

  return (
    <Input
      placeholder="Filter..."
      value={value}
      onChange={handleChange}
      onClick={preventClick}
      allowClear
      className={s.searchField}
      disabled={disabled}
      prefix={<SearchOutlined style={{ marginRight: 'var(--space-1)' }} />}
    />
  );
};
