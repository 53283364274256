import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import Ads from 'components/Settings/Ads/Ads'; 

const ads = {
    name: 'ads',
    parent: 'Settings',
    pageTitle: 'Ad Management',
    globalSearch: false,
    menuIcon: FileImageOutlined,
    ListComponent: Ads,
};

export default ads;
