import React from 'react';
import { MoneyCollectOutlined } from '@ant-design/icons';
import { SliderSettings } from 'components/Settings/SliderSettings/SliderSettings';

const sliderSettings = {
  name: 'slider',
  pageTitle: 'Slider settings',
  parent: 'Settings',
  globalSearch: false,
  menuIcon: MoneyCollectOutlined,
  ListComponent: SliderSettings,
};

export default sliderSettings;
