/* eslint-disable */
import React, { createContext, useRef } from 'react';
import { useWorker, useWorkerSubscribe } from 'hooks/useWorker';
import { calculateMatching, getMouseXY } from './helpers';
import { CalculateType, StartEvent } from './type';

import s from './style.module.css';
import { useDevice } from '../useDevice';

export const DRAG_EVENT = 'drag:event';
export const START_DRAG = 'drag:start';
export const MATCH_DRAG = 'drag:match';
export const DROP_DRAG = 'drop:drag';

export const DragContext = createContext({});

type Props = {
  onDrop: (data: { target: string; dropTarget: string }) => void;
  onMouseMove?: (
    clientX,
    clientY,
    handleCalculatePositions: () => void
  ) => void;
};

const SCROLL_OFFSET = 100;

export const DragProvider: React.FC<Props> = ({
  children,
  onDrop,
  onMouseMove = () => {},
}) => {
  const shadowRef = useRef<HTMLDivElement>(null);
  const dragContainer = useRef<HTMLDivElement>(null);
  const dragging = useRef({
    dragOnId: '',
    draggingId: '',
  });
  const dimensions = useRef<Record<string, CalculateType['dimensions']>>({});
  const { postMessage } = useWorker();
  const { isMobile } = useDevice();

  const handleCalculatePositions = () => {
    dragContainer.current!.querySelectorAll('[data-drag]').forEach(element => {
      const dataId = element.getAttribute('data-drag') as string;
      dimensions.current[dataId] = element.getBoundingClientRect();
    });
  };

  const setTransformShadow = e => {
    const idleValue = {
      x: e.clientX,
      y: e.clientY,
    };
    shadowRef.current!.style.transform = `translate(${idleValue.x}px, ${idleValue.y}px)`;
  };

  const handleDragStart = (data: StartEvent) => {
    handleCalculatePositions();
    const element = document.querySelector(`[data-drag="${data.dataId}"]`);
    setTransformShadow({ clientX: data.clientX, clientY: data.clientY });
    shadowRef.current!.innerHTML = '';
    shadowRef.current!.appendChild(element!.children[0].cloneNode(true));
    dragging.current.draggingId = data.dataId;
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('touchmove', mouseMove);
    window.addEventListener('mouseup', onDropDrag);
    window.addEventListener('touchend', onDropDrag);
    document.body.style.overflowY = 'hidden';
    if (!isMobile) {
      document.body.style.paddingRight = '16px';
    }
  };

  const scrollWindow = (clientY: number) => {
    if (clientY < 100) {
      window.scrollBy(0, -10);
    } else if (clientY > window.innerHeight - 100) {
      window.scrollBy(0, 10);
    }
    handleCalculatePositions();
  };

  const handleWindowScroll = (clientY: number) => {
    if (
      clientY > SCROLL_OFFSET &&
      clientY < window.innerHeight - SCROLL_OFFSET
    ) {
      return;
    }
    scrollWindow(clientY);
  };

  const mouseMove = e => {
    if (!e.targetTouches) {
      e.stopPropagation();
      e.preventDefault();
    }
    const { clientX, clientY } = getMouseXY(e);
    setTransformShadow({ clientX, clientY });
    handleWindowScroll(clientY);
    onMouseMove(clientX, clientY, handleCalculatePositions);
    const matched = calculateMatching(dimensions.current, {
      x: clientX,
      y: clientY,
    });
    dragging.current.dragOnId = matched;
    postMessage({
      type: DRAG_EVENT,
      dragId: matched,
      event: MATCH_DRAG,
      dragging: dragging.current.draggingId,
    });
  };

  const onDropDrag = () => {
    shadowRef.current!.innerHTML = '';
    setTransformShadow({ clientX: 0, clientY: 0 });

    const { draggingId, dragOnId } = dragging.current;
    if (draggingId !== dragOnId) {
      onDrop({
        target: draggingId,
        dropTarget: dragOnId,
      });
    }
    dragging.current = {
      dragOnId: '',
      draggingId: '',
    };
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('touchmove', mouseMove);
    window.removeEventListener('mouseup', onDropDrag);
    window.removeEventListener('touchend', onDropDrag);
    document.body.style.overflowY = 'visible';
    if (!isMobile) {
      document.body.style.paddingRight = '0';
    }
    setTimeout(() => {
      postMessage({
        type: DRAG_EVENT,
        event: DROP_DRAG,
      });
    }, 0);
  };

  useWorkerSubscribe(DRAG_EVENT, data => {
    switch (data.event) {
      case START_DRAG:
        return handleDragStart(data);

      default:
        return;
    }
  });

  return (
    <DragContext.Provider value={{}}>
      <div ref={dragContainer}>{children}</div>
      <div ref={shadowRef} className={s.dragShadow} />
    </DragContext.Provider>
  );
};
