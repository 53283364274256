import { Tag } from 'antd';
import { provideHandler } from 'utils/handlers';

type Props = {
  variables: { title: string; value: string }[];
  onClick: (value: string) => void;
};

export const SeoVariables = ({ variables, onClick }: Props) => {
  return (
    <div>
      {variables.map(({ title, value }) => {
        return (
          <Tag
            style={{ cursor: 'pointer', userSelect: 'none' }}
            key={value}
            onClick={provideHandler(value, onClick)}
            color="blue"
          >
            {title}
          </Tag>
        );
      })}
    </div>
  );
};
