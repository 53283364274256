import _isEmpty from 'lodash/isEmpty';
import { Category, Item } from 'graphql/types';
import { CompareCategory } from '../Settings/CSV/CSVProvider/types';
import { constructNewItem } from './CSVItem/helpers';

export const getItemKeys = (categories: Category[]) => {
  if (!categories.length) return [];
  return Object.keys(
    (categories.find(({ items }) => !!items && items[0])?.items as Item[])[0]
  );
};

export const constructAllNewItems = (
  categories: Record<string, CompareCategory[]>
) => {
  const result = [] as any;
  Object.entries(categories).forEach(([key, items]) => {
    // @ts-ignore
    const tts: CompareCategory[] = items.reduce((acc, item) => {
      const haveErrors = !_isEmpty(item.errors);
      if (haveErrors) return acc;
      return [...acc, constructNewItem(item)];
    }, []);
    result.push(...tts);
  });

  return result;
};

export const constructConfirmMeta = (
  itemsForUpdate: Item[],
  itemsForDelete: Item[]
) => {
  let text = `Take the new (${itemsForUpdate.length})`;
  // if (itemsForDelete.length > 0 && !itemsForUpdate.length) {
  //   text = `Archive items (${itemsForDelete.length})`;
  // }

  return {
    text,
    disabled: !itemsForUpdate.length && !itemsForDelete.length,
  };
};
