import React, { useContext, useState, useRef, useMemo } from 'react';
import _uniqBy from 'lodash/uniqBy';
import {
  Category,
  DynamicModel,
  Item,
  useGetCsvDataQuery,
} from 'graphql/types';
import { message } from 'antd';
import Papa from 'papaparse';
import * as providers from 'config/providers';
import { partialFetch } from 'utils/handlers';
import { getPlainString } from 'utils/string';
import { constructNewItem } from 'components/CSVUploader/CSVItem/helpers';
import { CompareCategory, CSVProviderState } from './types';
import { CSVSaveModal } from './CSVSaveModal/CSVSaveModal';
import { initialState, CSVContext } from './staticData';
import {
  getParsedItemsV2,
  transformCsvHeader,
  transformCsvValue,
} from './helpers';

export const CSVProvider = ({ children }) => {
  const chosenItems = useRef<CompareCategory[]>([]);
  const allItems = useRef<Item[]>([]);
  const allItemsMap = useRef<Record<string, Item>>({});
  const allParsedItems = useRef<Record<string, CompareCategory>>({});
  const newCategories = useRef<Partial<Category>[]>([]);
  const allCategories = useRef<Category[]>([]);
  const allDynamicModels = useRef<DynamicModel[]>([]);
  const [state, setState] = useState<CSVProviderState>(initialState);
  const [uploadState, setUploadState] = useState({
    successUploaded: 0,
    failUploaded: [] as { entity: any; msg: string }[],
    total: 0,
  });

  const { loading } = useGetCsvDataQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({
      categories: { data: categories },
      items: { data: items },
      dynamicModels,
    }) => {
      allCategories.current = categories as Category[];
      allItems.current = items as Item[];
      allItemsMap.current = items.reduce((acc, item) => {
        const title = getPlainString(item.title);
        return {
          ...acc,
          [title]: item,
        };
      }, {});
      allDynamicModels.current = dynamicModels as DynamicModel[];
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const dropChosen = () => {
    setState(prev => ({
      ...prev,
      chosen: {},
      chosenItems: [],
    }));
  };

  const onChooseItem = (items: CompareCategory[] | CompareCategory) => {
    const arrayedItems = Array.isArray(items) ? items : [items];
    const chosen = !arrayedItems.length
      ? {}
      : arrayedItems.reduce((acc, { title }) => {
          const tt = getPlainString(title);
          const isChosen = state.chosen[tt];
          return {
            ...acc,
            [tt]: !isChosen,
          };
        }, state.chosen);

    // @ts-ignore
    chosenItems.current = Object.entries(chosen).reduce(
      // @ts-ignore
      (acc, [title, isChosen]) => {
        if (!isChosen) return acc;
        const item = allParsedItems.current[getPlainString(title)];
        return [...acc, constructNewItem(item)];
      },
      []
    );
    setState(prev => ({
      ...prev,
      chosen,
    }));
  };

  const parseSCV = (
    {
      target: {
        files: [file],
      },
    }: any,
    callback = () => {},
    addingHeadersForParse?: string[]
  ) => {
    if (!file) return;
    const itemKeys = Object.keys(
      allItemsMap.current[Object.keys(allItemsMap.current)[0]]
    ).reduce((acc, key) => ({ ...acc, [getPlainString(key)]: key }), {});
    dropChosen();
    chosenItems.current = [];
    allParsedItems.current = {};
    newCategories.current = [];
    setState(prev => ({ ...prev, loading: true }));
    Papa.parse(file, {
      delimiter: '', // auto-detect
      newline: '', // auto-detect
      header: true,
      transformHeader: head => {
        return transformCsvHeader(head, itemKeys, addingHeadersForParse);
      },
      transform: (value: string, column: string = '') => {
        return transformCsvValue(value, column);
      },
      async complete(results) {
        const filteredResult = results.data.map(item => {
          delete item['undefined'];
          return item;
        });

        try {
          const {
            categories,
            newCategories: nCategories,
            allParsedItems: nAllParsedItems,
          } = await getParsedItemsV2(
            filteredResult,
            allCategories.current,
            allItemsMap.current,
            allDynamicModels.current
          );
          newCategories.current = nCategories;
          allParsedItems.current = nAllParsedItems;
          setState(prev => ({
            ...prev,
            categories,
            loading: false,
          }));
          callback();
        } catch (e) {
          console.log(e);
          message.error(e.message);
        }
      },
      error(err) {
        message.error(err.message);
        setState(prev => ({ ...prev, loading: false }));
      },
    });
  };

  const onUploadProgress = (
    success: number,
    failed: { entity: any; msg: string }[]
  ) => {
    setUploadState(prev => ({
      ...prev,
      successUploaded: success,
      failUploaded: failed,
    }));
  };

  const clearUpChosenItems = (items: CompareCategory[]) => {
    chosenItems.current = [];
    const updatedCategories = {};
    const itemsTitles = items.map(({ title }) => getPlainString(title));
    for (let category in state.categories) {
      updatedCategories[category] = state.categories[category].filter(
        ({ title }) => {
          return !itemsTitles.includes(getPlainString(title));
        }
      );
    }
    setState(prev => ({
      ...prev,
      categories: updatedCategories,
      chosen: {},
      makeActive: false,
    }));
  };

  const handleArchiveItems = async (items: Item[]) => {
    try {
      setState(prev => ({ ...prev, archiveLoading: true }));
      setUploadState(prev => ({
        ...prev,
        total: items.length,
        failUploaded: [],
        successUploaded: 0,
      }));
      await partialFetch(
        items,
        (item: Item) => {
          if (!item.id) return;
          return providers.item.deleteOne(item);
        },
        {
          onProcess: onUploadProgress,
        }
      );
    } catch (e) {
      message.error(e.message);
    } finally {
      setState(prev => ({ ...prev, archiveLoading: false }));
    }
  };

  const saveNewCategories = async () => {
    if (!newCategories.current.length) return allCategories.current;

    setState(prev => ({ ...prev, savingNewCategories: true }));
    setUploadState(prev => ({
      ...prev,
      total: newCategories.current.length,
      failUploaded: [],
      successUploaded: 0,
    }));
    const { result } = await partialFetch(
      newCategories.current,
      category => {
        if (category.id) {
          return providers.category.updateOne({ ...category, active: true });
        }
        return providers.category.createOne({ ...category, active: true });
      },
      { onProcess: onUploadProgress }
    );
    setState(prev => ({ ...prev, savingNewCategories: false }));

    return _uniqBy(
      [
        ...result.map(({ data }) => data.createCategory || data.updateCategory),
        ...allCategories.current,
      ],
      'id'
    );
  };

  const handleSaveItems = async () => {
    try {
      setState(prev => ({
        ...prev,
        saveModalOpened: true,
      }));
      const updatedCategories = await saveNewCategories();
      const itemsForSave = chosenItems.current.map(item => {
        item.categories = item.categories!.map(category => {
          if (category.id) return category;
          const needCategory = updatedCategories.find(
            ({ title }) =>
              getPlainString(title) === getPlainString(category.title)
          );
          return { ...category, id: needCategory.id };
        });
        return item;
      });

      setState(prev => ({ ...prev, savingItems: true }));
      setUploadState(prev => ({
        ...prev,
        total: itemsForSave.length,
        failUploaded: [],
        successUploaded: 0,
      }));

      // Handling of item fields already exists in "createOne" function
      const { result } = await partialFetch(
        itemsForSave,
        (item: Item) => {
          const updatedItem = { ...item, active: state.makeActive };
          if (updatedItem.id) return providers.item.updateOne(updatedItem);
          return providers.item.createOne(updatedItem);
        },
        { onProcess: onUploadProgress }
      );

      clearUpChosenItems(
        result.map(
          ({ data: { createItem, updateItem } }) => createItem || updateItem
        )
      );
    } catch (e) {
      message.error(e.message);
    } finally {
      setState(prev => ({ ...prev, savingItems: false }));
    }
  };

  const getRefData = () => {
    return {
      allItemsMap: allItemsMap.current,
      allCategories: allCategories.current,
      allParsedItems: allParsedItems.current,
      allItems: allItems.current,
      newCategories: newCategories.current,
    };
  };

  const label = useMemo(() => {
    switch (true) {
      case state.archiveLoading:
        return 'Archiving...';
      case state.savingNewCategories:
        return 'New categories...';
      case state.savingItems:
        return 'Saving items...';

      default:
        return undefined;
    }
  }, [uploadState]);

  const { successUploaded, failUploaded, total } = uploadState;
  const { archiveLoading, savingItems, savingNewCategories } = state;
  const currentLoading =
    archiveLoading || loading || savingItems || savingNewCategories;
  return (
    <CSVContext.Provider
      value={{
        ...state,
        loading: currentLoading,
        updateState: setState,
        getRefData,
        parseSCV,
        onChooseItem,
        handleSaveItems,
        handleArchiveItems,
        dropChosen,
      }}
    >
      {state.saveModalOpened && (
        <CSVSaveModal
          loading={currentLoading}
          successUploaded={successUploaded}
          failUploaded={failUploaded}
          total={total}
          label={label}
          onClose={() => {
            setState(prev => ({ ...prev, saveModalOpened: false }));
            setUploadState(prev => ({
              ...prev,
              successUploaded: 0,
              failUploaded: [],
              total: 0,
            }));
          }}
        />
      )}
      {children}
    </CSVContext.Provider>
  );
};

export const useCSV = () => useContext(CSVContext);
