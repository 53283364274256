import React, { useState } from 'react';
import { Input } from 'antd';
import { HexColorPicker } from 'react-colorful';

export const ColorPicker = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => setVisible(!visible);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
      }}
    >
      <Input
        style={{ width: 'calc(100% - 60px)' }}
        value={value || ''}
        onFocus={toggleVisible}
        onBlur={toggleVisible}
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      <div
        style={{
          width: '30px',
          height: '30px',
          backgroundColor: value,
          border: '1px solid grey',
          borderRadius: '2px',
          cursor: 'pointer',
        }}
      />
      {visible && (
        <HexColorPicker
          style={{
            width: '100%',
            zIndex: 2,
            position: 'absolute',
            top: '2.5rem',
          }}
          color={value}
          onChange={setValue}
        />
      )}
    </div>
  );
};
