const TgSend = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 29.7143L24 16L0 2.28571V12.9524L17.1429 16L0 19.0476V29.7143Z"
      fill="#FF6456"
    />
  </svg>
);

export default TgSend;
