import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Button, Modal, Typography } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { useCSV } from 'components/Settings/CSV/CSVProvider/CSVProvider';
import { Item } from 'graphql/types';
import { toggleValue } from 'utils/handlers';
import { CSVUploaderArchiveModal } from './CSVUploaderArchiveModal';
import { constructAllNewItems, constructConfirmMeta } from './helpers';

import s from './styles.module.scss';
import { getPlainString } from '../../utils/string';
import useDidReceiveProps from '../../hooks/useDidReceiveProps';

type Props = {
  visible: boolean;
  archiveModalOpened: boolean;
  parsed: boolean;
  onClose: (e: any) => void;
  changeModalState: React.Dispatch<any>;
};

export const CSVUploaderInitialModal = ({
  visible,
  onClose,
  archiveModalOpened,
  changeModalState,
  parsed,
}: Props) => {
  const {
    loading,
    handleSaveItems,
    handleArchiveItems,
    categories,
    onChooseItem,
    getRefData,
  } = useCSV();
  const [state, setState] = useState({
    allItemsForSave: [],
    archiveItems: [] as Item[],
  });

  const handleSave = async (deleteRest?: boolean) => {
    changeModalState(prev => ({
      ...prev,
      archiveModalOpened: false,
      actionsModalOpened: false,
    }));
    await handleSaveItems();
    if (deleteRest) {
      await handleArchiveItems(state.archiveItems);
    }
  };

  const handleSaveAllItems = async () => {
    onChooseItem(state.allItemsForSave);
    changeModalState(prev => ({
      ...prev,
      archiveModalOpened: true,
    }));
  };

  useEffect(() => {
    if (!parsed) return;

    const { allItems, allParsedItems } = getRefData();
    const allItemsForSave = constructAllNewItems(categories);
    const archiveItems = allItems.filter(
      ({ title }) => !allParsedItems[getPlainString(title)]
    );
    setState(prev => ({
      ...prev,
      allItemsForSave,
      archiveItems,
    }));
  }, [parsed]);

  useDidReceiveProps(() => {
    const isOnlyArchives =
      !state.allItemsForSave.length && !!state.archiveItems.length;
    changeModalState(prev => ({
      ...prev,
      archiveModalOpened: isOnlyArchives,
      actionsModalOpened: !isOnlyArchives,
    }));
  }, [state.allItemsForSave, state.archiveItems]);

  const { text, disabled } = constructConfirmMeta(
    state.allItemsForSave,
    state.archiveItems
  );
  return (
    <>
      {archiveModalOpened && (
        <CSVUploaderArchiveModal
          onClose={() =>
            changeModalState(prev => ({
              ...prev,
              archiveModalOpened: false,
            }))
          }
          onSave={handleSave}
          withArchiveItem={!!state.archiveItems.length}
          withSavingItem={!!state.allItemsForSave.length}
          itemsCount={state.archiveItems.length}
        />
      )}
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        title="Choose the action"
        onCancel={onClose}
      >
        <div className={s.uploaderActions}>
          <div className="flex-column">
            <Button
              type="ghost"
              size="large"
              onClick={handleSaveAllItems}
              loading={loading}
              disabled={disabled}
              className={s.takeNewButton}
            >
              <span>{text}</span>
            </Button>
          </div>
          <Button
            type="primary"
            size="large"
            onClick={() =>
              changeModalState(prev => ({
                ...prev,
                actionsModalOpened: false,
              }))
            }
            loading={loading}
          >
            Show compares
          </Button>
        </div>
      </Modal>
    </>
  );
};
