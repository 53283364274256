import React from 'react';
import { useTagVersionQuery } from '../../graphql/types';

export const Version = () => {
  const { data } = useTagVersionQuery();
  return (
    <div>
      <div style={{ position: 'static', bottom: 10, right: 30, color: '#000' }}>
        Old version: v{process.env.REACT_APP_VERSION}
      </div>
      <div style={{ position: 'static', bottom: 10, right: 30, color: '#000' }}>
        New version: v{data?.tagVersion}
      </div>
    </div>
  );
};
