import { useState, useRef } from 'react';
import { Button } from 'antd';
import { useCSV } from 'components/Settings/CSV/CSVProvider/CSVProvider';
import { CSVUploaderInitialModal } from './CSVUploaderInitialModal';
import { CSVTree } from './CSVUploaderModal/CSVTree';

export const CSVUploader = () => {
  const { parseSCV, loading, chosen, handleSaveItems } = useCSV();
  const input = useRef<HTMLInputElement>(null);
  const [state, setState] = useState({
    showCompared: false,
    parsed: false,
    actionsModalOpened: false,
    archiveModalOpened: false,
  });

  const onParse = async event => {
    const {
      target: {
        files: [file],
      },
    } = event;
    if (!file) return;
    setState(prev => ({
      ...prev,
      showCompared: false,
      parsed: false,
    }));
    parseSCV(event, () => {
      setState(prev => ({
        ...prev,
        showCompared: true,
        parsed: true,
      }));
      input.current!.value = '';
    });
  };

  const showUploadDialog = () => {
    input.current!.click();
  };

  const onCloseActionModal = () => {
    setState(prev => ({ ...prev, actionsModalOpened: false }));
    input.current!.value = '';
  };

  const updateAvailable = Object.values(chosen).find(v => !!v);
  return (
    <div className="fluid">
      <CSVUploaderInitialModal
        visible={state.actionsModalOpened}
        archiveModalOpened={state.archiveModalOpened}
        parsed={state.parsed}
        changeModalState={setState}
        onClose={onCloseActionModal}
      />
      <label>
        <Button type="primary" loading={loading} onClick={showUploadDialog}>
          Upload
        </Button>
        <Button
          type="primary"
          loading={loading}
          disabled={!updateAvailable}
          onClick={() =>
            setState(prev => ({ ...prev, archiveModalOpened: true }))
          }
          className="ml-2"
        >
          Save entries
        </Button>
        <input
          ref={input}
          type="file"
          hidden
          accept="text/csv"
          onChange={onParse}
          disabled={loading}
        />
      </label>
      {state.showCompared && <CSVTree />}
    </div>
  );
};
