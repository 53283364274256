import { ChangeEvent, useMemo, useRef, useState } from 'react';
import Papa from 'papaparse';
import { Button, message, Modal } from 'antd';
import {
  Category,
  ScreenSizeType,
  SortDirection,
  useGetSortCategoriesSizeLazyQuery,
  useUpdateScreenSizeMutation,
} from 'graphql/types';
import { getUpdateSortScreenSizePayload } from 'components/Categories/helpers';
import { CSVSortInputForm } from './CSVSortInputForm';
import { CSVSortCategoriesTree } from './CSVSortCategoriesTree';
import {
  collectCategories,
  fillUpSortCategories,
  transformCsvHeader,
} from './helpers';
import { useCSV } from '../CSVProvider/CSVProvider';

export type SortHeader = { key: string; sortDirection: SortDirection };
export type HeaderTypes = 'primaryHeader' | 'altHeader';

const DEFAULT_HEADER_VALUE = { key: '', sortDirection: SortDirection.Asc };

export const CSVSortProvider = () => {
  const { getRefData, loading } = useCSV();
  const input = useRef<HTMLInputElement>(null);
  const parsedItems = useRef<Record<string, string>[]>([]);
  const sortCategories = useRef([]);
  const [state, setState] = useState({
    modelOpened: false,
    updatedCategoriesTree: [] as Category[][],
    step: 'preParse' as 'preParse' | 'parse',
    headers: [] as string[],
    altHeader: DEFAULT_HEADER_VALUE,
    primaryHeader: DEFAULT_HEADER_VALUE,
  });

  const [fetchSortCategories, { loading: fetchSortLoading }] =
    useGetSortCategoriesSizeLazyQuery({
      fetchPolicy: 'no-cache',
      variables: {
        type: ScreenSizeType.Desktop,
      },
      onCompleted: ({ screenSizeV5 }) => {
        sortCategories.current = screenSizeV5 as any;
      },
      onError: e => {
        message.error(e.message);
      },
    });

  const [updateScreenSize] = useUpdateScreenSizeMutation({
    onCompleted: () => {
      message.success('Saved successfully');
      dropState();
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const handleModalOpen = async () => {
    input.current!.click();
    await fetchSortCategories();

    setState(prev => ({
      ...prev,
      loading: false,
    }));
  };

  const dropState = () => {
    input.current!.value = '';
    setState(prev => ({
      ...prev,
      loading: false,
      modelOpened: false,
      updatedCategoriesTree: [],
      step: 'preParse',
      headers: [] as string[],
      altHeader: DEFAULT_HEADER_VALUE,
      primaryHeader: DEFAULT_HEADER_VALUE,
    }));
  };

  const handleCloseModal = () => {
    if (state.step === 'parse') {
      setState(prev => ({ ...prev, step: 'preParse' }));
      return;
    }
    dropState();
  };

  const parseSCV = async ({
    target: {
      files: [csvFile],
    },
  }: ChangeEvent<any>) => {
    if (!csvFile) {
      message.error('Fill require fields');
      return;
    }

    const headers: string[] = [];
    Papa.parse(csvFile, {
      delimiter: '',
      newline: '',
      header: true,
      transformHeader: (head: string) => {
        const header = transformCsvHeader(head);
        if (header) {
          headers.push(header);
        }
        return header;
      },
      async complete(results) {
        parsedItems.current = results.data.map(item => {
          delete item['undefined'];
          return item;
        });
        setState(prev => ({
          ...prev,
          modelOpened: true,
          headers,
        }));
      },
      error(err) {
        message.error(err.message);
        setState(prev => ({ ...prev, loading: false }));
      },
    });
  };

  const onChangeHeaderSelected =
    (type: HeaderTypes) =>
    ({ target: { name } }) => {
      setState(prev => {
        return {
          ...prev,
          [type]:
            prev[type].key === name
              ? DEFAULT_HEADER_VALUE
              : { ...DEFAULT_HEADER_VALUE, key: name },
        };
      });
    };

  const handleConfirm = async () => {
    if (state.step === 'preParse') {
      const { allItemsMap } = getRefData();
      const categories = collectCategories(
        parsedItems.current,
        allItemsMap,
        state.primaryHeader,
        state.altHeader
      );
      const updatedCategoriesTree = fillUpSortCategories(
        sortCategories.current,
        categories
      );

      setState(prev => ({
        ...prev,
        updatedCategoriesTree,
        step: 'parse',
      }));
      return;
    }

    return updateScreenSize({
      variables: {
        type: ScreenSizeType.Desktop,
        input: getUpdateSortScreenSizePayload(state.updatedCategoriesTree),
      },
    });
  };

  const onChangeSortDirection =
    (key: 'primaryHeader' | 'altHeader', sortDirection: SortDirection) =>
    () => {
      setState(prev => ({ ...prev, [key]: { ...prev[key], sortDirection } }));
    };

  const ModalContent = useMemo(() => {
    if (state.step === 'preParse') {
      return (
        <CSVSortInputForm
          headers={state.headers}
          primaryHeader={state.primaryHeader}
          altHeader={state.altHeader}
          onChange={onChangeHeaderSelected as any}
          onChangeSortDirection={onChangeSortDirection as any}
        />
      );
    }

    return (
      <CSVSortCategoriesTree sortCategories={state.updatedCategoriesTree} />
    );
  }, [state]);

  return (
    <div>
      <label>
        <Button
          type="primary"
          onClick={handleModalOpen}
          loading={loading || fetchSortLoading}
        >
          Upload
        </Button>
        <input
          ref={input}
          type="file"
          hidden
          accept="text/csv"
          onChange={parseSCV}
        />
      </label>
      <Modal
        visible={state.modelOpened}
        onOk={handleConfirm}
        okButtonProps={{
          disabled: !state.primaryHeader.key,
        }}
        closable
        onCancel={handleCloseModal}
        title={state.step === 'parse' ? 'Result' : 'Choose sort column'}
        width={state.step === 'parse' ? '90%' : 720}
      >
        {ModalContent}
      </Modal>
    </div>
  );
};
