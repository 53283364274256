import React from 'react';
import { Button, message } from 'antd';
import { PictureDictionary, useIconCreateMutation } from 'graphql/types';

const ICON_SIZES = [48];

type Props = {
  icon?: string;
  url: string;
  name?: string;
  alternativeUrl?: string;
  onGet: (n: string, u: PictureDictionary) => void;
};

export const GetIconButton: React.FC<Props> = ({
  url,
  alternativeUrl,
  onGet,
  name,
}) => {
  const [fetchIcon, { loading }] = useIconCreateMutation();

  const handleGettingIcon = async (url: string, altUrl?: string) => {
    if (!url) {
      message.error('There is no such URL have been set');
      return;
    }
    try {
      const {
        // @ts-ignore
        data: { iconCreate },
      } = await fetchIcon({
        variables: {
          url,
          sizes: ICON_SIZES,
        },
      });
      onGet(name as string, iconCreate);
    } catch (e) {
      if (altUrl) {
        handleGettingIcon(altUrl);
        return;
      }
      message.error(e.message);
    }
  };

  return (
    <Button
      type="primary"
      loading={loading}
      onClick={() => handleGettingIcon(url, alternativeUrl)}
    >
      Get icon
    </Button>
  );
};
