import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Card, Form, Input, message, Typography, Button } from 'antd';
import { getFilter } from 'utils/route';
import { initializeApollo } from 'utils/apollo';
import { SetStaffPasswordDocument } from '../../graphql/types';

export const StaffRecoveryPage = () => {
  const { search } = useLocation();
  const { token } = getFilter(search) as any;
  const [state, setState] = useState({
    loading: false,
  });

  const onSubmit = async ({ password }) => {
    const client = initializeApollo();
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await client.mutate({
        mutation: SetStaffPasswordDocument,
        variables: {
          token,
          password,
        },
      });
      setState(prev => ({ ...prev, loading: false }));
    } catch (e) {
      message.error(e.message);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!token) {
      message.error('There is no such token provided');
    }
  }, []);

  return (
    <div
      className={classnames('page', { pending: state.loading })}
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: '5rem',
      }}
    >
      <Card
        style={{
          width: '30rem',
        }}
      >
        <div className="flex justify-center">
          <Typography.Title level={4}>Set staff password</Typography.Title>
        </div>
        <Form onFinish={onSubmit}>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Require field' }]}
          >
            <Input
              name="password"
              placeholder="Enter password..."
              disabled={!token}
            />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-center">
              <Button disabled={!token} type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
