import { DynamicData } from 'graphql/types';

export const getDynamicData = (
  dynamicData: DynamicData[],
  modelName: string,
  defaultValue: any = ''
) => {
  const data = dynamicData.find(({ model: { name } }) => name === modelName);
  if (!data) return defaultValue;

  return JSON.parse(data.data);
};
