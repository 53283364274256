import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { SettingsList } from 'components/Settings/SettingsList/SettingsList';

const modelsSettings = {
  name: 'models',
  parent: 'Settings',
  pageTitle: 'Models settings',
  globalSearch: false,
  menuIcon: SettingOutlined,
  ListComponent: SettingsList,
};

export default modelsSettings;
