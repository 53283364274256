import { filterTypeName } from 'utils/apollo';
import { DynamicData, DynamicModel } from 'graphql/types';

export const getInitialState = ({
  title = '',
  slug = '',
  slugCustom,
  shortDescription = '',
  longDescription = '',
  dynamic,
  active,
  color,
  icon,
  fontAwesomeIcon = { name: '', prefix: '', color: '' },
  image,
  faq,
  items,
  skills,
  seoCustom,
}) => ({
  title,
  slug,
  faq: faq || [],
  slugCustom,
  shortDescription,
  longDescription,
  dynamic: dynamic || [],
  active: active !== false ? true : active,
  color: color || '#',
  icon,
  fontAwesomeIcon,
  image: image || null,
  seoCustom: seoCustom || {},
  items: (items || []).map(item => filterTypeName(item)),
  skills: (skills || []).map(item => filterTypeName(item)),
});

export const getUpdatedDynamicFilters = (
  currentDynamics: DynamicData[],
  filterModels: DynamicModel[],
  data: Record<string, any>
) => {
  const filterModel = filterModels.find(({ name }) => name === 'filters');
  const stringData = JSON.stringify(data);

  return [
    ...currentDynamics.filter(({ model: { name } }) => name !== 'filters'),
    { model: filterModel, data: stringData },
  ];
};
