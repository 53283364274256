import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { DynamicData, DynamicFieldType, Item } from 'graphql/types';
import { Form } from 'antd';
import { useEffect, useMemo } from 'react';
import { FormInstance } from 'antd';
import { getDynamicData } from 'utils/dynamic';
import { CategoryItemsDynamicItem } from './CategoryItemsDynamicItem';
import {
  checkCategoryFilterDynamic,
  collectUniqDynamic,
  getDynamicFieldsFromCategoryItemsThatNotExists,
} from './helpers';
import { CategoryDynamicFilter } from '../../../type';

type Props = {
  items: Item[];
  categoryDynamic: DynamicData[];
  form: FormInstance;
};

export const CategoryItemsDynamic = React.memo(
  ({ items = [], categoryDynamic, form }: Props) => {
    const [state, setState] = useState<Record<string, CategoryDynamicFilter>>(
      {}
    );

    const checkFilterModel = async () => {
      await checkCategoryFilterDynamic();
      const dynamicData = await getDynamicFieldsFromCategoryItemsThatNotExists(
        items,
        getDynamicData(categoryDynamic, 'filters', {})
      );
      setState(dynamicData);
      form.setFieldsValue(dynamicData);
    };

    useEffect(() => {
      if (!categoryDynamic) return;
      checkFilterModel();
    }, [categoryDynamic]);

    const dynamics = useMemo(() => {
      return collectUniqDynamic(items);
    }, []);

    return (
      <Form className="editSideContent__item flex-column" form={form}>
        {Object.entries(state).map(([title, { type }]) => (
          <CategoryItemsDynamicItem
            key={title}
            title={title}
            form={form}
            type={type}
          />
        ))}
      </Form>
    );
  }
);
