export const getDottedDate = (date: string | Date | number) => {
  const dd = new Date(date);
  let day: any = dd.getDate();
  let month: any = dd.getMonth() + 1;
  const year = dd.getFullYear();
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return `${day}.${month}.${year}`;
};
