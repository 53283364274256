/*eslint-disable*/
import React, { FC } from 'react';
import { Table } from 'antd';
import './table_style.css';
import { ColumnsType } from 'antd/lib/table';
import { DashboardReportsQuery } from 'graphql/types';
import {linkGeneration} from './helper';

const title = (key: string): string => {
    switch (key) {
        case 'avgCTR':
            return 'Avg CTR';
        case 'impressions':
            return 'Impressions';
        case 'pageName':
            return 'Page name';
        case 'position':
            return 'Position';
        case 'searchTraffic':
            return 'Search traffic';
        case 'title':
            return 'Title';
        case 'clicks':
            return 'Clicks';
        case 'visitors':
            return 'Visitors';
        default:
            return key;
    }
};

const prepareValue = (key: string, v: any): string | number => {
    if (typeof v === 'number') {
        switch (key) {
            case 'avgCTR':
                return v.toFixed(2); // Format Avg CTR with two decimal places
            case 'position':
                return v.toFixed(1); // Format Position with one decimal place
            default:
                return v; // Return other numbers without formatting
        }
    }
    return v; // Return non-numbers as is
};

const initColumns = (data: DataStruct): ColumnsType<any> => {
    if (data.length) {
        let row: ColumnsType = [];
        for (const key in data[0]) {
            if(key === 'pageId' || key === 'pageType') continue
            row.push({
                title: title(key),
                dataIndex: key,
                key: key,
                width: !row.length ? 200 : undefined,
                render: (value, record) => <>{key === 'pageName'
                    ? linkGeneration(record)
                    : prepareValue(key, value)}
                </>, // Pass the key to prepareValue
            });
        }
        return row;
    }

    return [];
};

type DataStruct =
    | DashboardReportsQuery['content']
    | DashboardReportsQuery['countries']
    | DashboardReportsQuery['keywords']
    | DashboardReportsQuery['topPages'];

type Props = {
    data: DataStruct;
};

export const TableAnalytics: FC<Props> = ({ data }) => {
    return (
        <Table
            dataSource={data}
            columns={initColumns(data)}
            pagination={false}
            className={'table-analytic'}
            scroll={{ x: 555, y: 500 }}
        />
    );
};

export default TableAnalytics;
