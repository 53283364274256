import { useMemo } from 'react';
import { Tag } from 'antd';
import { useRouter } from 'hooks/useRouter';
import { collectTags } from './helpers';

import s from './styles.module.css';

export const FiltersTagsList = ({ filters, sort }) => {
  const { query, search, push, constructUrl, pathname } = useRouter();

  const handleDeleteFilter =
    ({ value, parent = '' }) =>
    () => {
      let updatedQuery;
      if (parent) {
        updatedQuery = {
          [parent]: query[parent]
            .split(',')
            .filter(item => item !== value)
            .join(','),
        };
      } else {
        updatedQuery = {
          [value]: '',
        };
      }
      if (value === 'sort') {
        updatedQuery.sortDirection = '';
      }
      push(constructUrl(pathname, { ...query, ...updatedQuery }));
    };

  const tags = useMemo(() => {
    return collectTags({ filters, sort, query });
  }, [search]);

  if (!search) return null;
  return (
    <div className={s.filterTags}>
      {tags.filters.map(({ name, value, parent }) => (
        <Tag
          key={value}
          onClick={handleDeleteFilter({ value, parent })}
          closable
          onClose={handleDeleteFilter({ value, parent })}
        >
          {window.decodeURIComponent(name)}
        </Tag>
      ))}
      {!!tags.sort && (
        <Tag
          onClick={handleDeleteFilter({ value: 'sort' })}
          closable
          onClose={handleDeleteFilter({ value: 'sort' })}
        >
          {tags.sort}
        </Tag>
      )}
    </div>
  );
};
