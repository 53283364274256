import { SortDirection } from '../graphql/types';

export const sortByKey = (
  items: Record<string, any>[],
  sortKey: string,
  type: 'string' | 'number' = 'string',
  direction: SortDirection = SortDirection.Asc
) => {
  const actualDirection = direction === SortDirection.Asc ? 1 : -1;
  return items.sort((a, b) => {
    const aValue = type === 'number' ? Number(a[sortKey]) : a[sortKey];
    const bValue = type === 'number' ? Number(b[sortKey]) : b[sortKey];
    return aValue > bValue ? actualDirection : -actualDirection;
  });
};

export const toggleValue = (arr: string[], value: string) => {
  const includes = arr.includes(value);
  return includes ? arr.filter(s => s !== value) : [...arr, value];
};
