import React, { useState, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Card, Input, Typography, Divider } from 'antd';
import { Entity, Item, ItemReview, Promo, SubmittedTool } from 'graphql/types';
import { getIconValue } from 'utils/getData';
import { MultiString } from 'nativeComponents/MultiString/MultiString';
import { SeoBox } from 'components/Common/Seo/Seo';
import { PicturesLoader } from 'nativeComponents/Common/Pictures/Pictures';
import { IconBox } from './IconBox/IconBox';
import { OptionType } from '../Common/SearchSelect/SearchSelect';
import { getInitialState } from './helpers';
import { DynamicFieldsComponent } from '../DynamicFieldsComponent';
import { ItemEditLongDescription } from './ItemEditLongDescription';
import { ItemEditShortDescription } from './ItemEditShortDescription';
import { ItemEditProsCons } from './ItemEditProsCons';
import { ItemEditReviews } from './ItemEditReviews';
import { ItemEditSideContent } from './ItemEditSideContent';
import { ItemEditSubmit } from './ItemEditSubmit';
import { ItemEditFreePlatform } from './ItemEditFreePlatform';
import { ItemSocial } from './ItemSocial/ItemSocial';
import { ItemEditPromo } from './ItemEditPromo';
import { SITE_NAMES } from 'hooks/ConfigProvider/helpers';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { ItemEditFeatures } from './ItemEditFeatures';
import { ThumbnailBox } from './ThumbnailBox/ThumbnailBox';
import { AskQuestions } from '../AskQuestions/AskQuestions';

import s from './styles.module.scss';

export const ItemEdit = ({ onSubmit, modelFields, ...props }) => {
  const [state, setState] = useState(
    getInitialState(props as Item & SubmittedTool)
  );
  const { site } = useConfig();

  const onIconUpload = (name: string, image: any) => {
    const updatedState = {
      ...state,
      [name]: image,
    };
    if (name === 'icon') {
      updatedState.iconSetManually = image.length > 1;
    }
    setState(updatedState);
  };

  const onDelete = (name: string) => {
    const updatedState = {
      ...state,
      [name]: [],
    };
    if (name === 'icon') {
      updatedState.iconSetManually = false;
    }
    setState(updatedState);
  };

  const onInputChange =
    (type?: string) =>
    ({ target: { name, value, checked } }: any) => {
      setState(prev => {
        if (!type) {
          return {
            ...prev,
            [name]: value,
          };
        }
        const isObject = prev[type] !== null && typeof prev[type] === 'object';
        return {
          ...prev,
          [type]: isObject
            ? {
                ...prev[type],
                [name]: typeof value === 'string' ? value : checked,
              }
            : value || checked,
        };
      });
    };

  const onChangeEnums =
    (key: string, name: string, multiple?: boolean) => () => {
      setState(prev => {
        const isExist = prev[key].includes(name);
        let updatedState = prev;
        if (multiple) {
          updatedState = {
            ...prev,
            [key]: isExist
              ? prev[key].filter(item => item !== name)
              : [...prev[key], name],
          };
        } else {
          updatedState = { ...prev, [key]: isExist ? [] : [name] };
        }

        return updatedState;
      });
    };

  const handleChangeSelect =
    (name: string) => (data: OptionType | OptionType[]) => {
      setState(prev => ({
        ...prev,
        [name]: prev[name] instanceof Array ? data : data[0],
      }));
    };

  const handleReviewChange = (reviews: (ItemReview & { id?: number })[]) => {
    setState(prev => ({ ...prev, reviews } as any));
  };

  const handleSubmit = async (
    redirect?: boolean,
    data = {} as Partial<Item>
  ) => {
    const payload = {
      ...state,
      icon: state.icon.filter(item => !_isEmpty(item)),
      reviews: state.reviews.map(({ id, ...review }) => review),
      skills: state.skills.map(({ id }) => id),
      redirect,
      mainCategory: state.mainCategory ?? { id: null, title: null },
      ...data,
    };
    await onSubmit(
      {
        ...payload,
        reviews: [
          // Fix: For empty review object error
          ...payload.reviews
            .filter(review => Object.values(review).length !== 0)
            .filter(review => review.title),
        ],
      },
      (updatedData: any) => {
        setState(prev => ({ ...prev, ...updatedData }));
      }
    );
  };

  const changeConsPros = (content: any) => {
    setState(prev => ({ ...prev, pros: content.pros, cons: content.cons }));
  };

  const changePromoCode = (event: any) => {
    const target = event.target;
    setState(prev => ({
      ...prev,
      promo: {
        ...prev.promo,
        [target.name]: target.value,
      },
    }));
  };

  const onChange = (value: any, name: string) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setState(getInitialState(props as Item & SubmittedTool));
  }, [props.id]);

  return (
    <Card
      bodyStyle={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
    >
      <div className="fluid flex">
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Title</Typography.Title>
            <Input
              value={state.title}
              onChange={onInputChange('title')}
              name="title"
            />
          </div>
          {modelFields.longDescription && (
            <ItemEditLongDescription
              text={state.longDescription}
              onChange={onInputChange}
            />
          )}
          {(modelFields.shortDescription || modelFields.tags) && (
            <div className="editTitle">
              {modelFields.shortDescription && (
                <ItemEditShortDescription
                  text={state.shortDescription}
                  onChange={onInputChange}
                />
              )}
              {modelFields.tags && (
                <MultiString
                  values={state.tags}
                  onChange={onChange}
                  name="tags"
                />
              )}
            </div>
          )}
          {modelFields.prosCons && (
            <ItemEditProsCons
              pros={state.pros}
              cons={state.cons}
              onChange={changeConsPros}
            />
          )}
          {site.name === SITE_NAMES.Manytools && (
            <ThumbnailBox images={state.thumbnail} handleThumbnail={setState} />
          )}
          {(modelFields.icon || modelFields.images) && (
            <div className="editTitle">
              {modelFields.icon && (
                <div className="mb-2">
                  <IconBox
                    icon={getIconValue(state.icon)}
                    onUpload={onIconUpload}
                    onDelete={onDelete}
                    url={state.link.url}
                    alternativeUrl={state.link.alternativeUrl}
                  />
                </div>
              )}
              {modelFields.images && (
                <PicturesLoader
                  siteUrl={state.link?.url}
                  pictures={state.images}
                  updatePictures={images => {
                    setState(prev => ({
                      ...prev,
                      images: [...prev.images, images],
                    }));
                  }}
                  setPictures={images => {
                    setState(prev => ({ ...prev, images }));
                  }}
                />
              )}
            </div>
          )}
          {modelFields.reviews && (
            <ItemEditReviews
              reviews={state.reviews}
              onChange={handleReviewChange}
              onSave={handleSubmit}
            />
          )}
          {modelFields.seo && state.seoCustom && (
            <div className={`editTitle ${s.seoBlock}`}>
              <SeoBox
                seo={state.seoCustom}
                onChange={onInputChange('seoCustom')}
                withImage
              />
            </div>
          )}
          {(site.name === SITE_NAMES.Manytools ||
            site.name === SITE_NAMES.ManytoolsAi) && (
            <AskQuestions faq={state.faq} />
          )}
          <ItemEditFreePlatform
            modelFields={modelFields}
            onChangeEnums={onChangeEnums}
            onInputChange={onInputChange}
            {...state}
          />
          <Divider />
          {(site.name === SITE_NAMES.Manytools ||
            site.name === SITE_NAMES.ManytoolsAi) && (
            <ItemEditPromo
              promo={state?.promo as Promo}
              onChange={changePromoCode}
            />
          )}
          <Divider />
          <ItemSocial {...(state as any)} onChange={onInputChange() as any} />
          {(site.name === SITE_NAMES.Manytools ||
            site.name === SITE_NAMES.ManytoolsAi) && (
            <ItemEditFeatures
              categories={state?.categories}
              features={state?.features}
              onSelectChange={handleChangeSelect('features')}
            />
          )}
          <DynamicFieldsComponent
            update={d => setState(prev => ({ ...prev, dynamic: d }))}
            dynamicData={state.dynamic}
            entity={Entity.Item}
          />
        </div>
        <ItemEditSideContent
          {...state}
          approve={props}
          modelFields={modelFields}
          onActiveChange={onChange}
          onChangeEnums={onChangeEnums}
          onInputChange={onInputChange}
          onSelectChange={handleChangeSelect}
        />
      </div>
      <ItemEditSubmit slug={props.slug} onSubmit={handleSubmit} />
    </Card>
  );
};
