import React, { CSSProperties, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, message, Popconfirm } from 'antd';
import { useResource } from 'hooks/useResource';
import { useRouter } from 'hooks/useRouter';

import s from './styles.module.css';

type Props = {
  onSubmit: (redirect?: boolean) => void;
  style?: CSSProperties;
  withDelete?: boolean;
  withCancel?: boolean;
  leftPostfixSlot?: React.ReactElement;
};

export const SubmitGroup = ({
  onSubmit,
  withDelete,
  withCancel = true,
  style = {},
  leftPostfixSlot,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { providers, id, resource } = useResource();
  const history = useHistory();
  const { query } = useRouter();
  const disabled = typeof query['approved'] !== 'undefined';

  const handleDelete = async () => {
    try {
      setLoading(true);
      await providers.deleteOne({ id });

      history.replace(`/${resource}`);
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    onSubmit(true);
  };

  const handleCancel = () => {};

  return (
    <div className={s.submitGroup} style={style}>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, .6)',
          }}
        />
      )}
      <div className={s.mainActions}>
        <Button
          type="primary"
          onClick={() => onSubmit()}
          className={s.fixWidthBtn}
          disabled={disabled}
        >
          Save and Continue
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          className={s.fixWidthBtn}
          disabled={disabled}
        >
          Save and close
        </Button>
        {leftPostfixSlot}
      </div>
      <div>
        {withDelete && (
          <Popconfirm title="Delete?" onConfirm={handleDelete}>
            <Button danger type="primary" className={s.deleteBtn} ghost>
              Delete
            </Button>
          </Popconfirm>
        )}
        {withCancel && (
          <Link to={`/${resource}`}>
            <Button className="danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
