import * as providers from '../config/providers';
import * as resources from '../config/resourses';

export const getFilter = (search: string): Partial<Record<string, string>> =>
  search
    .replace('?', '')
    .split('&')
    .reduce((acc, query) => {
      const [key, value] = query.split('=');
      if (!key || !value) return acc;
      return {
        ...acc,
        [key]: value,
      };
    }, {});

export const constructUrl = (
  pathname: string,
  queryObj: Record<string, string | number | boolean>
) => {
  return Object.entries(queryObj)
    .reduce((acc, [key, value]) => `${acc}${key}=${value}&`, `${pathname}?`)
    .replace(/&$/, '');
};

export const getResource = (pathname: string) => {
  const resourceName = pathname.split('?')[0].split('/')[1];
  const resource = resources[resourceName];

  return {
    provider: providers[resourceName],
    resource,
  };
};

class History {
  _lastRoute: string = '';

  get route() {
    return this._lastRoute;
  }

  set route(route) {
    this._lastRoute = route;
  }
}

export const lastHistory = new History();
