import { UserProfile } from 'components/User/Profile/Profile';

const pageResource = {
  name: 'profile',
  pageTitle: 'Manage account',
  filters: [],
  ListComponent: UserProfile,
  menuHidden: true,
};

export default pageResource;
