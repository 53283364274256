import {
  GetAuthorListDocument,
  GetAuthorDocument,
  AuthorInput,
  CreateAuthorDocument,
  UpdateAuthorDocument,
  Author,
  DeleteAuthorDocument,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';
const apolloClient = initializeApollo();

export const getList = async ({ page = 1, limit = 10, search = '' } = {}) => {
  const {
    data: {
      authors: { data, count },
    },
  } = await apolloClient.query({
    query: GetAuthorListDocument,
    fetchPolicy: 'no-cache',
    variables: {
      page: Number(page),
      limit: Number(limit),
      search,
    },
  });

  return { data, total: count };
};

export const getOne = async ({ id }) => {
  if (!id) return { data: { author: {} } };

  const {
    data: { author },
  } = await apolloClient.query({
    query: GetAuthorDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { data: author };
};

export const createOne = async (data: AuthorInput) => {
  return apolloClient.query({
    query: CreateAuthorDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: data,
    },
  });
};
export const updateOne = async (data: AuthorInput & { id: string }) => {
  const { id, ...rest } = data;
  return apolloClient.query({
    query: UpdateAuthorDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
      input: rest,
    },
  });
};

export const deleteOne = async (data: Author) => {
  await apolloClient.query({
    query: DeleteAuthorDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
