import { useMemo } from 'react';
import ApolloLinkTimeout from 'apollo-link-timeout';
import dotenv from 'dotenv';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

dotenv.config();
let apolloClient: ApolloClient<any>;

const timeoutLink = new ApolloLinkTimeout(1000 * 60 * 10); // 10 min timeout

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('adminToken');
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const createApolloClient = () =>
  new ApolloClient({
    ssrMode: false,
    link: authLink
      .concat(
        createUploadLink({
          // uri: 'https://backend.travelsites.com/query',
          uri: process.env.REACT_APP_API_URI,
        })
      )
      .concat(timeoutLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    credentials: 'include',
  });

export const initializeApollo = (initialState: any = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();
  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === 'undefined') return _apolloClient;
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
};

export const useApollo = (initialState: any) =>
  useMemo(() => initializeApollo(initialState), [initialState]);

export const filterTypeName = (
  obj: Record<string, any>
): Record<string, any> => {
  if (!obj || typeof obj !== 'object' || obj instanceof Array) return obj;
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (key === '__typename') return acc;
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};
