import React, { useState } from 'react';
import _noop from 'lodash/noop';
import moment from 'moment';
import { DatePicker, Button, Popconfirm, Input } from 'antd';
import { UIIcon } from 'nativeComponents/UI/UIIcons/UIIcon';
import {HTMLEditor} from 'nativeComponents/Common/HTMLEditor/HTMLEditor';
import { ItemReviewFooter } from '../ItemReviewFooter/ItemReviewFooter';
import { ItemReview, PictureDictionary } from 'graphql/types';
import {getIconValue, getImageValue} from 'utils/getData';

import s from '../styles.module.css';

type Props = ItemReview & {
  onChange: (a: { target: { name: string; value: any } }) => void;
  onDelete: () => void;
  onImageUpload: any;
  onDeleteImage: any;
};

export const ItemReviewForm: React.FC<Partial<Props>> = props => {
  const {
    title = '',
    description = '',
    authorName = '',
    authorAvatar,
    authorCompany = '',
    authorPosition = '',
    date = '',
    companyLink = '',
    onChange = _noop,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const handleDateChange = (date: Date) => {
    onChange({ target: { name: 'date', value: new Date(date) } });
  };

  const handleImageUpload = (name: string, image: PictureDictionary) => {
    onChange({ target: { name, value: getIconValue(image) } });
  };

  const handleImageDelete = () => {
    onChange({ target: { name: 'authorAvatar', value: '' } });
  };

  const toggleOpen = () => setOpen(true);

  return (
    <div className={s.reviewItem}>
      <div className={s.reviewItemHead}>
        <div className={s.datePicker} role="presentation" onClick={toggleOpen}>
          {!date && <span className={s.datePickerTitle}>Select date</span>}
          {!!date && (
            <span className={s.datePickerDate}>
              {moment(date).format('YYYY-MM-DD')}
            </span>
          )}
          <UIIcon icon="calendar" className={s.calendarIcon} />
          <DatePicker
            id={'date-picker-antd'}
            size="small"
            open={isOpen}
            // @ts-ignore
            onChange={handleDateChange}
            onOpenChange={setOpen}
            bordered={false}
            placeholder=""
            suffixIcon={null}
            value={moment(date || new Date())}
          />
        </div>
      </div>

      <Input
        className={`mt-4 mb-2`}
        placeholder="Title..."
        name="title"
        value={title}
        bordered={true}
        onChange={onChange}
      />
      <HTMLEditor value={description} onChange={onChange} currentLanguage={""} name={'description'} />
      {/*<Input.TextArea*/}
      {/*  placeholder="Description..."*/}
      {/*  name="description"*/}
      {/*  rows={7}*/}
      {/*  value={description}*/}
      {/*  bordered={true}*/}
      {/*  onChange={onChange}*/}
      {/*/>*/}

      <ItemReviewFooter
        authorName={authorName}
        authorAvatar={authorAvatar}
        authorCompany={authorCompany as string}
        authorPosition={authorPosition as string}
        companyLink={companyLink as string}
        onImageChange={handleImageUpload as any}
        onImageDelete={handleImageDelete}
        onChange={onChange}
      />
    </div>
  );
};
