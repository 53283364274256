import { Typography } from 'antd';
import { HTMLEditor } from 'nativeComponents/Common/HTMLEditor/HTMLEditor';
import React from 'react';
``;

export const ItemEditLongDescription = ({
  text,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="editTitle">
      <Typography.Title level={5}>Long description</Typography.Title>
      <HTMLEditor
        value={text}
        currentLanguage=""
        onChange={onChange('longDescription')}
      />
    </div>
  );
};
