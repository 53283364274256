import React, {useMemo} from 'react';
import {Select, Typography} from 'antd';
import {Category} from 'graphql/types';
import {OptionType} from '../Common/SearchSelect/SearchSelect';
import {ID} from 'graphql-ws';

type Props = {
    categories: Category[];
    features: any;
    onSelectChange: (value: any[]) => void;
};

export const ItemEditFeatures = React.memo(({categories, features, onSelectChange}: Props) => {


    const options = useMemo(() => {
        const flatCategories = categories?.map(({features}) => features).flat();
        return flatCategories?.map(item => (
            {label: item?.title, value: item?.title, id: item?.id as ID}
        ));
    }, [categories]);

    const handleChange = (value: string[] | null, option: OptionType) => {
        const editedOption = { title: option.title || option.label, id: option.id };
        const resultOptions = [...features, editedOption];
        onSelectChange(resultOptions?.filter(item => !!item && !!item.id));
    };

    const handleDeselect = (value: string, option: OptionType) => {
        onSelectChange(features?.filter(item => item.id !== option.id).filter(({id}) => !!id))
    }

    const valueSelect = features?.filter(item => !!item).map(({title}) => title)
    return <div className={"mb-4"}>
        <Typography.Title level={5}>Features</Typography.Title>
        <Select
            style={{width: '100%'}}
            value={valueSelect}
            options={options}
            mode={"multiple"}
            placeholder={'Select features'}
            onSelect={handleChange}
            onDeselect={handleDeselect}
        />
    </div>;
});

ItemEditFeatures.displayName = 'ItemEditFeatures';
