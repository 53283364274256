import { Switch, Tag } from 'antd';
import React from 'react';
import { BookOutlined } from '@ant-design/icons';
import { BlogPostEdit } from 'components/BlogPost/BlogPostEdit';
import { TitleWithImage } from 'nativeComponents/List/ListRowTitle/TitleWithImage';
import { SortBy, SortDirection } from 'graphql/types';
import { filters } from './filters';
import { sort } from './sort';

const postResource = {
  name: 'posts',
  menuIcon: BookOutlined,
  pageTitle: 'Posts',
  parent: 'Blog',
  ...filters,
  sort,
  editComponent: BlogPostEdit,
  list: {
    actions: ['active', 'delete'],
    columns: [
      {
        title: 'Title',
        key: 'title',
        render: (record, data) => {
          return (
            <TitleWithImage data={data} record={record} imageKey="thumb" />
          );
        },
      },
      {
        title: 'Categories',
        key: 'categories',
        render: record => {
          return (
            <div>
              {record.map(({ title }) => (
                <Tag key={title} className="mr-1">
                  {title}
                </Tag>
              ))}
            </div>
          );
        },
      },
      // New Labels column
      {
        title: 'Labels',
        key: 'labels',
        render: record => {
          return (
            <div>
              {record.map(({ name }) => (
                <Tag key={name} className="mr-1">
                  {name}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Date',
        key: 'date',
        sort: true,
        filter: true,
        render: record => {
          return <div>{new Date(record).toDateString()}</div>;
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: (record, data, { onChangeActive }) => {
          return (
            <Switch
              checked={data.active}
              onChange={checked => {
                onChangeActive(data.id, checked);
              }}
            />
          );
        },
      },
    ],
  },
};

export default postResource;
