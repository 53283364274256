import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Collapse, message, Checkbox, Typography } from 'antd';
import { useRouter } from 'hooks/useRouter';
import { debounce, clearDebounceTimer } from 'utils/handlers';
import {
  getFilterFetcher,
  getItemsFromData,
  sortByActive,
  getKekItem,
} from './helpers';

import s from '../styles.module.css';

const { Link } = Typography;

type Props = {
  placeholder?: string;
  value: string;
  name: string;
  sign?: string;
  disabled?: boolean;
  apply: (query: Record<string, any>) => void;
};

export const SearchFilter = ({
  placeholder = 'Filter...',
  value: filterValue,
  name,
  disabled,
  sign = 'slug',
  apply,
}: Props) => {
  const { query } = useRouter();
  const apolloClient = useApolloClient();

  const [state, setState] = useState({
    loading: false,
    search: '',
    items: [] as any,
  });

  const chosenItems = query[filterValue]
    ? query[filterValue].toString().split(',')
    : [];

  const getItems = async (variables: Record<string, any>) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: getFilterFetcher(filterValue) as any,
        variables,
      });

      setState(prev => ({
        ...prev,
        items: getItemsFromData(data, chosenItems, prev.items, sign),
        loading: false,
      }));
    } catch (e) {
      message.error(e.message);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChange = async ({ target: { value } }) => {
    setState(prev => ({
      ...prev,
      search: value,
    }));
  };

  const handleChooseFilter = (kek: string) => () => {
    const isExist = chosenItems.find(item => item.includes(kek));

    const chosenFilters = isExist
      ? chosenItems.filter(item => item !== kek)
      : [...chosenItems, kek];

    setState(prev => ({
      ...prev,
      items: sortByActive(prev.items, chosenFilters, sign),
    }));

    apply({
      ...query,
      [filterValue]: chosenFilters.join(','),
    });
  };

  const dropState = () => {
    clearDebounceTimer();
    setState(prev => ({
      ...prev,
      items: [],
      search: '',
    }));
    apply({
      ...query,
      [filterValue]: '',
    });
  };

  useEffect(() => {
    if (state.search) {
      debounce(getItems, 400)({ search: state.search });
    } else {
      dropState();
    }
  }, [state.search]);

  useEffect(() => {
    if (query[filterValue]) {
      getItems({ search: '' });
    }
  }, []);

  return (
    <Collapse
      expandIconPosition="right"
      ghost
      expandIcon={props => {
        return (
          <DownOutlined
            {...props}
            style={{
              transition: 'transform .3s',
              transform: props.isActive ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        );
      }}
    >
      <Collapse.Panel
        header={name}
        key={filterValue}
        className={s.collapseItem}
        disabled={disabled}
      >
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          className={s.searchField}
          value={state.search}
          prefix={<SearchOutlined style={{ marginRight: 'var(--space-1)' }} />}
        />
        {!!state.items.length && (
          <div className={`${s.itemsList}`}>
            {state.items.map(item => {
              const itemKek = getKekItem(item, sign);
              return (
                <Checkbox
                  key={item.id}
                  checked={chosenItems.includes(itemKek)}
                  onClick={handleChooseFilter(itemKek)}
                  style={{
                    marginLeft: 0,
                    marginBottom: 10,
                  }}
                >
                  {item.title}
                </Checkbox>
              );
            })}
          </div>
        )}
        {!!state.items.length && (
          <Link onClick={dropState} strong>
            Clear
          </Link>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
