import { IconStyle } from '@fortawesome/fontawesome-svg-core';
import { IconLight } from '../types';

export const getIconStyle = (
  icon: IconLight,
  mode: 'pro' | 'free' = 'pro'
): IconStyle[] => {
  const style = icon.familyStylesByLicense[mode].map(el => el.style);
  return Array.from(new Set(style).values());
};
