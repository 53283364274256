import React from "react";
import classnames from 'classnames';
import { Switch, Typography } from "antd";

import s from './styles.module.css';

type Props = {
	className?: string;
	active?: boolean;
	onChange: (checked: boolean) => void;
};

export const ActiveState = ({ className, active, onChange }: Props) => {
	return (
		<div className={classnames(className, s.activeState, 'sideContentItem')}>
			<Typography.Title level={5} style={{ marginBottom: 0 }}>Active state</Typography.Title>
			<Switch
				checked={active}
				onChange={onChange}
			/>
		</div>
	);
};
