import { useMemo } from 'react';
import { Dropdown, Checkbox, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useRouter } from 'hooks/useRouter';

import s from './styles.module.css';

type Props = {
  name: string;
  disabled?: boolean;
  variants: {
    name: string;
    value: string;
    status: any;
  }[];
  apply: (query: Record<string, any>) => void;
};

export const Filter = ({ name, variants, apply, disabled }: Props) => {
  const { query } = useRouter();
  const parsedQuery = {
    ...query,
    active:
      typeof query.active === 'boolean' ? String(query.active) : undefined,
  };

  const handleFilter =
    (value: string, status: any) =>
    ({ target: { checked } }) => {
      const filter: any = parsedQuery;
      filter[value] = checked ? status : '';

      apply(filter);
    };

  const resetFilters = () => {
    const emptyFilters = variants.reduce(
      (acc, variant) => ({
        ...acc,
        [variant.value]: '',
      }),
      {}
    );

    apply({ ...parsedQuery, ...emptyFilters });
  };

  const filtersCount = useMemo(() => {
    return Object.entries(parsedQuery).filter(([key, value]) =>
      variants.find(
        variant => variant.status === value && variant.value === key
      )
    ).length;
  }, [parsedQuery]);

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu className={s.dropDownMenuFilter}>
          {variants.map(({ name, value, status }) => (
            <Menu.Item key={name} className={s.item}>
              <Checkbox
                checked={parsedQuery[value] === status}
                onChange={handleFilter(value, status)}
                style={{
                  width: '100%',
                }}
                disabled={disabled}
              >
                {name}
              </Checkbox>
            </Menu.Item>
          ))}
          <Menu.Item className={`${s.item} ${s.clear}`}>
            <Button
              type="link"
              className="pa-0"
              onClick={resetFilters}
              disabled={disabled}
            >
              Clear all
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <div className={s.filter}>
        <span>{`${name} ${!filtersCount ? '' : `(${filtersCount})`}`}</span>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};
