import {
  GetPagesDocument,
  GetPageDocument,
  PageInput,
  PageCreateDocument,
  PageUpdateDocument,
  PageDeleteDocument,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';
import { ProviderParams } from 'type';

const apolloClient = initializeApollo();

export const getList = async (data: ProviderParams) => {
  const {
    data: { pages },
  } = await apolloClient.query({
    query: GetPagesDocument,
    fetchPolicy: 'no-cache',
    variables: {
      search: data.search,
    },
  });

  return { data: pages };
};

export const getOne = async ({ id, slug }) => {
  if (!id) return { data: {} };

  const {
    data: { page },
  } = await apolloClient.query({
    query: GetPageDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
      slug,
    },
  });

  return { data: page };
};

export const createOne = async (data: PageInput) => {
  return apolloClient.mutate({
    mutation: PageCreateDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: data,
    },
  });
};
export const updateOne = async (data: PageInput & { id: string }) => {
  const { id, ...input } = data;
  return apolloClient.mutate({
    mutation: PageUpdateDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
      input,
    },
  });
};

export const deleteOne = async (data: { id: string }) => {
  await apolloClient.mutate({
    mutation: PageDeleteDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
