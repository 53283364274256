import { IconLight } from '../types';

export const swapDuotoneFamilyToStyle = (icons: IconLight[]): IconLight[] => {
  const swappedIcons: IconLight[] = [];

  icons.forEach(el => {
    const duotoneFamilyStylesIndex = el.familyStylesByLicense.pro.findIndex(
      el => el.family === 'duotone'
    );

    if (duotoneFamilyStylesIndex !== -1) {
      const modifiedIcon: IconLight = {
        ...el,
        familyStylesByLicense: {
          ...el.familyStylesByLicense,
          pro: [
            ...el.familyStylesByLicense.pro.slice(0, duotoneFamilyStylesIndex),
            { family: 'classic', style: 'duotone' },
            ...el.familyStylesByLicense.pro.slice(duotoneFamilyStylesIndex + 1)
          ]
        }
      };
      swappedIcons.push(modifiedIcon);
    } else {
      swappedIcons.push(el);
    }
  });

  return swappedIcons;
};
