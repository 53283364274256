import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<
  { active: boolean } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
>;

export const IsLoading = React.forwardRef<HTMLDivElement, Props>(
  function IsLoading({ children, className, ...props }) {
    return (
      <div
        className={classNames(
          'w-100 d-flex align-items-center justify-content-center',
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);
