import {
  GetCurrentItemDocument,
  GetItemsDocument,
  CreateItemDocument,
  UpdateItemDocument,
  Item,
  DeleteItemDocument,
  GetItemsQuery,
  GetItemsQueryVariables,
  SortBy,
  SortDirection,
  GetDeletedItemsQuery,
  GetDeletedItemsQueryVariables,
  GetDeletedItemsDocument,
  RestoreItemMutationVariables,
  RestoreItemMutation,
  RestoreItemDocument,
  GetSubmittedToolsDocument,
  GetSubmittedToolsQuery,
  GetSubmittedToolsQueryVariables,
  GetSubmittedToolQuery,
  GetSubmittedToolDocument,
} from 'graphql/types';
import { getUpdateItemPayload } from 'components/Item/helpers';
import { initializeApollo } from 'utils/apollo';

const apolloClient = initializeApollo();

export const getList = async (
  {
    page = 1,
    limit = 10,
    sort = SortBy.CreationDate,
    sortDirection = SortDirection.Desc,
    search,
    filters = {},
  } = {} as any
) => {
  if (filters?.uploadedByUser) {
    try {
      const { data } = await apolloClient.query<
        GetSubmittedToolsQuery,
        GetSubmittedToolsQueryVariables
      >({
        query: GetSubmittedToolsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          page: Number(filters.page),
          limit: Number(limit),
          search,
        },
      });
      return {
        data: data.submittedTools.data,
        total: data.submittedTools.count,
      };
    } catch (e) {
      return {
        data: {},
        total: 0,
      };
    }
  } else {
    try {
      const {
        data: { items },
      } = await apolloClient.query<GetItemsQuery, GetItemsQueryVariables>({
        query: GetItemsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          page: Number(page),
          limit: Number(limit),
          search,
          sortBy: sort,
          sortByDirection: sortDirection,
          ...filters,
        },
      });

      const { data, count } = items;
      return { data, total: count };
    } catch (e) {
      return {
        data: {},
        total: 0,
      };
    }
  }
};

export const getDeletedList = async (
  variables: GetDeletedItemsQueryVariables = {}
) => {
  try {
    const {
      data: { deletedItems },
    } = await apolloClient.query<
      GetDeletedItemsQuery,
      GetDeletedItemsQueryVariables
    >({
      query: GetDeletedItemsDocument,
      fetchPolicy: 'no-cache',
      variables,
    });

    return { data: deletedItems, total: deletedItems.length };
  } catch (e) {
    return {
      data: {},
      total: 0,
    };
  }
};

export const restoreItem = async (id: string) => {
  const {
    data: { restoreItem },
  } = await apolloClient.query<
    RestoreItemMutation,
    RestoreItemMutationVariables
  >({
    query: RestoreItemDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { data: restoreItem };
};

export const getOne = async ({ id, ...props }) => {
  if (!id) return { data: { item: {} } };
  if (typeof props?.['approved'] !== 'undefined') {
    const {
      data: { submittedTool },
    } = await apolloClient.query({
      query: GetSubmittedToolDocument,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
    return { data: submittedTool };
  }
  const {
    data: { item },
  } = await apolloClient.query({
    query: GetCurrentItemDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });
  return { data: item };
};

export const createOne = async (data: any) => {
  const { id, ...rest } = getUpdateItemPayload(data);
  return apolloClient.query({
    query: CreateItemDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: rest,
    },
  });
};
export const updateOne = async data => {
  const { id, ...rest } = getUpdateItemPayload(data);
  return apolloClient.query({
    query: UpdateItemDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
      input: rest,
    },
  });
};

export const deleteOne = async (data: Item) => {
  await apolloClient.query({
    query: DeleteItemDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
