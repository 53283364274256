import React from 'react';
import { MoneyCollectOutlined } from '@ant-design/icons';
import { Seo } from 'components/Settings/Seo/Seo';

const seo = {
  name: 'seo',
  pageTitle: 'Seo settings',
  parent: 'Settings',
  globalSearch: false,
  menuIcon: MoneyCollectOutlined,
  ListComponent: Seo,
};

export default seo;
