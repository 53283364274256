import React, { useState } from 'react';
import { Card, Typography, Input, Tag } from 'antd';
import {
  SearchSelect,
  OptionType,
} from 'components/Common/SearchSelect/SearchSelect';
import { handleInputChange } from 'utils/handlers';
import { getIconValue } from 'utils/getData';
import { UIUpload } from '../../nativeComponents/UI';
import { SEARCH_SELECT_TYPES } from '../Common/SearchSelect/helpers';
import { SubmitGroup } from '../Common/SubmitGroup/SubmitGroup';
import { Entity } from '../../graphql/types';
import { DynamicFieldsComponent } from '../DynamicFieldsComponent';
import { getDynamicModelsPayload } from '../DynamicFieldsComponent/helpers';

export const AuthorEdit = (props = {} as any) => {
  const { user, name, lastName, avatar, onSubmit, posts = [], dynamic } = props;
  const [state, setState] = useState({
    user: user || {},
    name,
    lastName,
    avatar: avatar || [],
    dynamic: dynamic || [],
  });

  const handleChangeSelect = (name: string) => (data: OptionType) => {
    setState(prev => ({
      ...prev,
      [name]: { id: data[0].id, email: data[0].title },
    }));
  };

  const onImageUpload = (name: string, image: any) => {
    setState(prev => ({ ...prev, [name]: image }));
  };
  const onDelete = (name: string, index: number) => {
    setState(prev => ({
      ...prev,
      [name]: [],
    }));
  };

  const handleSubmit = (redirect?: boolean) => {
    onSubmit({
      ...state,
      redirect,
      user: state.user.id,
      dynamic: getDynamicModelsPayload(state.dynamic),
    });
  };

  return (
    <Card
      bodyStyle={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
    >
      <div className="fluid flex">
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Author name</Typography.Title>
            <Input
              placeholder="Select author name"
              value={state.name}
              onChange={handleInputChange(setState)}
              name="name"
            />
          </div>
          <div className="editTitle">
            <Typography.Title level={5}>Authors last name</Typography.Title>
            <Input
              placeholder="Select author's last name"
              value={state.lastName}
              onChange={handleInputChange(setState)}
              name="lastName"
            />
          </div>
          <div className="editTitle">
            <Typography.Title level={5}>Avatar</Typography.Title>
            <UIUpload
              previews={getIconValue(state.avatar)}
              onChange={onImageUpload}
              name="avatar"
              type="icon"
              onDelete={onDelete}
            />
          </div>
          {!!posts.length && (
            <div className="editTitle last">
              <Typography.Title level={5}>Posts</Typography.Title>
              {posts.map(({ title }) => (
                <Tag key={title} className="mb-1">
                  {title}
                </Tag>
              ))}
            </div>
          )}
          <DynamicFieldsComponent
            update={d => setState(prev => ({ ...prev, dynamic: d }))}
            dynamicData={state.dynamic}
            entity={Entity.Author}
          />
        </div>
        <div className="sideContent">
          <div className="sideContentItem">
            <Typography.Title level={4}>Staff user</Typography.Title>
            <SearchSelect
              placeholder="Select staff user"
              values={[{ id: state.user.id, title: state.user.email }]}
              onChange={handleChangeSelect('user') as any}
              name={SEARCH_SELECT_TYPES.STAFF}
              optionPickKey="email"
            />
          </div>
        </div>
      </div>
      <SubmitGroup onSubmit={handleSubmit} />
    </Card>
  );
};
