const Smile = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
        fill="#D0D3D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42771 14.3505C6.59997 14.2511 6.80468 14.2241 6.99681 14.2756C7.18894 14.3271 7.35275 14.4528 7.45221 14.625C7.91288 15.4235 8.57579 16.0866 9.37421 16.5474C10.1726 17.0082 11.0784 17.2505 12.0002 17.25C12.9221 17.2505 13.8278 17.0082 14.6262 16.5474C15.4246 16.0866 16.0875 15.4235 16.5482 14.625C16.5971 14.539 16.6625 14.4636 16.7407 14.403C16.8189 14.3424 16.9082 14.2978 17.0037 14.2719C17.0991 14.246 17.1988 14.2392 17.2969 14.252C17.395 14.2647 17.4896 14.2967 17.5752 14.3462C17.6609 14.3956 17.7359 14.4615 17.796 14.5401C17.8561 14.6187 17.9 14.7084 17.9253 14.804C17.9506 14.8996 17.9567 14.9993 17.9433 15.0973C17.9299 15.1953 17.8972 15.2897 17.8472 15.375C17.2549 16.4015 16.4026 17.2539 15.3762 17.8464C14.3498 18.4389 13.1854 18.7505 12.0002 18.75C10.8151 18.7505 9.65066 18.4389 8.62423 17.8464C7.59779 17.2539 6.74553 16.4015 6.15321 15.375C6.05376 15.2027 6.02681 14.998 6.07829 14.8059C6.12977 14.6138 6.25546 14.45 6.42771 14.3505Z"
        fill="#D0D3D9"
      />
      <path
        d="M10.5 9.75C10.5 10.992 9.828 12 9 12C8.172 12 7.5 10.992 7.5 9.75C7.5 8.508 8.172 7.5 9 7.5C9.828 7.5 10.5 8.508 10.5 9.75ZM16.5 9.75C16.5 10.992 15.828 12 15 12C14.172 12 13.5 10.992 13.5 9.75C13.5 8.508 14.172 7.5 15 7.5C15.828 7.5 16.5 8.508 16.5 9.75Z"
        fill="#D0D3D9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Smile;
