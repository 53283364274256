import { Form, Space, Input, Button, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';

export const DynamicModelFromVariants = () => {
  return (
    <Form.List name="variants" initialValue={['']}>
      {(fields, { add, remove }) => (
        <>
          <Typography.Title level={5}>Variants</Typography.Title>
          {fields.map(field => (
            <Space key={field.key} align="baseline" className="fluid">
              <Form.Item name={[field.name, 'id']} hidden />
              <Form.Item
                {...field}
                name={[field.name, 'value']}
                rules={[{ required: true, message: 'Missing value' }]}
              >
                <Input />
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(field.name)} />
            </Space>
          ))}

          <Form.Item>
            <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
              Add variant
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
