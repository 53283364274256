import {
  Category,
  CreateCategoryDocument,
  DeleteCategoryDocument,
  GetCategoriesListDocument,
  GetCategoriesListQuery,
  GetCategoriesListQueryVariables,
  GetCategoriesSuperLightListDocument,
  GetCurrentCategoryDocument,
  Skill,
  SortBy,
  SortDirection,
  UpdateCategoryDocument,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';
import { getUpdateCategoryPayload } from 'components/Categories/helpers';

const apolloClient = initializeApollo();

export const getLightList = async (
  {
    page = 1,
    limit = 10,
    sort = SortBy.CreationDate,
    sortDirection = SortDirection.Desc,
    search,
    filters = {},
  } = {} as any
) => {
  const {
    data: { categories },
  } = await apolloClient.query<
    GetCategoriesListQuery,
    GetCategoriesListQueryVariables
  >({
    query: GetCategoriesSuperLightListDocument,
    fetchPolicy: 'no-cache',
    variables: {
      page: Number(page),
      limit: Number(limit),
      search,
      sortBy: sort,
      sortByDirection: sortDirection,
      ...filters,
    },
  });

  const { data, count } = categories;
  return { data, total: count };
};

export const getList = async (
  {
    page = 1,
    limit = 10,
    sort = SortBy.CreationDate,
    sortDirection = SortDirection.Desc,
    search,
    filters = {},
  } = {} as any
) => {
  const {
    data: { categories },
  } = await apolloClient.query<
    GetCategoriesListQuery,
    GetCategoriesListQueryVariables
  >({
    query: GetCategoriesListDocument,
    fetchPolicy: 'no-cache',
    variables: {
      page: Number(page),
      limit: Number(limit),
      search,
      sortBy: sort,
      sortByDirection: sortDirection,
      ...filters,
    },
  });

  const { data, count } = categories;
  return { data, total: count };
};

export const getOne = async ({ id }) => {
  if (!id) {
    return { data: { category: {} } };
  }

  const {
    data: { category },
  } = await apolloClient.query({
    query: GetCurrentCategoryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { data: category };
};

export const createOne = async (data: Category) => {
  return apolloClient.query({
    query: CreateCategoryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: getUpdateCategoryPayload(data),
    },
  });
};
export const updateOne = async (data: Category) => {
  return apolloClient.query({
    query: UpdateCategoryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
      input: getUpdateCategoryPayload(data),
    },
  });
};

export const deleteOne = async (data: Category) => {
  await apolloClient.query({
    query: DeleteCategoryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
