/* eslint-disable */

import { Breadcrumbs } from 'nativeComponents/Breadcrumbs/Breadcrumbs';
import { Avatar, Button, Card, Form, Input, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useEffect, useState, ChangeEvent, useRef } from 'react';
import { useUser } from '../../../hooks/useUser';
import {
  PictureDictionaryInput,
  UploadInput,
  useUpdateProfileMutation,
  useUploadImageMutation,
} from '../../../graphql/types';

type UserInput = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: PictureDictionaryInput | null;
};

export const UserProfile = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [saveImage, { loading: imageLoading }] = useUploadImageMutation({
    onError: e => {
      message.error(e.message);
    },
    onCompleted: data => {
      setState({
        ...state,
        avatar: data?.uploadImage?.Sizes.find(x => x.size === 128),
      });
    },
  });

  const [save, { loading }] = useUpdateProfileMutation({
    onError: e => {
      message.error(e.message);
    },
    onCompleted: data => {
      message.success('Updated :)');
    },
  });

  const upload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file) return;
    const input: UploadInput = {
      sizes: [{ width: 128, aspectRatio: 1 }],
      file,
    };
    return saveImage({ variables: { input } });
  };

  const [emailNeedsChanging, setEmailNeedsChanging] = useState(false);

  const { user } = useUser();

  const [state, setState] = useState<UserInput | null>(null);

  useEffect(() => {
    if (user) {
      setState({
        email: user.email,
        firstName: user.firstName || undefined,
        lastName: user.lastName || undefined,
        avatar: user.avatar ? user.avatar[0] : undefined,
      });
    }
  }, [user]);

  return (
    <div style={{ width: '100%', minHeight: '80vh' }}>
      <Breadcrumbs />
      <Card style={{ height: '100%' }}>
        <h2>Account info</h2>
        <Avatar
          size={64}
          src={state?.avatar?.value || null}
          icon={<UserOutlined />}
          style={{ background: '#DCE6EC', marginRight: '20px' }}
        />
        <input ref={inputRef} type="file" hidden onChange={upload} />
        <Button
          loading={imageLoading}
          disabled={imageLoading}
          onClick={() => inputRef.current?.click()}
          style={{
            color: '#0F5EF7',
            borderColor: '#0F5EF7',
            marginRight: '10px',
          }}
        >
          Upload photo
        </Button>
        <Button
          onClick={() => setState({ ...state, avatar: null })}
          style={{ color: '#0F5EF7', borderColor: '#0F5EF7' }}
        >
          Remove photo
        </Button>
        <Form className={'mt-4 flex'} layout={'horizontal'}>
          <Form.Item label={'First name'} className={'flex-column align-start'}>
            <Input
              value={state?.firstName || ''}
              onChange={e => setState({ ...state, firstName: e.target.value })}
              style={{ height: '40px', width: '320px' }}
              className={'mr-4'}
            />
          </Form.Item>
          <Form.Item label={'Last name'} className={'flex-column align-start'}>
            <Input
              onChange={e => setState({ ...state, lastName: e.target.value })}
              value={state?.lastName || ''}
              style={{ height: '40px', width: '320px' }}
            />
          </Form.Item>
        </Form>
        <p style={{ color: '#8C8C8C' }}>
          Use your first and last name as they appear on your
          government-issuedID
        </p>
        <Form.Item label={'Email'} className={'flex-column align-start'}>
          <div style={{ alignItems: 'center' }} className={'flex'}>
            {emailNeedsChanging ? (
              <Input
                onChange={e => setState({ ...state, email: e.target.value })}
                style={{ height: '40px', width: '320px' }}
                value={state?.email || ''}
              />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {state?.email || ''}
              </div>
            )}
            <Button
              onClick={() => setEmailNeedsChanging(!emailNeedsChanging)}
              type={'link'}
              style={{ marginLeft: '8px' }}
              className={'pa-0'}
            >
              {emailNeedsChanging ? 'Ok' : 'Change email'}
            </Button>
          </div>
        </Form.Item>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => save({ variables: { ...state } })}
          type={'primary'}
          style={{ height: '32px', width: '196px' }}
        >
          Save changes
        </Button>
      </Card>
    </div>
  );
};
