import { Typography } from 'antd';
import { CSVUploader } from 'components/CSVUploader/CSVUploader';
import { CSVProvider } from './CSVProvider/CSVProvider';
import { CSVSortProvider } from './CSVSortProvider/CSVSortProvider';

const { Title } = Typography;

export const CSVSettings = () => {
  return (
    <CSVProvider>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <Title level={5}>Handle items</Title>
          <CSVUploader />
        </div>
        <div className="mt-3">
          <Title level={5}>Handle sorting</Title>
          <CSVSortProvider />
        </div>
      </div>
    </CSVProvider>
  );
};
