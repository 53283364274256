import React from 'react';
import { Typography } from 'antd';
import { Version } from 'nativeComponents/Version/Version';
import { useConfig } from 'hooks/ConfigProvider/useConfig';

import s from './styles.module.css';

export const SideMenuHead = React.memo(() => {
  const { site: siteConfig } = useConfig();

  return (
    <div className={`${s.triggerLink} pa-4 flex align-center justify-between`}>
      <div className="flex align-center">
        <img
          className="mr-2"
          src={siteConfig.icon}
          style={{ width: 30, minWidth: 30, height: 30 }}
        />
        <div className="flex-column">
          <Typography.Title level={4} style={{ margin: 0 }}>
            {siteConfig.name}
          </Typography.Title>
          <Version />
        </div>
      </div>
    </div>
  );
});
