import _get from 'lodash/get';
import {DynamicData, Picture, PictureDictionary} from 'graphql/types';

type GetIdsType =
    | { id?: string | number; [key: string]: any }[]
    | string[]
    | { id?: string | number; [key: string]: any }
    | string;
export const getIds = (data: GetIdsType) => {
    if (data instanceof Array) {
        return data
            .map(item => (typeof item === 'string' ? item : (item || {}).id))
            .filter(item => !!item);
    }

    const isPrimitive = typeof data === 'string' || typeof data === 'number';
    return isPrimitive ? data : (data as any).id;
};

export const filterStateWithModelFields = (
    state: Record<string, any>,
    modelFields: Record<string, boolean>
): Record<string, any> => {
    const exclude = Object.entries(modelFields)
        .filter(([_, value]) => !value)
        .map(([key]) => key);
    return Object.entries(state).reduce((acc, [key, value]) => {
        if (exclude.includes(key)) return acc;
        return {...acc, [key]: value};
    }, {});
};

export const getImageValue = (image: Picture[] | any, size?: number) => {
    const sizes: Picture['Sizes'] = _get(image, 'Sizes', []);
    if (size) {
        const result = sizes.find(item => item.size === size);
        return result ? result.value : (sizes[0] || {}).value || '';
    }
    return (sizes[0] || {}).value || '';
};

export const getIconValue = (icon: PictureDictionary[] | any) => {
    return _get(icon, '[0].value', '');
};

export const getIconUrl = (icon: PictureDictionary[] | any) => {
    return _get(icon, '[0].url', '');
};

export const getDynamicPayload = (data: DynamicData[]) =>
    data.map(dd => ({
        data: dd.data,
        modelID: dd.model.id,
    }));
