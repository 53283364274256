export const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  const fr = new FileReader();
  return new Promise(res => {
    fr.onload = async function () {
      const img = new Image();
      img.onload = async function () {
        res({ width: img.width, height: img.height });
      };
      img.src = fr.result as string;
    };
    fr.readAsDataURL(file);
  });
};
