import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Key } from 'antd/es/table/interface';
import { constructUrl, getFilter } from 'utils/route';

import s from './styles.module.css';

type Props = {
  data: any;
  total: number;
  options: any;
  columns: ColumnsType<any>;
  checked: string[];
  onSelect?: (selectedRowKeys: Key[], selectedRows: any[]) => void;
};

export const ListTable = ({
  data,
  total,
  options,
  columns,
  onSelect,
  checked,
}: Props) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { page: defaultPage = 1, ...restSearch } = getFilter(search);
  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        className={s.tableList}
        rowClassName={record => {
          return record.active === false ? s.disabledRow : '';
        }}
        rowSelection={
          onSelect
            ? {
                selectedRowKeys: checked,
                onChange: onSelect,
              }
            : undefined
        }
        rowKey={({ id }) => id}
        pagination={{
          defaultPageSize: 10,
          current: Number(defaultPage),
          showSizeChanger: true,
          total,
          onChange: (page: number, pageSize: number = 10) => {
            history.push(
              constructUrl(pathname, { ...restSearch, page, limit: pageSize })
            );
          },
          showQuickJumper: true,
          ...(options.list ? options.list.pagination : {}),
        }}
      />
    </>
  );
};
