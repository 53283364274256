import React from 'react';
import { UIUpload } from 'nativeComponents/UI/UIUpload/UIUpload';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import { getImageValue } from 'utils/getData';
import {
  DynamicFieldModel,
  DynamicModel,
  PictureDictionary,
} from 'graphql/types';
import { getData } from './helpers';

type Props = {
  onUpdate: (a: any) => void;
  dynamicDataParser: DynamicDataParser | null;
  model: DynamicModel;
  field: DynamicFieldModel;
  index?: number;
};

export const IMageDynamicComponent = ({
  onUpdate,
  field,
  model,
  dynamicDataParser,
  index,
}: Props) => {
  const handleChange = (name: string, value: PictureDictionary[]) => {
    onUpdate(
      dynamicDataParser!.changeData(model, field, getImageValue(value), index)
    );
  };

  const onDelete = () => {
    onUpdate(dynamicDataParser!.changeData(model, field, '', index));
  };

  return (
    <UIUpload
      previews={getData(dynamicDataParser?.getFieldData(model, field, index))}
      onChange={handleChange}
      onDelete={onDelete}
      name="image"
      type="image"
    />
  );
};
