import { Typography } from 'antd';

import s from './styles.module.scss';

const { Text } = Typography;

type Props = {
  item: { title: string; value: any };
  opened: Record<string, string[]>;
  onShow: (data: ['one' | 'second', string]) => () => void;
  error?: string;
};

export const CVSCompareContentItem = ({ item, error }: Props) => {
  return (
    <div className={s.compareContentItem}>
      <div className={s.compareContentItemHead}>
        <span className={s.subItemTitle}>{item.title}</span>
      </div>
      <p>{item.value}</p>
      {!!error && (
        <Text type="danger" className={s.error}>
          {error}
        </Text>
      )}
    </div>
  );
};
