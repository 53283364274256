import React from 'react';
import { Tag } from 'antd';

export const tagRender = props => {
  const {
    index,
    handleDragStart,
    handleDragEnter,
    dragging,
    label,
    onTagDelete,
    tagId,
  } = props;
  const onPreventMouseDown = event => {
    event.stopPropagation();
  };
  const onDragStart = e => handleDragStart(e, index);
  const handleDeleteTag = () => {
    onTagDelete(tagId);
  };

  return (
    <div
      onDragStart={onDragStart}
      onDragEnter={dragging ? e => handleDragEnter(e, index) : undefined}
      draggable
    >
      <Tag
        style={{ cursor: 'move' }}
        closable
        onMouseDown={onPreventMouseDown}
        onClose={handleDeleteTag}
      >
        {label}
      </Tag>
    </div>
  );
};
