import React, { ChangeEvent } from 'react';
import { Checkbox, Typography, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type Props = {
  slugCustom?: boolean;
  slug?: string;
  onChange: (
    type: string
  ) => (e: CheckboxChangeEvent | ChangeEvent<HTMLInputElement>) => void;
};

export const ItemEditCustomSlug = React.memo(
  ({ slugCustom, slug, onChange }: Props) => {
    return (
      <div className="sideContentItem fluid mb-3">
        <Checkbox
          checked={slugCustom}
          onChange={onChange('slugCustom')}
          disabled={!slug}
          className="mb-4"
        >
          Custom slug
        </Checkbox>
        <Input
          value={slug}
          disabled={!slug || !slugCustom}
          onChange={onChange('slug')}
        />
      </div>
    );
  }
);
