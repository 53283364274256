import React, { useEffect, useRef, useState } from 'react';
import { Area } from '@antv/g2plot';
import { Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useDashboardOverviewLazyQuery } from '../../graphql/types';

const { Option } = Select;

const periodDays = [7, 14, 30] as const;

type Period = typeof periodDays[number];

const periodTitle = (p: Period): string => {
  switch (p) {
    case 7:
      return 'Last week';
    case 14:
      return 'Last 2 weeks';
    case 30:
      return 'Last 30 days';
    default:
      return '';
  }
};

export const fields = [
  'sessions',
  'avgSessionDuration',
  'pageviews',
  'bounceRate',
] as const;

export type OverViewField = typeof fields[number];

export const fieldTitle = (field: OverViewField): string => {
  switch (field) {
    case 'avgSessionDuration':
      return 'AVG session duration';
    case 'bounceRate':
      return 'Bounce rate';
    case 'pageviews':
      return 'Page views';
    case 'sessions':
      return 'Sessions';
    default:
      return '';
  }
};

export const GPlot = () => {
  const [fetchData, { data, loading, error }] = useDashboardOverviewLazyQuery(
    {}
  );

  const ref = useRef<HTMLDivElement>(null);

  const [period, setPeriod] = useState<Period>(30);
  const [overviewField, setOverviewField] = useState<OverViewField>('sessions');
  const area = useRef<Area>(null);

  const draw = () => {
    if (area.current) {
      area.current.changeData(
        data?.overview?.map(x => ({
          date: `${moment(x.date.slice(0, 10)).date()} ${
            moment(x.date.slice(0, 10)).month() + 1
          } ${moment(x.date.slice(0, 10)).year()}`,
          value: x[overviewField],
        })) || []
      );
      return;
    }
    // @ts-ignore
    area.current = new Area('plot-container', {
      data:
        data?.overview?.map(x => ({
          date: `${moment(x.date.slice(0, 10)).date()} ${
            moment(x.date.slice(0, 10)).month() + 1
          } ${moment(x.date.slice(0, 10)).year()}`,
          value: x[overviewField],
        })) || [],
      xField: 'date',
      yField: 'value',
      xAxis: {
        range: [0, 1],
        // grid: {
        //   line: {
        //     style: {
        //       width: 1,
        //     },
        //   },
        // },
      },
      yAxis: {},
      smooth: false,
    });
    area.current.render();
  };

  const clean = () => {
    // if (ref.current) {
    //   ref.current.innerHTML = '';
    // }
  };

  useEffect(() => {
    if (data?.overview) {
      draw();
    }
    return clean;
  }, [data, overviewField]);

  useEffect(() => {
    fetchData({ variables: { days: period } });
    return clean;
  }, [period]);

  return (
    <div style={{ marginBottom: '44px' }}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '22px 0' }}>
        <Select
          value={overviewField}
          onChange={value => setOverviewField(value)}
        >
          {fields.map(f => (
            <Option value={f} key={f}>
              {fieldTitle(f)}
            </Option>
          ))}
        </Select>
        <Select
          value={period}
          onChange={value => setPeriod(value)}
          style={{ marginLeft: '27px' }}
        >
          {periodDays.map(f => (
            <Option value={f} key={f}>
              {periodTitle(f)}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ width: '100%', height: '400px', position: 'relative' }}>
        {loading && (
          <div
            style={{
              width: '100%',
              height: '400px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 100,
            }}
          >
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        )}
        <div ref={ref} id="plot-container" />
      </div>
    </div>
  );
};
