import React from 'react';
import { Typography, message, Switch } from 'antd';
import { useSubmitToolCategoryApprovalMutation } from '../../graphql/types';
import { useRouter } from '../../hooks/useRouter';
import s from './styles.module.scss';

export const ItemToolToCategory = ({ categoryApproved }) => {
  const { query } = useRouter();
  const [submitToolCategory, { loading }] =
    useSubmitToolCategoryApprovalMutation();

  const sendToolCategory = async (value: boolean) => {
    await submitToolCategory({
      variables: {
        id: query.id,
        value,
      },
    })
      .then(() => {
        location.reload();
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  return (
    <div
      className={s.approve}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography.Title level={5}>Display in to category</Typography.Title>
      <Switch
        checked={categoryApproved}
        onChange={e => sendToolCategory(e)}
        loading={loading}
      />
    </div>
  );
};
