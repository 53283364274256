import React, { FC, useEffect, useRef, useState } from 'react';
import { Form, message } from 'antd';
import {
  DynamicFieldType,
  DynamicModel,
  DynamicVariant,
  Entity,
  useDeleteDynamicVariantMutation,
  useDynamicModelLazyQuery,
  useSaveSettingsMutation,
} from 'graphql/types';
import { DynamicModelForm } from './DynamicModelForm/DynamicModelForm';
import {
  getDataForFormFromDynamicModel,
  getDynamicModelFromForm,
  saveDynamicField,
  saveDynamicModel,
  saveDynamicVariants,
} from './helpers';

type Props = {
  entity: Entity;
  close: () => void;
  id?: string | null;
};

// export const getVariantInitialData = (type: DynamicFieldType): any => {
//   switch (type) {
//     case DynamicFieldType.Boolean:
//       return false;
//     case DynamicFieldType.Number:
//       return 0;
//     case DynamicFieldType.String:
//     case DynamicFieldType.Image:
//       return '';
//     default:
//       return null;
//   }
// };

export const DynamicModelView: FC<Props> = ({ entity, close, id }) => {
  const [form] = Form.useForm();
  const initialDynamicData = useRef<DynamicModel>({} as DynamicModel);
  const [loading, setLoading] = useState(false);

  const [getModelData] = useDynamicModelLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ dynamicModel }) => {
      form.setFieldsValue(getDataForFormFromDynamicModel(dynamicModel));
      initialDynamicData.current = dynamicModel;
      setLoading(false);
    },
    onError: e => {
      message.error(e.message);
    },
  });
  const [deleteDynamicVariants] = useDeleteDynamicVariantMutation();

  const createNew = async data => {
    const { model: modelPayload, fieldType } = getDynamicModelFromForm(
      data,
      entity
    );
    try {
      setLoading(true);
      const model = await saveDynamicModel(modelPayload);
      await saveDynamicField({
        modelID: model.id,
        name: model.name,
        type: fieldType,
      });

      const variants =
        data.variants &&
        data.variants.filter(v => !!v).map(({ id, value }) => ({ id, value }));
      if (variants && variants.length > 0) {
        await saveDynamicVariants(
          variants.map(variant => {
            return {
              modelID: model.id,
              value: JSON.stringify({ [model.name]: variant.value }),
            };
          })
        );
      }
      return getModelData({ variables: { id: model.id } });
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const updateModel = async data => {
    try {
      setLoading(true);
      const { id, fields, variants } = data;
      const { model, fieldType } = getDynamicModelFromForm(data, entity);
      const modelPayload = {
        ...model,
        id,
      };
      const fieldPayload = {
        id: fields[0].id,
        name: modelPayload.name,
        type: fieldType,
        isArray: false,
        primaryField: true,
      };
      const variantsPayload =
        variants?.map(variant => ({
          id: variant.id,
          modelID: modelPayload.id,
          value: JSON.stringify({ [modelPayload.name]: variant.value }),
        })) || [];

      const variantsForDelete =
        initialDynamicData.current.variants?.filter(
          variant => !variants?.find(({ id }) => id === variant.id)
        ) || [];

      await Promise.all([
        saveDynamicVariants(variantsPayload),
        saveDynamicModel(modelPayload),
        saveDynamicField(fieldPayload),
        Promise.all(
          variantsForDelete.map(variant => {
            return deleteDynamicVariants({ variables: { id: variant.id } });
          })
        ),
      ]);
      return getModelData({ variables: { id: modelPayload.id } });
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = data => {
    if (!data.id) {
      return createNew(data);
    }

    return updateModel(data);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getModelData({ variables: { id } });
    }
  }, []);

  return (
    <DynamicModelForm
      onClose={close}
      onSubmit={handleSubmit}
      form={form}
      loading={loading}
    />
  );
};
