import { Collapse, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useRouter } from 'hooks/useRouter';

import s from '../styles.module.css';

type Props = {
  name: string;
  value: string;
  disabled?: boolean;
  variants: {
    name: string;
    value: string;
    status: string | boolean;
  }[];
  apply: (query: Record<string, any>) => void;
};

export const VariantFilter = ({
  variants,
  name,
  value,
  disabled,
  apply,
}: Props) => {
  const { query } = useRouter();
  // TEMP FIX
  const actualQuery = {
    ...query,
    active:
      typeof query.active === 'boolean' ? String(query.active) : undefined,
  };

  const handleChangeFilter =
    (value: string, status: boolean | string) => () => {
      const isChosen = actualQuery[value] === status;

      apply({ ...actualQuery, [value]: isChosen ? '' : status });
    };

  return (
    <Collapse
      expandIconPosition="right"
      ghost
      expandIcon={props => {
        return (
          <DownOutlined
            {...props}
            style={{
              transition: 'transform .3s',
              transform: props.isActive ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        );
      }}
    >
      <Collapse.Panel
        header={name}
        key={value}
        className={s.collapseItem}
        disabled={disabled}
      >
        {variants.map(({ name, value: variantValue }) => (
          <Radio
            key={name}
            onClick={handleChangeFilter(value, variantValue)}
            checked={actualQuery[value] === variantValue}
          >
            {name}
          </Radio>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};
