import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

const LAST_VIEWED_ENTITIES = ['item', 'category', 'skill', 'posts'];

export const getBreadcrumbs = (
  source: Record<string, any>,
  data: Record<string, any>,
  query: Record<string, string>,
  resource: string
) => {
  if (!query.id) return `Create ${resource}`;
  if (source.editBreadcrumbs && !_isEmpty(data)) {
    return source.editBreadcrumbs(data) || '';
  }
  return data.title || '';
};

export const getLastViewed = () => {
  if (typeof window === 'undefined') return [];
  const lastViewed = localStorage.getItem('lastViewed') || '[]';
  return JSON.parse(lastViewed);
};
export const setLastViewed = (
  entity: Record<string, any>,
  resource: string
) => {
  if (
    typeof window === 'undefined' ||
    !LAST_VIEWED_ENTITIES.includes(resource) ||
    !entity.id
  )
    return;
  localStorage.setItem(
    'lastViewed',
    JSON.stringify(
      _uniqBy([{ resource, ...entity }, ...getLastViewed()], 'id').slice(0, 5)
    )
  );
};
export const updateLastViewed = (
  entity: Record<string, any>,
  resource: string
) => {
  if (typeof window === 'undefined' || !LAST_VIEWED_ENTITIES.includes(resource))
    return;

  const lastViewed = getLastViewed();
  localStorage.setItem(
    'lastViewed',
    JSON.stringify(
      lastViewed.map(item => {
        if (entity.id === item.id) return entity;
        return item;
      })
    )
  );
};
