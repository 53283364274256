/* eslint-disable */
import { DynamicData, DynamicFieldType } from 'graphql/types';

export const getDynamicModelsPayload = (dynamic: DynamicData[]) => {
  return dynamic.map(({ model, data }) => {
    const average = model?.fields?.find(x => x.name === '0average');
    if (average && average.type == DynamicFieldType.Number) {
      const obj = JSON.parse(data);
      let count = 0;
      let sum = 0;
      for (const objKey in obj) {
        if (objKey !== '0average' && !isNaN(Number(obj[objKey]))) {
          count += 1;
          sum += Number(obj[objKey]);
        }
      }
      obj['0average'] = sum / count;
      data = JSON.stringify(obj);
    }
    return {
      modelID: model.id,
      data,
    };
  });
};

export const getData = (value: any, isArray?: boolean) => {
  if (!isArray && value instanceof Array) return value[0];
  if (isArray && !(value instanceof Array)) return [value];
  return value;
};
