import { createContext } from 'react';
import { CSVContextType } from './types';

export const initialState = {
  categories: {} as any,
  chosen: {},
  savingItems: false,
  archiveLoading: false,
  savingNewCategories: false,
  saveModalOpened: false,
  makeActive: true,
};

export const CSVContext = createContext<CSVContextType>({
  ...initialState,
  updateState: () => ({} as any),
  parseSCV: () => {},
  onChooseItem: () => {},
  handleSaveItems: () => ({} as any),
  handleArchiveItems: () => ({} as any),
  getRefData: () => ({} as any),
  dropChosen: () => ({} as any),
});
