import { constructUrl, queryParse, queryStringify } from 'w-querify';
import { useLocation, useHistory, useParams } from 'react-router-dom';

type Router = {
  action: string;
  block: (prompt: string) => void;
  constructUrl: (
    pathname: string,
    queryObj: Record<string, string | number | boolean>
  ) => string;
  queryStringify: (
    queryObj: Record<string, string | number | boolean>
  ) => string;
  go: (to: string) => void;
  goBack: () => void;
  goForward: () => void;
  hash: string;
  key: string;
  length: number;
  listen: (listener: string) => void;
  location: {
    pathname: string;
    search: string;
    hash: string;
    state?: Record<string, string>;
    key: string;
  };
  params: Record<string, string>;
  pathname: string;
  push: (path: string, state?: Record<string, string>) => void;
  replace: (path: string, state?: Record<string, string>) => void;
  query: Record<string, any>;
  search: string;
  state?: Record<string, string>;
};

export const useRouter = (): Router => {
  const location = useLocation();
  const history = useHistory();
  const params: Record<string, string> = useParams();

  return {
    // @ts-ignore
    query: queryParse(location.search),
    constructUrl,
    queryStringify,
    params,
    ...location,
    ...history,
  };
};
