import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useRouter } from 'hooks/useRouter';
import { message } from 'antd';
import { Key } from 'antd/es/table/interface';
import * as resources from 'config/resourses';
import * as providers from 'config/providers';
import { Breadcrumbs } from 'nativeComponents/Breadcrumbs/Breadcrumbs';
import { Item } from 'graphql/types';
import { ListTable } from './ListTable';
import { FilterSet } from './FilterSet/FilterSet';
import { ListRowTitle } from './ListRowTitle/ListRowTitle';
import { getActionColumn } from './ActionColumn/ActionColumn';
import { ListActions } from './ListActions/ListActions';

import s from './styles.module.css';

export const List = ({ resource }) => {
  const { query, search } = useRouter();
  const resourceProvider = providers[resource];
  const [state, setState] = useState({
    data: [] as any[],
    total: 0,
    search,
    loading: false,
    checked: [] as any[],
    switchActivePending: false,
  });

  const getList = async () => {
    try {
      const { sort, sortDirection, search, ...filters } = query;
      let fetcher = resourceProvider && resourceProvider.getList;

      if (filters.deleted) {
        fetcher = resourceProvider.getDeletedList;
      }
      if (!fetcher) {
        console.error('No such "getList" found');
        return;
      }
      setState(prev => ({ ...prev, loading: true }));
      const data = await fetcher({
        filters: Object.entries(filters || {}).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: /~/.test(value)
              ? value.split(',').map(item => item.split('~')[1])
              : value,
          };
        }, {}),
        search: search ? search.toString() : undefined,
        sort,
        sortDirection,
      });

      if (!data) {
        message.error('Some error happened');
        return;
      }

      setState(prev => {
        return {
          ...prev,
          data: data.data.filter(item => !!item),
          total: data.total,
          loading: false,
        };
      });
    } catch (e) {
      setState(prev => ({ ...prev, loading: false }));
      message.error(e.message);
    }
  };

  const onDelete = async (ids: string[] | string) => {
    const deleteHandler = resourceProvider && resourceProvider.deleteOne;
    if (!deleteHandler) return;

    const updatedIds = ids instanceof Array ? ids : [ids];
    setState(prev => ({ ...prev, loading: true }));
    await Promise.all(
      state.data
        .filter((item: any) => updatedIds.includes(item.id))
        .map((record: Record<string, any>) => deleteHandler(record))
    );
    setState(prev => ({ ...prev, checked: [] }));
    await getList();
  };

  const onRestoreItem = async (record: Item) => {
    try {
      const restoreHandler = resourceProvider && resourceProvider.restoreItem;
      if (!restoreHandler) return;

      await restoreHandler(record.id);
      await getList();
    } catch (e) {
      message.error(e.message);
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeActive = async (ids: string[] | string, isActive: boolean) => {
    const updateHandler = resourceProvider && resourceProvider.updateOne;
    if (!updateHandler) return;
    setState(prev => ({ ...prev, loading: true }));

    const updatedIds = ids instanceof Array ? ids : [ids];
    await Promise.all(
      state.data
        .filter((item: any) => updatedIds.includes(item.id))
        .map((record: Record<string, any>) => {
          return updateHandler({
            ...record,
            active: isActive,
          });
        })
    );

    setState(prev => ({
      ...prev,
      loading: false,
      checked: [],
      data: prev.data.map((item: any) => {
        if (!updatedIds.includes(item.id)) return item;
        return { ...item, active: isActive };
      }),
    }));
  };

  const handleCheckChange = (selectedRowKeys: Key[], selectedRows: any[]) => {
    setState(prev => ({ ...prev, checked: selectedRows.map(({ id }) => id) }));
  };

  const userData = resources[resource];
  const isHaveAction =
    !!userData.list.actions && !!userData.list.actions.length;

  const editedColumns = userData.list
    ? [
        ...(userData.list.columns || []).map(
          (
            {
              title,
              key,
              sort,
              source,
              render,
              filter,
              dataIndex,
              asLink,
              ...restColumnProps
            },
            i
          ) => ({
            title,
            key,
            dataIndex: dataIndex || key,
            render: (record, data) => {
              if (i === 0) {
                return (
                  <ListRowTitle
                    record={record}
                    data={data}
                    render={render}
                    withLink={asLink}
                  />
                );
              }
              return render ? (
                render(record, data, { onChangeActive, query })
              ) : (
                <h4 style={{ marginBottom: 0 }}>{record}</h4>
              );
            },
            ...(restColumnProps || {}),
          })
        ),
        isHaveAction &&
          getActionColumn({
            onChangeActive,
            onDelete,
            onRestore: onRestoreItem,
            isDeleted: query.deleted,
          }),
      ].filter(item => !!item)
    : [];

  useEffect(() => {
    getList();
  }, [search]);

  const CustomComponent = userData.render;
  const isAppliedDeletedQuery = !!query.deleted;
  return (
    <div
      className={classnames(`flex-column flex-grow ${s.tableContainer}`, {
        pending: state.loading,
      })}
    >
      {/*MOCK*/}
      {/*<ChangeItems />*/}
      {/*<CategoriesTech />*/}
      {/*MOCK_END*/}
      <Breadcrumbs />
      <FilterSet
        filters={userData.filters}
        extendFilters={userData.extendFilters}
        booleanFilters={userData.booleanFilters}
        sort={userData.sort}
        disabled={isAppliedDeletedQuery}
      />
      <ListActions
        onActive={onChangeActive}
        onDelete={onDelete}
        checked={state.checked}
        items={state.data}
        disabled={isAppliedDeletedQuery}
      />
      {!CustomComponent && (
        <ListTable
          data={state.data}
          total={state.total}
          options={userData}
          columns={editedColumns}
          checked={state.checked}
          onSelect={isHaveAction ? handleCheckChange : undefined}
        />
      )}
      {!!CustomComponent && <CustomComponent {...state.data} />}
    </div>
  );
};
