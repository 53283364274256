import {
  GetBlogPostsDocument,
  GetBlogPostDocument,
  BlogPostInput,
  CreateBlogPostDocument,
  UpdateBlogPostDocument,
  DeleteBlogPostDocument,
  BlogPost,
  SortBy,
  SortDirection,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';
import { getIds } from '../../utils/getData';

const apolloClient = initializeApollo();

// Function to fetch a list of blog posts with optional filters, sorting, and pagination
export const getList = async (
  {
    page = 1,
    limit = 10,
    sort = SortBy.CreationDate,
    sortDirection = SortDirection.Desc,
    search,
    filters = {},
  } = {} as any
) => {
  const {
    data: {
      blogPosts: { data, count },
    },
  } = await apolloClient.query({
    query: GetBlogPostsDocument,
    fetchPolicy: 'no-cache',
    variables: {
      page: Number(page),
      limit: Number(limit),
      search,
      sortBy: sort,
      sortByDirection: sortDirection,
      ...filters,
    },
  });

  return { data, total: count };
};

// Function to fetch details of a single blog post by its ID
export const getOne = async ({ id }) => {
  if (!id) return { data: { blogPost: {} } };

  const {
    data: { blogPost },
  } = await apolloClient.query({
    query: GetBlogPostDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { data: blogPost };
};

// Function to create a new blog post
export const createOne = async (data: BlogPostInput) => {
  return apolloClient.mutate({
    mutation: CreateBlogPostDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        ...data,
        date: new Date(data.date),
      },
    },
  });
};

// Function to update an existing blog post
export const updateOne = async ({
  id,
  title,
  thumb,
  content,
  shortDescription,
  categories,
  labels, 
  active,
  dynamic,
  slug,
  date,
}: BlogPost) => {
  return apolloClient.mutate({
    mutation: UpdateBlogPostDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id,
      input: {
        title,
        thumb,
        content,
        categories: getIds(categories || []) as string[],
        labels: getIds(labels || []) as string[], 
        shortDescription,
        active,
        dynamic,
        slug,
        date: new Date(date), 
      },
    },
  });
};

// Function to delete a blog post
export const deleteOne = async (data: { id: string }) => {
  await apolloClient.mutate({
    mutation: DeleteBlogPostDocument,
    fetchPolicy: 'no-cache',
    variables: {
      id: data.id,
    },
  });

  return data.id;
};
