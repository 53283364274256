import React, { useEffect, useState } from 'react';
import { useResource } from 'hooks/useResource';
import { Popconfirm, Button } from 'antd';

import s from './styles.module.css';

type Props = {
  onActive: (ids: string[], isActive: boolean) => void;
  onDelete: (ids: string[]) => void;
  checked: string[];
  items: any[];
  disabled?: boolean;
};

export const ListActions = ({
  onActive,
  onDelete,
  checked,
  items,
  disabled: disabledProps,
}: Props) => {
  const [activeItems, setActiveItems] = useState({});
  const {
    resources: {
      list: { actions = [] },
    },
  } = useResource();

  const getChangeStatus = () => {
    return !!checked.find(itemId => !activeItems[itemId]);
  };

  const handleCheck = () => {
    if (!checked || !checked.length) return;
    onActive(checked, getChangeStatus());
  };

  const handleDelete = () => {
    if (!checked || !checked.length) return;
    onDelete(checked);
  };

  useEffect(() => {
    if (!items.length) return;
    setActiveItems(
      items.reduce(
        (acc, { active, id }: any) => ({
          ...acc,
          [id]: active,
        }),
        {}
      )
    );
  }, [items]);

  const activeBtnTitle = getChangeStatus() ? 'Set active' : 'Set Inactive';
  const disabled = !checked.length || disabledProps;
  return (
    <div className={s.listActions}>
      {actions.includes('delete') && (
        <Popconfirm
          title="Delete?"
          onConfirm={handleDelete}
          disabled={disabled}
        >
          <Button danger type="primary" ghost disabled={disabled}>
            Delete
          </Button>
        </Popconfirm>
      )}
      {actions.includes('active') && (
        <Button
          onClick={handleCheck}
          type="primary"
          ghost
          className="danger ml-3"
          disabled={disabled}
        >
          {activeBtnTitle}
        </Button>
      )}
    </div>
  );
};
