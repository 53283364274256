import React from 'react';
import { useResource } from 'hooks/useResource';
import { useRouter } from 'hooks/useRouter';
import { Link } from 'react-router-dom';
import { UIIcon } from 'nativeComponents/UI/UIIcons/UIIcon';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { SITE_NAMES } from 'hooks/ConfigProvider/helpers';
import { TYPE_PAGES } from 'nativeComponents/Breadcrumbs/Breadcrumbs';

import s from './styles.module.css';

type Props = {
  record: string;
  data: Record<string, any>;
  render?: (record: string, data: Record<string, any>) => React.ReactElement;
  withLink?: boolean;
};

export const ListRowTitle = ({ record, data, render, withLink }: Props) => {
  const { resource } = useResource();
  const { query } = useRouter();
  const { site } = useConfig();

  const Title = !render ? (
    <h3 className="ma-0">{record}</h3>
  ) : (
    render(record, data)
  );

  const generateUrl = () => {
    if (resource === 'item' && typeof data['status'] !== 'undefined') {
      return `${resource}/edit?id=${data.id}&approved=${data?.approved}`;
    }
    return `${resource}/edit?id=${data.id}`;
  };

  return (
    <div className={s.listRowTitle}>
      {query.deleted && Title}
      {!query.deleted && <Link to={generateUrl()}>{Title}</Link>}
      {withLink && (
        <a
          href={`${process.env.REACT_APP_CLIENT_URL}${
            resource === TYPE_PAGES.item &&
            site.name !== SITE_NAMES.Travelsites &&
            site.name !== SITE_NAMES.Streamingsites
              ? '/review'
              : ''
          }/${data.slug}`}
          className="ml-1"
          target="_blank"
          rel="noreferrer"
          style={{
            marginTop: 3,
          }}
        >
          <UIIcon icon="link" />
        </a>
      )}
    </div>
  );
};
