import { Category, Item } from 'graphql/types';
import { sortByKey } from 'utils/array';
import { getPlainString, toSnakeCase } from 'utils/string';
import { SortHeader } from './CSVSortProvider';

export const transformCsvHeader = (head: string) => {
  const plainHead = getPlainString(head);
  if (plainHead === 'category') return 'category';
  if (plainHead === 'categoryid') {
    return toSnakeCase(head);
  }
  return head;
};

const getSortedItems = (
  items: Record<string, string>[],
  primarySort: SortHeader,
  altSort: SortHeader
) => {
  const itemsWithSortKey: Record<string, string>[] = [];
  const itemsWithAltSortKey: Record<string, string>[] = [];
  const itemsWithoutSortKey: Record<string, string>[] = [];
  items.forEach(item => {
    if (item[primarySort.key]) {
      itemsWithSortKey.push(item);
    } else if (item[altSort.key]) {
      itemsWithAltSortKey.push(item);
    } else {
      itemsWithoutSortKey.push(item);
    }
  });

  return [
    ...sortByKey(
      itemsWithSortKey,
      primarySort.key,
      'number',
      primarySort.sortDirection
    ),
    ...sortByKey(
      itemsWithAltSortKey,
      altSort.key,
      'number',
      altSort.sortDirection
    ),
    ...itemsWithoutSortKey,
  ];
};

export const collectCategories = (
  items: Record<string, string>[],
  allItemsMap: Record<string, Item>,
  sortKey: SortHeader,
  altSortKey: SortHeader
) => {
  const categories: Record<string, Record<string, any>[]> = {};
  items.forEach(item => {
    const actualItem = allItemsMap[getPlainString(item.title || item['Title'])];
    if (actualItem) {
      categories[item.category] = [
        ...(categories[item.category] || []),
        {
          ...actualItem,
          ...item,
        },
      ];
    }
  });

  return Object.entries(categories).reduce((acc, [categoryTitle, items]) => {
    return {
      ...acc,
      [categoryTitle]: getSortedItems(items, sortKey, altSortKey),
    };
  }, {});
};

export const fillUpSortCategories = (
  sortCategories: Category[][],
  csvCategories: Record<string, Item[]>
) => {
  return sortCategories.map(categories => {
    return categories.map(category => {
      return {
        ...category,
        items: csvCategories[category.title] || category.items,
      };
    });
  });
};
