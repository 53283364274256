import React, { useState, useEffect, useRef } from 'react';
import { Card, Typography, Input, message } from 'antd';
import _uniqBy from 'lodash/uniqBy';
import _noop from 'lodash/noop';
import { UIUpload } from 'nativeComponents/UI';
import classnames from 'classnames';
import { getIconValue } from 'utils/getData';
import { useApollo } from 'utils/apollo';
import { SubmitGroup } from 'components/Common/SubmitGroup/SubmitGroup';
import {
  Category,
  GetCategoriesListDocument,
  GetCategoriesListQuery,
  GetCategoriesListQueryVariables,
} from 'graphql/types';
import { updateOne as updateCategory } from 'config/providers/category';
import { SearchSelect } from '../Common/SearchSelect/SearchSelect';
import { SEARCH_SELECT_TYPES } from '../Common/SearchSelect/helpers';
import {useConfig} from 'hooks/ConfigProvider/useConfig';
import {SITE_NAMES} from 'hooks/ConfigProvider/helpers';
import {BindedIcon, FaIconPicker} from '../../entities/font-awesome-icon';

export const SkillsEdit = ({ title, icon, onSubmit, fontAwesomeIcon, modelFields, id }) => {
  const {site} = useConfig()
  const apollo = useApollo({});
  const initialCategoriesList = useRef<Partial<Category>[]>([]);
  const [state, setState] = useState({
    title,
    fontAwesomeIcon,
    icon: icon || [],
    categories: [] as { title: string; id: string }[],
    loading: false,
  });

  const onChange = ({ target: { value } }) => {
    setState(prev => ({ ...prev, title: value }));
  };

  const onChangeIcon = (name: string, image: any) => {
    setState(prev => ({ ...prev, [name]: image }));
  };
  const onDelete = (name: string, index: number) => {
    setState(prev => ({
      ...prev,
      [name]: [],
    }));
  };

  const handleFontAwesomeIcon = (icon: BindedIcon) => {
    setState(prev => ({
      ...prev,
      fontAwesomeIcon: {
        ...prev.fontAwesomeIcon,
        prefix: icon.prefix,
        name: icon.name,
      }
    }))
  }

  const setColorFontAwesomeIcon = (color: string) => {
    setState(prev => ({
      ...prev,
      fontAwesomeIcon: { ...prev.fontAwesomeIcon, color },
    }));
  };

  const handleUpdateCategories = async () => {
    const {
      data: {
        categories: { data: categories },
      },
    } = await apollo.query<
      GetCategoriesListQuery,
      GetCategoriesListQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: GetCategoriesListDocument,
      variables: {
        limit: 0,
        page: 1,
        onlyIds: _uniqBy(
          [...initialCategoriesList.current, ...state.categories],
          'id'
        ).map(({ id }) => id),
      },
    });
    await Promise.all(
      categories.map(category => {
        const isNoNeedToUpdate =
          state.categories.find(({ id }) => category.id === id) &&
          initialCategoriesList.current.find(({ id }) => category.id === id);
        if (isNoNeedToUpdate) {
          return _noop;
        }

        const skills = category.skills || [];
        const isSkillInCategory = state.categories.find(
          ({ id }) => category.id === id
        );
        const categorySkills = !isSkillInCategory
          ? skills.filter(skill => skill.id !== id)
          : [...skills.map(skill => skill.id), id];

        return updateCategory({
          ...category,
          skills: categorySkills,
        } as Category);
      })
    );
    initialCategoriesList.current = state.categories;
  };

  const handleSubmit = async (redirect?: boolean) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const isHaveCategoriesForUpdate =
        state.categories.length > 0 || initialCategoriesList.current.length > 0;
      if (isHaveCategoriesForUpdate) {
        await handleUpdateCategories();
      }
      onSubmit({
        ...state,
        redirect,
      });
    } catch (e) {
      message.error(e.message);
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onSelectChange = (categories: { title: string; id: string }[]) => {
    setState(prev => ({
      ...prev,
      categories,
    }));
  };

  const fetchCategoriesCallback = (categories: Category[]) => {
    const skillCategories = categories.filter(({ skills }) =>
      (skills || []).find(skill => skill.id === id)
    );
    initialCategoriesList.current = skillCategories;
    setState(prev => ({
      ...prev,
      categories: skillCategories,
    }));
  };

  useEffect(() => {
    setState(prev => ({
      ...prev,
      title,
      icon: icon || [],
      fontAwesomeIcon,
    }));
  }, [id]);

  /*eslint-disable*/
  return (
    <Card
      bodyStyle={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
      className={classnames({
        pending: state.loading,
      })}
    >
      <div className="fluid flex" style={{ paddingBottom: 150 }}>
        <div className="editContent">
          <div className="editTitle">
            <Typography.Title level={5}>Title</Typography.Title>
            <Input value={state.title} onChange={onChange} />
          </div>
          {site.name === SITE_NAMES.ManytoolsAi ? (
              <div className={'editTitle'} style={{ maxWidth: '300px' }}>
                <Typography.Title level={5}>Icon</Typography.Title>
                {state.fontAwesomeIcon && <FaIconPicker
                    value={state.fontAwesomeIcon as BindedIcon}
                    onChange={e => handleFontAwesomeIcon(e)}
                    setColor={setColorFontAwesomeIcon}
                />}
              </div>
          ) : (
              modelFields.icon &&
              (<div className="editTitle" style={{ border: 'none' }}>
                <Typography.Title level={5}>Icon</Typography.Title>
                <UIUpload
                    previews={getIconValue(state.icon)}
                    onChange={onChangeIcon}
                    onDelete={onDelete}
                    name="icon"
                    type="icon"
                />
              </div>)
          )}
        </div>
        <Card style={{ width: '100%' }}>
          <Typography.Title level={5}>Categories</Typography.Title>
          <SearchSelect
            mode="multiple"
            allowClear
            placeholder="Select categories"
            values={state.categories}
            onChange={onSelectChange as any}
            name={SEARCH_SELECT_TYPES.CATEGORY}
            fetchCallback={fetchCategoriesCallback}
          />
        </Card>
      </div>

      <SubmitGroup onSubmit={handleSubmit} />
    </Card>
  );
};
