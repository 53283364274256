import { Input } from 'antd';
import React from 'react';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import {
  DynamicFieldModel,
  DynamicFieldType,
  DynamicModel,
} from 'graphql/types';
import { getData } from './helpers';

type Props = {
  onUpdate: (a: any) => void;
  dynamicDataParser: DynamicDataParser | null;
  model: DynamicModel;
  field: DynamicFieldModel;
  index?: number;
};

export const StringDynamicComponent = ({
  onUpdate,
  dynamicDataParser,
  model,
  field,
  index,
}: Props) => {
  const type = field.type === DynamicFieldType.Number ? 'number' : 'text';
  return (
    <Input
      placeholder={field.name}
      type={type}
      value={getData(dynamicDataParser?.getFieldData(model, field, index))}
      onChange={e => {
        onUpdate(
          dynamicDataParser!.changeData(model, field, e.target.value, index)
        );
      }}
    />
  );
};
