import React, {
  createContext,
  DragEvent as DE,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _flatten from 'lodash/flatten';
import _chunk from 'lodash/chunk';
import { Category, ScreenSizeType } from 'graphql/types';
import { useWorker } from 'hooks/useWorker';
import { ContextModel } from './types';
import {
  constructDragItems,
  constructSortCategories,
  getSortActiveSortCategories,
} from './helpers';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { SITE_NAMES } from '../../hooks/ConfigProvider/helpers';

export const DRAG_ENTER_EVENT = 'drag_enter';

const Context = createContext<ContextModel>({
  data: [],
  handleDragStart: () => {},
  dragging: false,
  drag: null,
  handleDragEnter: () => {},
  changed: false,
  globalExpand: false,
  setGlobalExpand: () => {},
  updateVisibleItems: () => {},
  updateTotalItems: () => {},
  updateItemsSort: () => {},
  save: () => {},
  handleFillUp: () => {},
  loading: false,
  updateColumnsCount: () => {},
  screenSize: ScreenSizeType.Desktop,
});

type Props = {
  sortList: Array<Array<Category>>;
  screenSize: ScreenSizeType;
  saveData: (
    data: { categories: Category[][]; screenSize: ScreenSizeType },
    callback: () => void
  ) => void;
  fillUp: (callback: () => void) => void;
};

export const SortV4Provider: FC<Props> = ({
  children,
  sortList,
  saveData,
  screenSize,
  fillUp,
}) => {
  const { site } = useConfig();
  const { postMessage } = useWorker();
  const [data, setData] = useState<Category[][]>([[]]);
  const drag =
    useRef<{
      index: number;
      column: number;
      type: 'category' | 'item';
      categoryIndex?: number;
    } | null>(null);
  const dragNode = useRef<HTMLDivElement | null>(null);
  const dragContainer = useRef<HTMLDivElement>(null);
  const dragOn =
    useRef<{
      index: number;
      column: number;
      type: 'category' | 'item';
      categoryIndex?: number;
    } | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [globalExpand, setGlobalExpand] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDragEnd = () => {
    dragNode.current?.removeEventListener('dragend', handleDragEnd);
    dragNode.current?.removeEventListener('drag', handleDragEdgeScroll);
    setDragging(false);
    postMessage({
      type: DRAG_ENTER_EVENT,
      drag: { type: 'end_drag' },
    });
    if (!dragOn.current) return;

    let newList = data;
    if (drag.current!.type === 'category') {
      newList = constructSortCategories(data, dragOn.current, drag.current);
      setChanged(true);
    } else {
      newList = constructDragItems(data, dragOn.current, drag.current);
      setChanged(true);
    }

    setData(newList);
    drag.current = null;
    dragOn.current = null;
    dragNode.current = null;
  };

  const handleDragStart = (
    e: DE<HTMLDivElement>,
    index: number,
    column: number,
    type: 'category' | 'item',
    categoryIndex?: number
  ) => {
    e.stopPropagation();

    drag.current = { index, column, type, categoryIndex };
    dragNode.current = e.target as HTMLDivElement;
    dragNode.current!.addEventListener('dragend', handleDragEnd);
    dragNode.current!.addEventListener('drag', handleDragEdgeScroll);
    setTimeout(() => {
      setDragging(true);
    }, 0);
  };

  const handleDragging = ({ clientX, clientY }) => {
    const { left, right, top, bottom } =
      dragContainer.current!.getBoundingClientRect();
    const leftEdge = clientX < left;
    const rightEdge = clientX > right;
    const topEdge = clientY < top;
    const bottomEdge = clientY > bottom;
    if (leftEdge || rightEdge || topEdge || bottomEdge) {
      dragOn.current = null;
      postMessage({
        type: DRAG_ENTER_EVENT,
        drag: { type: 'end_drag' },
      });
    }
  };

  const handleDragEnter = (
    e: DE<HTMLDivElement>,
    index: number,
    column: number,
    type: 'category' | 'item',
    categoryIndex?: number
  ) => {
    if (e.target === dragNode.current) return;
    if (type === 'category') {
      const isSame =
        index === drag.current?.index && column === drag.current?.column;
      if (isSame) return;
      postMessage({
        type: DRAG_ENTER_EVENT,
        drag: { index, column, categoryIndex, type: 'category' },
      });
    } else {
      postMessage({
        type: DRAG_ENTER_EVENT,
        drag: { index, column, categoryIndex, type: 'item' },
      });
    }
    dragOn.current = { index, column, type, categoryIndex };
  };

  const updateVisibleItems = (
    column: number,
    categoryIndex: number,
    value: string
  ) => {
    const val = Number(value);
    if (isNaN(val)) return;
    const newList: Array<Array<Category>> = JSON.parse(JSON.stringify(data));
    newList[column][categoryIndex].sortItemsCountVisible = val;
    setData(newList);
    setChanged(true);
  };

  const updateTotalItems = (
    column: number,
    categoryIndex: number,
    value: string,
    withUpdate?: boolean
  ) => {
    const val = Number(value);
    if (isNaN(val)) return;
    const newList: Array<Array<Category>> = JSON.parse(JSON.stringify(data));
    newList[column][categoryIndex].sortItemsCountTotal = val;
    if (site.name !== SITE_NAMES.Fetishsites && site.name !== SITE_NAMES.Hentaisites) {
      newList[column][categoryIndex].sortItemsCountVisible = val;
    }
    setData(newList);
    if (withUpdate) {
      setChanged(true);
    }
  };

  const updateColumnsCount = (columns: string) => {
    const val = Number(columns);
    if (!columns || isNaN(val) || val === data.length) return;
    if (val === sortList.length) {
      setData(sortList);
      return;
    }

    const flattenList = _flatten(sortList);
    setData(_chunk(flattenList, Math.ceil(flattenList.length / val)));
    setChanged(true);
  };

  const updateItemsSort = (
    column: number,
    categoryIndex: number,
    oldIndex: number,
    value: string
  ) => {
    let newIndex = Number(value);
    if (isNaN(newIndex)) return;
    newIndex -= 1;
    const newList: Array<Array<Category>> = JSON.parse(JSON.stringify(data));
    newList[column][categoryIndex!].items!.splice(
      newIndex,
      0,
      newList[column][categoryIndex].items!.splice(oldIndex, 1)[0]
    );
    setData(newList);
    setChanged(true);
  };

  const save = () => {
    setLoading(true);
    saveData({ categories: data, screenSize }, () => {
      setChanged(false);
      setLoading(false);
    });
  };

  const handleFillUp = (changeChanged = true) => {
    setLoading(true);
    fillUp(() => {
      if (changeChanged) {
        setChanged(false);
      }
      setLoading(false);
    });
  };

  const documentDragPreventDefault = (e: DragEvent) => {
    e.preventDefault();
  };

  const handleDragEdgeScroll = e => {
    if (!dragNode.current) return;
    const isTopEdge = e.clientY <= 100;
    const isBottomEdge = e.clientY >= window.innerHeight - 100;
    if (!isBottomEdge && !isTopEdge) return;
    window.scrollBy(0, isTopEdge ? -10 : 10);
  };

  const deInit = () => {
    document.removeEventListener('dragover', documentDragPreventDefault);
    document.removeEventListener('drag', handleDragging);
  };

  useEffect(() => {
    document.addEventListener('dragover', documentDragPreventDefault);
    document.addEventListener('drag', handleDragging);
    return deInit;
  }, []);

  useEffect(() => {
    const sortedCategories = getSortActiveSortCategories(sortList);
    setData(sortedCategories);
  }, [sortList]);

  useEffect(() => {
    if (!changed) return;
    save();
  }, [changed]);

  return (
    <Context.Provider
      value={{
        data,
        handleDragStart,
        dragging,
        drag: drag.current,
        handleDragEnter,
        changed,
        globalExpand,
        setGlobalExpand,
        updateVisibleItems,
        updateTotalItems,
        updateItemsSort,
        save,
        loading,
        updateColumnsCount,
        screenSize,
        handleFillUp,
      }}
    >
      <div ref={dragContainer}>{children}</div>
    </Context.Provider>
  );
};

export const useSortV4 = () => useContext(Context);
