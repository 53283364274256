import { Input, Form, Select, Modal } from 'antd';
import React, { useEffect } from 'react';
import { MODEL_TYPES } from './modelTypes';
import { DynamicModelType } from 'graphql/types';
import { DynamicModelFromVariants } from './DynamicModelFromVariants/DynamicModelFromVariants';

export const DynamicModelForm = ({ onClose, onSubmit, form, loading }) => {
  const types = Form.useWatch('types', form);
  const [modelType] = (types || '').split('__');

  const handleConfirm = () => {
    onSubmit(form.getFieldsValue());
  };

  const isMultipleSelect =
    modelType === DynamicModelType.ManyToMany ||
    modelType === DynamicModelType.OneToMany;
  return (
    <Modal
      open
      onCancel={onClose}
      onOk={handleConfirm}
      maskClosable={false}
      closable={false}
      width={700}
    >
      <Form form={form} disabled={loading}>
        <Form.Item name="id" hidden />
        <Form.Item
          labelCol={{ span: 6 }}
          labelAlign="left"
          label="Model's name"
          name="title"
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['fields', 0, 'id']} hidden />
        <Form.Item name={['fields', 0, 'name']} hidden />
        <Form.Item
          label="Type"
          name="types"
          labelCol={{ span: 6 }}
          labelAlign="left"
          rules={[{ required: true, message: 'Missing value' }]}
        >
          <Select>
            {MODEL_TYPES.map(({ model, title, field }) => {
              const value = `${model.type}__${field.type}`;
              return (
                <Select.Option key={value} value={value}>
                  {title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {isMultipleSelect && <DynamicModelFromVariants />}
      </Form>
    </Modal>
  );
};
