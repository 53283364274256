import React, {
  CSSProperties,
  DragEvent as DE,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import { Button, Input } from 'antd';
import { DragOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

import s from './styles.module.css';

interface Props {
  list: Array<string>;
  changeList: (list: Array<string>) => void;
  title: string;
  handleDragEnter: (e: DE<HTMLDivElement>, params: { colIdx; itemIdx }) => void;
  handleDragStart: (e: DE<HTMLDivElement>, params: { colIdx; itemIdx }) => void;
  colIdx: number;
  dragging: boolean;
  drag: MutableRefObject<any>;
}

export const ProsConsList = ({
  list,
  drag,
  dragging,
  colIdx,
  changeList,
  title,
  handleDragEnter,
  handleDragStart,
}: Props) => {
  const listRef = useRef<HTMLDivElement>(null);
  const getStyles = (
    dragging: boolean,
    params: { colIdx; itemIdx }
  ): CSSProperties => {
    return dragging &&
      params.colIdx === drag.current?.colIdx &&
      params.itemIdx === drag.current?.itemIdx
      ? {
          display: 'flex',
          marginTop: '8px',
          alignItems: 'center',
          opacity: 0.5,
        }
      : { display: 'flex', marginTop: '8px', alignItems: 'center' };
  };

  const handleAdd = () => {
    changeList([...list, '']);
  };

  useEffect(() => {
    if (list[list.length - 1] !== '') return;
    const inputs = listRef.current?.querySelectorAll('input');
    if (!inputs || !inputs.length) return;

    inputs[inputs.length - 1].focus();
  }, [list.length]);

  return (
    <div
      // @ts-ignore
      onDragEnter={
        dragging && !list.length
          ? e => handleDragEnter(e, { colIdx, itemIdx: 0 })
          : null
      }
      className={s.prosConsList}
      ref={listRef}
    >
      <h6>{title}</h6>
      {list.map((item, index) => (
        <div
          draggable="true"
          onDragStart={e => handleDragStart(e, { colIdx, itemIdx: index })}
          // @ts-ignore
          onDragEnter={
            dragging
              ? e => handleDragEnter(e, { itemIdx: index, colIdx })
              : null
          }
          style={getStyles(dragging, { colIdx, itemIdx: index })}
          key={index}
        >
          <DragOutlined
            style={{ height: '14px', marginRight: '4px', cursor: 'move' }}
          />
          <Input
            onPaste={event => {
              event.preventDefault();
              const values = event.clipboardData.getData('Text').split('\n');
              const newList = [
                ...list.slice(0, index),
                ...values,
                ...list.slice(index + 1),
              ];
              changeList(newList);
            }}
            className={s.input}
            value={item}
            onChange={e => {
              changeList([
                ...list?.map((x, idx) => (idx === index ? e.target.value : x)),
              ]);
            }}
          />
          <Button
            icon={<CloseOutlined />}
            onClick={() =>
              changeList([
                // @ts-ignore
                ...list?.reduce(
                  // @ts-ignore
                  (acc, x, idx) => (idx === index ? acc : [...acc, x]),
                  []
                ),
              ])
            }
            type="link"
            className={s.deleteBtn}
          />
        </div>
      ))}
      <Button
        onClick={handleAdd}
        icon={<PlusOutlined />}
        className={s.addBtn}
        type="link"
      >
        {`Add ${title.toLowerCase()}`}
      </Button>
    </div>
  );
};
