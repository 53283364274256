import React, { useEffect, useState } from 'react';
import { Checkbox, Form, FormInstance, Select } from 'antd';
import { niceTitle } from 'utils/string';
import { DynamicFieldType } from 'graphql/types';

import s from './styles.module.scss';

const TYPES = [
  {
    value: DynamicFieldType.Number,
    title: 'Number',
  },
  {
    value: DynamicFieldType.String,
    title: 'Text',
  },
  {
    value: DynamicFieldType.Boolean,
    title: 'Checkbox',
  },
];

type Props = {
  title: string;
  form: FormInstance;
  type: DynamicFieldType;
};

export const CategoryItemsDynamicItem = React.memo(
  ({ title, form, type }: Props) => {
    const formItemValue = Form.useWatch(title, form);
    const fieldType =
      (TYPES.find(({ value }) => value === type) || {}).title ||
      DynamicFieldType.String;
    return (
      <div>
        <p>{`${niceTitle(title)} - ${fieldType}`}</p>
        <div className={s.filtersModel}>
          <div className="flex">
            <Form.Item
              label="Sort"
              name={[title, 'sort']}
              valuePropName="checked"
              className="mr-2"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label="by"
              name={[title, 'sortType']}
              style={{ width: 150 }}
            >
              <Select disabled={!formItemValue?.sort}>
                {TYPES.map(type => (
                  <Select.Option value={type.value} key={type.value}>
                    {type.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            label="Filter"
            name={[title, 'filter']}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Disabled"
            name={[title, 'disabled']}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>
      </div>
    );
  }
);
