import React, { useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import {
  useGetCategoriesListLazyQuery,
  useRecreateScreenShotsMutation,
} from 'graphql/types';
import { UILoader } from 'nativeComponents/UI/UILoader/UILoader';
import { constructUploadImages } from 'nativeComponents/Common/Pictures/helpers';
import { HardConfirmAction } from 'components/Common/HardConfirmAction/HardConfirmAction';
import { useUser } from 'hooks/useUser';
import { ScreenshotGenerateItem } from './ScreenshotGenerateItem';
import { LogList } from './LogList';
import { useConfig } from '../../../hooks/ConfigProvider/useConfig';

export const ScreenshotGenerate = () => {
  const { screenshot } = useConfig();
  const logRef = useRef<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [logs, updateLog] = useState<string[]>([]);
  const [list, setList] = useState([]);
  const [checked, setChecked] = useState<string[]>([]);

  const isCheckAll = checked.length === list.length;

  const [recreateScreenshots] = useRecreateScreenShotsMutation();
  const [fetchCategories, { loading: fetchLoading }] =
    useGetCategoriesListLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ categories }) => {
        const { data } = categories;
        setList((data || []) as any);
      },
      onError: e => {
        message.error(e.message);
      },
    });

  const handleCheck = (id: string) => {
    const isExistInList = checked.includes(id);
    setChecked(
      isExistInList ? checked.filter(item => item !== id) : [...checked, id]
    );
  };

  const toggleCheckAll = () => {
    if (isCheckAll) {
      setChecked([]);
    } else {
      setChecked(list.map(({ id }) => id));
    }
  };

  const fetchForGenerate = async (id: string) => {
    try {
      await recreateScreenshots({
        variables: {
          categoryID: id,
          sizes: constructUploadImages(screenshot.sizes).map(
            ({ width }) => width
          ),
        },
      });
    } catch (e) {
      logRef.current = [...logRef.current, e.message];
    }
  };

  const handleGenerate = async () => {
    setLoading(true);
    const categories = list.filter(({ id }) => checked.includes(id));
    await Promise.all(categories.map(({ id }) => fetchForGenerate(id)));

    updateLog(logRef.current);
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories({
      variables: {
        limit: 1000,
      },
    });
  }, []);

  const currentLoading = loading || fetchLoading;
  return (
    <div className="pa-8 fluid">
      {currentLoading && <UILoader />}
      <h1>Screenshot generate</h1>
      {!!logs.length && <LogList logs={logs} />}
      <Button onClick={toggleCheckAll} type="primary" className="mb-2">{`${
        isCheckAll ? 'Uncheck' : 'Check all'
      }`}</Button>
      <div className="flex flex-wrap">
        {list.map(({ id, title }) => (
          <ScreenshotGenerateItem
            key={id}
            checked={checked.includes(id)}
            title={title}
            id={id}
            onChange={handleCheck}
          />
        ))}
      </div>

      <HardConfirmAction
        typeText="Generate screenshots"
        onConfirm={handleGenerate}
      >
        <Button type="primary" className="mt-8">
          Generate screenshots
        </Button>
      </HardConfirmAction>
    </div>
  );
};
