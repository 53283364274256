import React from 'react';
import { BlockOutlined } from '@ant-design/icons';
import { LabelInfo } from 'components/Label/LabelInfo';

const Labels = () => {
  return (
    <div>
      <LabelInfo />
    </div>
  );
};

// Make sure the structure of this object matches what is expected elsewhere in your app
export const labelsResource = {
  name: 'labels',
  menuIcon: BlockOutlined,
  pageTitle: 'Labels',
  parent: 'Blog',
  ListComponent: Labels,
};

export default labelsResource;
