import { Tree } from 'antd';
import { useCSV } from 'components/Settings/CSV/CSVProvider/CSVProvider';
import { CVSUploaderCategoryTitle } from './CVSUploaderCategoryTitle';
import { CSVItem } from '../CSVItem/CSVItem';

import s from './styles.module.scss';

export type Steps = 'choose' | 'compare' | 'items';

export const CSVTree = () => {
  const { categories } = useCSV();

  const existsData = Object.entries(categories).map(([title, items], i) => {
    return {
      title: <CVSUploaderCategoryTitle title={title} items={items} />,
      key: i,
      children: (items as any).map((item, index) => ({
        title: <CSVItem item={item} />,
        key: `${i}-${index}`,
      })),
    };
  });

  return (
    <div className={s.treeModal}>
      <Tree selectable={false} treeData={existsData} />
    </div>
  );
};
