import { IconFamily } from '@fortawesome/fontawesome-svg-core';
import { IconLight } from '../types';

export const getIconFamily = (
  icon: IconLight,
  mode: 'pro' | 'free' = 'pro'
): IconFamily[] => {
  const family = icon.familyStylesByLicense[mode].map(el => el.family);
  return Array.from(new Set(family).values());
};
