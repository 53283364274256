const booleanLinks = [
  'forceNoFollow',
  'forceNoIndex',
  'forceNoOpenner',
  'targetBlank',
];

export const booleanSort = (
  data: Record<string, string | boolean>
): Record<string, Record<string, string | boolean>> => {
  const boolean: Record<string, string | boolean> = {};
  const string: Record<string, string | boolean> = {};
  Object.entries(data).forEach(([key, value]) => {
    if (booleanLinks.includes(key)) {
      boolean[key] = value;
    } else {
      string[key] = value;
    }
  });

  return {
    string,
    boolean,
  };
};
