import { CloseOutlined } from '@ant-design/icons';
import { getLastViewed } from 'nativeComponents/Edit/helpers';
import { GlobalSearchColumn } from './GlobalSearchColumn';

import s from './styles.module.css';

export type ArrayItem = { items: any[]; count: number };
type Props = {
  items: ArrayItem;
  categories: ArrayItem;
  skills: ArrayItem;
  posts: ArrayItem;
  onLoadMore: (entity: string, fetchDocument: string) => void;
  onDropList: () => void;
  loading?: string;
};

export const ItemsList = ({
  items,
  categories,
  skills,
  posts,
  onLoadMore,
  onDropList,
  loading,
}: Props) => {
  const lastViewed = getLastViewed();
  return (
    <div className={s.list}>
      <CloseOutlined className={s.closeIcon} onClick={onDropList} />
      <GlobalSearchColumn
        title="Last viewed"
        items={{ items: lastViewed, count: lastViewed.length }}
        onClick={onDropList}
      />
      <GlobalSearchColumn
        title="Items"
        items={items}
        resource="item"
        loading={loading === 'items'}
        onLoadMore={() => onLoadMore('items', 'GetItemsSuperLightDocument')}
        onClick={onDropList}
      />
      <GlobalSearchColumn
        title="Categories"
        items={categories}
        resource="category"
        loading={loading === 'categories'}
        onLoadMore={() => onLoadMore('categories', 'GetCategoriesListDocument')}
        onClick={onDropList}
      />
      <GlobalSearchColumn
        title="Topics"
        items={skills}
        resource="skill"
        loading={loading === 'skills'}
        onLoadMore={() => onLoadMore('skills', 'GetSkillListDocument')}
        onClick={onDropList}
      />
      <GlobalSearchColumn
        title="Posts"
        items={posts}
        resource="posts"
        loading={loading === 'posts'}
        onLoadMore={() => onLoadMore('posts', 'GetBlogPostsDocument')}
        onClick={onDropList}
      />
    </div>
  );
};
