/*eslint-disable*/
import React from 'react';
import './analytics-style.css';
import { Glance } from './Glance';
import { GPlot } from './GPlot';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { Reports } from './Reports';

export const Analytics = () => {
  return (
    <div className={'analytics-page'}>
      <h2>Overview report</h2>
      <ErrorBoundary>
        <GPlot />
      </ErrorBoundary>
      <h2>At a glance</h2>
      <Glance />
      <h2>Reports</h2>
      <Reports />
    </div>
  );
};
