import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import store from 'redux/store';

import './assets/antdRewrite.css';
import './assets/csClasses.css';
import './assets/markup.css';
import './assets/variables.css';
import './assets/global.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Provider } from 'react-redux';

library.add(fab, fat, fad, fal, far, fas, fass);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
