export const SEO_TEMPLATES = [
  {
    title: 'Seo item template',
    name: 'item',
    variables: [
      {
        title: 'Item title',
        value: '%item title%',
      },
      {
        title: 'Number of items',
        value: '%number of category items%',
      },
      {
        title: 'Category title',
        value: '%category title%',
      },
      {
        title: "Item's domain",
        value: '%items domain name%',
      },
    ],
  },
  {
    title: 'Seo category template',
    name: 'category',
    variables: [
      {
        title: 'Category title',
        value: '%category title%',
      },
      {
        title: 'Number of items',
        value: '%number of category items%',
      },
    ],
  },
];
