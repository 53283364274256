import React, { useState } from 'react';
import classnames from 'classnames';
import { message } from 'antd';
import {
  useUpdateScreenSizeMutation,
  useSyncItemsInSortScreenMutation,
  ScreenSizeType,
  Category,
} from 'graphql/types';
import { Breadcrumbs } from 'nativeComponents/Breadcrumbs/Breadcrumbs';
import { SortScreenSizeItem } from './SortCategotiesSize/SortScreenSizeItem/SortScreenSizeItem';
import { getUpdateSortScreenSizePayload } from '../helpers';

export const CategoriesRating = () => {
  const [open, setOpen] = useState('');

  const [fillUpSizes] = useSyncItemsInSortScreenMutation();
  const [updateScreenSize] = useUpdateScreenSizeMutation({
    onError: e => {
      message.error(e.message);
    },
  });

  const handleFillUpSizes = async (
    type: ScreenSizeType,
    callback: () => void
  ) => {
    try {
      await fillUpSizes({
        variables: {
          type,
        },
      });
    } catch (e) {
      message.error(e.message);
    } finally {
      callback();
    }
  };

  const handleSave = async (
    data: { categories: Category[][]; screenSize: ScreenSizeType },
    callback: () => void
  ) => {
    await updateScreenSize({
      variables: {
        type: data.screenSize,
        input: getUpdateSortScreenSizePayload(data.categories),
      },
    });
    callback();
  };

  return (
    <div className="fluid">
      <Breadcrumbs breadcrumbs={[]} />
      <div className="flex-column pb-4">
        {Object.values(ScreenSizeType).map(type => (
          <SortScreenSizeItem
            key={type}
            type={type}
            isOpen={open === type}
            handleOpen={() => setOpen(open === type ? '' : type)}
            onSave={handleSave}
            onFillUp={handleFillUpSizes}
          />
        ))}
      </div>
    </div>
  );
};
