const Lock = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M23.9144 11.5144H7.97151C5.03651 11.5144 2.65723 13.8936 2.65723 16.8286V24.8001C2.65723 27.7351 5.03651 30.1144 7.97151 30.1144H23.9144C26.8494 30.1144 29.2287 27.7351 29.2287 24.8001V16.8286C29.2287 13.8936 26.8494 11.5144 23.9144 11.5144Z"
        stroke="#8C8DA2"
        strokeWidth="2"
      />
      <path
        d="M15.943 23.4712C17.4105 23.4712 18.6002 22.2815 18.6002 20.814C18.6002 19.3465 17.4105 18.1569 15.943 18.1569C14.4755 18.1569 13.2859 19.3465 13.2859 20.814C13.2859 22.2815 14.4755 23.4712 15.943 23.4712Z"
        fill="#8C8DA2"
      />
      <path
        d="M23.9145 11.5143V8.85716C23.9145 4.45427 20.346 0.885727 15.9431 0.885727C11.5402 0.885727 7.97168 4.45427 7.97168 8.85716V11.5143"
        stroke="#8C8DA2"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="31" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Lock;
