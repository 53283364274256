import _isEmpty from 'lodash/isEmpty';
import { getLightList as getCategories } from 'config/providers/category';
import { getList as getSkills } from 'config/providers/skill';
import { getList as getCompanies } from 'config/providers/company';
import { getList as getStaffList } from 'config/providers/staff';
import { getList as getLabels } from 'config/providers/label';

export enum SEARCH_SELECT_TYPES {
  CATEGORY = 'CATEGORY',
  SKILL = 'SKILL',
  COMPANY = 'COMPANY',
  STAFF = 'STAFF',
  FEATURE = 'FEATURE',
  LABEL = 'LABEL'
}

export const getSearchHandler = (name: string) => {
  switch (name) {
    case SEARCH_SELECT_TYPES.CATEGORY:
      return getCategories;
    case SEARCH_SELECT_TYPES.SKILL:
      return getSkills;
    case SEARCH_SELECT_TYPES.COMPANY:
      return getCompanies;
    case SEARCH_SELECT_TYPES.STAFF:
      return getStaffList;
    case SEARCH_SELECT_TYPES.LABEL:
      return getLabels;
    default:
      return (a: any) => ({ data: [] });
  }
};

export const getConstructedOptions = (
  options: any[],
  optionPickKey: string,
  search: string
) => {
  const exactMatch: any[] = [];
  const nearMatch: any[] = [];
  options
    .filter(item => !_isEmpty(item))
    .map(item => ({
      label: item[optionPickKey],
      value: item[optionPickKey],
      id: item.id,
    }))
    .forEach(item => {
      const value = (item.value || '').toLowerCase();
      const actualSearch = search.toLowerCase();
      if (value.startsWith(actualSearch)) {
        exactMatch.push(item);
      } else if (value.includes(actualSearch)) {
        nearMatch.push(item);
      }
    });

  return [...exactMatch, ...nearMatch];
};
