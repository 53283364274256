import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { Slash } from 'react-feather';
import { getIcon } from '../../lib/get-icon';
import s from './styles.module.scss';
import classNames from 'classnames';
import { IconLight } from '../../types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = { iconData: IconLight; isHighlighted: boolean } & ButtonProps;

export const IconLightButton: React.FC<Props> = React.forwardRef<
  HTMLButtonElement,
  Props
>(function FaIconLightButton(
  { iconData, isHighlighted, color: colorProp = 'flat-secondary', ...props },
  ref
) {
  const icon = getIcon(iconData);
  const isIconNotExist = icon === undefined;
  const color = getIconButtonColor({
    isIconNotExist,
    isHighlighted,
    defaultColor: colorProp,
  });

  return (
    <Button
      disabled={isIconNotExist}
      className={classNames('p-50 m-50', s.lightBtn)}
      color={color}
      innerRef={ref}
      name={iconData.label}
      data-label={iconData.label}
      {...props}
      outline
    >
      {isIconNotExist ? (
        <Slash size={14} />
      ) : (
        <FontAwesomeIcon icon={icon as IconProp} size="2x" />
      )}
    </Button>
  );
});

const getIconButtonColor = (arg: {
  isIconNotExist: boolean;
  isHighlighted: boolean;
  defaultColor: string;
}) => {
  switch (true) {
    case arg.isIconNotExist:
      return 'danger';
    case arg.isHighlighted:
      return 'secondary';
    default:
      return arg.defaultColor;
  }
};
