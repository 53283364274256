import { ControlOutlined } from '@ant-design/icons';
import { CategoriesRating } from '../../components/Categories/CategoriesRating/CategoriesRating';

const sortResource = {
  name: 'sort',
  pageTitle: 'Sort',
  menuIcon: ControlOutlined,
  ListComponent: CategoriesRating,
};

export default sortResource;
