import {
  DynamicFieldType,
  DynamicModelType,
  Entity,
  Item,
  ItemFieldsDocument,
} from 'graphql/types';
import {
  createDynamicField,
  createDynamicModel,
  fetchDynamicModels,
} from 'api/dynamic';
import { initializeApollo } from 'utils/apollo';
import { CategoryDynamicFilter } from 'type';

const DEFAULT_CATEGORY_DYNAMIC_FILTER = {
  sort: false,
  filter: false,
  type: DynamicFieldType.Boolean,
  sortType: DynamicFieldType.Boolean,
  disabled: true,
};

export const collectUniqDynamic = (items: Item[]) => {
  const result = new Set<string>();
  items.forEach(({ dynamic }) => {
    dynamic?.forEach(dyn => {
      result.add(dyn.model.name);
    });
  });

  return Array.from(result).sort((a, b) => (a > b ? -1 : 1));
};

export const checkCategoryFilterDynamic = async () => {
  const allDynamicModels = await fetchDynamicModels({
    entity: Entity.Category,
  });
  let filterModel = allDynamicModels!.find(({ name }) => name === 'filters');
  if (!filterModel) {
    filterModel = await createDynamicModel({
      entity: Entity.Category,
      name: 'filters',
      type: DynamicModelType.Inline,
    });
  }

  let field = filterModel!.fields[0];
  if (!filterModel!.fields.length) {
    field = await createDynamicField({
      modelID: filterModel!.id,
      type: DynamicFieldType.String,
      name: 'filters',
      isArray: false,
      primaryField: true,
    });
  }
  return { ...filterModel, fields: [field] };
};

export const getDynamicFieldsFromCategoryItemsThatNotExists = async (
  items: Item[],
  categoryDynamic: Record<string, CategoryDynamicFilter>
) => {
  // Handling Boolean fields
  const apolloClient = initializeApollo();
  const {
    data: { dynamicModels: itemsDynamic },
  } = await apolloClient.query({
    query: ItemFieldsDocument,
  });

  itemsDynamic.forEach(({ name, fields }) => {
    const filterModelField = fields.find(field => field.name === name);
    const boolField =
      filterModelField && filterModelField.type === DynamicFieldType.Boolean;
    if (boolField && !categoryDynamic[name]) {
      categoryDynamic[name] = DEFAULT_CATEGORY_DYNAMIC_FILTER;
    }
    if (filterModelField) {
      categoryDynamic[name] = {
        ...(categoryDynamic[name] || {}),
        type: filterModelField.type,
        sortType: categoryDynamic[name]?.sortType || DynamicFieldType.String,
      };
    }
  });

  return categoryDynamic;
};
