import rootReducer from 'redux/rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { faApiBaseApi } from 'entities/font-awesome-icon';

import { setupListeners } from '@reduxjs/toolkit/dist/query';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([faApiBaseApi.middleware]);
  },
});

setupListeners(store.dispatch);

export default store;
