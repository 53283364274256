import { useEffect, useState } from 'react';
import MobileDetect from 'mobile-detect';

export enum ScreenSizeType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

const getDeviceType = (userAgent: string) => {
  const md = new MobileDetect(userAgent);
  return (
    (md.tablet() && ScreenSizeType.Tablet) ||
    (md.mobile() && ScreenSizeType.Mobile) ||
    ScreenSizeType.Desktop
  );
};

export const useDevice = (userAgent?: string) => {
  const [state, setState] = useState<{
    device: ScreenSizeType;
    isMobile: boolean;
  }>(() => {
    if (!userAgent) {
      return {
        device: ScreenSizeType.Desktop,
        isMobile: false,
      };
    }
    return {} as any;
  });

  useEffect(() => {
    const device = getDeviceType(window.navigator.userAgent);
    setState(prev => ({
      ...prev,
      device,
      isMobile: device !== ScreenSizeType.Desktop,
    }));
  }, []);

  return state;
};
