import React from 'react';
import { FileJpgOutlined } from '@ant-design/icons';
import { ScreenshotGenerate } from 'components/Settings/ScreenShotsGenerate/ScreenshotGenerate';

const screenshotSettings = {
  name: 'screenshot',
  parent: 'Settings',
  pageTitle: 'Screenshots settings',
  globalSearch: false,
  menuIcon: FileJpgOutlined,
  ListComponent: ScreenshotGenerate,
};

export default screenshotSettings;
