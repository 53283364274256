import { initWebWorker } from '../hooks/WebWorkerProvider/initWebWorker';
import { worker as webWorker } from '../hooks/WebWorkerProvider/worker';

export const handleInputChange =
  (setState: any, customKey?: string, isArray?: boolean) =>
  ({ target: { name, value, type } }: any) => {
    const valueFromType = type === 'checkbox' ? name : value;
    setState(prev => {
      const actualName = customKey || name;
      const currentValue = !isArray
        ? valueFromType
        : prev[actualName].includes(valueFromType)
        ? prev[actualName].filter(v => v !== valueFromType)
        : [...prev[actualName], valueFromType];
      return { ...prev, [actualName]: currentValue };
    });
  };

export const toggleValue =
  (setState: any, field: string, value?: boolean) => () => {
    setState(prev => ({ ...prev, [field]: value || !prev[field] }));
  };

export const provideHandler =
  (value: any, handler: (value: any) => any) => () => {
    return handler(value);
  };

let debounceTimer: number | null = null;
export const debounce = (func: Function, time = 250) => {
  return (...args) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      debounceTimer = null;
    }
    debounceTimer = setTimeout(() => func(...args), time) as any;
  };
};
export const clearDebounceTimer = () => {
  clearTimeout(debounceTimer as any);
  debounceTimer = null;
};

export const preventClick = e => {
  e.preventDefault();
  e.stopPropagation();
};

export type PartialFetchOptions = {
  onProcess?: (success: number, failed: { entity: any; msg: string }[]) => void;
  partial?: number;
  timeout?: number;
};
export const partialFetchWorker = initWebWorker(webWorker) as any;
export const partialFetch = async (
  map: any[],
  handler: (data: any) => void,
  options: PartialFetchOptions = {} as PartialFetchOptions
) => {
  let page = 0;
  const { onProcess = () => {}, partial = 1, timeout = 100 } = options;
  const maxPage = Math.ceil(map.length / partial);
  const errors: { entity: any; msg: string }[] = [];
  const result: any[] = [];

  const cancellingRequest = data => {
    if (data.data.type !== 'abort_upload') return;
    page = maxPage + 1;
  };
  if (partialFetchWorker) {
    partialFetchWorker.addEventListener('message', cancellingRequest);
  }

  while (page <= maxPage) {
    let currentEntities;
    try {
      currentEntities = map
        .slice(page * partial, page * partial + partial)
        .map(entity => entity);
      const data = await Promise.all(
        currentEntities.map(entity => {
          return handler(entity);
        })
      );
      result.push(...data);
    } catch (e) {
      errors.unshift(
        ...currentEntities.map(en => ({ entity: en, msg: e.message }))
      );
    } finally {
      await new Promise(res => {
        setTimeout(() => {
          page += 1;
          res({});
        }, timeout);
      });
      onProcess(result.length, errors);
    }
  }

  partialFetchWorker.removeEventListener('message', cancellingRequest);
  return {
    errors,
    result,
  };
};
