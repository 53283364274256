import React, { useCallback, useRef, useState } from 'react';
import { Typography, Calendar } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { toggleValue } from 'utils/handlers';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

type Props = {
  date: string;
  onChange: (date: string) => void;
};

export const BlogPostDate = React.memo(
  ({ date = new Date().toString(), onChange }: Props) => {
    const calendarRef = useRef(null);
    const [state, setState] = useState({
      opened: false,
    });

    const onClose = useCallback(() => {
      setState(prev => ({ ...prev, opened: false }));
    }, []);

    const onDateChange = (date: Moment) => {
      onChange(date.toString());
    };

    useOnClickOutside(calendarRef, onClose);
    return (
      <div className="sideContentItem flex-column">
        <Typography.Title level={5}>Date</Typography.Title>
        <div
          className="flex justify-between"
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <div
            onClick={toggleValue(setState, 'opened')}
            role="presentation"
            className="flex align-center justify-between fluid"
          >
            <Typography.Text>{new Date(date).toDateString()}</Typography.Text>
            <CalendarOutlined />
          </div>
          <div
            ref={calendarRef}
            style={{
              border: '1px solid grey',
              position: 'absolute',
              top: '150%',
              right: 0,
              display: state.opened ? 'block' : 'none',
            }}
          >
            <Calendar fullscreen={false} onSelect={onDateChange} />
          </div>
        </div>
      </div>
    );
  }
);
