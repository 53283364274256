import React from 'react';
import { LoggedUser } from 'nativeComponents/User/LoggedUser';
import { ActiveModelProvider } from 'nativeComponents/ActiveModel/ActiveModel';
import { SideMenu } from '../SideMenu/SideMenu';
import { UIGlobalSearch } from '../UI';
import { Routes } from '../../routes';

import s from './styles.module.css';

export const Main = () => {
  return (
    <ActiveModelProvider>
      <div className="flex">
        <SideMenu />
        <div className={s.content}>
          <div className={s.search}>
            <UIGlobalSearch />
            <LoggedUser />
          </div>
          <Routes />
        </div>
      </div>
    </ActiveModelProvider>
  );
};
