import React from 'react';
import { Typography } from 'antd';
import { ProsAndConsContainer } from 'components/Common/ProsAndCons/ProsAndConsContainer';

export const ItemEditProsCons = ({ pros, cons, onChange }) => {
  return (
    <div className="editTitle">
      <Typography.Title level={5}>Pros & Cons</Typography.Title>
      <ProsAndConsContainer content={{ pros, cons }} changeContent={onChange} />
    </div>
  );
};
