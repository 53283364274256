import React, { useState } from 'react';
import { Button, Typography, Modal, Input, List, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useSubmitToolApproveMutation } from 'graphql/types';
import { useRouter } from 'hooks/useRouter';

import s from './styles.module.scss';

export const ItemApproveTool = () => {
  const { query, push } = useRouter();
  const [approveTool, { loading }] = useSubmitToolApproveMutation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [reasons, setReasons] = useState<{ id: number; title: string }[]>([]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setReasons([]);
  };

  const addComment = () => {
    setReasons(prev => [
      ...prev,
      {
        id: new Date().getTime(),
        title: comment,
      },
    ]);
    setComment('');
  };

  const deleteComment = id => {
    setReasons(reasons.filter(i => i.id !== id));
  };

  const sendApproveTool = async (value: boolean) => {
    await approveTool({
      variables: {
        id: query.id,
        reasons: reasons.map(({ title }) => title),
        value,
      },
    })
      .then(res => {
        setIsModalOpen(false);
        setReasons([]);
        if (!res.data?.submitToolApprove) {
          message.info('Tool was successfully reject');
        } else {
          push(`/item/edit?id=${res.data?.submitToolApprove}`);
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  return (
    <div className={s.approve}>
      <Typography.Title level={5}>Approve new tool</Typography.Title>
      <div className={s.approveAction}>
        <Button
          type="primary"
          loading={loading}
          onClick={() => sendApproveTool(true)}
        >
          Yes
        </Button>
        <Button danger onClick={() => setIsModalOpen(true)}>
          No
        </Button>
      </div>
      <Modal
        title="ADD COMMENTS TO THE LETTER"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <>
            <Button
              loading={loading}
              disabled={!reasons.length}
              type="primary"
              onClick={() => sendApproveTool(false)}
            >
              Reject
            </Button>
          </>
        }
      >
        <List
          dataSource={reasons}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <DeleteOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteComment(item.id)}
                />,
              ]}
            >
              <Typography.Text>{index + 1})</Typography.Text> {item.title}
            </List.Item>
          )}
        />
        <Input
          value={comment}
          onChange={e => setComment(e.target.value)}
          placeholder="Comment"
          style={{ marginTop: 10 }}
        />
        <Button
          style={{ marginTop: 10 }}
          disabled={!comment.length}
          onClick={addComment}
        >
          Add a comment
        </Button>
      </Modal>
    </div>
  );
};
