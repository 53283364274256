import { AppstoreOutlined } from '@ant-design/icons';
import { Analytics } from 'components/Analytics/Analytics';

const analyticsResource = {
  name: 'dashboard',
  pageTitle: 'Dashboard',
  menuIcon: AppstoreOutlined,
  ListComponent: Analytics,
};

export default analyticsResource;
