import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { menu as menuItems } from 'nativeComponents/menu';
import { useActiveModel } from 'nativeComponents/ActiveModel/ActiveModel';
import { capitalFirst } from 'utils/string';
import { RebuildContext, RebuildProvider } from 'utils/rebuildProvider';
import { RebuildStatus } from 'graphql/types';
import { SideMenuHead } from './SideMenuHead';
import { getGroupItems, IGNORE_REDIRECT_PATHNAMES } from './helpers';
import BuildInfo from 'nativeComponents/SideMenu/BuildInfo';

import s from './styles.module.css';

const { Sider } = Layout;

export const SideMenu = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { activeModels } = useActiveModel();
  const [currentMenu, setCurrentMenu] = useState<typeof menuItems>([]);
  useEffect(() => {
    const exclude = Object.entries(activeModels)
      .filter(([_, value]) => !value)
      .map(([key]) => key);
    const currentModels = menuItems.filter(
      (item: any) =>
        !exclude.includes(item.name.toLowerCase()) &&
        !exclude.includes((item.parent || '').toLowerCase())
    );
    setCurrentMenu(currentModels);
  }, [activeModels]);

  const defaultMenu: any =
    currentMenu.find(({ name }) => pathname.indexOf(name) !== -1) || {};

  if (
    !defaultMenu.name &&
    !IGNORE_REDIRECT_PATHNAMES.includes(pathname) &&
    currentMenu[0]
  ) {
    history.replace(`/${currentMenu[0].name}`);
  }

  const menu = getGroupItems(currentMenu);

  return (
    <div className={`${s.sideMenu} hideScroll`} style={{ zIndex: 5 }}>
      <Sider collapsed={false}>
        <SideMenuHead />
        <Menu
          selectedKeys={[defaultMenu.name || currentMenu[0]?.name]}
          mode="inline"
        >
          {menu.map(item => {
            const { name, MenuIcon, href, pageTitle, subItems, parent } = item;
            if (subItems) {
              return (
                <Menu.SubMenu
                  key={parent}
                  title={parent}
                  className={s.subMenuItem}
                  icon={<MenuIcon />}
                >
                  {subItems.map(({ name, href }) => (
                    <Menu.Item key={name}>
                      <Link to={href}>{capitalFirst(name)}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item key={name} icon={<MenuIcon />} className={s.menuItem}>
                <Link to={href}>{pageTitle}</Link>
              </Menu.Item>
            );
          })}
          <Menu.Item>
            <RebuildProvider>
              <RebuildContext.Consumer>
                {({ state, run, getButtonText }) => (
                  <Button
                    onClick={run}
                    disabled={
                      state === null ||
                      state === RebuildStatus.Running ||
                      state === RebuildStatus.Pending
                    }
                    loading={
                      state === RebuildStatus.Running ||
                      state === RebuildStatus.Pending
                    }
                    type="primary"
                  >
                    {getButtonText()}
                  </Button>
                )}
              </RebuildContext.Consumer>
            </RebuildProvider>
          </Menu.Item>

		<BuildInfo/>

        </Menu>
      </Sider>
    </div>
  );
};

