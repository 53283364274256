import { SortBy, SortDirection } from 'graphql/types';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export const sort = [
  {
    name: 'Product title',
    value: SortBy.Title,
    variants: [
      {
        icon: ArrowUpOutlined,
        name: 'A-Z',
        value: SortBy.Title,
        direction: SortDirection.Asc,
      },
      {
        icon: ArrowDownOutlined,
        name: 'Z-A',
        value: SortBy.Title,
        direction: SortDirection.Desc,
      },
    ],
  },
  {
    name: 'Created',
    value: SortBy.CreationDate,
    variants: [
      {
        icon: ArrowUpOutlined,
        name: 'Newest first',
        direction: SortDirection.Desc,
      },
      {
        icon: ArrowDownOutlined,
        name: 'Oldest first',
        direction: SortDirection.Asc,
      },
    ],
  },
  {
    name: 'Updated',
    value: SortBy.UpdatedDate,
    variants: [
      {
        icon: ArrowUpOutlined,
        name: 'Newest first',
        value: SortBy.UpdatedDate,
        direction: SortDirection.Desc,
      },
      {
        icon: ArrowDownOutlined,
        name: 'Oldest first',
        value: SortBy.UpdatedDate,
        direction: SortDirection.Asc,
      },
    ],
  },
];
