import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FamilyStyle } from '../types';

type Transform = {
  fromNamePrefix: (arg: { name: IconName; prefix: IconPrefix }) => {
    id: IconName;
    meta: FamilyStyle;
  };
  toNamePrefix: (arg: { id: IconName; meta: FamilyStyle }) => {
    name: IconName;
    prefix: IconPrefix;
  };
};
export const transform: Transform = {
  fromNamePrefix: ({ name: id, prefix }) => {
    switch (prefix) {
      case 'fab':
        return { id, meta: { family: 'classic', style: 'brands' } };
      case 'fad':
        return { id, meta: { family: 'classic', style: 'duotone' } };
      case 'fal':
        return { id, meta: { family: 'classic', style: 'light' } };
      case 'far':
        return { id, meta: { family: 'classic', style: 'regular' } };
      case 'fas':
        return { id, meta: { family: 'classic', style: 'solid' } };
      case 'fass':
        return { id, meta: { family: 'sharp', style: 'solid' } };
      case 'fat':
        return { id, meta: { family: 'classic', style: 'thin' } };
      default:
        throw new Error('Unknown prefix for icon');
    }
  },
  toNamePrefix: ({ id: name, meta: { family, style } }) => {
    if (family === 'sharp') {
      return { name, prefix: 'fass' };
    } else {
      switch (style) {
        case 'brands':
          return { name, prefix: 'fab' };
        case 'duotone':
          return { name, prefix: 'fad' };
        case 'light':
          return { name, prefix: 'fal' };
        case 'regular':
          return { name, prefix: 'far' };
        case 'solid':
          return { name, prefix: 'fas' };
        case 'thin':
          return { name, prefix: 'fat' };
        default:
          return { name, prefix: 'far' };
      }
    }
  }
};
