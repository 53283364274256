import {
  IconName,
  IconFamily,
  IconStyle,
  IconPrefix
} from '@fortawesome/fontawesome-svg-core';

export const getIconPrefix = (arg: {
  name: IconName;
  style?: IconStyle;
  family?: IconFamily;
}): Undefinable<IconPrefix> => {
  const { family, style } = arg;
  switch (true) {
    case family === 'sharp':
      return 'fass';
    case style === 'brands':
      return 'fab';
    case style === 'duotone' || family === 'duotone':
      return 'fad';
    case style === 'light':
      return 'fal';
    case style === 'regular':
      return 'far';
    case style === 'solid':
      return 'fas';
    case style === 'thin':
      return 'fat';
    default:
      return undefined;
  }
};
