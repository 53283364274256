import classnames from 'classnames';
import s from './styles.module.scss';
import { Checkbox } from 'antd';
import React from 'react';
import { useCSV } from 'components/Settings/CSV/CSVProvider/CSVProvider';
import { constructNewItem } from './helpers';
import { CompareCategory } from '../../Settings/CSV/CSVProvider/types';
import { getPlainString } from '../../../utils/string';

type Props = {
  item: CompareCategory;
  disabled?: boolean;
};

export const CSVConflictItem = ({ item, disabled }: Props) => {
  const { onChooseItem, chosen } = useCSV();

  const onChoose = () => {
    onChooseItem(constructNewItem(item) as CompareCategory);
  };

  const isChosen = !!chosen[getPlainString(item.title)];
  return (
    <div className={s.compareItem}>
      <Checkbox checked={isChosen} onChange={onChoose} disabled={disabled}>
        <div
          className={classnames({
            [s.compareElem]: true,
          })}
        >
          <span className={s.itemTitle}>{item.title}</span>
        </div>
      </Checkbox>
    </div>
  );
};
