import React, { createContext, FC, useEffect, useState } from 'react';
import { message } from 'antd';
import {
  RebuildStatus,
  useCheckRebuildStatusLazyQuery,
  useRebuildRunMutation,
} from '../graphql/types';

type ContextData = {
  state: RebuildStatus | null;
  run: () => void;
  getButtonText: () => string;
};

export const RebuildContext = createContext<ContextData>({
  state: null,
  run: () => null,
  getButtonText: () => 'Rebuild pages',
});

export const RebuildProvider: FC = ({ children }) => {
  const [state, setState] = useState<RebuildStatus | null>(null);
  let timeoutId: number | null = null;

  const deInit = () => {
    if (timeoutId) {
      clearInterval(timeoutId);
      timeoutId = null;
    }
  };

  const [checkStatus] = useCheckRebuildStatusLazyQuery({
    onCompleted: data => {
      if (
        data.staticRebuildStatus === RebuildStatus.Success ||
        data.staticRebuildStatus === RebuildStatus.Failed ||
        data.staticRebuildStatus === RebuildStatus.Canceled
      )
        deInit();
      if (data.staticRebuildStatus === RebuildStatus.Failed) {
        message.error('Last rebuild has completed with status "Failed"');
      }
      if (data.staticRebuildStatus === RebuildStatus.Canceled) {
        message.error('Last rebuild has canceled.');
      }
      setState(data.staticRebuildStatus || null);
    },
    onError: error => {
      return message.error(error?.message);
    },
    fetchPolicy: 'no-cache',
  });

  const [rebuild] = useRebuildRunMutation({
    onCompleted: data => {
      if (data.staticRebuildRun) {
        timeoutId = setInterval(checkStatus, 3000);
      }
    },
    onError: error => {
      return message.error(error?.message);
    },
  });

  const run = async () => {
    setState(RebuildStatus.Running);
    await rebuild();
  };

  const getButtonText = (): string => {
    switch (state) {
      case RebuildStatus.Pending:
        return 'In the queue';
      case RebuildStatus.Running:
        return 'Running';
      case RebuildStatus.Failed:
        return 'Failed. Try rebuild again';
      case RebuildStatus.Canceled:
        return 'Canceled. Rebuild again';
      default:
        return 'Rebuild pages';
    }
  };

  useEffect(() => {
    checkStatus();
    return deInit;
  }, []);

  return (
    <RebuildContext.Provider value={{ state, run, getButtonText }}>
      {children}
    </RebuildContext.Provider>
  );
};
