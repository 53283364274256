import { Typography } from 'antd';
import { ItemReviewList } from './ItemReviews/ItemReviewList';
import React from 'react';

export const ItemEditReviews = ({ reviews, onChange, onSave }) => {
  return (
    <div className="editTitle">
      <Typography.Title level={5}>Reviews</Typography.Title>
      <ItemReviewList reviews={reviews} onChange={onChange} onSave={onSave} />
    </div>
  );
};
