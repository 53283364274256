import React, {
  ChangeEvent,
  DragEvent as DE,
  useEffect,
  useRef,
  useState,
} from 'react';
import _get from 'lodash/get';
import { Button, message } from 'antd';
import {
  CameraOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { ImageSizes, ReactCropTool } from 'components/CropTool';
import {
  ParentType,
  Picture,
  useGetImageOriginUrlLazyQuery,
  useUploadCropImageMutation,
  useUploadImageMutation,
} from 'graphql/types';
import { useConfig } from 'hooks/ConfigProvider/useConfig';
import { Screenshot } from './Screenshot';
import {
  constructImagesFroCropShow,
  constructUploadImagesWithRetina,
  getNewCroppingImagesList,
  handleSaveImage,
} from './helpers';
import { PicturePreview } from './PicturePreview';
import { PictureOriginalImage } from './PictureOriginalImage';

import './style.scss';
import classnames from 'classnames';
import {getImageValue} from '../../../utils/getData';

interface Props {
  pictures: Array<any>;
  setPictures: (pictures: any) => void;
  updatePictures: (pictures: any) => void;
  siteUrl?: string;
}

export const PicturesLoader = ({
  pictures,
  setPictures,
  siteUrl = '',
  updatePictures,
}: Props) => {
  const config = useConfig();
  const input = useRef<HTMLInputElement>(null) as any;
  const [data, setData] = useState<Picture[]>(pictures);
  const [originalImage, setOriginalImage] = useState<string>('');
  const [popup, setPopup] = useState(false);
  const drag = useRef(null) as any;
  const dragNode = useRef(null) as any;
  const [dragging, setDragging] = useState<boolean>(false);

  const [cropping, setCropping] =
    useState<{
      index: number;
      size: number;
      aspectRatio: number;
      showCropRatio?: number;
      description: string;
      watermark?: boolean;
    } | null>(null);

  const [uploadCropImage, { loading: cropLoading }] =
    useUploadCropImageMutation({
      onError: e => {
        message.error(e.message);
      },
    });

  useEffect(() => {
    setData(pictures)
  }, [pictures])

  const [saveImage, { loading: saveLoading }] = useUploadImageMutation({
    onError: e => {
      message.error(e.message);
    },
  });

  const [getOriginImage, { loading: getOriginLoading }] =
    useGetImageOriginUrlLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ getImageOriginUrl: url }) => {
        setOriginalImage(url);
      },
      onError: e => {
        message.error(e.message);
      },
    });

  const uploadHandler = async (
    imageData: ImageSizes,
    onComplete: () => void = () => {}
  ) => {
    try {
      const { x, y, width, height } = imageData;
      const positions = {
        x: Math.round(x),
        y: Math.round(y),
        width: Math.round(width),
        height: Math.round(height),
      };
      const cropSizes = config.screenshot?.oneByOneCrop
        ? [
            {
              width: cropping!.size,
              aspectRatio: cropping!.aspectRatio,
              parentType: ParentType.Item,
            },
          ]
        : config.screenshot?.sizes;

      const { data } = await uploadCropImage({
        variables: {
          input: {
            imageUrl: originalImage,
            sizes: constructUploadImagesWithRetina(cropSizes),
            positions,
          },
        },
      });
      const newList = getNewCroppingImagesList(
        pictures,
        data?.uploadCropImage as Picture,
        cropping as any,
        positions,
        config.screenshot
      );
      setPictures(newList);
      setData(newList);
      setCropping(null);
      onComplete();
    } catch (e) {
      console.error(e);
    }
  };

  const upload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files && e.target.files[0];
      const images = await handleSaveImage(
        saveImage,
        file,
        config.screenshot?.sizes
      );
      updatePictures(images);
      setData([...data, images]);
      message.success('Successfully uploaded!');
    } catch (e: any) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    document.addEventListener('dragover', documentDragPreventDefault);
    return () => {
      document.removeEventListener('dragover', documentDragPreventDefault);
    };
  }, [pictures]);

  const documentDragPreventDefault = (e: DragEvent) => {
    e.preventDefault();
  };

  const handleDragStart = (e: DE<HTMLDivElement>, index: number) => {
    drag.current = index;
    dragNode.current = e.target;
    dragNode.current.addEventListener('dragend', handleDragEnd);
    setTimeout(() => {
      setDragging(true);
    }, 0);
  };

  const handleDragEnd = (e: DE<HTMLDivElement>) => {
    dragNode.current?.removeEventListener('dragend', handleDragEnd);
    drag.current = null;
    dragNode.current = null;
    setDragging(false);
  };

  const deleteItem = (index: number) => {
    const copy = [...pictures];
    copy.splice(index, 1);
    setPictures(copy);
    setData(copy);
  };

  const handleDragEnter = (e: DE<HTMLDivElement>, index: number) => {
    if (e.target === dragNode.current) return;
    const newList: Array<Picture> = JSON.parse(JSON.stringify(data));
    newList.splice(index, 0, newList.splice(drag.current!, 1)[0]);
    drag.current = index;
    setData(newList);
    setPictures(newList);
  };

  const getOriginalImage = async (cropIndex: number) => {
    if (!data.length) return;
    setOriginalImage('');
    const imageID = pictures[cropIndex].id;
    if (imageID == 0) {
      const imageValue = (
        pictures[cropIndex].Sizes.find(({ size }) => size == 0) || {}
      ).value;
      setOriginalImage(imageValue);
      return;
    }

    try {
      await getOriginImage({
        variables: {
          imageID,
        }
      });

    } catch (e: any) {
      message.error(e.message);
    }
  };

  const handleClickToCropImage =
    index =>
    ({
      size,
      aspectRatio,
      showCropRatio,
    }: {
      size: number;
      aspectRatio: number;
      showCropRatio?: number;
    }) => {
      setCropping({
        index,
        size,
        aspectRatio,
        showCropRatio,
        description: `Width: ${size}, aspect ratio ${
          showCropRatio || aspectRatio
        }`,
      });
    };

  const onLoadScreenshot = (screenshot: Picture) => {
    updatePictures(screenshot);
    setData([...data, screenshot]);
  };

  useEffect(() => {
    document.body.style.overflow = cropping ? 'hidden' : 'visible';
    if (cropping) {
      getOriginalImage(cropping!.index);
    }
  }, [cropping]);

  const loading = getOriginLoading || cropLoading || saveLoading;
  const lockCropSize = (config.screenshot.sizes.find(({ lock }) => lock) || {})
    .width;

  return (
    <div
      style={{
        marginTop: 40,
      }}
    >
      <div>
        <Button
          loading={loading}
          onClick={() => input.current?.click()}
          htmlType="button"
          icon={<UploadOutlined />}
          type="primary"
        >
          Upload
        </Button>
        <span style={{ margin: '0 8px', color: 'var(--color-grey)' }}>
          - or -
        </span>
        <Button
          onClick={() => setPopup(true)}
          htmlType="button"
          icon={<CameraOutlined />}
          type="primary"
        >
          Screenshot
        </Button>
      </div>
      {!!pictures?.length && (
        <p style={{ margin: '8px 0', fontWeight: 'lighter', color: 'grey' }}>
          Double-click on the thumbnail to open the crop tool.
        </p>
      )}
      <div
        onDrop={e => {
          e.preventDefault();
          setPictures(data);
        }}
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        {data.map((item, index) => {
          const { screenshots, meta } = constructImagesFroCropShow(
            config.screenshot,
            item
          );

          return (
            <div
              draggable={loading}
              className={classnames({
                ['pictures__item']: true,
                fluid: config.screenshot.oneByOneCrop,
                current: drag.current === index,
              })}
              // @ts-ignore
              onDragStart={loading ? null : e => handleDragStart(e, index)}
              // @ts-ignore
              onDragEnter={dragging ? e => handleDragEnter(e, index) : null}
              key={index}
            >
              <Button
                onClick={() => {
                  deleteItem(index);
                }}
                danger={true}
                icon={<DeleteOutlined style={{ fontSize: '12px' }} />}
                className="delete-picture"
              />
              <PictureOriginalImage
                src={_get(item, 'Sizes[0].value', '')}
                aspectRatio={meta.aspectRatio}
                showCropRatio={meta.showCropRatio}
                size={meta.width}
                preview={getImageValue(item, meta.width)}
                disabled={config.screenshot.oneByOneCrop}
                onCrop={handleClickToCropImage(index)}
                positions={item.positions}
              />
              {!!screenshots.length && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  {screenshots.map(
                    (
                      {
                        width: size,
                        aspectRatio,
                        disabledToEdit,
                        showCropRatio,
                      },
                      idx
                    ) => {
                      return (
                        <PicturePreview
                          key={idx}
                          index={index}
                          sizes={item.Sizes}
                          onCrop={handleClickToCropImage(index)}
                          aspectRatio={aspectRatio}
                          showCropRatio={showCropRatio}
                          size={size}
                          disabled={disabledToEdit}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <input
        onChange={upload}
        multiple
        accept="image/*"
        ref={input}
        type="file"
        hidden
      />
      {cropping && originalImage && (
        <ReactCropTool
          aspectRatio={cropping.aspectRatio}
          showCropRatio={cropping.showCropRatio}
          description={cropping.description}
          close={() => {
            setCropping(null);
          }}
          src={originalImage}
          lockSize={lockCropSize}
          uploadHandler={uploadHandler}
        />
      )}
      <Screenshot
        onFinish={onLoadScreenshot}
        siteUrl={siteUrl}
        visible={popup}
        onClose={() => setPopup(false)}
      />
    </div>
  );
};
