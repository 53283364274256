import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { message } from 'antd';
import _uniq from 'lodash/uniq';
import { DragProvider } from 'hooks/DragProvider/DragProvider';
import {
  findSliceIndexes,
  getReconstructList,
} from 'hooks/DragProvider/helpers';
import { DragItem } from 'hooks/DragProvider/DragItem';
import {
  Category,
  useSaveSliderCategoriesMutation,
  useSliderCategoriesLazyQuery,
} from 'graphql/types';
import { getIds } from 'utils/getData';
import { SliderSettingsItem } from './SliderSettingsItem';

import s from './style.module.css';

export const SliderSettings = () => {
  const [state, setState] = useState({
    categories: [] as Category[],
    pending: false,
  });
  const categoriesRef = useRef<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const sliderContainer = useRef<HTMLDivElement>(null);
  const sliderContent = useRef<HTMLDivElement>(null);
  const timer = useRef<any>(null);

  const [fetchCategories, { loading: fetchLoading }] =
    useSliderCategoriesLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ sliderCategories }) => {
        setState(prev => ({
          ...prev,
          categories: sliderCategories as Category[],
        }));
        categoriesRef.current = sliderCategories;
      },
      onError: e => {
        message.error(e.message);
        throw e;
      },
    });

  const [saveSortedCategories, { loading: saveLoading }] =
    useSaveSliderCategoriesMutation({
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        message.info('Success saved!');
      },
      onError: e => {
        message.error(e.message);
        throw e;
      },
    });

  const onDrop = async ({
    target,
    dropTarget,
    targetIndex,
    dropIndex,
  }: any) => {
    try {
      setState(prev => ({ ...prev, pending: true }));

      let newIndex = dropIndex;
      let prevIndex = targetIndex;
      if (newIndex === undefined || prevIndex === undefined) {
        const { targetIndex: pi, dropIndex: ni } = findSliceIndexes(
          { target, dropTarget },
          categoriesRef.current
        );
        newIndex = ni;
        prevIndex = pi;
      }

      const newList = getReconstructList(
        newIndex,
        prevIndex,
        categoriesRef.current
      );

      await saveSortedCategories({
        variables: {
          ids: _uniq(getIds(newList) as string[]),
        },
      });

      setState(prev => ({ ...prev, categories: newList, pending: false }));
      categoriesRef.current = newList;
    } catch (e) {
      setState(prev => ({ ...prev, pending: false }));
      message.error(e.message);
    }
  };

  const handleScroll = (clientX, handleCalculatePositions) => {
    const { left, right } = sliderContainer.current!.getBoundingClientRect();
    const leftEdge = clientX - left;
    const rightEdge = right - clientX;

    clearInterval(timer.current);
    timer.current = null;

    if (leftEdge > 50 && rightEdge > 50) {
      return;
    }

    timer.current = setInterval(() => {
      if (leftEdge < 50) {
        sliderContainer.current!.scrollBy(-10, 0);
      } else if (rightEdge < 50) {
        sliderContainer.current!.scrollBy(10, 0);
      }
      handleCalculatePositions();
    }, 10);
  };

  const onMouseMove = (clientX, _, handleCalculatePositions) => {
    handleScroll(clientX, handleCalculatePositions);
  };

  const handleChange = (newIndex, prevIndex) => {
    onDrop({ targetIndex: newIndex, dropIndex: prevIndex });
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  /* eslint-disable */
  const loading = fetchLoading || saveLoading || state.pending;
  return (
    <DragProvider onDrop={onDrop} onMouseMove={onMouseMove}>
      <div
        className={classnames('pa-8 fluid', {
          pending: loading,
        })}
      >
        <h1>Slider settings</h1>
        <div className={s.sliderContainer} ref={sliderContainer}>
          <div className={s.sliderContent} ref={sliderContent}>
            {state.categories.map((category, i) => (
              <DragItem
                dataId={category.slug}
                key={category.slug}
                orientation="horizontal"
                disabledPrevent
                className={s.dragItem}
              >
                {({ onMouseDown, onMouseUp }) => {
                  return (
                    <SliderSettingsItem
                      onMouseDown={onMouseDown}
                      onMouseUp={onMouseUp}
                      onChange={handleChange}
                      title={category.title}
                      index={i + 1}
                    />
                  );
                }}
              </DragItem>
            ))}
          </div>
        </div>
      </div>
    </DragProvider>
  );
};
