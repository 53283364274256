import React from 'react';
import { FileJpgOutlined } from '@ant-design/icons';
import { CropSettings } from 'components/Settings/CropSettings/CropSettings';

const cropSettings = {
  name: 'crop',
  parent: 'Settings',
  pageTitle: 'Crop rebuilder',
  globalSearch: false,
  menuIcon: FileJpgOutlined,
  ListComponent: CropSettings,
};

export default cropSettings;
