import { Typography, Tabs } from 'antd';
import { ScreenshotSettings } from './ScreenshotSettings';

const { Title } = Typography;

export const ProjectSettings = () => {
  return (
    <div className="fluid">
      <Title level={1}>Project settings</Title>
      <div className="card-container">
        <Tabs type="card">
          <Tabs.TabPane key="screenshot" tab="Screenshot">
            <ScreenshotSettings />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
