import { BlogPost } from 'graphql/types';

export const constructState = (props: BlogPost) => {
  const {
    thumb,
    title,
    content,
    slug,
    shortDescription,
    categories,
    dynamic,
    date,
    labels,
  } = props;
  
  const transformedLabels = labels?.map(label => ({
    id: label.id,
    title: label.name,
  })) || [];
  
  return {
    thumb: thumb || null,
    title: title || '',
    content: content || '',
    slug: slug || '',
    shortDescription: shortDescription || '',
    categories: categories || [],
    dynamic: dynamic || [],
    date,
    labels: transformedLabels || [],
  };
};
