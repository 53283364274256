const Link = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6V11.4C12 11.5591 11.9368 11.7117 11.8243 11.8243C11.7117 11.9368 11.5591 12 11.4 12H0.6C0.44087 12 0.288258 11.9368 0.175736 11.8243C0.0632141 11.7117 0 11.5591 0 11.4V0.6C0 0.44087 0.0632141 0.288258 0.175736 0.175736C0.288258 0.0632141 0.44087 0 0.6 0H6C6.15913 0 6.31174 0.0632141 6.42426 0.175736C6.53679 0.288258 6.6 0.44087 6.6 0.6C6.6 0.75913 6.53679 0.911742 6.42426 1.02426C6.31174 1.13679 6.15913 1.2 6 1.2H1.2V10.8H10.8V6C10.8 5.84087 10.8632 5.68826 10.9757 5.57574C11.0883 5.46321 11.2409 5.4 11.4 5.4C11.5591 5.4 11.7117 5.46321 11.8243 5.57574C11.9368 5.68826 12 5.84087 12 6ZM11.6292 0.0461999C11.5566 0.0157826 11.4787 7.90996e-05 11.4 0H8.4C8.24087 0 8.08826 0.0632141 7.97574 0.175736C7.86321 0.288258 7.8 0.44087 7.8 0.6C7.8 0.75913 7.86321 0.911742 7.97574 1.02426C8.08826 1.13679 8.24087 1.2 8.4 1.2H9.9516L5.5758 5.5758C5.51849 5.63115 5.47278 5.69736 5.44134 5.77056C5.40989 5.84376 5.39334 5.92249 5.39265 6.00216C5.39196 6.08183 5.40714 6.16083 5.43731 6.23457C5.46748 6.30831 5.51203 6.3753 5.56836 6.43164C5.6247 6.48797 5.69169 6.53252 5.76543 6.56269C5.83917 6.59286 5.91817 6.60804 5.99784 6.60735C6.07751 6.60666 6.15624 6.59011 6.22944 6.55866C6.30265 6.52722 6.36885 6.48151 6.4242 6.4242L10.8 2.0484V3.6C10.8 3.75913 10.8632 3.91174 10.9757 4.02426C11.0883 4.13679 11.2409 4.2 11.4 4.2C11.5591 4.2 11.7117 4.13679 11.8243 4.02426C11.9368 3.91174 12 3.75913 12 3.6V0.6C11.9996 0.521325 11.9839 0.443477 11.9538 0.3708C11.8929 0.22385 11.7762 0.107094 11.6292 0.0461999Z"
      fill="#0F5EF7"
    />
  </svg>
);

export default Link;
