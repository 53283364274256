import {
  CreateUserDocument,
  GetCurrentUserDocument,
  SignInDocument,
  UserInput,
} from 'graphql/types';
import { initializeApollo } from 'utils/apollo';

const client = initializeApollo();

export const createUser = async (data: UserInput) => {
  const {
    data: { createUser: res },
  } = await client.mutate({
    mutation: CreateUserDocument,
    variables: {
      input: {
        email: data.email,
        password: data.password,
      },
    },
  });
  return res;
};

export const getCurrentUser = async () => {
  const {
    data: { me },
  } = await client.mutate({
    mutation: GetCurrentUserDocument,
    fetchPolicy: 'no-cache',
  });
  return me;
};

// export async function getUserSettings() {
//   const {
//     data: { defaultSizeSettings },
//   } = await client.query<SettingsQuery, SettingsQueryVariables>({
//     query: SettingsDocument,
//     fetchPolicy: 'no-cache',
//   });
//   return defaultSizeSettings;
// }
// export const updateUserSettings = async (sizes: SizeDefaultSettings[]) => {
//   const { data } = await client.mutate<
//     SaveSettingsMutation,
//     SaveSettingsMutationVariables
//   >({
//     mutation: SaveSettingsDocument,
//     fetchPolicy: 'no-cache',
//     variables: {
//       sizes,
//     },
//   });
//   return data;
// };

export const authUser = async ({ email, password }: UserInput) => {
  const {
    data: {
      signIn: { me, token },
    },
  } = await client.mutate({
    mutation: SignInDocument,
    fetchPolicy: 'no-cache',
    variables: {
      email,
      password,
    },
  });

  return { ...me, token };
};
