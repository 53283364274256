const AngleRight = () => (
  <svg
    style={{
      width: '1.25em',
      height: '3.25em',
    }}
    viewBox="0 0 20 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L18 26L2 50"
      stroke="#FF6456"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AngleRight;
