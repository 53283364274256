import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useResource } from 'hooks/useResource';
import { Input, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { preventClick } from 'utils/events';
import { clearDebounceTimer, debounce } from 'utils/handlers';
import { initializeApollo } from 'utils/apollo';
import * as gfql from 'graphql/types';
import { ItemsList } from './ItemsList';

import s from './styles.module.css';

export const GLOBAL_SEARCH_LIMIT = 5;

const DEFAULT_STATE = {
  value: '',
  items: {
    items: [] as any[],
    count: 0,
  },
  categories: {
    items: [] as any[],
    count: 0,
  },
  skills: {
    items: [] as any[],
    count: 0,
  },
  posts: {
    items: [] as any[],
    count: 0,
  },
  loading: '',
};

export const UIGlobalSearch: React.FC = () => {
  const apolloClient = useRef(initializeApollo());
  const [state, setState] = useState(DEFAULT_STATE);
  const { pathname } = useLocation();

  const [fetchSearch, { loading }] = gfql.useGlobalSearchQueryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({
      items: { data: items, count: itemsCount },
      categories: { data: categories, count: categoriesCount },
      skills: { data: skills, count: skillsCount },
      blogPosts: { data: posts, count: postsCount },
    }) => {
      setState(prev => ({
        ...prev,
        items: { items, count: itemsCount },
        categories: { items: categories, count: categoriesCount },
        skills: { items: skills, count: skillsCount },
        posts: { items: posts, count: postsCount },
      }));
    },
    onError: e => {
      message.error(e.message);
    },
  });

  const handleChange = ({ target: { value: searchValue } }) => {
    setState(prev => ({ ...prev, value: searchValue }));
  };

  const onLoadMore = async (entity: string, document: string) => {
    try {
      setState(prev => ({ ...prev, loading: entity }));
      const nextPage =
        Math.ceil(state[entity].items.length / GLOBAL_SEARCH_LIMIT) + 1;
      const { data } = await apolloClient.current.query({
        fetchPolicy: 'no-cache',
        query: gfql[document],
        variables: {
          search: state.value,
          page: nextPage,
          limit: GLOBAL_SEARCH_LIMIT,
        },
      });

      const firstKey = Object.keys(data)[0];
      setState(prev => ({
        ...prev,
        [entity]: {
          ...prev[entity],
          items: [...prev[entity].items, ...data[firstKey].data],
        },
        loading: '',
      }));
    } catch (e) {
      message.error(e.message);
      setState(prev => ({ ...prev, loading: '' }));
    }
  };

  const onDropList = () => {
    clearDebounceTimer();
    setState(DEFAULT_STATE);
  };

  const debounceFetch = debounce(fetchSearch, 400);

  useEffect(() => {
    if (!state.value) {
      onDropList();
      return;
    }
    debounceFetch({
      variables: {
        search: state.value,
        page: 1,
        limit: GLOBAL_SEARCH_LIMIT,
      },
    });
  }, [state.value]);

  useEffect(() => {
    setState(prev => ({ ...prev, value: '', data: [] }));
  }, [pathname]);

  const { items, categories, skills, posts } = state;
  const isHaveItems = [items, categories, skills, posts].find(
    ({ items }) => !!items.length
  );
  return (
    <div
      className="flex-grow"
      style={{
        position: 'relative',
      }}
    >
      <Input
        placeholder="Search everywhere..."
        value={state.value}
        onChange={handleChange}
        onClick={preventClick}
        className={s.globalSearch}
        disabled={!!state.loading}
        prefix={
          <SearchOutlined
            style={{
              marginRight: 'var(--space-1)',
              color: 'var(--color-grey)',
            }}
          />
        }
      />
      {isHaveItems && (
        <ItemsList
          items={items}
          categories={categories}
          skills={skills}
          posts={posts}
          onLoadMore={onLoadMore}
          onDropList={onDropList}
          loading={state.loading}
        />
      )}
    </div>
  );
};
