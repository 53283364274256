import React from 'react';
import { FileJpgOutlined } from '@ant-design/icons';
import { IconsGenerate } from 'components/Settings/IconsGenerate/IconsGenerate';

const iconsSettings = {
  name: 'favicons',
  parent: 'Settings',
  pageTitle: 'Icons settings',
  globalSearch: false,
  menuIcon: FileJpgOutlined,
  ListComponent: IconsGenerate,
};

export default iconsSettings;
